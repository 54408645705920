import "./index.scss";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState, useMemo } from "react";
import bond from "../../../apis/bond";
import HeaderTitle from "../../../components/HeaderTitle";
import {
  VerticalAlignBottomOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  addMillimeter,
  debounce,
  getLicenseValid,
  saveFile,
} from "../../../unit/unit";
import { useGetState } from "ahooks";
import MySpin from "../../../components/MySpin";
import Scrollbars from "react-custom-scrollbars";
import {
  Pagination,
  message,
  Popover,
  Typography,
  Tag,
  Checkbox,
  Select,
  Tooltip,
  Switch,
  DatePicker,
} from "antd";
import bg from "../../../assets/imgBg.png";
import MyEmpty from "../../../components/Empty";
import { useSize } from "ahooks";
import DetailsDrawer from "../../../components/DetailsDrawer";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";

const { Option } = Select;
const { Paragraph } = Typography;

const Card = (props) => {
  const { info, colorMap, onClick } = props;

  const initDomItem = (showFiledObj, eventInfo) => {
    let list = Object.keys(showFiledObj)
      ?.map((key) => ({
        key: key,
        ...(showFiledObj[key] || {}),
      }))
      ?.sort((a, b) => a?.index - b?.index);

    return (
      <>
        {list?.map((item) => {
          //objectArray格式数据
          if (item.is_object_array) {
            let list = eventInfo[item?.key] || [];
            let str = "";
            list?.forEach((_, index) => {
              if (index) {
                str += item?.is_join;
              }
              str += _[item?.get_field];
            });
            return (
              <div
                className="domItemBox"
                key={item.key}
                style={{ width: (item.width ? item.width : 50) + "%" }}
              >
                <span className="labelSpan">{item.name}</span>
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: {
                      title: <div className="eventTypeParagraphTip">{str}</div>,
                      overlayClassName: "eventTypeParagraphTooltip",
                    },
                  }}
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  {str}
                </Paragraph>
              </div>
            );
          }
          //判断没有值则不显示
          if (item.not_empty_field === "sec_type") {
            if (!eventInfo[item?.key]) {
              return <></>;
            }
          }
          //正常数据
          return (
            <div
              className="domItemBox"
              key={item.key}
              style={{
                width: `calc(${(item.width ? item.width : 50) + "%"} - 5px)`,
              }}
            >
              <span className="labelSpan">{item.name}</span>
              <Paragraph
                ellipsis={{
                  rows: item.is_simple_show ? 2 : 1,
                  tooltip: {
                    title: (
                      <div className="eventTypeParagraphTip">
                        {eventInfo[item?.key]
                          ? item.need_millimeter
                            ? addMillimeter(eventInfo[item?.key])
                            : eventInfo[item?.key]
                          : ""}
                      </div>
                    ),
                    overlayClassName: "eventTypeParagraphTooltip",
                  },
                }}
                style={{
                  marginBottom: "0px",
                }}
              >
                {item?.is_click ? (
                  <a
                    href={eventInfo[item?.get_field]}
                    target="_blank"
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                  >
                    {eventInfo[item?.key]}
                  </a>
                ) : eventInfo[item?.key] ? (
                  item.need_millimeter ? (
                    addMillimeter(eventInfo[item?.key])
                  ) : (
                    eventInfo[item?.key]
                  )
                ) : (
                  "-"
                )}
              </Paragraph>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div
      className="cardItem"
      key={Math.random()}
      onClick={() => {
        onClick && onClick(info);
      }}
    >
      <div className="imgBox" style={{ background: colorMap[info.event_type] }}>
        <img src={bg} className="imgBg" />
        <img
          src={info?.event_logo || null}
          alt={info?.event_src}
          className="iconImg"
        />
        <div className="typeBox">{info?.event_type}</div>
      </div>
      <div className="publicSentimentCardContent">
        <div className="cardTitleBox">
          <Paragraph
            ellipsis={{
              rows: 1,
              tooltip: {
                title: info?.title,
                overlayStyle: {
                  maxWidth: '600px'
                },
                overlayInnerStyle: {
                  maxWidth: '600px'
                },
              },
              expandable: false,
            }}
            style={{
              marginBottom: "0px",
            }}
          >
            {info?.title}
          </Paragraph>
          <div className="tipBox">
            <span>来源：{info?.event_src || "-"}</span>
            <span>发布时间：{info?.pub_time === '1970-01-01' ? '-' : (info?.pub_time || "-")}</span>
            <span>获取时间：{info?.sub_time || "-"}</span>
          </div>
        </div>

        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <div className="contentBoxTitle">
            {info?.company_name}
            <div className="tagsBox">
              {info?.rpi_type_mapping?.map((tag) => (
                <Tag key={Math.random()}>{tag?.type_name}</Tag>
              ))}
            </div>
          </div>
          <div className="contentBox_">
            {initDomItem(info?.show_filed || {}, info?.event_json || {})}
          </div>
        </div>
      </div>

      {info?.is_import && (
        <svg
          className="importSvg"
          fill="none"
          version="1.1"
          width="13"
          height="16"
          viewBox="0 0 13 16"
        >
          <g>
            <path
              d="M0,0L13,0L13,16L6.55345,13.0444L0,16L0,0ZM6.5,2.90909L5.10828,5.17527L2.88889,5.61745L4.5435,7.60655L4.27772,10.0364L6.4805,8.92436L8.72228,10.0371L8.4565,7.60655L10.1111,5.61745L7.77111,5.17527L6.5,2.90909Z"
              fill="#FE545F"
              fill-opacity="1"
            />
          </g>
        </svg>
      )}
    </div>
  );
};
const TableContent = (props) => {
  const { id } = useParams();
  const [state, setState] = useUrlState({});

  const { defaultTableName, typeName } = props;
  const [orderField, setOrderField, getOrderField] = useGetState("sub_time"); //排序字段
  const [orderAsc, setOrderAsc, getOrderAsc] = useGetState(-1);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [data, setData] = useState([]);
  const [upDateTime, setUpDateTime, getUpDateTime] = useGetState(null);

  const [eventSubject, setEventSubject, getEventSubject] = useGetState([]);
  const [dicCode, setDicCode, getDicCode] = useGetState([
    "CDD_BOND_SELF",
    "CDD_BOND_CTRL_SUB",
    "CDD_BOND_MAIN_SHRHLDR",
    "CDD_BOND_CTRL",
  ]);
  const [eventType, setEventType, getEventType] = useGetState([]);

  const [dicCodeList, setDicCodeList, getDicCodeList] = useGetState([]);
  const [eventTypeList, setEventTypeList, getEventTypeList] = useGetState([]);
  const [colorMap, setColorMap, getColorMap] = useGetState({});

  const [subjectList, setSubjectList] = useState([]);
  const [showSubjectList, setShowSubjectList] = useState([]);
  const [subjectLoading, setSubjectLoading, getSubjectLoading] =
    useGetState(false);

  const [subjectCheckAll, setSubjectCheckAll] = useState(false);
  const [subjectCheckAllIndeterminate, setSubjectCheckAllIndeterminate] =
    useState(false);

  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);

  const [downLoading, setDownLoading] = useState(false);

  const [isImport, setIsImport, getIsImport] = useGetState(false);

  const [startDate, setStartDate, getStartDate] = useGetState("");
  const [endDate, setEndDate, getEndDate] = useGetState("");

  const ref = useRef(null);
  const size = useSize(ref);

  const loadOptions = () => {
    setSubjectLoading(true);
    setSubjectList([]);
    setShowSubjectList([]);
    const item = getEventTypeList()?.find((__) => __.name === getEventType());
    let event_codes = item?.event_code || [];
    let sub_type = getDicCode()?.length > 0 ? getDicCode() : null;
    if (!sub_type) {
      sub_type = item?.event_scope || null;
    }
    const params = {
      event_codes: event_codes?.length > 0 ? JSON.stringify(event_codes) : null,
      sub_type: sub_type ? JSON.stringify(sub_type) : null,
      event_type: defaultTableName,
    };
    return bond.getRpiGroup(id, params).then((res) => {
      let list = [];
      if (res.code === 200) {
        list = res.data?.data || [];
        getEventSubject()?.forEach((_) => {
          if (!list?.some((__) => __.value === _)) {
            list.push({
              value: _,
              label: _?.split("~~~")?.at(0),
            });
          }
        });
        setSubjectList(JSON.parse(JSON.stringify(list)));
        setShowSubjectList(JSON.parse(JSON.stringify(list)));
      } else {
        setSubjectList([]);
        setShowSubjectList([]);
      }
      if (getEventSubject().length === list?.length) {
        setSubjectCheckAll(true);
        setSubjectCheckAllIndeterminate(false);
      } else {
        setSubjectCheckAllIndeterminate(true);
        setSubjectCheckAll(false);
      }
      if (getEventSubject().length === 0) {
        setSubjectCheckAllIndeterminate(false);
      }
      if (list.length === 0) {
        setSubjectCheckAll(false);
      }
      setSubjectLoading(false);
    });
  };

  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const getDataFunc = () => {
    setLoading(true);
    const item = getEventTypeList()?.find((__) => __.name === getEventType());

    let event_codes = item?.event_code || [];
    let sub_type = getDicCode()?.length > 0 ? getDicCode() : null;
    if (!sub_type) {
      sub_type = item?.event_scope || null;
    }
    const params = {
      page: getPageNumber(),
      page_size: getPageSize(),
      order_field: getOrderField(),
      order_type: getOrderAsc() === 1 ? "asc" : "desc",
      event_codes: event_codes?.length > 0 ? JSON.stringify(event_codes) : null,
      sub_type: sub_type ? JSON.stringify(sub_type) : null,
      is_import: getIsImport() ? 1 : undefined,
      pub_start_time: getStartDate(),
      pub_end_time: getEndDate(),
      r_ids:
        getEventSubject()?.length > 0
          ? JSON.stringify(
              getEventSubject()?.map((_) => _?.split("~~~")?.at(1))
            )
          : null,
    };
    bond.getDataFunc(defaultTableName, id, params).then((res) => {
      if (res.code === 200) {
        setData(res.data?.data);
        setTotal(res.data?.total);
        setUpDateTime(res.data?.data_update_time);
      } else {
        setData([]);
        setTotal(0);
        setUpDateTime(null);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  /* 排列顺序改变 */
  const orderChange = async (e, key) => {
    if (orderField === key) {
      const asc = await getOrderAsc();
      await setOrderAsc(asc === 1 ? -1 : 1);
    } else {
      await setOrderField(key);
      await setOrderAsc(1);
    }
    setPageNumber(1);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };

  const initDomFunc = (item, list) => {
    let inList = item.event_scope || [];
    let dicCode =
      item.event_scope?.length > 4
        ? item.event_scope?.slice(0, 4)
        : item.event_scope;
    const optionsList = list.map((_) => {
      if (!inList?.includes(_?.dic_item_code)) {
        _.dis = true;
      } else {
        _.dis = false;
      }
      return _;
    });
    setDicCodeList([...optionsList]);
    setDicCode([...dicCode]);
  };

  const getCodeListFunc = () => {
    setLoading(true);
    setSearchLoading(true);
    const api1 = bond.getDicCodeInfo("CDD_BOND_RPI").then((res) => {
      if (res.code === 200) {
        setDicCodeList(res.data || []);
        // setDicCode(res.data?.map((_) => _.dic_item_code) || []);
        if (state?.event_code) {
          setDicCode([]);
        }
      } else {
        setDicCodeList([]);
        setDicCode([]);
      }
      return Promise.resolve(res);
    });

    const api2 = bond.getEventTypeMapping(defaultTableName).then((res) => {
      if (res.code === 200) {
        let obj = {};
        setEventTypeList(res.data?.event_list || []);
        (res.data?.event_list || [])?.forEach((item) => {
          obj[item.name] = item.color;
        });
        setColorMap(obj);
        if (state?.event_code) {
          const item = res.data?.event_list?.find((_) =>
            _.event_code?.includes(state?.event_code)
          );
          if (item) {
            setEventType(item?.name);
          }
        }
        // setEventType(res.data?.event_list?.map((_) => _.name) || []);
      } else {
        setEventTypeList([]);
        setColorMap({});
        setEventType(null);
      }
      return Promise.resolve(res);
    });

    Promise.all([api1, api2])
      .then((res) => {
        if (!state?.event_code) {
          const name = res?.at(1).data?.event_list?.at(0)?.name;
          setEventType(name);
          initDomFunc(res?.at(1).data?.event_list?.at(0), res?.at(0)?.data);
        }
        setSearchLoading(false);
        setTimeout(() => {
          loadOptions().then(() => {
            if (state.r_id && state.r_name) {
              setEventSubject([state.r_name + "~~~" + state.r_id]);
            }
            setTimeout(() => {
              getDataFunc();
            }, 50);
          });
        }, 50);
      })
      .catch((sea) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCodeListFunc();
    if (state.is_import) {
      setIsImport(!!state.is_import);
    }
  }, [defaultTableName, typeName]);

  const pageChange = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
    setTimeout(() => {
      getDataFunc();
    }, 100);
  };

  const valueChange = async (value) => {
    setEventSubject(value);
    if (value.length === subjectList?.length) {
      await setSubjectCheckAll(true);
      await setSubjectCheckAllIndeterminate(false);
    } else {
      await setSubjectCheckAllIndeterminate(true);
      await setSubjectCheckAll(false);
    }
    if (value.length === 0) {
      await setSubjectCheckAllIndeterminate(false);
    }
  };

  const choiceItemFunc = (item) => {
    setChoiceItem(item);
    setTimeout(() => {
      setDetailsDrawerVisible(true);
    }, 50);
  };

  const exportDataFile = () => {
    setDownLoading(true);

    const item = getEventTypeList()?.find((__) => __.name === getEventType());
    let event_codes = item?.event_code || [];
    let sub_type = getDicCode()?.length > 0 ? getDicCode() : null;
    if (!sub_type) {
      sub_type = item?.event_scope || null;
    }
    const params = {
      order_field: getOrderField(),
      order_type: getOrderAsc() === 1 ? "asc" : "desc",
      event_codes: event_codes?.length > 0 ? JSON.stringify(event_codes) : null,
      sub_type: sub_type ? JSON.stringify(sub_type) : null,
      is_import: getIsImport() ? 1 : undefined,
      pub_start_time: getStartDate(),
      pub_end_time: getEndDate(),
      r_ids:
        getEventSubject()?.length > 0
          ? JSON.stringify(
              getEventSubject()?.map((_) => _?.split("~~~")?.at(1))
            )
          : null,
    };
    bond.exportDataFunc(defaultTableName, id, params).then((res) => {
      if (res.status === 200) {
        const fileName = window.decodeURI(
          res.headers["content-disposition"]?.split("''")?.at(1)
        );
        saveFile(res.data, fileName);
      } else {
        message.error("下载失败");
      }
      setDownLoading(false);
    });
  };

  return (
    <div className="bondTableContentBox">
      <div className="searchBox" ref={ref}>
        <HeaderTitle
          title={typeName}
          extra={
            <span style={{ fontSize: "12px", color: "#9E9E9E" }}>
              更新时间：{getUpDateTime()}
            </span>
          }
        ></HeaderTitle>
        {searchLoading ? (
          <div className="searchSpinBox">
            <MySpin></MySpin>
          </div>
        ) : (
          <div className="searchItems">
            <div className="searchItem">
              <div className="label">事件类型：</div>
              <Popover
                placement="bottomLeft"
                overlayClassName="myCardPopover eventTypePopover"
                destroyTooltipOnHide={true}
                content={
                  <div className="eventTypePopoverBox">
                    <div className="eventTypePopoverTitle">事件范围</div>
                    {eventTypeList?.map((_) => (
                      <div className="eventTypePopoverItem">
                        <span>{_.name}</span>
                        <span className="scope">{_.scope}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <QuestionCircleOutlined className="helpIcon" />
              </Popover>
              <div className="optionsItems">
                {/* { name: "全部" }, */}
                {[...eventTypeList]?.map((item, index) => (
                  <span
                    key={item.name}
                    className={
                      eventType === item.name
                        ? "optionsItem activeOptionsItem"
                        : "optionsItem"
                    }
                    onClick={() => {
                      setEventType(item.name);
                      initDomFunc(item, getDicCodeList());
                      setSubjectCheckAll(false);
                      setSubjectCheckAllIndeterminate(false);
                      setEventSubject([]);
                      setPageNumber(1);
                      setTimeout(() => {
                        loadOptions();
                        getDataFunc();
                      }, 100);
                    }}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="searchItem">
              <div className="label">主体范围：</div>
              <div className="optionsItems">
                {[
                  { dic_item_name: "全部", dic_item_code: null },
                  ...(dicCodeList?.filter((_) => !_?.dis) || []),
                ]?.map((item, index) => (
                  <span
                    key={item.dic_item_name}
                    className={
                      (
                        index
                          ? dicCode?.includes(item.dic_item_code)
                          : dicCode?.length ===
                            (dicCodeList?.filter((_) => !_?.dis) || [])?.length
                      )
                        ? "optionsItem activeOptionsItem"
                        : "optionsItem"
                    }
                    onClick={() => {
                      if (item.dis) {
                        return;
                      }
                      if (item.dic_item_name === "全部") {
                        const _dicCodeList =
                          dicCodeList?.filter((_) => !_?.dis) || [];
                        setDicCode(
                          dicCode?.length === _dicCodeList?.length
                            ? []
                            : _dicCodeList?.map((_) => _.dic_item_code)
                        );
                      } else {
                        setDicCode((prev) => {
                          if (dicCode?.includes(item.dic_item_code)) {
                            return prev?.filter(
                              (_) => _ !== item.dic_item_code
                            );
                          }
                          return [...prev, item.dic_item_code];
                        });
                      }
                      setSubjectCheckAll(false);
                      setSubjectCheckAllIndeterminate(false);
                      setEventSubject([]);
                      setPageNumber(1);
                      setTimeout(() => {
                        loadOptions();
                        getDataFunc();
                      }, 100);
                    }}
                  >
                    {/* {true ? index + 1 + "." : null} */}
                    {item.dic_item_name}
                  </span>
                ))}
              </div>
            </div>
            <div className="searchItem">
              <div className="label">事件主体：</div>
              <Select
                placeholder="全部"
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                value={eventSubject}
                tagRender={tagRender}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode || document.body
                }
                bordered={false}
                style={{
                  width: "270px",
                  borderBottom: "1px solid rgba(0,0,0,0.08)",
                  // padding: "2px 4px",
                  // height: "25px",
                  marginLeft: "20px",
                }}
                suffixIcon={null}
                filterOption={false}
                showSearch
                onSearch={debounce((e) => {
                  if (e !== "") {
                    setShowSubjectList(
                      subjectList?.filter((item) => item.label?.includes(e))
                    );
                  } else {
                    setShowSubjectList(JSON.parse(JSON.stringify(subjectList)));
                  }
                }, 1000)}
                onChange={valueChange}
                onClear={() => {
                  setSubjectCheckAll(false);
                  setSubjectCheckAllIndeterminate(false);
                  setEventSubject([]);
                  setPageNumber(1);
                  setTimeout(() => {
                    getDataFunc();
                  }, 100);
                }}
                onInputKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setPageNumber(1);
                    setTimeout(() => {
                      getDataFunc();
                    }, 100);
                    e.stopPropagation();
                  }
                }}
                onBlur={() => {
                  setShowSubjectList(JSON.parse(JSON.stringify(subjectList)));
                  setPageNumber(1);
                  setTimeout(() => {
                    getDataFunc();
                  }, 100);
                }}
                dropdownRender={(menu) =>
                  getSubjectLoading() ? (
                    <MySpin></MySpin>
                  ) : (
                    <div className="subjectScopeSearchPoP">
                      <Checkbox
                        disabled={false}
                        style={{ margin: "5px", marginLeft: "12px" }}
                        indeterminate={subjectCheckAllIndeterminate}
                        checked={subjectCheckAll}
                        onChange={(e) => {
                          setSubjectCheckAll(e.target.checked);
                          setSubjectCheckAllIndeterminate(false);
                          if (e.target.checked) {
                            setEventSubject(subjectList.map((_) => _.value));
                          } else {
                            setEventSubject([]);
                          }
                          // e.stopPropagation();
                        }}
                      >
                        全选
                      </Checkbox>
                      {menu}
                    </div>
                  )
                }
              >
                {showSubjectList?.map((item) => {
                  return (
                    <Option value={item.value} key={item.value}>
                      <a className="textItem" title={item.label}>
                        {item.label}
                      </a>
                    </Option>
                  );
                })}
              </Select>

              <div className="label" style={{ marginLeft: "50px" }}>
                发布时间：
              </div>

              <div className="rangePickerBox">
                <DatePicker
                  style={{
                    width: "130px",
                    border: "none",
                    boxShadow: "none",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                    borderRadius: "0px",
                    marginLeft: "20px",
                  }}
                  showToday={false}
                  format="YYYY-MM-DD"
                  value={startDate ? moment(startDate) : startDate}
                  allowClear={true}
                  placeholder="开始时间"
                  disabledDate={(currentDate) => {
                    let max = moment(new Date());
                    if (getEndDate()) {
                      max = moment.min(
                        moment(new Date()),
                        moment(getEndDate())
                      );
                    }
                    if (max) {
                      return moment(currentDate).diff(moment(max)) > 0;
                    }
                    return null;
                  }}
                  onChange={(a, b) => {
                    setStartDate(b);
                    setPageNumber(1);
                    setTimeout(() => {
                      getDataFunc();
                    }, 100);
                  }}
                />
                <SwapRightOutlined />
                <DatePicker
                  style={{
                    width: "130px",
                    border: "none",
                    boxShadow: "none",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                    borderRadius: "0px",
                  }}
                  showToday={false}
                  format="YYYY-MM-DD"
                  value={endDate ? moment(endDate) : endDate}
                  allowClear={true}
                  placeholder="结束时间"
                  disabledDate={(currentDate) => {
                    let max = moment(new Date());
                    let min = null;
                    if (getStartDate()) {
                      min = moment(getStartDate());
                    }
                    if (max && min) {
                      return (
                        moment(currentDate).diff(moment(max)) > 0 ||
                        moment(currentDate).diff(moment(min)) <= 0
                      );
                    }
                    if (max) {
                      return moment(currentDate).diff(moment(max)) > 0;
                    }
                    if (min) {
                      return moment(currentDate).diff(moment(min)) < 0;
                    }
                    return null;
                  }}
                  onChange={(a, b) => {
                    setEndDate(b);
                    setPageNumber(1);
                    setTimeout(() => {
                      getDataFunc();
                    }, 100);
                  }}
                />
              </div>
              {/* <MyRangePicker
                style={{
                  width: "270px",
                  border: "none",
                  boxShadow: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: "0px",
                  marginLeft: "20px"
                }}
                format="YYYY-MM-DD"
                allowClear={true}
                onChange={onDateChange}
              ></MyRangePicker> */}

              <div className="label" style={{ marginLeft: "50px" }}>
                重要事件：
              </div>
              <Popover
                placement="top"
                // overlayClassName="myCardPopover eventTypePopover"
                destroyTooltipOnHide={true}
                content={
                  <div className="eventTypePopoverBox">
                    重要事件即符合《公司债券临时报告信息披露格式指引》要求的事件
                  </div>
                }
              >
                <QuestionCircleOutlined
                  className="helpIcon"
                  style={{ marginTop: "0px", marginRight: "5px" }}
                />
              </Popover>
              <Switch
                checked={isImport}
                style={{ marginBottom: "0px" }}
                onChange={(checked) => {
                  setIsImport(checked);
                  setPageNumber(1);
                  setTimeout(() => {
                    getDataFunc();
                  }, 100);
                }}
              />

              {/* <Checkbox
                checked={isImport}
                style={{ marginBottom: "0px", marginLeft: "20px" }}
                onChange={(e) => {
                  setIsImport(e.target.checked);
                  setPageNumber(1);
                  setTimeout(() => {
                    getDataFunc();
                  }, 100);
                }}
              >
                是否重要
              </Checkbox> */}
            </div>
          </div>
        )}
      </div>
      <div className="optionsDiv">
        <div className="optionsBox">
          {[
            { label: "获取时间", key: "sub_time", width: "70px" },
            // { label: "事件主体", key: "type_index_field", width: "70px" },
          ].map((item) => (
            <span
              key={item.key}
              className="optionItem"
              onClick={debounce(orderChange, 200, item.key)}
              style={{
                color: orderField === item.key ? "#0068b2" : "#535353",
                width: item.width,
              }}
            >
              {item.label}
              {orderField === item.key &&
                (orderAsc === 1 ? <CaretUpOutlined /> : <CaretDownOutlined />)}
            </span>
          ))}
        </div>
        <div>
          {downLoading ? (
            <LoadingOutlined />
          ) : (
            <Tooltip placement="top" title="下载" arrowPointAtCenter>
              <VerticalAlignBottomOutlined
                className="downloadIcon"
                onClick={() => {
                  getLicenseValid(false, true).then((res) => {
                    if (res) {
                      exportDataFile();
                    }
                  });
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div
        className="infoBox"
        style={{ height: `calc(100% - ${(size?.height || 0) + 42}px)` }}
      >
        {loading ? (
          <MySpin></MySpin>
        ) : data?.length > 0 ? (
          <div className="cardContentBox">
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{
                height: `calc(100% - 50px)`,
                width: "100%",
              }}
            >
              <div className="cardsBox">
                {data?.map((item) => (
                  <Card
                    onClick={(item) => {
                      choiceItemFunc(item);
                    }}
                    key={item?.company_id + item?.event_id + item?.event_code + item?.sub_time}
                    info={item}
                    colorMap={colorMap}
                  ></Card>
                ))}
              </div>
            </Scrollbars>
            <div className="paginationBox">
              <Pagination
                className="myPagination"
                current={pageNumber}
                total={total >= 10000 ? 10000 : total}
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={pageChange}
                showTotal={() => `共 ${total >= 10000 ? "9999+" : total} 条`}
                showSizeChanger
              />
            </div>
          </div>
        ) : (
          <div className="emptyBox">
            <MyEmpty></MyEmpty>
          </div>
        )}
      </div>

      <DetailsDrawer
        isBond={true}
        title={typeName + "详情"}
        visible={detailsDrawerVisible}
        dataTable={defaultTableName}
        item={choiceItem}
        typeName={typeName}
        onCancel={() => {
          setDetailsDrawerVisible(false);
        }}
      ></DetailsDrawer>
    </div>
  );
};
export default TableContent;
