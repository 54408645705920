import "./index.scss";
import HeaderTitle from "../HeaderTitle";
import { useHover, useGetState } from "ahooks";
import React, { useRef } from "react";
import CommentsIcon from "../Icons/CommentsIcon";
import InfoIcon from "../Icons/InfoIcon";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import projectApi from "../../apis/project";
import { message } from "antd";
import { showTipFunc } from "../../unit/unit";

const CommentsHeaderTitle = (props) => {
  const { title, dataTable, typeName, extra, icon } = props;
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [show, setShow] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [code, setCode, getCode] = useGetState(null);
  const [comments, setComments] = useState(null);
  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_CONCERN_TYPE").then((res) => {
      if (res.code === 200) {
        const _code = res.data.find((item) => item.dic_item_name === typeName);
        setCode(_code?.dic_item_code);
        getData(_code?.dic_item_code);
      } else {
        setCode("");
        console.error("未查到对应的枚举code");
      }
    });
  };

  const getData = (code) => {
    if (!code) {
      code = getCode();
    }
    projectApi
      .getSpecialFocusRemark(projectInfo.id, {
        table_name: dataTable,
        event_type_code: code,
      })
      .then((res) => {
        if (res.code === 200) {
          setComments(res.data.event_type_remark);
        } else {
          message.error(res.message);
        }
      });
  };

  useEffect(() => {
    getCodeInfo();
  }, []);

  const showTip = showTipFunc(projectProgressCode, typeName);

  return (
    <HeaderTitle
      title={
        <div className="commentsHeaderTitleBox" ref={ref}>
          {title}
          {comments &&
          projectInfo?.project_permission_urls.includes(
            "CDD_PRJ_COMMENT_VIEW"
          ) ? (
            <InfoIcon
              setShow={setShow}
              projectId={projectInfo.id}
              mode={30}
              dataTable={dataTable}
              getData={getData}
              typeName={typeName}
              info={{
                comments,
              }}
            ></InfoIcon>
          ) : null}
          {(isHovering || show) &&
          projectInfo?.project_permission_urls.includes("CDD_PRJ_COMMENT") ? (
            <CommentsIcon
              setShow={setShow}
              projectId={projectInfo.id}
              mode={30}
              dataTable={dataTable}
              getData={getData}
              typeName={typeName}
              info={{}}
            ></CommentsIcon>
          ) : null}
        </div>
      }
      extra={extra}
      icon={icon}
      showTip={showTip}
    ></HeaderTitle>
  );
};

export default CommentsHeaderTitle;
