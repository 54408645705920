import "./index.scss";
import { Button, Drawer } from "antd";
import Subscribe from "../../index";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onClose();
        }}
        type="primary"
      >
        关闭
      </Button>
    </div>
  );
};

const SubscribeDrawer = (props) => {
  const { projectId, isBond } = props;
  return (
    <Drawer
      className="subscribeDrawer"
      width="600px"
      closable={false}
      title="全部订阅消息"
      placement="right"
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={<BtnBox onClose={props.onClose}></BtnBox>}
    >
      <>
        {props.visible ? (
          <Subscribe
            hasHeader={false}
            hasSearch={true}
            projectId={projectId}
            isBond={isBond}
          ></Subscribe>
        ) : (
          <></>
        )}
      </>
    </Drawer>
  );
};

export default SubscribeDrawer;
