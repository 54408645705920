import "./index.scss";
import png from "../../../assets/数据采集.png";
import { Button, message } from "antd";
import CreatCollenctionModal from "./components/CreatCollenctionModal";
import CreatCollenctionDrawer from "./components/CreatCollenctionDrawer";
import { useState, useEffect } from "react";
import CollenctionInfoDrawer from "./components/CollenctionInfoDrawer";
import { useParams } from "react-router-dom";
import projectApi from "../../../apis/project";
import { useSelector } from "react-redux";
import { getLicenseValid } from "../../../unit/unit";

const CreateCollection = (props) => {
  const { onSuccess, urlType } = props;
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);
  const myInfo = useSelector((state) => state.User.userInfo);
  const projectInfo = useSelector((state) => state.Project.projectInfo);
  const [data, setData] = useState([]);

  const creatCollenctionFunc = () => {
    if (isCollecting) {
      CollenctionInfoDrawer({
        projectId: id,
        myInfo,
        projectInfo,
      });
    } else {
      getLicenseValid().then((res) => {
        if (res) {
          projectApi.getLicenseSupport("ADV_TAX_ANALYSIS").then((res2) => {
            if (res2.code === 200 && res2.data) {
              //关闭
              if(projectInfo?.status === 'CDD_PRJ_STATUS_CLS'){
                message.warning('项目已关闭，该功能暂时无法使用，请重新启动项目后进行采集')
                return
              }
              CreatCollenctionModal({
                onOk: () => {
                  console.log("确认收集");
                  setVisible(true);
                },
              });
              return;
            }
            message.error(res2.msg);
          });
        }
      });
    }
  };

  const successFunc = () => {
    setVisible(false);
    getCollectionStatus();
    setTimeout(() => {
      onSuccess && onSuccess();
    }, 1000 * 10);
  };

  //获取 采集信息是否完成 的接口
  const getCollectionStatus = () => {
    const params = {
      page: 1,
      page_size: 10000,
    };
    projectApi.getAuthorizationProcess(id, params).then((res) => {
      if (res.code === 200) {
        setData(res.data.data);
        setIsCollecting(res.data.data?.length !== 0);
      } else {
        message.error(res.message);
        setData([]);
        setIsCollecting(false);
      }
    });
  };

  useEffect(() => {
    getCollectionStatus();
  }, []);

  return (
    <div className="createCollectionContent">
      <div className="topTitleBox">
        {isCollecting
          ? "数据采集中......"
          : `${
              urlType ? urlType : "财税分析"
            }数据来自税务总局纳税及开票数据，需要先进行数据采集后才能使用`}
      </div>
      <div className="imgBox">
        <img src={png} alt="数据采集流程图" />
      </div>
      <div className="btnBox">
        <Button
          type="primary"
          style={{ width: "200px" }}
          onClick={creatCollenctionFunc}
        >
          {isCollecting ? `查看采集进度（${data?.length}）` : "发起数据采集"}
        </Button>
      </div>

      <CreatCollenctionDrawer
        projectId={id}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        userInfo={myInfo}
        onSuccess={successFunc}
      ></CreatCollenctionDrawer>
    </div>
  );
};

export default CreateCollection;
