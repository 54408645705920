import "./index.scss";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import MySpin from "../MySpin";
const { Option } = Select;

function DebounceSelect({
  fetchOptions,
  selectChange,
  debounceTimeout = 800,
  changeClear = false,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const [searchValue,setSearchValue] = useState('');
  const [value,setValue] = useState('');

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const valueChange = (info) => {
    const [id, name] = info?.value?.split("&&&&&&&") || [];
    setTimeout(()=>{
      setValue(name)
      setSearchValue(name);
    },50)
    const item = options.find(
      (_) => _.company_id === Number(id) && _.name === name
    );
    props.onChange && props.onChange(item);
    selectChange && selectChange(item)
    if (changeClear) {
      setOptions([]);
    }
  };
  const showCode = (row) => {
    if (row.credit_code) {
      return (
        "统一信用号:" +
        row.credit_code +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.org_number) {
      return (
        "统一信用号:" +
        row.org_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.reg_number) {
      return (
        "统一信用号:" +
        row.reg_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.legal_person_name) {
      return "法人:" + (row.legal_person_name ? row.legal_person_name : "");
    } else {
      return "无详细信息";
    }
  };

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={(value)=>{
        setSearchValue(value)
        debounceFetcher(value)
      }}
      notFoundContent={fetching ? <MySpin /> : null}
      {...props}
      onChange={valueChange}
      searchValue={searchValue}
      onFocus={(e)=>{
        setSearchValue(value)
      }}
      onBlur={()=>{
        setOptions([]);
      }}
    >
      {options.map((item) => (
        <Option
          value={item.company_id + "&&&&&&&" + item.name}
          key={item.company_id + item.name}
          className="debounceSelectItem"
        >
          <div className="addComp">
            <a className="name" title={item.label}>
              {item.label}
            </a>
            <a className="info" title={showCode(item)}>
              {showCode(item)}
            </a>
          </div>
        </Option>
      ))}
    </Select>
  );
}

export default DebounceSelect;
