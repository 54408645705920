import "./index.scss";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../../../../../assets/下拉.svg";
import projectApi from "../../../../../apis/project";
import CasePartiesModal from "../CasePartiesModal";
import moment from "moment";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  message,
  Radio,
  Spin,
  Space,
  Modal,
} from "antd";
import {
  CloseOutlined,
  CloudFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import debounce from "lodash/debounce";

import { useParams } from "react-router-dom";
import { useRef } from "react";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const CaseDialogDrawer = (props) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [caseType, setCaseType] = useState([]);
  const [caseIdentity, setCaseIdentity] = useState([]);
  const [caseState, setCaseState] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [typeValue, setTypeValue] = useState(0);

  const _id = useRef(1);
  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* form表单列表 */
    formList,
    /* 初始值 */
    defaultData,
    visible,
    /* 判断新增还是修改 */
    addNew,
  } = props;

  // 获取涉案类型
  const getCaseType = () => {
    projectApi.getDicCodeInfo("CDD_CASE_TYPE").then((res) => {
      if (res.code === 200) {
        setCaseType(res.data);
      } else {
        setCaseType([]);
      }
    });
  };
  // 获取案中身份
  const getCaseIdentity = () => {
    projectApi.getDicCodeInfo("CDD_CASE_IDENTITY").then((res) => {
      if (res.code === 200) {
        setCaseIdentity(res.data);
      } else {
        setCaseIdentity([]);
      }
    });
  };
  // 涉案状态
  const getCaseState = () => {
    projectApi.getDicCodeInfo("CDD_CASE_STATE").then((res) => {
      if (res.code === 200) {
        setCaseState(res.data);
      } else {
        setCaseState([]);
      }
    });
  };
  useEffect(() => {
    getCaseType();
    getCaseIdentity();
    getCaseState();
  }, []);
  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    form.resetFields();
  }, [defaultData]);

  const handleOk = () => {
    onOk && onOk();
  };

  const radioChange = (e) => {
    // console.log("radio checked", e.target.value);
    setTypeValue(e.target.value);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width="600px"
      className="CaseDialogDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              handleOk();
            }}
            type="primary"
          >
            {okText ? okText : "确认"}
          </Button>
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="dialogDrawerForm"
          preserve={false}
        >
          <Form.Item
            name="title"
            label="案件名称"
            style={{
              width: "100%",
            }}
            rules={[{ required: true, message: "请填写案件名称" }]}
            initialValue={addNew ? null : defaultData?.title}
          >
            <Input allowClear />
          </Form.Item>

          {/* 原告 */}
          <Form.List
            name="原告"
            // initialValue={[{ 原告类型: 0 }]}
          >
            {(fields, { add, remove }) => (
              <>
                <Form.Item
                  rules={[{ required: true }]}
                  name="原告"
                  label="原告"
                  style={{
                    width: "calc(100% - 30px)",
                  }}
                ></Form.Item>
                <PlusCircleOutlined
                  style={{ fontSize: "18px", color: "#9E9E9E" }}
                  onClick={() => {
                    add();
                  }}
                />
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      <Form.Item
                        {...restField}
                        label="原告类型"
                        name={[name, "原告类型"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        style={{ width: "254px" }}
                      >
                        <Radio.Group onChange={radioChange} value={typeValue}>
                          <Radio value={0} style={{ marginRight: "40px" }}>
                            法人
                          </Radio>
                          <Radio value={1}>自然人</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>
                    {typeValue === 0 ? (
                      <Form.Item
                        {...restField}
                        label="原告名称 (企业名称)"
                        name={[name, "原告名称(企业名称)"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="请选择"
                          allowClear
                          showSearch={true}
                          suffixIcon={
                            <ReactSVG
                              src={DropdownSvg}
                              style={{ width: "16px", height: "16px" }}
                            ></ReactSVG>
                          }
                          style={{ width: "270px" }}
                        >
                          <Option value="jack">Jack</Option>
                          <Option value="lucy">Lucy</Option>
                          <Option value="tom">Tom</Option>
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        {...restField}
                        label="原告名称 (姓名)"
                        name={[name, "原告名称(企业名称)"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input allowClear style={{ width: "270px" }} />
                      </Form.Item>
                    )}

                    <CloseCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: "#FE545F", fontSize: "18px" }}
                      className="dynamic-delete-button"
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>

          {/* 被告 */}
          <Form.List name="被告">
            {(fields, { add, remove }) => (
              <>
                <Form.Item
                  rules={[{ required: true }]}
                  name="被告"
                  label="被告"
                  style={{
                    width: "calc(100% - 30px)",
                  }}
                ></Form.Item>
                <PlusCircleOutlined
                  style={{ fontSize: "18px", color: "#9E9E9E" }}
                  onClick={() => {
                    add();
                  }}
                />
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      <Form.Item
                        {...restField}
                        label="被告类型"
                        name={[name, "被告类型"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        style={{ width: "254px" }}
                      >
                        <Radio.Group onChange={radioChange}>
                          <Radio value="0" style={{ marginRight: "40px" }}>
                            法人
                          </Radio>
                          <Radio value="1">自然人</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>
                    {typeValue === "0" ? (
                      <Form.Item
                        {...restField}
                        label="被告名称 (企业名称)"
                        name={[name, "被告名称(企业名称)"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="请选择"
                          allowClear
                          showSearch={true}
                          suffixIcon={
                            <ReactSVG
                              src={DropdownSvg}
                              style={{ width: "16px", height: "16px" }}
                            ></ReactSVG>
                          }
                          style={{ width: "270px" }}
                        >
                          <Option value="jack">Jack</Option>
                          <Option value="lucy">Lucy</Option>
                          <Option value="tom">Tom</Option>
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        {...restField}
                        label="被告名称 (姓名)"
                        name={[name, "被告名称(企业名称)"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input allowClear style={{ width: "270px" }} />
                      </Form.Item>
                    )}

                    <CloseCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: "#FE545F", fontSize: "18px" }}
                      className="dynamic-delete-button"
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>

          <Form.Item
            name="related_case_no"
            label="案号"
            style={{
              width: "100%",
            }}
            initialValue={addNew ? null : defaultData?.related_case_no.join()}
            rules={[{ required: true, message: "请填写案号" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="case_type"
            label="案件类型"
            style={{
              width: "48%",
            }}
            initialValue={addNew ? [] : defaultData?.case_type}
          >
            <Select
              placeholder="请选择"
              // mode="multiple"
              showSearch
              allowClear
              // filterOption={(inputValue, option) =>
              //   option.children.includes(inputValue)
              // }
              showArrow={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
            >
              {caseType?.map((item) => {
                return (
                  <Option value={item.dic_item_code} key={item.dic_item_code}>
                    {item.dic_item_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="case_identity"
            label="案件身份"
            style={{
              width: "48%",
            }}
            initialValue={addNew ? null : defaultData?.case_identity[0]}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
            >
              {caseIdentity?.map((item) => {
                return (
                  <Option value={item.dic_item_code} key={item.dic_item_code}>
                    {item.dic_item_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="case_reason"
            label="案由"
            style={{
              width: "100%",
            }}
            initialValue={addNew ? null : defaultData?.case_reason}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="case_state"
            label="案件状态"
            rules={[{ required: true }]}
            style={{
              width: "100%",
            }}
            initialValue={addNew ? null : defaultData?.case_state}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
            >
              {caseState?.map((item) => {
                return (
                  <Option value={item.dic_item_code} key={item.dic_item_code}>
                    {item.dic_item_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* 其他相关案号 */}
          <Form.List name="其他相关案号" initialValue={[]}>
            {(fields, { add, remove }) => (
              <>
                <Form.Item
                  name="其他相关案号"
                  label="其他相关案号"
                  style={{
                    width: "calc(100% - 30px)",
                    marginBottom: 0,
                  }}
                ></Form.Item>
                <PlusCircleOutlined
                  style={{ fontSize: "18px", color: "#9E9E9E" }}
                  onClick={() => add()}
                />
                {fields.length > 0 ? null : (
                  <div className="noRelatedCase">
                    <div className="border">无</div>
                  </div>
                )}
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline" wrap="true">
                    <Form.Item
                      {...restField}
                      label="案号"
                      name={[name, "related_case_no"]}
                      // name="related_case_no"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input allowClear style={{ width: "525px" }} />
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        label="案件类型"
                        // name="case_type"
                        name={[name, "case_type"]}
                      >
                        <Select
                          placeholder="请选择"
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          allowClear
                          showArrow="true"
                          suffixIcon={
                            <ReactSVG
                              src={DropdownSvg}
                              style={{ width: "16px", height: "16px" }}
                            ></ReactSVG>
                          }
                          style={{ width: "250px" }}
                        >
                          {caseType?.map((item) => {
                            return (
                              <Option
                                value={item.dic_item_code}
                                key={item.dic_item_code}
                              >
                                {item.dic_item_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="案件身份"
                        name={[name, "case_identity"]}
                        // name="case_identity"
                        {...restField}
                      >
                        <Select
                          placeholder="请选择"
                          allowClear
                          showSearch={true}
                          suffixIcon={
                            <ReactSVG
                              src={DropdownSvg}
                              style={{ width: "16px", height: "16px" }}
                            ></ReactSVG>
                          }
                          style={{ width: "250px" }}
                        >
                          {caseIdentity?.map((item) => {
                            return (
                              <Option
                                value={item.dic_item_code}
                                key={item.dic_item_code}
                              >
                                {item.dic_item_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <CloseCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: "#FE545F", fontSize: "18px" }}
                      className="dynamic-delete-button1"
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>

          <Form.Item
            name="当前审理程序"
            label="当前审理程序"
            style={{
              width: "48%",
            }}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="irst_time"
            label="当前审理程序日期"
            style={{
              width: "48%",
            }}
            initialValue={addNew ? null : moment(defaultData?.last_time)}
          >
            <DatePicker
              onChange={(date, dateString) => {
                console.log(date, dateString);
              }}
              style={{ width: "270px" }}
            />
          </Form.Item>
        </Form>
      </Scrollbars>
      <CasePartiesModal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      ></CasePartiesModal>
    </Drawer>
  );
};

export default CaseDialogDrawer;
