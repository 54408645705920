import "./index.scss";
import { Button, Drawer, message, Upload, Typography } from "antd";
import {
  CloseOutlined,
  VerticalAlignBottomOutlined,
  CloudFilled,
  LinkOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import DebounceSelect from "../DebounceSelect";
import projectApi from "../../apis/project";
import xlsUrl from "../../assets/疑似关联方核查对象提交模板.xlsx";
import xlsUrl2 from "../../assets/关联方批量上传模板.xlsx";
import xlsUrl3 from "../../assets/招投标竞争对手提交模板.xlsx";
import xlsUrl4 from "../../assets/发行人批量上传模板.xlsx";

import MySpin from "../MySpin";
import upIcon from "../../assets/上传.svg";
import { ReactSVG } from "react-svg";
const { Dragger } = Upload;
const { Paragraph } = Typography;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
        loading={props.loading}
      >
        确定
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const UploadFileDrawer = (props) => {
  const [fileList, setFileList] = useState([]);

  const clearAll = () => {
    setFileList([]);
  };

  useEffect(() => {
    if (!props.visible) {
      clearAll();
    } else {
    }
  }, [props.visible]);

  const onFinish = () => {
    if (fileList.length === 0) {
      message.info("请至少上传一个文件");
      return;
    }
    props.successFunc && props.successFunc(fileList);
  };

  const downloadTemplate = () => {
    let link = document.createElement("a");
    link.style.display = "none";
    let title = "";
    if (props.title === "上传核查对象") {
      link.href = xlsUrl;
      title = "疑似关联方核查对象提交模板.xlsx";
    }else if(props.title === "上传竞争对手"){
      link.href = xlsUrl3;
      title = "招投标竞争对手提交模板.xlsx";
    } else if(props.title === "上传发行人"){
      link.href = xlsUrl4;
      title = "发行人批量上传模板.xlsx";
    } else {
      link.href = xlsUrl2;
      title = "关联方批量上传模板.xlsx";
    }
    link.setAttribute("download", title);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const fileChange = (info) => {
    if (info.file?.size / 1048576 > 10) {
      message.info("请上传10MB以下的文件");
      return;
    }

    setFileList((prev) => {
      if (props.multiple) {
        prev.push(info.file);
      } else {
        prev = [info.file];
      }
      return [...prev];
    });
  };

  return (
    <Drawer
      className="uploadFileDrawer"
      width="600px"
      closable={false}
      title={props.title}
      placement="right"
      push={false}
      maskClosable={false}
      keyboard={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <BtnBox
          loading={props.loading}
          onClose={props.onClose}
          onFinish={onFinish}
        ></BtnBox>
      }
    >
      {props.loading ? (
        <div style={{ width: "100%", height: "100%" }}>
          <MySpin tip="文件解析中"></MySpin>
        </div>
      ) : (
        <div className="uploadFileDrawerContent">
          <div className="downloadTemplate">
            <div></div>
            <div className="iconBox" onClick={() => downloadTemplate()}>
              <VerticalAlignBottomOutlined />
              <span className="text">下载导入模板</span>
            </div>
          </div>

          <Dragger
            className="draggerBox"
            fileList={[]}
            name="file"
            multiple={props.multiple}
            action=""
            onChange={fileChange}
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <div className="uploadBox">
              {/* <CloudFilled /> */}
              <ReactSVG className="uploadSvg" src={upIcon}></ReactSVG>
              <div className="text">
                将文件拖拽到此处或<span>点击上传</span>
              </div>
            </div>
          </Dragger>

          <div className="fileTip">（只能上传xls/xlsx文件，且不超过10MB）</div>

          {fileList.map((file, index) => (
            <div className="fileLineItem" key={Math.random()}>
              <div className="fileItem">
                <LinkOutlined />
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: file.name,
                    expandable: false,
                  }}
                  style={{
                    width: `520px`,
                    color: "#313131",
                  }}
                >
                  {file.name}
                </Paragraph>
              </div>
              <CloseCircleOutlined
                className="closeIcon"
                onClick={() => {
                  setFileList((prev) => {
                    prev = prev.filter((_) => {
                      return _.uid !== file.uid;
                    });
                    return [...prev];
                  });
                }}
              ></CloseCircleOutlined>
            </div>
          ))}
        </div>
      )}
    </Drawer>
  );
};
export default UploadFileDrawer;
