import "./index.scss";
import React, { useEffect, useState } from "react";
import { Drawer, Button, message, Tag } from "antd";
import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import HeaderTitle from "../../../../../components/HeaderTitle";
import MySpin from "../../../../../components/MySpin";
import TimeLineContent from "../TimeLineContent";
import projectApi from "../../../../../apis/project";

const CaseDetailsDrawer = (props) => {
  const { id } = useParams();
  const {
    /* 标题 */
    title,
    visible,
    /* 关闭触发的回调 */
    onCancel,
    caseId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState({});

  // 关闭Drawer
  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 获取司法案件详情
  const getCaseDetailInfo = () => {
    projectApi.getCaseDetailInfo(id, caseId).then((res) => {
      console.log("司法案件详情", res);
      if (res.code === 200) {
        setShowData(res.data);
        setLoading(false);
      } else {
        message.error(res.message);
        handleCancel();
      }
    });
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      getCaseDetailInfo();
    } else {
      setTimeout(() => {
        setShowData({});
      }, 300);
    }
  }, [visible]);

  return (
    <Drawer
      title={title}
      placement="right"
      width="624px"
      className="CaseDetailsDrawer"
      onClose={handleCancel}
      visible={visible}
      closable={false}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            关闭
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        {loading ? (
          <MySpin></MySpin>
        ) : (
          <div className="detailsContent">
            {/* 基本信息 */}
            <div className="baseContent">
              <HeaderTitle
                title="基本信息"
                style={{ fontSize: "14px" }}
              ></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">案件名称</div>
                  <div className="detailValue">{showData.title}</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">相关案号</div>
                  <div className="detailValue">
                    {showData.related_case_no?.join("，")}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">主体范围</div>
                  <div className="detailValue">
                    {showData.case_subject_scope?.join("，")}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">涉案主体</div>
                  <div className="detailValue">
                    {showData.case_parties?.join("，")}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">案件类型</div>
                  <div className="detailValue">
                    {showData.case_type?.join("，")}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">案由</div>
                  <div className="detailValue">{showData.case_reason}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">涉案金额</div>
                  <div className="detailValue">
                    {Number(showData.case_amount).toLocaleString()}元
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">案中身份</div>
                  <div className="detailValue">
                    {showData.case_identity?.join("，")}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">案件状态</div>
                  {showData.case_state !== "" &&
                  showData.case_state !== undefined ? (
                    <div
                      className="detailValue caseState"
                      style={{
                        backgroundColor:
                          showData.case_state === "未结案" ? "red" : "#61D4AD",
                      }}
                    >
                      {showData.case_state}
                    </div>
                  ) : null}
                </div>

                <div className="detailItem">
                  <div className="detailKey">当前审理程序</div>
                  <div className="detailValue">{showData.current_process}</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">最新审理程序日期</div>
                  <div className="detailValue">
                    {showData?.current_trial_date === null
                      ? ""
                      : showData.current_trial_date}
                  </div>
                </div>
              </div>
            </div>
            {/* 详情 */}
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <HeaderTitle title="详情"></HeaderTitle>
              <div className="detailsBox">
                {/* <div className="tagValue">
                  {tagsData.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </div> */}
                {/* {details.map((item) => {
                  return (
                    <div
                      className="detailItem"
                      style={{
                        width: "100%",
                      }}
                      key={item.key}
                    >
                      <div className="detailKey">{item.key}</div>
                      <div className="detailValue">{item.value}</div>
                    </div>
                  );
                })} */}
                {showData.timeline?.length !== 0 ? (
                  <div className="caseProgress">案件程序进展</div>
                ) : null}
                {/* <div className="caseProgress">案件程序进展</div> */}
                <TimeLineContent
                  projectId={id}
                  timeLineData={showData.timeline}
                ></TimeLineContent>
              </div>
            </div>
          </div>
        )}
      </Scrollbars>
    </Drawer>
  );
};
export default CaseDetailsDrawer;
