import { Tag } from "antd";
import "./index.scss";
const StatusTag = (props) => {
  const { status } = props;
  switch (status) {
    case "尽调中":
      return (
        <Tag color="#61D4AD" className="statusTag">
          尽调中
        </Tag>
      );
    case "关闭":
      return <></>;
    case "尽调失败":
      return (
        <Tag color="#FE545F" className="statusTag">
          尽调失败
        </Tag>
      );
    case "":
      return <></>;
    default:
      return (
        <Tag color="#FEC054" className="statusTag">
          {status}
        </Tag>
      );
  }
};
export default StatusTag;
