import { useEffect, useState } from "react";
import { useGetState } from "ahooks";
import { Pagination, message } from "antd";
import MySpin from "../../../components/MySpin";
import Empty from "../../../components/Empty";
import "./index.scss";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import BubbleBox from "../../../components/BubbleBox";
import MyJudicial from "./components/MyJudicial";
import CaseCard from "./components/CaseCard";
import CaseDialogDrawer from "./components/CaseDialogDrawer";
import projectApi from "../../../apis/project";
import { useParams } from "react-router-dom";
const JudicialCases = (props) => {
  const { id } = useParams();
  const [caseInfo, setCaseInfo, getCaseInfo] = useGetState({});
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [defaultData, setDdefaultData] = useState(null);
  const [addNew, setAddNew] = useState(true);
  const [total, setTotal] = useState(0);
  const [searchInfo, setSearchInfo] = useState({}); // 搜索区域下拉返回值
  const [updateTime, setUpdateTime] = useState(null);

  const [data, setData] = useState({
    case_no: "",
    case_tag: "",
    case_state: "",
    case_type: "",
    case_reason: "",
    case_identity: "",
    case_subject_scope: "",
    case_subject: "",
    case_amount_min: 0,
    case_amount_max: 0,
    case_start_time: "",
    case_end_time: "",
    page_number: pageNumber,
    page_size: pageSize,
  });

  const [needWidth, setNeedWidth] = useState(document.body.clientWidth < 1689);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (document.body.clientWidth < 1689) {
        setNeedWidth(true);
      } else {
        setNeedWidth(false);
      }
    });
  }, []);

  // 获取子组件检索数据
  const getMyJudicialValue = async (item) => {
    await setPageNumber(1);
    await setData({ ...data, ...item, page_number: 1 });
  };

  const getCaseInfoFunc = () => {
    setLoading(true);
    //案号小于3
    if(data?.case_no?.length < 3 && data?.case_no?.length > 0){
      message.info('案号搜索至少输入3个非空字符')
    }
    projectApi.getCaseHomePageInfo(id, data).then((res) => {
      if (res.code === 200) {
        setSearchInfo(res.data.statistic);
        if (JSON.stringify(res.data.tags || []) !== JSON.stringify(getCaseInfo().tags || [])) {
          getType(res.data);
        }
        setCaseInfo(res.data);
        setUpdateTime(res.data?.data_update_time);
        setTotal(res.data.total);
      } else {
        setCaseInfo({});
        setUpdateTime(null);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCaseInfoFunc();
  }, [data]);

  // 分页
  const onChange = async (page, pageSize) => {
    // const totalPage = Math.ceil((caseInfo.total - 1) / pageSize);
    await setPageSize(pageSize);
    await setPageNumber(page);
    setData({ ...data, page_number: page, page_size: pageSize });
  };

  // 泡泡
  // useEffect(() => {
  //   caseInfo.tags && getType();
  // }, [caseInfo.tags]);

  const getType = (caseInfo) => {
    const docList = [];
    const list = caseInfo.tags;
    list?.forEach((item) => {
      const obj = {
        label: item.case_type,
        // label:
        //   item.case_type?.length >= 10
        //     ? item.case_type.substring(0, 10) + "..."
        //     : item.case_type,
        // id: Math.random().toString(),
        value: item.size,
      };
      docList?.push(obj);
    });
    setType(docList?.slice(0, 7));
  };

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);

  const onCanvasClick = () => {
    console.log("点击画布");
  };
  const onNodeClick = (node) => {
    console.log("点击node", node);
  };

  // 新增司法案件
  const handle = (item, addNew) => {
    // projectApi.getTableStyle(dataTable).then((res) => {
    //   if (res.code === 200) {
    //     setDrawerTitle((addNew ? "新增" : "修改") + '司法案件');
    //     setDdefaultData(item);
    //     setFormList(res.data);
    //     setDialogDrawerVisible(true);
    //   } else {
    //     message.error(res.message);
    //   }
    // });
    setDialogDrawerVisible(true);
    setDrawerTitle("新增司法案件");
    setAddNew(true);
  };

  // 修改司法案件
  const changeDialogDrawer = () => {
    setAddNew(false);
    setDialogDrawerVisible(true);
    setDrawerTitle("修改司法案件");
  };

  const success = () => {
    setDialogDrawerVisible(false);
  };
  // 删除数据处理分页
  const changePagination = (num) => {
    setTotal(total - num);
  };

  return (
    <>
      <div className="judicialCasesContent">
        <div className="header">
          <CommentsHeaderTitle
            title="司法案件"
            dataTable="judicial_cases"
            typeName="司法案件"
          ></CommentsHeaderTitle>
          {/* <ExclamationCircleOutlined className="warningIcon" />
          <CommentsIcon style={{ color: "#0068B2", fontSize: "16px" }} /> */}
        </div>

        <div className="dateBox">更新时间：{updateTime || "-"}</div>

        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "calc(100% - 70px)", width: "100%" }}
        >
          <div className="infoBox">
            {/* 上边部分 */}
            <div className="optionsBox">
              {/* 快速检索区域 */}
              <div
                className="optionsCardBox"
                style={{
                  width: needWidth ? "calc(60% - 10px)" : "calc(50% - 10px)",
                }}
              >
                <MyJudicial
                  getValue={getMyJudicialValue}
                  setData={setData}
                  loading={loading}
                  searchInfo={searchInfo}
                />
              </div>
              {/* 司法分析区域 */}
              <div
                className="optionsCardBox"
                style={{
                  width: needWidth ? "calc(40% - 10px)" : "calc(50% - 10px)",
                }}
              >
                <div className="cardTitle">司法分析</div>
                {loading ? (
                  <MySpin></MySpin>
                ) : caseInfo.total > 0 ? (
                  <BubbleBox
                    data={type}
                    // data={list}
                    onCanvasClick={onCanvasClick}
                    onNodeClick={onNodeClick}
                  ></BubbleBox>
                ) : (
                  <div className="judicialAnalysiNoData">
                    <Empty></Empty>
                  </div>
                )}
              </div>
            </div>
            {/* 中间部分 */}
            <div className="topBox">
              <div className="overview">案件概览</div>
              <div className="content">
                <div className="item">
                  <div className="num">
                    <span style={{ marginRight: "3px" }}>
                      {caseInfo.total ? caseInfo.total : 0}
                    </span>
                    <span style={{ fontSize: "12px" }}>件</span>
                  </div>
                  <div className="data">总案件数</div>
                </div>
                <div className="item">
                  <div className="num">
                    <span style={{ color: "#006AB2", marginRight: "3px" }}>
                      {caseInfo.case_amount
                        ? Number(caseInfo.case_amount).toLocaleString()
                        : 0}
                    </span>
                    <span style={{ fontSize: "12px" }}>元</span>
                  </div>
                  <div className="data">涉案总金额</div>
                </div>
                <div className="item">
                  <div className="num">
                    <span style={{ marginRight: "3px" }}>
                      {caseInfo.case_not_has_been
                        ? caseInfo.case_not_has_been
                        : 0}
                    </span>
                    <span style={{ fontSize: "12px" }}>件</span>
                  </div>
                  <div className="data">未结案数</div>
                </div>
                <div className="item">
                  <div className="num">
                    <span style={{ marginRight: "3px" }}>
                      {caseInfo.case_has_been ? caseInfo.case_has_been : 0}
                    </span>
                    <span style={{ fontSize: "12px" }}>件</span>
                  </div>
                  <div className="data">已结案数</div>
                </div>
              </div>
            </div>

            {/* 模板卡片部分 */}
            <div className="cardBox">
              <div className="iconValue">
                {/* <Tooltip placement="top" title="新增" arrowPointAtCenter>
                  <PlusCircleOutlined
                    className="addIcon"
                    onClick={() => {
                      handle(true);
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined style={{ fontSize: "18px" }} />
                </Tooltip> */}
              </div>
              {/* <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "calc(100% - 60px)", width: "100%" }}
              > */}
              {loading ? (
                <MySpin></MySpin>
              ) : caseInfo.total > 0 ? (
                <>
                  <div className="CaseCard">
                    {caseInfo.result?.map((item, index) => {
                      return (
                        <CaseCard
                          key={index}
                          caseDetailInfo={item}
                          changeCaseDialogDrawer={async () => {
                            await setDdefaultData(item);
                            changeDialogDrawer();
                          }}
                          changePagination={changePagination}
                        />
                      );
                    })}
                  </div>
                  {total > 6 ? (
                    <div
                      className="paginationBox"
                      // className={
                      //   caseInfo?.result.length <= 4
                      //     ? "myPaginationSite"
                      //     : "paginationBox"
                      // }
                    >
                      <Pagination
                        className="myPagination"
                        showQuickJumper
                        current={pageNumber}
                        total={total}
                        pageSize={pageSize}
                        pageSizeOptions={[10, 20, 50, 100]}
                        onChange={onChange}
                        showTotal={(total) => `共 ${total} 条`}
                        showSizeChanger={true}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="noData">
                  <Empty></Empty>
                </div>
              )}
              {/* </Scrollbars> */}
            </div>
          </div>
        </Scrollbars>
      </div>

      <CaseDialogDrawer
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultData}
        addNew={addNew}
        // formList={formList}
        onOk={success}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
      ></CaseDialogDrawer>
    </>
  );
};
export default JudicialCases;
