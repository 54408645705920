import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import MySpin from "../../../../../components/MySpin";
import { Table, message, Modal, Tooltip, Button, Tag, Input } from "antd";
import BidWinningSituation from "./components/BidWinningSituation";
import TypeComparison from "./components/TypeComparison";
import ViscosityAnalysis from "./components/ViscosityAnalysis";
import projectApi from "../../../../../apis/project";
import {
  PlusCircleOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import DialogDrawer from "../../../../../components/DialogDrawer";
import MessageModal from "../../../../../components/MessageModal";
import {
  addMillimeter,
  addMillimeterAndInit,
  getLicenseValid,
  saveFile,
} from "../../../../../unit/unit";
import { useGetState, useUnmount } from "ahooks";
import MyEmpty from "../../../../../components/Empty";
import deleteIcon from "../../../../../assets/delete2.svg";
import { ReactSVG } from "react-svg";
import UploadFileDrawer from "../../../../../components/UploadFileDrawer";
import { useSelector } from "react-redux";
import comSvg from "../../../../../assets/公司列表.svg";
import CompanyCard from "../CompanyCard";
const CompetitivenessAnalysis = (props) => {
  const { searchTableFunc } = props;
  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const [competitiveHitData, setCompetitiveHitData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [downLoading2, setDownLoading2] = useState(false);
  const [okLoading, setOkLoading] = useState(false);

  const [competitiveData, setCompetitiveData] = useState([]);
  const [bidCompanyName, setBidCompanyName] = useState(null);

  const [data, setData] = useState({});
  const [drawerTitle, setDrawerTitle] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const [formList, setFormList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const ref = useRef();
  const [fileVisible, setFileVisible] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [upData, setUpData] = useState({});
  const [visible, setVisible] = useState(false);
  const [isDown, setIsDown, getIsDown] = useGetState(false);
  const [checkCompanyList, setCheckCompanyList, getCheckCompanyList] =
    useGetState([]);
  const [searchValue, setSearchValue, getSearchValue] = useGetState(null);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const getBiddingCompetitivenessAnalysis = () => {
    setLoading(true);
    let params = {
      project_id: id,
      page: 1,
      page_size: 10000,
    };
    projectApi.getBiddingCompetitivenessAnalysis(params).then((res) => {
      console.log("竞争力分析:-------", res);
      if (res.code === 200) {
        setCompetitiveData(res.data.competitive_data || []);
        setBidCompanyName(
          (
            (res.data.competitive_data || [])?.find((_) => _.is_cdd_company) ||
            {}
          )?.bid_winner
        );
        setData(res.data);
        setLoading(false);
        setUpdateTime(res.data.data_update_time);
        if (getCheckCompanyList()?.length === 0) {
          setCheckCompanyList(
            res.data.competitive_data?.slice(0, 5)?.map((_) => _.bid_winner)
          );
        }
      } else {
        message.error(res.message);
        setCompetitiveHitData([]);
        setCompetitiveData([]);
        setBidCompanyName(null);
        setData({});
        setBidCompanyName(null);
        setUpdateTime(null);
        setLoading(false);
      }
    });
  };
  const timer = useRef(null);

  useUnmount(() => {
    clearInterval(timer.current);
  });

  useEffect(() => {
    getBiddingCompetitivenessStatus(true);
    timer.current = setInterval(() => {
      getBiddingCompetitivenessStatus();
    }, 5000);
    // getBiddingCompetitivenessAnalysis();
  }, []);

  //获取 数据获取状态
  const getBiddingCompetitivenessStatus = (first) => {
    projectApi.getBiddingCompetitivenessStatus(id).then((res) => {
      console.log("查询状态:", res.data?.complete_flag);
      if (res.code === 200) {
        if ((res.data?.complete_flag === 1 && getIsDown() === true) || first) {
          getBiddingCompetitivenessAnalysis();
        }
        if (res.data?.complete_flag === 0) {
          setIsDown(true);
        } else {
          setIsDown(false);
        }
      } else {
        setIsDown(false);
        message.error(res.message);
      }
    });
  };

  const deleteCompetitorsAll = () => {
    MessageModal({
      type: "warning",
      description: "确定要删除所有竞争对手吗？",
      title: "删除",
      onOk: () => {
        projectApi.deleteBiddingCompetitivenessAll(id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getBiddingCompetitivenessAnalysis();
            setCheckCompanyList([]);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const deleteCompetitors = (item) => {
    console.log(item);
    MessageModal({
      type: "warning",
      description: `确定要删除竞争对手"${item.bid_winner}"吗？`,
      title: "删除",
      onOk: () => {
        projectApi.deleteBiddingCompetitiveness(item.id, id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setCheckCompanyList((prev) => {
              prev = prev?.filter((_) => _ !== item?.bid_winner);
              return [...prev];
            });
            setTimeout(() => {
              getBiddingCompetitivenessAnalysis();
            }, 100);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const addCompetitors = () => {
    getLicenseValid().then((res) => {
      if (res) {
        setDrawerTitle("新增竞争对手");
        setFormList([
          {
            field: "bid_winner",
            field_name: "竞争对手名称（企业名称）",
            index: 1,
            is_required: true,
            options: [],
            search_place_holder: "请输入需要查询的企业名称",
            type: "search_company_person",
            type_key: "r_type",
            width: 100,
            only_need_name: true,
          },
        ]);
        setTimeout(() => {
          setDrawerVisible(true);
        }, 100);
      }
    });
  };

  const success = (obj) => {
    setOkLoading(true);
    projectApi
      .addBiddingCompetitiveness({
        project_id: id,
        bid_winner: obj.bid_winner,
      })
      .then((res) => {
        if (res.code === 200) {
          setDrawerVisible(false);
          // getBiddingCompetitivenessAnalysis();
          getBiddingCompetitivenessStatus(true);
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setOkLoading(false);
      });
  };

  const columns = useRef([
    {
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      align: "center",
      width: "80px",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      key: "bid_winner",
      title: "竞争对手",
      dataIndex: "bid_winner",
      align: "center",
      render: (text, record, index) => (
        <>
          {text}
          {getIsDown() ? (
            <span style={{ color: "#9e9e9e" }}>（数据获取中）</span>
          ) : (
            <span style={{ color: "#0068B2" }}>
              （{record?.competitive_count}）
            </span>
          )}
        </>
      ),
    },
    {
      key: "操作",
      title: "操作",
      dataIndex: "操作",
      align: "center",
      width: "80px",
      render: (text, record, index) => (
        <Tooltip placement="top" title="删除" arrowPointAtCenter>
          <ReactSVG
            onClick={(e) => {
              e.stopPropagation();
              deleteCompetitors(record);
            }}
            className="deleteIcon hoverIcon"
            src={deleteIcon}
          ></ReactSVG>
        </Tooltip>
      ),
    },
  ]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "竞争对手列表",
        is_bid: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  //上传成功回调
  const uploadFileSuccess = (files) => {
    setUpLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file[]", file?.originFileObj);
    });
    projectApi.checkUploadBiddingCompetitiveness(id, formData).then((res) => {
      if (res.code === 200) {
        setUpData(res.data);
        setFileVisible(false);
        setVisible(true);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  const uploadSuccess = () => {
    setLoading(true);
    projectApi
      .upBiddingCompetitiveness(
        id,
        JSON.stringify(upData?.success_data || []),
        upData?.file_name
      )
      .then((res) => {
        if (res.code === 200) {
          setVisible(false);
          // getBiddingCompetitivenessAnalysis();
          getBiddingCompetitivenessStatus(true);
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setLoading(false);
      });
    // onOk();
  };

  const columns2 = useRef([
    {
      title: "行号",
      dataIndex: "idx",
      key: "idx",
      width: 70,
    },
    {
      title: "竞争对手",
      dataIndex: "company",
      key: "company",
      width: 240,
    },
    {
      title: "校验结果",
      key: "fail_msg",
      render: (_, record) => (
        <div style={{ color: "red" }}>{record.fail_msg}</div>
      ),
    },
  ]);

  if (!projectInfo?.project_permission_urls.includes("CDD_PRJ_CDD_ITEM_EDIT")) {
    columns.current = columns.current?.filter((_) => _.key !== "操作");
  }
  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="competitivenessAnalysisContent">
      <div className="dataInput">
        更新时间：{updateTime || "-"}
        {getIsDown() && (
          <Tag color="#0068B2" style={{ marginLeft: "10px" }}>
            中标数据正在获取中
          </Tag>
        )}
      </div>

      <div className="chartsBox">
        <div className="chartsLeftBox">
          {/* 竞争对手列表 */}
          <div className="chartBox">
            <div className="headerTitleBox">
              <div className="imgTitleBox">
                <img src={comSvg}></img> 公司列表
              </div>
              <div style={{ display: "flex" }}>
                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                ) &&
                  competitiveData?.length > 0 && (
                    <Tooltip
                      placement="top"
                      title="全部删除"
                      arrowPointAtCenter
                    >
                      <ReactSVG
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteCompetitorsAll();
                        }}
                        className="deleteIcon hoverIcon"
                        src={deleteIcon}
                      ></ReactSVG>
                    </Tooltip>
                  )}

                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                ) &&
                  (competitiveData?.length > 0 ? (
                    <Tooltip
                      placement="top"
                      title="添加竞争对手"
                      arrowPointAtCenter
                    >
                      <PlusCircleOutlined
                        style={{ marginLeft: "10px" }}
                        className="hoverIcon"
                        onClick={() => {
                          addCompetitors();
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip zIndex={2} open={true} title="请先添加竞争对手">
                      <PlusCircleOutlined
                        style={{ marginLeft: "10px" }}
                        className="hoverIcon"
                        onClick={() => {
                          addCompetitors();
                        }}
                      />
                    </Tooltip>
                  ))}
                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EXPORT"
                ) &&
                  (downLoading ? (
                    <LoadingOutlined
                      style={{ marginLeft: "10px" }}
                    ></LoadingOutlined>
                  ) : (
                    <Tooltip placement="top" title="下载" arrowPointAtCenter>
                      <VerticalAlignBottomOutlined
                        style={{ marginLeft: "10px" }}
                        className="hoverIcon"
                        onClick={() => {
                          if (competitiveData?.length === 0) {
                            return;
                          }
                          downloadFile();
                        }}
                      />
                    </Tooltip>
                  ))}
                {projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                ) && (
                  <Tooltip
                    placement="top"
                    title="上传竞争对手"
                    arrowPointAtCenter
                  >
                    <VerticalAlignTopOutlined
                      style={{ marginLeft: "10px" }}
                      className="hoverIcon"
                      onClick={() => {
                        getLicenseValid().then(res=>{
                          if(res){
                            setFileVisible(true);
                          }
                        })
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="tableContentBox">
              {/* <Table
                rowKey={() => Math.random()}
                // onRow={(record) => {
                //   return {
                //     onClick: (event) => {
                //       clickRow(record);
                //     }, // 点击行
                //   };
                // }}
                className="myFormTable2"
                bordered={false}
                pagination={false}
                columns={columns.current}
                dataSource={competitiveData}
                scroll={{ y: "218px" }}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
              ></Table> */}
              {competitiveData?.length > 0 && (
                <Input
                  placeholder="输入公司名称搜索"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  prefix={<SearchOutlined />}
                  style={{
                    width: "calc(100% - 32px)",
                    borderRadius: "18px",
                    margin: "0 16px 10px 16px",
                  }}
                ></Input>
              )}

              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "calc(100% - 50px)", width: "100%" }}
              >
                {competitiveData?.length > 0 ? (
                  <div
                    style={{ padding: "0 16px", width: "100%", height: "100%" }}
                  >
                    {competitiveData?.filter((_) => {
                      if (!getSearchValue()) {
                        return true;
                      }
                      return _?.bid_winner?.includes(getSearchValue());
                    })?.length !== 0 ? (
                      competitiveData
                        ?.filter((_) => {
                          if (!getSearchValue()) {
                            return true;
                          }
                          return _?.bid_winner?.includes(getSearchValue());
                        })
                        ?.map((item, index) => (
                          <CompanyCard
                            key={item.id}
                            info={item}
                            index={index + 1}
                            type="竞争对手"
                            deleteFunc={deleteCompetitors}
                            checkCompanyList={checkCompanyList}
                            dataList={competitiveData}
                            setCheckCompanyList={setCheckCompanyList}
                          ></CompanyCard>
                        ))
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MyEmpty></MyEmpty>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ padding: "0 16px", width: "100%", height: "100%" }}
                  >
                    {[1, 2, 3, 4, 5]?.map((item) => (
                      <CompanyCard
                        key={item}
                        index={item}
                        empty={true}
                      ></CompanyCard>
                    ))}
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>

        <div className="chartsRightBox">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{ height: "100%", width: "100%" }}
          >
            {/* 竞争对手历年中标情况 */}
            <div className="chartBox">
              <BidWinningSituation
                data={(data?.competitive_history_bid_count || [])?.filter(
                  (item) => getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                bidCompanyName={bidCompanyName}
                keyList={competitiveData}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
              ></BidWinningSituation>
            </div>
            {/* 竞争对手项目类型对比分析 */}
            <div className="chartBox">
              <TypeComparison
                data={(data?.competitive_bid_info_type_count || [])?.filter(
                  (item) => getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                bidCompanyName={bidCompanyName}
                keyList={competitiveData}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
              ></TypeComparison>
            </div>

            {/* 竞争对手客户粘度对比分析 */}
            <div className="chartBox">
              <ViscosityAnalysis
                data={(data?.competitive_tendree_bid_count || [])?.filter(
                  (item) => getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                bidCompanyName={bidCompanyName}
                keyList={competitiveData}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
              ></ViscosityAnalysis>
            </div>
          </Scrollbars>
        </div>
      </div>

      <DialogDrawer
        okLoading={okLoading}
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultData}
        formList={formList}
        onOk={success}
        visible={drawerVisible}
        onCancel={() => {
          setDrawerVisible(false);
        }}
      ></DialogDrawer>

      <UploadFileDrawer
        loading={upLoading}
        visible={fileVisible}
        onClose={() => setFileVisible(false)}
        successFunc={uploadFileSuccess}
        title="上传竞争对手"
        multiple={true}
      ></UploadFileDrawer>

      <Modal
        title="上传数据校验"
        wrapClassName="uploadModal"
        visible={visible}
        width={900}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setVisible(false);
          // getBiddingCompetitivenessAnalysis();
        }}
        centered
      >
        <div className="uploadFileModalContent">
          <div className="uploadInfo">
            共读取到
            <span style={{ fontSize: "16px", color: "#313131" }}>
              {upData?.total}
            </span>
            条数据，校验通过
            <span style={{ fontSize: "16px", color: "#61D4AD" }}>
              {upData?.success}
            </span>
            条，校验失败
            <span style={{ fontSize: "16px", color: "#FE545F" }}>
              {upData?.fail}
            </span>
            条
          </div>
          {upData?.fail_data?.length > 0 ? (
            <div className="uploadTip">
              以下数据校验失败，请修改后再重新上传，如果继续上传将忽略以下数据。
            </div>
          ) : null}
          {upData?.fail_data?.length > 0 ? (
            <Table
              className="myFormTable"
              rowClassName="upLoadFileModalTableRow"
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowKey={(record) => record.idx}
              bordered={true}
              pagination={false}
              scroll={{
                y: "50vh",
              }}
              columns={columns2.current}
              dataSource={upData?.fail_data || []}
            />
          ) : null}

          <div className="uploadBtnBox">
            <Button
              className="noBg"
              onClick={() => {
                setVisible(false);
                // getBiddingCompetitivenessAnalysis();
              }}
            >
              暂不上传
            </Button>
            <Button
              type="primary"
              onClick={() => {
                uploadSuccess();
              }}
              loading={loading}
            >
              继续上传
            </Button>
            {/* <Button
              type="primary"
              onClick={() => {
                setVisible(false);
                getBiddingCompetitivenessAnalysis();
              }}
              loading={loading}
            >
              确定
            </Button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CompetitivenessAnalysis;
