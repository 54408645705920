import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect, useRef } from "react";
import HeaderTitle from "../../components/HeaderTitle";
import Subscribe from "../Subscribe";
import MyProjects from "../MyProjects";
import {
  PlusCircleFilled,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import icon1 from "../../assets/前期开发尽调项目(1).svg";
import icon2 from "../../assets/IPO申报尽调项目(1).svg";
import icon3 from "../../assets/自定义尽调项目(1).svg";

import Follow from "../Follow";
import ProjectApi from "../../apis/project/index";
import CreatProjectDrawer from "../CreatProjectDrawer";
import { useSize, useLongPress, useMouse, useEventListener } from "ahooks";
import { useSelector } from "react-redux";
import { Empty, message, Popover, Avatar } from "antd";
import { ReactSVG } from "react-svg";

import Slider from "react-slick";

import emptyImg from "../../assets/no1.png";
import MySpin from "../../components/MySpin";
import { getAdminsDom, getContactBusinessDom, getLicenseValid, verifyAdmin } from "../../unit/unit";
const RightBox = (props) => {
  const moveRef = useRef(null);
  const contentRef = useRef(null);
  const mouse = useMouse(contentRef.current);
  const [isMove, setIsMove] = useState(false);
  const [y, setY] = useState(365);
  const { activeType, setActiveType } = props;
  useLongPress(
    () => {
      setIsMove(true);
    },
    moveRef,
    {
      delay: 0,
    }
  );

  useEventListener("mouseup", () => {
    setIsMove(false);
  });

  useEffect(() => {
    if (!isMove) {
      return;
    }
    const minH = 250;
    if (
      mouse.elementY > minH + 10 &&
      mouse.elementH - mouse.elementY > minH + 10
    ) {
      setY(mouse.elementY);
    }
  }, [mouse]);
  return (
    <div className="rightBox" ref={contentRef}>
      {/* 关注 */}
      <div className="rightTop" style={{ height: y - 10 + "px" }}>
        <Follow
          hasHeader={true}
          defaultType={activeType}
          setActiveType={setActiveType}
          isHome={true}
        ></Follow>
      </div>
      <div className="line">
        <div
          className={isMove ? "moveBox activeMove" : "moveBox"}
          ref={moveRef}
          style={{ cursor: isMove ? "move" : "pointer" }}
        ></div>
      </div>
      {/* 订阅 */}
      <div
        className="rightBottom"
        style={{ height: `calc(100% - ${y - 10 + 20}px)` }}
      >
        <Subscribe hasHeader={true}></Subscribe>
      </div>
    </div>
  );
};
const Home = (props) => {
  const colorList = [
    {
      color: "#1A7E7E",
      iconColor: "#16D8D8",
      background:
        "linear-gradient(139deg, rgba(22, 216, 216, 0.4000000059604645) 0%, rgba(22, 216, 216, 0.10000000149011612) 100%)",
    },
    {
      color: "#5A801A",
      iconColor: "#AAD461",
      background:
        "linear-gradient(139deg, rgba(170, 212, 97, 0.4000000059604645) 0%, rgba(170, 212, 97, 0.10000000149011612) 100%)",
    },
    {
      color: "#805D20",
      iconColor: "#FEC054",
      background:
        "linear-gradient(139deg, rgba(254, 192, 84, 0.4000000059604645) 0%, rgba(254, 192, 84, 0.10000000149011612) 100%)",
    },
  ];
  const iconList = [icon1, icon2, icon3];
  const [creatTypeList, setCreatTypeList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [creatTypeLoading, setCreatTypeLoading] = useState(true);

  const [projectType, setProjectType] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [activeKey, setActiveKey] = useState("我的关注");
  const [activeType, setActiveType] = useState("我的");
  const userPermission = useSelector((state) => state.User.userPermission);
  const userInfo = useSelector((state) => state.User.userInfo) || {};
  const admins = useSelector((state) => state.User.admins) || {};

  const carouselRef = useRef(null);

  const [notHasPrev, setNotHasPrev] = useState(true);
  const [notHasNext, setNotHasNext] = useState(false);

  useEffect(() => {
    ProjectApi.getScenes().then((res) => {
      const list = [];
      if (res.code == 200) {
        res.data.forEach((item, index) => {
          list.push({
            ...item,
            title: item.scene_name + "尽调项目",
            ...colorList[index % 3],
            icon: iconList[index % 3],
          });
        });
      }
      // list.push({
      //   title: "自定义尽调项目",
      //   scene_code: "auto",
      //   id: 9999,
      //   ...colorList[list.length % 3],
      // });
      setCreatTypeList(list);
      setCreatTypeLoading(false);
    });
  }, []);

  const CreatProjectHandle = (item) => {
    //判断是否授权
    getLicenseValid(true).then((res) => {
      if (res) {
        setProjectType(item.scene_code);
        setVisible(true);
      } else if (res === false) {
        //未授权
        message.warning({
          content: verifyAdmin(userInfo) ? (
            <span>
              产品授权到期，无法创建新项目，请
              {getContactBusinessDom()}
              续订
            </span>
          ) : (
            <span>
              产品授权到期，该功能暂时无法使用，请联系
              {getAdminsDom()}
              续订
            </span>
          ),
        });
      }
    });
  };

  const creatSuccess = () => {
    setVisible(false);
    setRefresh(true);
    setTimeout(() => {
      // setActiveKey("我关注的");
      setRefresh(false);
    }, 100);
  };

  if (refresh) {
    return <></>;
  }
  return (
    <div className="homeContent">
      <div className="leftBox">
        {/* 项目信息 */}

        <div className="leftTop">
          <HeaderTitle title="新建项目"></HeaderTitle>
          {creatTypeLoading ? (
            <div className="creatCardBox">
              <MySpin></MySpin>
            </div>
          ) : (
            <div className="creatCardBox">
              {creatTypeList?.length === 0 ? (
                <div>
                  <Empty
                    image={emptyImg}
                    description="没有新建项目权限,请联系管理员!"
                  />
                </div>
              ) : (
                <Slider
                  dots={true}
                  infinite={false}
                  speed={500}
                  slidesToShow={3}
                  slidesToScroll={1}
                  // autoplay={true}
                  // autoplaySpeed={5000}
                  ref={carouselRef}
                  beforeChange={(current, next) => {
                    setNotHasPrev(next === 0);
                    setNotHasNext(next + 3 === creatTypeList?.length);
                  }}
                >
                  {creatTypeList.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        display: "block",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        className="creatCard"
                        style={{ background: item.background }}
                        bg={item.background}
                        onClick={() => {
                          if (userPermission?.includes("CDD_PRJ_CREATE")) {
                            CreatProjectHandle(item);
                          } else {
                            message.info("你没有新建项目权限，请联系管理员");
                          }
                        }}
                      >
                        <div className="title" style={{ color: item.color }}>
                          {item.title}
                        </div>
                        <div className="text" style={{ color: item.color }}>
                          {item.scene_describe}
                        </div>
                        <div className="iconBox">
                          <PlusCircleFilled
                            style={{
                              color: item.iconColor,
                              fontSize: "34px",
                              position: "absolute",
                              bottom: "10px",
                            }}
                          />

                          <ReactSVG
                            src={item.icon}
                            className="iconS"
                          ></ReactSVG>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}

              {creatTypeList?.length > 3 && !notHasPrev && (
                <LeftOutlined
                  className="carouselIcon LeftOutlined"
                  onClick={() => carouselRef.current?.slickPrev()}
                />
              )}

              {creatTypeList?.length > 3 && !notHasNext && (
                <RightOutlined
                  className="carouselIcon RightOutlined"
                  onClick={() => carouselRef.current?.slickNext()}
                />
              )}
            </div>
          )}
        </div>

        <div className="leftBottom">
          <MyProjects
            setActiveKey={setActiveKey}
            defaultKey={activeKey}
          ></MyProjects>
        </div>
      </div>

      <RightBox
        activeType={activeType}
        setActiveType={setActiveType}
      ></RightBox>

      <CreatProjectDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        successFunc={creatSuccess}
        projectType={projectType}
      ></CreatProjectDrawer>
    </div>
  );
};

export default Home;
