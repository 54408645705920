import {
    createSlice
} from '@reduxjs/toolkit';
const BondProjectInfo = createSlice({
    name: 'project',
    initialState: {
        bondProjectInfo: {},
        bondMenuList: []
    },
    reducers: {
        setBondProjectInfo: (state, action) => {
            state.bondProjectInfo = action.payload
        },
        setBondMenusList: (state, action) => {
            state.bondMenuList = action.payload
        },
    },
});
export const {
    setBondProjectInfo,
    setBondMenusList
} = BondProjectInfo.actions;

export default BondProjectInfo;