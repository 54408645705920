import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { Tabs, message } from "antd";
import MyTable from "../../../components/MyTable";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import projectApi from "../../../apis/project";

import MyDiv from "../../../components/MyDiv/index";
import { guid } from "../../../unit/unit.js";
import MySpin from "../../../components/MySpin";
import useUrlState from "@ahooksjs/use-url-state";

const { TabPane } = Tabs;

const KeyPersonnel = (props) => {
  const { id } = useParams();
  const [projectInfo, setProjectInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const tabs = useRef({});
  const [isBond,setIsBond] = useState(undefined)
  useEffect(() => {
    if (id === undefined) {
      // navigate("/projectManagement");
      return;
    }
    getProjectInfo();
  }, [id]);

  const getProjectInfo = () => {
    setLoading(true);
    projectApi.getProjectInfo(id).then((res) => {
      if (res.code === 200) {
        setProjectInfo(res.data.data[0]);
        setIsBond(res.data.data[0]?.is_listed_company === 1)
        tabs.current = res.data.data[0]?.is_listed_company === 1
          ? {
              最新公示: "listed_stock_management",
              历史公示: "listed_stock_management_his",
              工商登记: "company_personnel_information",
              历史工商登记: "company_personnel_history",
            }
          : {
              工商登记: "company_personnel_information",
              历史工商登记: "company_personnel_history",
            };
        setLoading(false);
      } else {
        message.error(res.message);
      }
    });
  };

  const typeNameList = {
    最新公示: "主要人员(公示)",
    历史公示: "主要人员(历史公示)",
    工商登记: "主要人员(工商)",
    历史工商登记: "主要人员(历史工商)",
  };

  const [type, setType] = useState("");

  const [showHeader, setShowHeader] = useState(true);
  const [dataInfo, setDataInfo] = useState({});
  const tableRef = useRef(null);

  const [state, setState] = useUrlState({});

  useEffect(() => {
    if(isBond === undefined){
      return
    }
    let _type =  isBond ? "最新公示" : "工商登记";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
    }
    setType(_type);
  }, [isBond]);

  const tabChange = (key) => {
    setType(key);
    setDataInfo({})
  };

  return (
    <div className="keyPersonnelContent">
      <HeaderTitle title="主要人员"></HeaderTitle>
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        id: guid(),
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        tableRef.current?.getData();
                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "calc(100% + 10px)", width: "100%" }}
                >
                  <div className="infoBox">
                    <MyTable
                      typeName={typeNameList[key]}
                      dataTable={tabs.current[key]}
                      dataChange={setDataInfo}
                      ref={tableRef}
                    ></MyTable>
                  </div>
                </Scrollbars>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
    </div>
  );
};
export default KeyPersonnel;
