import "./index.scss";
import {
  Button,
  Drawer,
  Modal,
  Form,
  Select,
  Table,
  message,
  Input,
  Tooltip,
  Tag,
  Avatar,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import projectApi from "../../../../apis/project/index";
import deleteIcon from "../../../../assets/删除.svg";
import { ReactSVG } from "react-svg";
import { useSize } from "ahooks";

const { Search } = Input;

const { Option } = Select;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
      >
        确定
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};
const UserDrawer = (props) => {
  const { info, successFunc } = props;
  const [userOptions, setUserOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const myInfo = useSelector((state) => state.User.userInfo);

  const size = useSize(document.querySelector("body"));

  const userRoleChange = (key, item) => {
    setUserList((prev) => {
      let result = prev.map((_item) => {
        if (item.id === _item.id) {
          let roleInfo = roleOptions.find((item) => item.dic_item_code === key);
          _item.role_code = key;
          _item.user_role = roleInfo.dic_item_name;
        }
        return _item;
      });
      return [...result];
    });
  };

  const deleteUser = (item) => {
    setUserList((prev) => {
      let result = prev.filter((_item) => item.id !== _item.id);
      return [...result];
    });
  };

  const columns = [
    {
      title: "用户名",
      dataIndex: "user_name",
      key: "user_name",
      align: "left",
      minWidth: "150px",
      render: (text, item) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              whiteSpace: "break-spaces",
            }}
          >
            <Avatar
              size={32}
              src={item.pf_photo}
              style={{ marginRight: "8px", flex: "none" }}
            ></Avatar>
            {text}
          </div>
        );
      },
    },
    {
      title: "姓名",
      dataIndex: "user_code",
      key: "user_code",
      align: "center",
    },
    {
      title: "角色",
      dataIndex: "user_role",
      key: "user_role",
      width: "150px",
      render: (text, item) => {
        if (item.disable || !canEdit) {
          return <div>{item.user_role}</div>;
        }
        return (
          <Select
            style={{ width: "150px" }}
            defaultValue={item.role_code}
            bordered={false}
            onChange={(key) => {
              userRoleChange(key, item);
            }}
            // getPopupContainer={triggerNode => ((userList?.length > 3 ? triggerNode.parentNode : null) || document.body)}
          >
            {roleOptions.map((item) => (
              <Option key={item.dic_item_code} value={item.dic_item_code}>
                {item.dic_item_name}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];
  if (canEdit) {
    columns.push({
      title: "操作",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "40px",
      render: (text, item) =>
        item.disable ? (
          <></>
        ) : (
          <Tooltip placement="top" title="删除" arrowPointAtCenter>
            <ReactSVG
              src={deleteIcon}
              className="deleteIcon"
              onClick={() => {
                deleteUser(item);
              }}
            ></ReactSVG>
          </Tooltip>
        ),
    });
  }
  const getUsers = () => {
    return projectApi.getUsers().then((res) => {
      let arr = [];
      if (res.code === 200) {
        Object.values(res.data).forEach((item) => {
          arr = arr.concat(item);
        });
      }

      // setCanEdit
      let options = arr;
      // if(){
      //   options = arr.filter((item) => item.user_id !== myInfo.co_user_id);
      // }
      setUserOptions(options);
      return arr;
    });
  };

  const getRole = () => {
    return projectApi.getDicCodeInfo("CDD_PRJ_ROLE").then((res) => {
      let arr = [];
      if (res.code === 200) {
        arr = res.data;
      }
      arr = arr.filter((item) => item.dic_item_code !== "CDD_PRJ_ROLE_CREATOR");
      let obj = info?.member_list?.find((_) => _.user_id === myInfo.co_user_id);
      if (obj?.role_code === "CDD_PRJ_ROLE_MEM") {
        arr = arr.filter((item) => item.dic_item_code !== "CDD_PRJ_ROLE_ADMIN");
      }
      setRoleOptions(arr);
      return res.data;
    });
  };

  const clearAll = () => {
    setUserList([]);
    setIsFirst(true);
    setUserOptions([]);
    setRoleOptions([]);
    form?.resetFields();
  };

  const initUserList = (list) => {
    let _obj = info?.member_list?.find((_) => _.user_id === myInfo.co_user_id);
    let arr = list.map((item) => {
      let obj = {
        key: item.user_id,
        id: item.user_id,
        user_code: item.assist_name,
        user_name: item.name,
        user_role: item.role_code_name,
        role_code: item.role_code,
        pf_photo: item.pf_photo,
        disable:
          item.role_code === "CDD_PRJ_ROLE_CREATOR" ||
          item.user_id === myInfo.co_user_id ||
          (_obj?.role_code === "CDD_PRJ_ROLE_MEM" &&
            item.role_code === "CDD_PRJ_ROLE_ADMIN"),
      };
      return obj;
    });
    arr = arr.sort((a, b) => {
      if (a.disable && b.disable) {
        return 0;
      } else if (a.disable) {
        return -1;
      } else if (b.disable) {
        return 1;
      } else {
        return 0;
      }
    });
    setIsFirst(true);
    setUserList(arr);
    setLoading(false);
    setTimeout(() => {
      setIsFirst(false);
    }, 100);
  };

  useEffect(() => {
    // let userInfo = userList?.find((item) => item.id === myInfo.co_user_id);
    if (userList?.length > 0 && !isFirst) {
      onFinish(true);
    }
  }, [userList]);

  useEffect(() => {
    if (info?.project_permission_urls?.includes("CDD_PRJ_MBR_MGT")) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [info]);

  useEffect(() => {
    if (!props.visible) {
      clearAll();
      if (canEdit) {
        successFunc && successFunc();
      }
    } else {
      setLoading(true);
      const userPromise = getUsers();
      const rolePromise = getRole();
      Promise.all([userPromise, rolePromise]).then((res) => {
        initUserList(info.member_list);
      });
    }
  }, [props.visible]);

  const addUser = () => {
    const { userName, userRole } = form.getFieldsValue([
      "userName",
      "userRole",
    ]);
    let fields = [];
    if (!userName || userName?.length === 0) {
      fields.push({ name: "userName", errors: ["请选择用户"] });
    }
    if (!userRole) {
      fields.push({ name: "userRole", errors: ["请选择角色"] });
    }
    if (fields.length > 0) {
      return form.setFields(fields);
    }
    // let hasUser = userList.some((item) => item.id === userName);
    // if (hasUser) {
    //   message.info("不能添加重复用户!");
    //   return;
    // }
    let roleInfo = roleOptions.find((item) => item.dic_item_code === userRole);
    let userList = [];
    userName.forEach((name) => {
      let userInfo = userOptions.find((item) => item.user_id === name);
      userList.push({
        key: userInfo.user_id,
        id: userInfo.user_id,
        user_code: userInfo.assist_name,
        user_name: userInfo.username,
        user_role: roleInfo.dic_item_name,
        role_code: userRole,
        pf_photo: userInfo.pf_photo,
      });
    });
    setUserList((prev) => {
      return [...prev, ...userList];
    });
    form.setFieldsValue({ userName: [], userRole: undefined });
  };

  const filterUserOptions = () => {
    let _userOptions = [];
    _userOptions = userOptions.filter((item) => {
      return !userList.some((_item) => _item.id === item.user_id);
    });
    return _userOptions;
  };

  const userNameChange = (key) => {
    if (key.length > 0) {
      form.setFieldsValue({ userName: key });
    }
    // form.resetFields(["userName"]);
  };

  const roleChange = (key) => {
    form.resetFields(["userRole"]);
    form.setFieldsValue({ userRole: key });
  };

  const onFinish = (noMsg) => {
    const _userList = userList.map((item) => ({
      user_id: item.id,
      role_code: item.role_code,
    }));
    projectApi
      .changeProjectUsers(info.id, JSON.stringify(_userList))
      .then((res) => {
        if (res.code === 200) {
          message.success(res.message);
          !noMsg && successFunc && successFunc(noMsg);
        } else {
          message.error(res.message);
        }
      });
  };

  const invitation = () => {
    projectApi.creatInviteLinks(info.id).then((res) => {
      if (res.code === 200) {
        let link_url = res.data.link_url;
        let url =
          window.location.origin + "/invitation?" + link_url.split("?")?.at(1);
        setLinkUrl(url);
        setIsModalVisible(true);
      } else {
        message.error(res.message);
      }
    });
  };

  const copy = (value) => {
    navigator.clipboard.writeText(value);
    message.success("复制成功");
    setIsModalVisible(false);
  };

  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label, value, closable } = props;
    const item = (filterUserOptions() || []).find((_) => _.user_id === value);
    return (
      <Tag
        style={{
          marginRight: 3,
        }}
        className="optionUserTag"
      >
        <Avatar
          size={26}
          src={item.pf_photo}
          style={{ marginRight: "8px" }}
        ></Avatar>
        {item.username}
      </Tag>
    );
  };

  return (
    <Drawer
      className="userChangeDrawer"
      width="600px"
      closable={false}
      title="成员管理"
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      // maskClosable={false}
      keyboard={false}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      // footer={<BtnBox onClose={props.onClose} onFinish={onFinish}></BtnBox>}
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form form={form} autoComplete="off" layout="vertical">
          <div className="userListBox">
            {canEdit ? (
              <div className="invitation">
                <div className="iconBox" onClick={() => invitation()}>
                  <UserAddOutlined />
                  <span className="text">邀请项目成员</span>
                </div>
                <div></div>
              </div>
            ) : null}

            <div className="searchBox">
              {/* 用户名或姓名 */}
              <Form.Item style={{ width: "calc(50% - 60px)" }} name="userName">
                <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  style={{ width: "100%" }}
                  placeholder="请选择用户名或姓名"
                  onChange={userNameChange}
                  allowClear
                  showSearch
                  tagRender={tagRender}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, option) => {
                    const item = (filterUserOptions() || []).find(
                      (_) => _.user_id === option.value
                    );
                    return (
                      item.username
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      item.assist_name
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    );
                  }}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode || document.body
                  }
                >
                  {(filterUserOptions() || []).map((item) => (
                    <Option
                      key={item.user_id}
                      value={item.user_id}
                      userName={item.name}
                    >
                      <div className="userOptionsItemBox">
                        <div>
                          <Avatar
                            size={26}
                            src={item.pf_photo}
                            style={{ marginRight: "8px" }}
                          ></Avatar>
                          {item.username}
                        </div>
                        <div className="userOptionsItemBoxSpan">
                          {item.assist_name}
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* 角色 */}
              <Form.Item style={{ width: "calc(50% - 60px)" }} name="userRole">
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择角色"
                  onChange={roleChange}
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode || document.body
                  }
                >
                  {roleOptions.map((item) => (
                    <Option key={item.dic_item_code} value={item.dic_item_code}>
                      {item.dic_item_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* 添加按钮 */}
              {canEdit ? (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    addUser();
                  }}
                >
                  添加
                </Button>
              ) : (
                <Tooltip
                  key={Math.random()}
                  placement="topRight"
                  title="请联系项目创建人或项目管理员添加项目成员！"
                >
                  <Button
                    icon={<PlusOutlined />}
                    disabled={true}
                    onClick={() => {
                      addUser();
                    }}
                  >
                    添加
                  </Button>
                </Tooltip>
              )}
            </div>
            <Table
              showHeader={false}
              pagination={false}
              columns={columns}
              dataSource={userList}
              className="myTable userTable"
              loading={loading}
              scroll={{
                y: size?.height - 200 + "px",
              }}
            />
          </div>
        </Form>
      </Scrollbars>

      <Modal
        centered
        footer={null}
        maskClosable={false}
        title="通过链接添加项目成员"
        visible={isModalVisible}
        wrapClassName="myModal invitationModal"
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <div className="content">
          <Search
            enterButton="复制链接"
            value={linkUrl}
            size="large"
            onSearch={copy}
          />
          <div className="tip">链接有效期：3天</div>
        </div>
      </Modal>
    </Drawer>
  );
};
export default UserDrawer;
