import { useEffect, useRef } from "react";
import "./index.scss";
import {
  IeOutlined,
  WechatOutlined,
  AndroidOutlined,
  AppleOutlined,
  WeiboOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  AlipayCircleOutlined,
  DribbbleOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import icon from "../../../../assets/项目管理.svg";
import { ReactSVG } from "react-svg";
import { useGetState, useUnmount } from "ahooks";
const PieMenu = (props) => {
  const { onChange, menuList } = props;

  const [choiceMenu, setChoiceMenu, getChoiceMenu] = useGetState();
  const [allMenuList, setAllMenuList, getAllMenuList] = useGetState([]);

  const colorList = ["#0068B2", "#FEC054", "#FE545F", "#6CAEFF", "#AAD461"];
  const iconList = [
    <IeOutlined></IeOutlined>,
    <WechatOutlined></WechatOutlined>,
    <AndroidOutlined></AndroidOutlined>,
    <AppleOutlined></AppleOutlined>,
    <WeiboOutlined></WeiboOutlined>,
    <YoutubeOutlined></YoutubeOutlined>,
    <FacebookOutlined></FacebookOutlined>,
    <DribbbleOutlined></DribbbleOutlined>,
    <AlipayCircleOutlined></AlipayCircleOutlined>,
    <LinkedinOutlined></LinkedinOutlined>,
  ];

  const timer = useRef(null);

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  useEffect(()=>{
    setAllMenuList(menuList?.filter(_=>!_.disable) || [])
  },[menuList])

  const mkChart = () => {
    const color = "#93caee";
    let El = (el) => document.querySelector(el);
    let Els = (el) => document.querySelectorAll(el);
    //角度
    let edg = 360 / menuList.length;
    //配置各种宽高
    //  直径
    var svgWsm = El("#peiMenu").clientWidth - 100,
      //半径
      svgW2 = svgWsm / 2,
      svgH2 = svgWsm / 2,
      //容器宽高
      svgW = El("#peiMenu").clientWidth,
      svgH = El("#peiMenu").clientHeight;

    //三角形长边
    let h = svgH2,
      //三角形短边
      w = svgH2 * Math.tan((edg * Math.PI) / 180),
      //三角形斜边
      xie = Math.sqrt(
        Math.pow(svgH2, 2) +
          Math.pow(svgH2 * Math.tan((edg * Math.PI) / 180), 2)
      );
    // console.log("角的度数===>", edg);
    // console.log("高===>", h);
    // console.log("宽===>", w);
    // console.log("斜边===>", xie);
    // console.log("小宽===>", (h * w) / xie);
    // console.log("小高===>", h - (h * h) / xie);
    // console.log("比例===>", Math.tan((edg * Math.PI) / 180));
    // console.log('=========================');

    //一半的角 形成的三角形的长边
    let h1 = svgH2,
      //一半的角 形成的三角形的短边
      w1 = svgH2 * Math.tan(((edg / 2) * Math.PI) / 180),
      //一半的角 形成的三角形的斜边
      xie2 = Math.sqrt(
        Math.pow(Math.tan(((edg / 2) * Math.PI) / 180) * h1, 2) +
          Math.pow(h1, 2)
      ),
      h2 = (svgH2 * svgH2) / xie2,
      w2 = (h2 * w1) / svgH2;

    // console.log("宽2===>", w1);
    // console.log("斜2===>", xie2);
    // console.log("小宽===>", (h1 * w1) / xie2);
    // console.log("小高2===>", (h1 * h1) / xie2);
    // console.log("比例2===>",Math.tan((edg/2 * Math.PI) / 180));

    var ds = `M${svgW2 + 50} ${svgH2 + 50} L${
      svgW2 + 50
    } 50 A ${svgW2} ${svgH2} 0 0 0 ${svgW2 - (h * w) / xie + 50} ${
      h - (h * h) / xie + 50
    } Z`;

    //循环扇形
    Els(".paths").forEach((item, index) => {
      item.setAttribute("d", ds);
      //自己旋转
      item.style.transform = `rotate(${edg * index + edg / 2}deg)`;
      item.style.transformOrigin = `${svgW2 + 50}px ${svgH2 + 50}px`;

      //触碰
      item.onmouseenter = () => {
        // handleIntervalFunc(false);
        if (item.getAttribute("fill") !== "#0068B2") {
          Els(".list")[index].setAttribute("class", "list active");
          item.setAttribute("fill", color);
        }
      };

      item.onmouseleave = () => {
        // handleIntervalFunc(true);
        if (item.getAttribute("fill") !== "#0068B2") {
          Els(".list")[index].setAttribute("class", "list noAction");
          item.setAttribute("fill", "#EDF6FC");
        }
      };
    });

    //循环扇形内容
    Els(".list").forEach((item, index) => {
      let textItem = Els(".text")[index];
      //自己旋转
      item.style.transform = `rotate(${edg * index + edg / 2}deg)`;
      item.style.transformOrigin = `${svgW2 + 50}px ${svgH2 + 50}px`;
      //子元素反旋转显示正位
      item.children[0].style.transform = `rotate(-${edg * index + edg / 2}deg)`;

      //字体元素 旋转
      textItem.style.transform = `rotate(${edg * index + edg / 2}deg)`;
      textItem.style.transformOrigin = `${svgW2 + 50}px ${svgH2 + 50}px`;
      textItem.children[0].style.transform = `rotate(-${
        (edg * (index - 0.5) > 90 && edg * (index - 0.5) < 270 ? 180 : 0) +
        edg -
        20
      }deg)`;
      //触碰
      item.onmouseenter = () => {
        // handleIntervalFunc(false);
        if (Els(".paths")[index].getAttribute("fill") !== "#0068B2") {
          item.setAttribute("class", "list active");
          Els(".paths")[index].setAttribute("fill", color);
        }
      };
      item.onmouseleave = () => {
        // handleIntervalFunc(true);
        if (Els(".paths")[index].getAttribute("fill") !== "#0068B2") {
          item.setAttribute("class", "list noAction");
          Els(".paths")[index].setAttribute("fill", "#EDF6FC");
        }
      };
      //触碰
      textItem.onmouseenter = () => {
        // handleIntervalFunc(false);
        if (Els(".paths")[index].getAttribute("fill") !== "#0068B2") {
          item.setAttribute("class", "list active");
          Els(".paths")[index].setAttribute("fill", color);
        }
      };
      textItem.onmouseleave = () => {
        // handleIntervalFunc(true);
        if (Els(".paths")[index].getAttribute("fill") !== "#0068B2") {
          item.setAttribute("class", "list noAction");
          Els(".paths")[index].setAttribute("fill", "#EDF6FC");
        }
      };
      //里面的位置
      item.setAttribute("x", svgW / 2 - 0.7 * w2 - svgW2 / 2 / 2);
      item.setAttribute("y", svgH / 2 - 0.7 * h2 - svgW2 / 2 / 2);
      item.setAttribute("width", svgW2 / 2);
      item.setAttribute("height", svgW2 / 2);
      item.children[0].children[0].style.width = svgW2 / 4 + "px";
      item.children[0].children[0].style.height = svgW2 / 4 + "px";
      if (item.children[0].children[0].children[0]) {
        item.children[0].children[0].children[0].style["font-size"] =
          svgW2 / 7 + "px";
      }
      textItem.setAttribute("x", svgW / 2 - 0.9 * w2 - svgW2 / 2 / 2);
      textItem.setAttribute("y", svgW / 2 - 0.75 * h2 - svgW2 / 2 / 2);
      textItem.setAttribute("width", svgW2 / 2);
      textItem.setAttribute("height", 60);
      textItem.children[0].style["font-size"] = svgW2 / 14 + "px";
    });
  };

  useEffect(() => {
    choice(menuList[0]);
    handleIntervalFunc(true);
  }, [menuList]);

  useEffect(() => {
    mkChart();
  });

  const choice = (item) => {
    if (item?.disable) {
      return;
    }
    setChoiceMenu(item);
    onChange(item?.id);
  };

  const nextItem = (index, list) => {
    if (index === list?.length - 1) {
      index = 0;
    } else {
      index = index + 1;
    }
    return list?.at(index);
  };

  const handleIntervalFunc = (isAdd) => {
    // clearTimeout(timer.current); 划入去掉定时器
    if (isAdd) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        let index = getAllMenuList()?.findIndex((_) => _?.id === getChoiceMenu()?.id);
        const item = nextItem(index, getAllMenuList());
        choice(item);
        handleIntervalFunc(true)
      }, 5000);
    }
  };

  const clickChoice = (item) => {
    clearTimeout(timer.current)
    choice(item)
    timer.current = setTimeout(() => {
      handleIntervalFunc(true)
    },60 * 1000)
  }
  return (
    <div className="pieMenuContent">
      <svg id="peiMenu" version="1.1" width="100%" height="100%">
        {menuList.map((item, index) => (
          <path
            key={item?.id}
            className="paths"
            // stroke="#fff"
            strokeWidth="1"
            fill={choiceMenu?.id === item?.id ? "#0068B2" : "#EDF6FC"}
            title={item?.function_name}
            onClick={() => clickChoice(item)}
          />
        ))}
        {menuList.map((item, index) => (
          <foreignObject
            className={
              choiceMenu?.id === item?.id ? "list active" : "list noAction"
            }
            key={item?.id}
            onClick={() => clickChoice(item)}
          >
            <div className="box">
              <div
                className="iconBox"
                style={{
                  backgroundColor: item?.disable
                    ? "#9E9E9E"
                    : choiceMenu?.id === item?.id
                    ? "#FFFFFF"
                    : colorList[index % 4],
                }}
              >
                {<ReactSVG src={item.full_logo_url}></ReactSVG>}
                {/* {item?.icon ? item?.icon : iconList[index]} */}
              </div>
            </div>
          </foreignObject>
        ))}
        {menuList.map((item, index) => (
          <foreignObject
            className="text noAction"
            key={item?.id}
            onClick={() => clickChoice(item)}
          >
            <div>{item?.function_name}</div>
          </foreignObject>
        ))}
      </svg>
    </div>
  );
};
export default PieMenu;
