import "./index.scss";
import {
  Button,
  Drawer,
  Checkbox,
  Input,
  DatePicker,
  message,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../components/HeaderTitle";
import { useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useEffect } from "react";
import projectApi from "../../../../apis/project";
import bondApi from "../../../../apis/bond";
import UserApi from "../../../../apis/user";
import moment from "moment";
import MySpin from "../../../../components/MySpin/index";
import { useSelector } from "react-redux";
import icon1 from "../../../../assets/订阅对象.svg";
import icon2 from "../../../../assets/订阅内容.svg";
import icon3 from "../../../../assets/订阅方式.svg";
import icon4 from "../../../../assets/订阅有效期.svg";
import MessageModal from "../../../../components/MessageModal";
import psl from "psl";
import Cookie from "js-cookie";
import { useGetState } from "ahooks";
import { setUserInfo } from "../../../../store/user/user";

const { Option } = Select;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        loading={props.loading}
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
      >
        确认
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const SubscribeSettingDrawer = (props) => {
  const { projectId, subId, success, subscribeList, isBond, companyId } = props;

  const userInfo = useSelector((state) => state.User.userInfo);
  const dispatch = useDispatch();
  const userTimer = useRef(null);
  /* 订阅对象相关变量 */
  const [subscriptionObjectKey, setSubscriptionObjectKey] = useState([]);
  const [subscriptionObjectIndeterminate, setSubscriptionObjectIndeterminate] =
    useState(false);
  const [subscriptionObjectCheckAll, setSubscriptionObjectCheckAll] =
    useState(false);
  const [subscriptionObjectList, setSubscriptionObjectList] = useState([]);

  const subscriptionObjectCheckListChange = (keys) => {
    setSubscriptionObjectKey(keys);
    setSubscriptionObjectIndeterminate(
      !!keys.length && keys.length < subscriptionObjectList.length
    );
    setSubscriptionObjectCheckAll(
      keys.length === subscriptionObjectList.length
    );
  };

  const onSubscriptionObjectCheckAllChange = (e) => {
    setSubscriptionObjectKey(
      e.target.checked ? subscriptionObjectList.map((item) => item.id) : []
    );
    setSubscriptionObjectIndeterminate(false);
    setSubscriptionObjectCheckAll(e.target.checked);
  };

  /* 订阅内容相关变量 */
  const [subscriptionContentAllKey, setSubscriptionContentAllKey] = useState(
    []
  );
  const [subscriptionContentKey, setSubscriptionContentKey] = useState([]);
  const [subscriptionContentList, setSubscriptionContentList] = useState([]);
  const [
    subscriptionContentIndeterminate,
    setSubscriptionContentIndeterminate,
  ] = useState(false);
  const [subscriptionContentCheckAll, setSubscriptionContentCheckAll] =
    useState(false);

  const subscriptionContentCheckListChange = (keys) => {
    setSubscriptionContentKey(keys);
    setSubscriptionContentIndeterminate(
      !!keys.length && keys.length < subscriptionContentAllKey.length
    );
    setSubscriptionContentCheckAll(
      keys.length === subscriptionContentAllKey.length
    );
  };

  const onSubscriptionContentCheckAllChange = (e) => {
    setSubscriptionContentKey(
      e.target.checked ? subscriptionContentAllKey : []
    );
    setSubscriptionContentIndeterminate(false);
    setSubscriptionContentCheckAll(e.target.checked);
  };

  useEffect(() => {
    let arr = subscriptionContentList.map((item) => item.dic_item_code);
    setSubscriptionContentAllKey(arr);
  }, [subscriptionContentList]);

  /* 订阅方式 */
  const [typeList, setTypeList] = useState([]);
  const [date, setDate] = useState(null);

  const [projectList, setProjectList] = useState([]);
  const [proId, setProId] = useState(null);

  const [expirationTime, setExpirationTime, getExpirationTime] =
    useGetState(null);

  useEffect(() => {
    projectId && setProId(Number(projectId));
  }, [projectId]);

  useEffect(() => {
    companyId && setProId(Number(companyId));
  }, [companyId]);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const onFinish = () => {
    if (!proId) {
      if (isBond) {
        message.info("请选择发行人");
        return;
      }
      message.info("请选择尽调项目");
      return;
    }
    const types = typeList.filter((item) => item.checked && item.contentText);
    if (subscriptionObjectKey.length === 0) {
      message.info("请至少选择一项订阅对象");
      return;
    }
    if (subscriptionContentKey.length === 0) {
      message.info("请至少选择一项订阅内容");
      return;
    }
    if (types.length === 0) {
      message.info("请选择订阅方式");
      return;
    }
    if (!date) {
      message.info("请选择订阅有效期");
      return;
    }
    const sub_way_list = types.map((item) => {
      const obj = {
        sub_way_code: item.dic_item_code,
      };

      if (item.dic_item_code === "CDD_SUBS_SMS") {
        obj.phone = item.contentText;
      }
      if (item.dic_item_code === "CDD_SUBS_EMAIL") {
        obj.email = item.contentText;
      }
      return obj;
    });
    setUploading(true);
    const params = {
      valid_limit_time: moment(date).format("YYYY-MM-DD"),
      sub_object_list: JSON.stringify(
        subscriptionObjectKey.map((item) => ({ sub_object_code: item }))
      ),
      sub_content_list: JSON.stringify(
        subscriptionContentKey.map((item) => ({ sub_content_code: item }))
      ),
      sub_way_list: JSON.stringify(sub_way_list),
    };
    if (subId) {
      params.sub_id = subId;
    }
    let api = projectApi;
    if (isBond) {
      api = bondApi;
    }
    api.setSub(proId, params).then((res) => {
      if (res.code === 200) {
        props.onClose && props.onClose();
        success && success();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      setUploading(false);
    });
  };
  //获取订阅内容
  const getSubContent = (projectId) => {
    if (isBond) {
      return projectApi.getDicCodeInfo("CDD_BOND_MODULE").then((res) => {
        if (res.code === 200) {
          setSubscriptionContentList(res.data);
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }

    if (!projectId) {
      return Promise.resolve([]);
    }
    return projectApi.getSubContent(projectId).then((res) => {
      if (res.code === 200) {
        setSubscriptionContentList(res.data);
        return Promise.resolve(res.data);
      }
      return Promise.reject(res);
    });
  };

  const creatUserTimer = () => {
    if (userTimer.current) {
      clearTimeout(userTimer.current);
      userTimer.current = null;
    }
    userTimer.current = setTimeout(() => {
      getUserInfoFunc();
    }, 10 * 1000);
  };

  const getUserInfoFunc = async () => {
    let { data } = await UserApi.getUserInfo();
    dispatch(setUserInfo(data));
    if (!data?.email) {
      creatUserTimer();
    }else{
      clearTimeout(userTimer.current);
      userTimer.current = null;
      setTypeList(prev=>{
        prev = prev.map(_=>{
          if(_.dic_item_code === 'CDD_SUBS_EMAIL'){
            _.contentText = data?.email;
          }
          return _
        })
        return [...prev]
      })
    }
  };

  useEffect(() => {
    if (!props.visible) {
      setProjectList([]);
      setProId(null);
      setSubscriptionContentList([]);
      if (userTimer.current) {
        clearTimeout(userTimer.current);
        userTimer.current = null;
      }
      return;
    }
    if (isBond) {
      companyId && setProId(Number(companyId));
    } else {
      projectId && setProId(Number(projectId));
    }
    setLoading(true);
    //订阅时间
    let getLicenseValid = projectApi.getLicenseValid;
    if (isBond) {
      getLicenseValid = projectApi.getBondLicenseValid;
    }
    getLicenseValid().then((res) => {
      if (res.code === 200) {
        let date = res.data?.at(1);
        setExpirationTime(date);
        if (moment(date)?.isBefore(moment(new Date())?.add(1, "years"))) {
          setDate(moment(date));
        } else {
          setDate(moment(new Date())?.add(1, "years"));
        }
      } else {
        setExpirationTime(null);
      }
    });
    //尽调对象
    let promise1 = projectApi
      .getDicCodeInfo(isBond ? "CDD_BOND_RPI" : "CDD_SUB")
      .then((res) => {
        if (res.code === 200) {
          setSubscriptionObjectList(
            res.data.map((item) => {
              item.value = isBond ? item.dic_item_desc : item.dic_item_name;
              item.id = item.dic_item_code;
              return item;
            })
          );
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
    //订阅内容
    let promise2 = getSubContent(projectId);
    //订阅方式
    let promise3 = projectApi.getDicCodeInfo("CDD_SUBS_METHOD").then((res) => {
      if (res.code === 200) {
        //隐藏短信
        setTypeList(
          res.data
            .filter((_) => _.dic_item_code !== "CDD_SUBS_SMS")
            .map((item) => {
              if (item.dic_item_code === "CDD_SUBS_SMS") {
                item.contentText = userInfo?.mobile_no;
              }
              if (item.dic_item_code === "CDD_SUBS_EMAIL") {
                item.contentText = userInfo?.email;
              }
              return item;
            })
        );
        return Promise.resolve(res.data);
      }
      return Promise.reject();
    });
    //尽调列表
    let promise4 = null;
    if (isBond) {
      promise4 = bondApi
        .getIssuersList({
          page_size: 99999,
          page: 1,
        })
        .then((res) => {
          if (res.code === 200) {
            let data = [];
            if (companyId) {
              data = res.data.data.filter((_) => _.company_id === companyId);
            } else {
              data = res.data.data.filter((item) => {
                return !(subscribeList || []).some(
                  (_) => _.company_id === item.company_id
                );
              });
            }
            setProjectList(data);
          } else {
            setProjectList([]);
          }
        });
    } else {
      promise4 = projectApi.getProjectList().then((res) => {
        if (res.code === 200) {
          let data = [];
          if (projectId) {
            data = res.data.data.filter((_) => _.id == projectId);
          } else {
            data = res.data.data.filter((item) => {
              return (
                !(subscribeList || []).some((_) => _.project_id === item.id) &&
                item?.project_permission_urls?.includes("CDD_PRJ_SUBSCRIBE")
              );
            });
          }
          setProjectList(data);
        } else {
          setProjectList([]);
        }
      });
    }
    Promise.all([promise1, promise2, promise3, promise4])
      .then((dataList) => {
        const [subscriptionObjectList, subscriptionContentList] = dataList;
        if (subId) {
          //详细信息
          let api = projectApi;
          if (isBond) {
            api = bondApi;
          }
          api.getSubInfo(subId).then((res) => {
            if (res.code === 200) {
              //订阅对象
              setSubscriptionObjectKey(
                res.data.sub_object_list?.map((_) => _.sub_object_code)
              );
              let flag =
                res.data.sub_object_list.length ===
                subscriptionObjectList.length;
              setSubscriptionObjectIndeterminate(!flag);
              setSubscriptionObjectCheckAll(flag);

              //订阅内容
              setSubscriptionContentKey(
                res.data.sub_content_list?.map((_) => _.sub_content_code)
              );
              let flag2 =
                res.data.sub_content_list.length ===
                subscriptionContentList.length;
              setSubscriptionContentIndeterminate(!flag2);
              setSubscriptionContentCheckAll(flag2);
              //订阅方式
              setTypeList((prev) => {
                prev = prev.map((_) => {
                  let obj = res.data.sub_way_list?.find(
                    (__) => __.sub_way_code === _.dic_item_code
                  );
                  if (obj) {
                    _.checked = true;
                    // _.contentText = obj.email || obj.phone;
                  }
                  return _;
                });
                return prev;
              });
              //时间
              setDate(moment(res.data.valid_limit_time));
            } else {
              message.error(res.message);
              props.onClose && props.onClose();
            }
            setLoading(false);
          });
        } else {
          clearAll();
          //订阅方式
          setTypeList((prev) => {
            prev = prev.map((_) => {
              if (_.dic_item_code === "CDD_SUBS_EMAIL" && _.contentText) {
                _.checked = true;
              }
              return _;
            });
            return prev;
          });
          setLoading(false);
        }
        if (!userInfo?.email) {
          creatUserTimer();
        }
      })
      .catch((sea) => {
        setLoading(false);
        message.error(sea?.message);
        props.onClose && props.onClose();
      });
  }, [props.visible]);

  const clearAll = () => {
    setSubscriptionObjectKey([]);
    setSubscriptionObjectIndeterminate(false);
    setSubscriptionObjectCheckAll(false);
    setSubscriptionContentKey([]);
    setSubscriptionContentIndeterminate(false);
    setSubscriptionContentCheckAll(false);
    // setDate(null);
  };

  return (
    <Drawer
      className="subscribeSettingDrawer"
      width="600px"
      closable={false}
      title="订阅设置"
      placement="right"
      maskClosable={false}
      keyboard={false}
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <BtnBox
          loading={uploading}
          onClose={props.onClose}
          onFinish={onFinish}
        ></BtnBox>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {loading ? (
          <MySpin></MySpin>
        ) : (
          <div style={{ padding: "20px" }}>
            <div className="subscriptionProject">
              <div
                className="projectKey"
                style={{ width: isBond ? "60px" : "80px" }}
              >
                {isBond ? "发行人" : "尽调项目"}
              </div>
              <Select
                style={{
                  width: `calc(100% - ${isBond ? "60px" : "80px"})`,
                }}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode || document.body
                }
                placeholder={isBond ? "请选择发行人" : "请选择尽调项目"}
                onChange={(value) => {
                  setProId(value);
                  getSubContent(value);
                  // setSubscriptionContentKey([]);
                  // setSubscriptionContentIndeterminate(false);
                  // setSubscriptionContentCheckAll(false);
                }}
                value={proId}
                disabled={isBond ? companyId : projectId}
                showSearch
                filterOption={(input, option) =>
                  option.children.includes(input)
                }
              >
                {projectList.map((_) =>
                  isBond ? (
                    <Option key={_.company_id} value={_.company_id}>
                      {_.company_name}
                    </Option>
                  ) : (
                    <Option key={_.id} value={_.id}>
                      {_.project_name}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="subscriptionObject">
              <HeaderTitle
                icon={<img src={icon1}></img>}
                title="订阅对象"
              ></HeaderTitle>
              <div className="subscriptionObjectBox">
                <Checkbox
                  value="全部"
                  indeterminate={subscriptionObjectIndeterminate}
                  onChange={onSubscriptionObjectCheckAllChange}
                  checked={subscriptionObjectCheckAll}
                >
                  全部
                </Checkbox>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={subscriptionObjectCheckListChange}
                  value={subscriptionObjectKey}
                >
                  {subscriptionObjectList.map((item) => (
                    <div key={item.id}>
                      <Checkbox value={item.id}>{item.value}</Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            </div>
            <div className="subscriptionContent">
              <HeaderTitle
                icon={<img src={icon2}></img>}
                title="订阅内容"
              ></HeaderTitle>
              <div className="subscriptionContentBox">
                {subscriptionContentList.length > 0 ? (
                  <Checkbox
                    value="全部"
                    indeterminate={subscriptionContentIndeterminate}
                    onChange={onSubscriptionContentCheckAllChange}
                    checked={subscriptionContentCheckAll}
                  >
                    全部
                  </Checkbox>
                ) : null}

                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={subscriptionContentCheckListChange}
                  value={subscriptionContentKey}
                >
                  <div className="CheckboxContent">
                    {subscriptionContentList.map((item) => (
                      <div className="CheckboxItem" key={item.dic_item_code}>
                        <Checkbox value={item.dic_item_code}>
                          {item.dic_item_name}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </div>

            <div className="subscriptionMethod">
              <HeaderTitle
                icon={<img src={icon3}></img>}
                title="订阅方式"
              ></HeaderTitle>
              <div className="subscriptionMethodBox">
                {/*  "短信" */}
                {typeList.map((item) => (
                  <div className="searchItem" key={item.dic_item_code}>
                    <Checkbox
                      checked={item.checked}
                      onChange={(e) => {
                        if (e.target.checked && !item.contentText) {
                          MessageModal({
                            // type: "info",
                            noIcon: true,
                            description: "需要立即设置邮箱或手机号吗?",
                            message: "您还未设置邮箱或手机号",
                            title: "提醒",
                            cancelText: "暂不设置",
                            okText: "立即设置",
                            onOk: () => {
                              const url =
                                window.localStorage.getItem("sys_url") +
                                "saas/personalsettings";
                              window.open(url, "email");
                              creatUserTimer()
                            },
                          });
                          return;
                        }
                        setTypeList((prev) => {
                          prev = prev.map((_item) => {
                            if (_item.dic_item_code === item.dic_item_code) {
                              _item.checked = e.target.checked;
                            }
                            return _item;
                          });
                          return [...prev];
                        });
                      }}
                    >
                      {item.dic_item_name}
                    </Checkbox>
                    <div
                      className="textInputDiv"
                      onClick={(e) => {
                        if (!item.checked && !item.contentText) {
                          MessageModal({
                            // type: "info",
                            noIcon: true,
                            description: "需要立即设置邮箱或手机号吗?",
                            message: "您还未设置邮箱或手机号",
                            title: "提醒",
                            cancelText: "暂不设置",
                            okText: "立即设置",
                            onOk: () => {
                              let domain = psl.get(window.location.hostname);
                              domain = domain && `.${domain}`;
                              let options = {
                                expires: new Date(
                                  new Date().getTime() + 7 * 24 * 60 * 60 * 1000
                                ),
                              };
                              if (domain) {
                                options.domain = domain;
                              }
                              const href = window.location;
                              Cookie.set("callbackUrl", href, options);
                              const url =
                                window.localStorage.getItem("sys_url") +
                                "saas/personalsettings";
                              window.open(url, "email");
                              creatUserTimer()
                            },
                          });
                          return;
                        }
                        setTypeList((prev) => {
                          prev = prev.map((_item) => {
                            if (_item.dic_item_code === item.dic_item_code) {
                              _item.checked = !item.checked;
                            }
                            return _item;
                          });
                          return [...prev];
                        });
                      }}
                    >
                      {item.contentText}
                    </div>
                    {/* <Input
                      // placeholder={
                      //   item.dic_item_name === "邮件" ? "请输入邮箱" : "请输入"
                      // }
                      // disabled={!item.checked}
                      disabled
                      bordered={false}
                      className="borderBottom"
                      value={item.contentText}

                      // onChange={(e) => {
                      //   setTypeList((prev) => {
                      //     prev = prev.map((_item) => {
                      //       if (_item.dic_item_code === item.dic_item_code) {
                      //         _item.contentText = e.target.value;
                      //       }
                      //       return _item;
                      //     });
                      //     return [...prev];
                      //   });
                      // }}
                      // allowClear
                    /> */}
                  </div>
                ))}
              </div>
            </div>

            <div className="subscriptionDate">
              <HeaderTitle
                icon={<img src={icon4}></img>}
                title="订阅有效期"
              ></HeaderTitle>
              <div className="subscriptionDateBox">
                <div className="dateItem">
                  <DatePicker
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode || document.body
                    }
                    style={{ width: "100%" }}
                    value={date}
                    showToday={false}
                    onChange={(date) => setDate(date)}
                    disabledDate={(current) => {
                      if (getExpirationTime()) {
                        return (
                          current > moment(getExpirationTime()).endOf("day") ||
                          current <= moment().startOf("day")
                        );
                      }
                      return current <= moment().startOf("day");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Scrollbars>
    </Drawer>
  );
};

export default SubscribeSettingDrawer;
