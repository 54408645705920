import "./index.scss";
import { Spin } from "antd";
import { ReactSVG } from "react-svg";
import LoadingSvg from "../../assets/loading.svg";

const MySpin = (props) => {
  return (
    <Spin
      className="mySpinLoading"
      tip={props.tip}
      indicator={
        <ReactSVG
          src={LoadingSvg}
          className="anticon-spin anticon myLoadingIcon"
        />
      }
    ></Spin>
  );
};

export default MySpin;
