import "./index.scss";
import { useRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Drawer, Select, message, Tag, Pagination } from "antd";
import { CloseOutlined, CaretDownOutlined } from "@ant-design/icons";
import icon from "./操作记录.svg";
import { ReactSVG } from "react-svg";
import MyRangePicker from "../../components/MyRangePicker/index";
import MySpin from "../../components/MySpin/index";
import Scrollbars from "react-custom-scrollbars";
import Empty from "../../components/Empty";
import projectApi from "../../apis/project/index.js";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";

import { useSize, useLongPress, useMouse, useEventListener } from "ahooks";
import HeadPortrait from "../HeadPortrait";

const { Option } = Select;

const MoveContent = (props) => {
  const { setVisible } = props;
  const [x, setX] = useState(
    document.documentElement.querySelector("body").clientWidth - 20 - 64
  );
  const [y, setY] = useState(
    document.documentElement.querySelector("body").clientHeight - 20 - 64
  );
  const mouse = useMouse();
  const moveRef = useRef(null);
  const [isMove, setIsMove] = useState(false);

  const timer = useRef(null);

  useLongPress(
    () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      setIsMove(true);
    },
    moveRef,
    {
      delay: 150,
    }
  );

  useEventListener("mouseup", () => {
    timer.current = setTimeout(() => {
      setIsMove(false);
    }, 200);
  });

  useEffect(() => {
    if (!isMove) {
      return;
    }
    let MaxX = document.documentElement.querySelector("body").clientWidth;
    let MaxY = document.documentElement.querySelector("body").clientHeight;
    let x = 0;
    let y = 0;
    if (mouse.pageX - 32 < 0) {
      x = 0;
    } else if (mouse.pageX - 32 > MaxX - 64) {
      x = MaxX - 64;
    } else {
      x = mouse.pageX - 32;
    }

    if (mouse.pageY - 32 < 0) {
      y = 0;
    } else if (mouse.pageY - 32 > MaxY - 64) {
      y = MaxY - 64;
    } else {
      y = mouse.pageY - 32;
    }
    setX(x);
    setY(y);
  }, [mouse]);

  return (
    <div
      className="operationRecordBox"
      style={{
        left: x + "px",
        top: y + "px",
        cursor: isMove ? "move" : "pointer",
      }}
      onClick={() => {
        if (isMove) {
          return;
        }
        setVisible(true);
      }}
      ref={moveRef}
    >
      {/* <div
        className="moveBox"
        ref={moveRef}
        onClick={(e) => {
          e.cancleBubble = true;
          e.stopPropagation();
        }}
      ></div> */}
      <ReactSVG src={icon}></ReactSVG>
      <div>操作记录</div>
    </div>
  );
};
const OperationRecordContent = (props) => {
  const { routers } = props;
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [operationRecordList, setOperationRecordList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [total, setTotal, getTotal] = useGetState(0);
  const [key, setKey] = useState(Math.random());

  const [eventType, setEventType, getEventType] = useGetState(null);
  const [handleType, setHandleType, getHandleType] = useGetState(null);
  const [handleUser, setHandleUser, getHandleUser] = useGetState(null);
  const [dateArr, setDateArr, getDateArr] = useGetState([]);

  const eventTypeList = useRef([]);
  const handleTypeList = useRef([]);
  const options = useRef([]);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  // useEffect(() => {
  //   const item = routers.find(
  //     (_) => _.menu_url === location.pathname.split(id)[1]
  //   );
  //   if (item) {
  //     setEventType(item.menu_name);
  //   }
  // }, [location, id]);

  // 获取数据
  const getData = () => {
    setLoading(true);
    let params = {
      project_id: id,
      page_number: getPageNumber(),
      page_size: pageSize,
    };
    const date = getDateArr();
    if (date?.at(0) || date?.at(1)) {
      params.start_time = date?.at(0);
      params.end_time = date?.at(1);
    }
    if (getHandleUser()) {
      params.opt_user_id = getHandleUser();
    }
    if (getEventType()) {
      let opt_event = {};
      opt_event.event_type = getEventType();
      if (getHandleType()) {
        opt_event.item = getHandleType();
      } else {
        opt_event.item = "";
      }
      params.opt_event = opt_event;
    }
    projectApi.getOptLog(params).then((res) => {
      console.log("----日志---- 参数为:", params, "结果为:", res);
      if (res.code === 200) {
        setTotal(res.data.total);
        setOperationRecordList(res.data.data);
      } else {
        setTotal(0);
        setOperationRecordList([]);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  // 获取事件类型枚举
  const getCodeInfo = () => {
    projectApi
      .getDicCodeInfo("CDD_CONCERN_TYPE")
      .then((res) => {
        if (res.code === 200) {
          eventTypeList.current = res.data;
        }
      })
      .then(() => {
        projectApi.getLogEnums().then((res) => {
          if (res.code === 200) {
            let arr = [];
            Object.keys(res.data).map((a) => {
              let item = eventTypeList.current.find(
                (_) => _.dic_item_code === a
              ) || {};
              return item;
            }).sort((a,b)=>a.seq - b.seq).forEach((key) => {
              arr = arr.concat(
                (res.data[key.dic_item_code] || []).map((_) => ({
                    handleType: _,
                    eventType: key.dic_item_code,
                  }))
              );
            });
            options.current = arr;
            handleTypeList.current = arr;
          }
        });
      });
  };

  // 首次渲染获取枚举
  useEffect(() => {
    getCodeInfo();
  }, []);

  // 展开获取数据,关闭清空数据
  useEffect(() => {
    if (visible) {
      getData();
    } else {
      setOperationRecordList([]);
      setPageNumber(1);
      setTotal(0);
      setEventType(null);
      setHandleType(null);
      setHandleUser(null);
      setDateArr([]);
      setKey(Math.random());
    }
  }, [visible]);

  // 时间选择变化
  const onChange = async (dates, dateStrings) => {
    await setPageNumber(1);
    await setDateArr(dateStrings);
    getData();
  };

  return (
    <>
      <MoveContent setVisible={setVisible}></MoveContent>

      <Drawer
        className="operationRecordDrawer"
        width="600px"
        closable={false}
        title="操作记录"
        placement="right"
        push={false}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setVisible(false);
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <>
          <div className="searchBox">
            <div className="iconBox">
              <div className="iconItem">
                <Select
                  className="selectItem"
                  style={{ minWidth: "90px" }}
                  dropdownMatchSelectWidth={140}
                  bordered={false}
                  placeholder="事件类型"
                  suffixIcon={
                    <CaretDownOutlined style={{ pointerEvents: "none" }} />
                  }
                  value={eventType}
                  onChange={async (value) => {
                    await setPageNumber(1);
                    await setEventType(value);
                    await setHandleType(null);
                    if (value) {
                      handleTypeList.current = options.current.filter(
                        (item) => item.eventType === value
                      );
                    } else {
                      handleTypeList.current = options.current;
                    }
                    getData();
                  }}
                  allowClear
                >
                  {eventTypeList.current.map((item) => (
                    <Option key={item.dic_item_code} value={item.dic_item_code}>
                      {item.dic_item_name}
                    </Option>
                  ))}
                </Select>
                {/* 事项类型 <CaretDownOutlined /> */}
              </div>
              <div className="iconItem">
                <Select
                  className="selectItem"
                  style={{ minWidth: "90px" }}
                  dropdownMatchSelectWidth={150}
                  bordered={false}
                  placeholder="操作类型"
                  suffixIcon={
                    <CaretDownOutlined style={{ pointerEvents: "none" }} />
                  }
                  value={handleType}
                  onChange={async (value) => {
                    await setPageNumber(1);
                    await setHandleType(value);
                    if (!eventType) {
                      let item = handleTypeList.current.find(
                        (_) => _.handleType === value
                      );
                      await setEventType(item.eventType);
                      handleTypeList.current = options.current.filter(
                        (_) => _.eventType === item.eventType
                      );
                    }
                    getData();
                  }}
                  allowClear
                >
                  {handleTypeList.current.map((item) => (
                    <Option key={item.handleType} value={item.handleType}>
                      {item.handleType}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="iconItem">
                <Select
                  className="selectItem"
                  style={{ minWidth: "60px" }}
                  bordered={false}
                  placeholder="操作人"
                  suffixIcon={
                    <CaretDownOutlined style={{ pointerEvents: "none" }} />
                  }
                  value={handleUser}
                  onChange={async (value) => {
                    await setPageNumber(1);
                    await setHandleUser(value);
                    getData();
                  }}
                  allowClear
                >
                  {(projectInfo.member_list || []).map((item) => (
                    <Option key={item.user_id} value={item.user_id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <MyRangePicker
              key={key}
              style={{ width: "calc(50% - 10px)", borderRadius: "30px" }}
              format="YYYY-MM-DD"
              allowClear={true}
              onChange={onChange}
            ></MyRangePicker>
          </div>
          {loading ? (
            <div
              style={{
                height: `calc(100% - 45px)`,
                width: "100%",
              }}
            >
              <MySpin></MySpin>
            </div>
          ) : (
            <div
              style={{
                height: `calc(100% - ${total > 10 ? 85 : 45}px)`,
                width: "100%",
                padding: "5px 0",
              }}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{
                  height: `100%`,
                  width: "100%",
                }}
              >
                {operationRecordList.length > 0 ? (
                  <div className="operationRecordListBox">
                    {operationRecordList.map((item) => (
                      <div key={item.id} className="operationRecordItem">
                        <div className="top">
                          <Tag color="#EFEFEF" style={{color:'#535353'}}>{item.item}</Tag>
                          <div className="text">
                            <HeadPortrait size={22} src={item.pf_photo} style={{marginRight:'5px'}} title={item.show_name + (item.assist_name ? "/" + item.assist_name : "")}></HeadPortrait>
                            <span>{item.show_name}</span>
                            {item.create_time}
                          </div>
                        </div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: item?.log_content_tips,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="noData">
                    <Empty></Empty>
                  </div>
                )}
              </Scrollbars>
              {total > 10 && (
                <div className="PaginationBox">
                  <Pagination
                    current={pageNumber}
                    total={total}
                    showSizeChanger={false}
                    onChange={async (page, pageSize) => {
                      await setPageNumber(page);
                      getData();
                    }}
                    showTotal={(total) => `共 ${total} 条`}
                  />
                </div>
              )}
            </div>
          )}
        </>
      </Drawer>
    </>
  );
};

export default OperationRecordContent;
