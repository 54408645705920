import { useState } from "react";
import "./index.scss";
import Empty from "../../../../../components/Empty";
import moment from "moment";
import { Modal } from "antd";
import { RightOutlined, FileSearchOutlined } from "@ant-design/icons";

const TimeLineContent = (props) => {
  const { timeLineData, projectId } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initData, setInitData] = useState([]);
  const showModal = (item) => {
    setIsModalOpen(true);
    setInitData(item);
  };

  const initColumns = (item) => {
    if (item === "litigant") {
      return initData[item].replace(/,/gi, "，");
    }
    if (initData.rows[item].is_json) {
      return Object.values(initData[item])
        .map((data) => data[initData.rows[item].show_key])
        .join("，");
    }
    if (item.indexOf("Time") !== -1 || item.indexOf("Date") !== -1) {
      if (!initData[item]) {
        return "";
      }
      if (initData[item]?.includes("1970-01-01")) {
        return "";
      } else {
        return moment(initData[item]).format("YYYY-MM-DD");
      }
    }
    if (item.indexOf("Money") !== -1) {
      return `${Number(initData[item]).toLocaleString() + "(元)"}`;
    } else {
      return initData[item];
    }
  };

  const openWindow = (item) => {
    let str = "";
    let optionsStr = "";
    let case_type = item.case_type2;

    if (case_type === "被执行人") {
      str = `personSubjectedToExecution?`;
      //如果有案号
      if (item.caseCode) {
        optionsStr = `defaultFilterCriteria=${JSON.stringify([
          { key: "caseCode", values: [item.caseCode], is_input_select: 1 },
        ])}`;
      } else {
        let list = [];
        list.push({ key: "caseCode", values: [""], is_input_select: 1 });
        list.push({ key: "pName", values: [item.company_name] });
        if (item.caseCreateTime) {
          list.push({
            key: "caseCreateTime",
            values: [item.caseCreateTime, item.caseCreateTime],
            is_date_select: 1,
          });
        }
        optionsStr = `defaultFilterCriteria=${JSON.stringify(list)}`;
      }
    } else if (case_type === "失信被执行人") {
      str = `dishonestPeople?`;
      //如果有案号
      if (item.caseCode) {
        optionsStr = `defaultFilterCriteria=${JSON.stringify([
          { key: "caseCode", values: [item.caseCode], is_input_select: 1 },
        ])}`;
      } else {
        let list = [];
        list.push({ key: "caseCode", values: [""], is_input_select: 1 });
        list.push({ key: "name", values: [item.company_name] });
        if (item.regDate) {
          list.push({
            key: "regDate",
            values: [item.regDate, item.regDate],
            is_date_select: 1,
          });
        }
        optionsStr = `defaultFilterCriteria=${JSON.stringify(list)}`;
      }
    } else if (case_type === "执行裁定书" || case_type === "民事裁定书") {
      case_type = "裁判文书";
      str = `legalInstrument?type=${case_type}&`;
      //如果有案号
      if (item.caseNo) {
        optionsStr = `defaultFilterCriteria=${JSON.stringify([
          { key: "caseNo", values: [item.caseNo], is_input_select: 1 },
        ])}`;
      } else {
        let list = [];
        list.push({ key: "caseNo", values: [""], is_input_select: 1 });
        list.push({
          key: "companyId",
          values: [`${item.company_name}~~~${item.companyId}`],
        });
        if (item.judgeTime) {
          list.push({
            key: "judgeTime",
            values: [item.judgeTime, item.judgeTime],
            is_date_select: 1,
          });
        }
        list.push({ key: "plaintiffs", values: [item.plaintiffs || ""] });

        list.push({ key: "defendants", values: [item.defendants || ""] });

        optionsStr = `defaultFilterCriteria=${JSON.stringify(list)}`;
      }
    } else {
      str = `legalInstrument?type=${case_type}&`;
      //如果有案号
      if (item.caseNo) {
        optionsStr = `defaultFilterCriteria=${JSON.stringify([
          { key: "caseNo", values: [item.caseNo], is_input_select: 1 },
        ])}`;
      } else {
        let list = [];
        list.push({ key: "caseNo", values: [""], is_input_select: 1 });
        list.push({
          key: "companyId",
          values: [`${item.company_name}~~~${item.companyId}`],
        });
        if (case_type === "立案信息") {
          if (item.filingDate) {
            list.push({
              key: "filingDate",
              values: [item.filingDate, item.filingDate],
              is_date_select: 1,
            });
          }
          list.push({ key: "plaintiff", values: [item.plaintiff || ""] });

          list.push({ key: "defendant", values: [item.defendant || ""] });
        } else if (case_type === "开庭公告") {
          if (item.startDate) {
            list.push({
              key: "startDate",
              values: [item.startDate, item.startDate],
              is_date_select: 1,
            });
          }
          list.push({ key: "litigant", values: [item.litigant || ""] });
        } else if (case_type === "法院公告") {
          if (item.publishDate) {
            list.push({
              key: "publishDate",
              values: [item.publishDate, item.publishDate],
              is_date_select: 1,
            });
          }
          list.push({ key: "party1", values: [item.party1 || ""] });
        }
        optionsStr = `defaultFilterCriteria=${JSON.stringify(list)}`;
      }
    }

    window.open(`/company/${projectId}/${str}${optionsStr}`);
  };

  return (
    <div className="caseTimelineContent">
      {timeLineData?.map((item, index) => {
        return (
          <div className="timelineContent" key={index}>
            <div className="timeLine">
              <div className="lineBox">
                <div className="iconBox">
                  <div className="icon iconExpend"></div>
                </div>
                <div
                  className={index !== timeLineData.length - 1 ? "line" : null}
                ></div>
              </div>
              <div className="time">{item.time}</div>
            </div>
            {/* 信息内容区域 */}
            <div className="contentDetail">
              <div className="contentDetailTime">{item.show_date || "-"}</div>
              <div className="title" onClick={() => showModal(item)}>
                {item.case_type2}
              </div>
              {item.case_type2?.indexOf("裁定书") ? (
                <FileSearchOutlined className="detailIcon" />
              ) : null}

              <span
                className="openUrl"
                onClick={() => {
                  openWindow(item);
                }}
              >
                查看文书
                <RightOutlined />
              </span>

              <div className="detailsMessage">
                <div className="details">
                  法院：{item.court || item.courtCode || item.execCourtName}
                  &nbsp; &nbsp;案号：
                  {item.caseNo || item.caseCode}&nbsp; &nbsp;...
                </div>
                <div className="moreDetail" onClick={() => showModal(item)}>
                  更多
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Modal
        title={initData.case_type2}
        destroyOnClose={true}
        footer={false}
        visible={isModalOpen}
        wrapClassName="myModal"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        centered
        width={560}
      >
        <div className="content">
          {initData.rows ? (
            Object.keys(initData.rows)
              ?.sort((a, b) => initData.rows[a].index - initData.rows[b].index)
              ?.map((item, index) => (
                <div
                  className="contentValue"
                  key={index}
                  style={{
                    width:
                      initData.rows[item].width === 100
                        ? "100%"
                        : `calc(${initData.rows[item].width}% - 10px)`,
                  }}
                >
                  <div className="detailKey">{initData.rows[item].name}</div>
                  <div className="detailValue">{initColumns(item)}</div>
                </div>
              ))
          ) : (
            <Empty></Empty>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default TimeLineContent;
