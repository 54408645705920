import "./index.scss";
import React, { useState } from "react";
import wrapper from "./wrapper";
import {
  Drawer,
  Button,
  message,
  Tooltip,
  Descriptions,
  Table,
  Typography,
  Tag,
} from "antd";
import {
  CloseOutlined,
  VerticalAlignBottomOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import projectApi from "../../apis/project";
import MySpin from "../MySpin";
import HeaderTitle from "../HeaderTitle";
import MyDiv from "../MyDiv";
import { Provider } from "react-redux";
import store from "../../store/index";
import MessageModal from "../MessageModal";
import MyEmpty from "../Empty";
import { addMillimeter, addMillimeterAndInit } from "../../unit/unit";
import img4 from "../../assets/警告@3x.png";
const { Paragraph } = Typography;

const CompanyInfoDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tableDate, setTableDate] = useState({});

  const [shareholderType, setShareholderType] = useState("主要股东");
  const [personnelType, setPersonnelType] = useState("工商登记");

  const [shareholderData, setShareholderData] = useState({});
  const [personnelData, setPersonnelData] = useState({});

  const [shareholderColumns, setShareholderColumns] = useState([]);
  const [personnelColumns, setPersonnelColumns] = useState([]);

  const [shareholderTypeList, setShareholderTypeList] = useState([
    "主要股东",
    "历史主要股东",
  ]);
  const [personnelTypeList, setPersonnelTypeList] = useState([
    "工商登记",
    "历史主要人员",
  ]);

  const [isAllDataEmpty, setIsAllDataEmpty] = useState(false);

  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 项目ID */
    projectId,
    /* 查看的公司名称 */
    companyName,
    /* 查看的公司Id */
    companyId,
    /* 不展示 主要股东和人员 */
    notShowShareholderAndPersonnel,
  } = props;

  useEffect(() => {
    setVisible(true);
    getInfo();
  }, []);

  //查看省略部分信息
  const lookUp = (value, item) => {
    MessageModal({
      // type: "warning",
      // description: "确定要删除此条数据吗？",
      maskClosable: true,
      noIcon: true,
      noBtn: true,
      infoText: value,
      title: item.name,
    });
  };

  const initShareholder = (shareholderType, tableDate) => {
    let obj = [];
    if (shareholderType === "最新公示") {
      obj = tableDate?.listed_stock_holder_top10;
    } else if (shareholderType === "主要股东") {
      obj = tableDate?.major_shareholders;
    } else if (shareholderType === "历史主要股东") {
      obj = tableDate?.major_shareholders_history;
    }
    setShareholderData(obj);
    initColumns(obj?.rows || {}, "shareholder");
  };

  const initPersonnel = (personnelType, tableDate) => {
    let obj = [];
    if (personnelType === "工商登记") {
      obj = tableDate?.company_personnel_information;
    } else if (personnelType === "历史主要人员") {
      obj = tableDate?.company_personnel_history;
    } else if (personnelType === "最新公示") {
      obj = tableDate?.listed_stock_management;
    } else if (personnelType === "历史公示") {
      obj = tableDate?.listed_stock_management_his;
    }
    setPersonnelData(obj);
    initColumns(obj?.rows || {}, "personnel");
  };

  //格式化 table columns
  const initColumns = (rows, type) => {
    const minWidth = 26;
    let columns = Object.keys(rows)
      .filter((key) => rows[key]?.name !== "最终收益股份")
      .map((key, index) => {
        const item = rows[key];
        let column = {
          key: key,
          title: item.name,
          dataIndex: key,
          // width,
          align: "center",
        };

        if (item.name === "出资金额" || item.name === "持股比例") {
          column.width = "90px";
        }
        if (item.name === "出资时间") {
          column.width = "110px";
        }
        if (item.name === "类型") {
          column.width = "60px";
        }
        if (
          (item.name === "姓名" || item.name === "职位") &&
          personnelType === "工商登记"
        ) {
          column.width = "100px";
        }
        if (item.name === "个人简介") {
          column.width = "200px";
        }
        column.render = (text, record, index) => {
          if (
            record[key]?.val === null ||
            record[key]?.val === undefined ||
            record[key]?.val === ""
          ) {
            return "-";
          }

          if (item.is_object) {
            return JSON.stringify(record[key]?.val);
          }
          if (item.need_millimeter) {
            if (item.is_arrow) {
              return (
                <>
                  <div>
                    {item.is_int
                      ? Number(record[key]?.val).toLocaleString("en-US")
                      : addMillimeter(record[key]?.val, 2)}
                    {record[key]?.compare_result === "+" ? (
                      <ArrowUpOutlined
                        style={{ marginLeft: "5px", color: "#f50" }}
                      />
                    ) : null}
                    {record[key]?.compare_result === "-" ? (
                      <ArrowDownOutlined
                        style={{ marginLeft: "5px", color: "#87d068" }}
                      />
                    ) : null}
                  </div>
                  {record[key]?.compare_result === "+" ||
                  record[key]?.compare_result === "-" ? (
                    <div
                      style={{
                        fontSize: "10px",
                        color:
                          record[key]?.compare_result === "-"
                            ? "#87d068"
                            : record[key]?.compare_result === "+"
                            ? "#f50"
                            : null,
                        wordWrap: "normal",
                      }}
                    >
                      （
                      {record[key]?.compare_result +
                        (item.is_int
                          ? Math.abs(
                              Number(record[key]?.compare_result_val)
                            ).toLocaleString("en-US")
                          : addMillimeter(
                              Math.abs(record[key]?.compare_result_val),
                              2
                            ))}
                      ）
                    </div>
                  ) : null}
                </>
              );
            }
            //带单位
            if (item.join_field) {
              return (
                addMillimeterAndInit(record[key]?.val, 2) +
                record[item.join_field]?.val
              );
            }
            return addMillimeter(record[key]?.val, 2);
          }
          //转化为百分比
          if (item.need_percent) {
            return record[key]?.val
              ? (Number(record[key]?.val) * 100).toFixed(2) + "%"
              : "-";
          }
          //省略
          if (item.is_simple_show) {
            return (
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        lookUp(record[key]?.val, item);
                      }}
                    >
                      查看
                    </span>
                  ),
                }}
              >
                {record[key]?.val}
              </Paragraph>
            );
          }
          //带上主体为范围
          if (item.is_show_related_parties) {
            if (record[key]?.val) {
              return (
                // record[key]?.val + "(" + record?.relatedPartiesType?.val + ")"
                <div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      tooltip: {
                        title: record[key]?.val,
                      },
                    }}
                    style={{ textAlign: "left" }}
                  >
                    {record[key]?.val}
                  </Paragraph>
                  <div className="tagBox">
                    {(record?.relatedPartiesType?.val || []).map((tag) => {
                      // const len = (tag.length || 0) * 15;
                      return (
                        <Tooltip
                          key={Math.random()}
                          placement="top"
                          title={tag.full_name}
                          arrowPointAtCenter
                        >
                          <div>
                            <Tag
                              style={{
                                maxWidth: "100%",
                                whiteSpace: "normal",
                                flex: "none",
                              }}
                              key={Math.random()}
                            >
                              {tag.short_name}
                            </Tag>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return "-";
          }
          //tips显示
          if (item.is_ellipsis) {
            return (
              <Paragraph
                ellipsis={{
                  rows: 3,
                  tooltip: {
                    title: (
                      <div className="tableColumnsParagraphTip">
                        {record[key]?.val}
                      </div>
                    ),
                    overlayClassName: "tableColumnsParagraphTooltip",
                  },
                }}
              >
                <div style={{ textAlign: "left", width: "200px" }}>
                  {record[key]?.val}
                </div>
              </Paragraph>
            );
          }
          return record[key]?.val;
        };
        column.index = item.index + 1;
        return column;
      });
    //添加序号
    columns.unshift({
      index: 1,
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      width: "60px",
      align: "center",
      render: (text, record, index) => <>{index + 1}</>,
    });
    columns = columns.sort((a, b) => a.index - b.index);
    if (type === "shareholder") {
      setShareholderColumns(columns);
    } else if (type === "personnel") {
      setPersonnelColumns(columns);
    }
  };

  const getInfo = () => {
    setLoading(true);
    projectApi
      .getChegetLegalPersonInfo(
        projectId,
        companyId,
        notShowShareholderAndPersonnel
      )
      .then((res) => {
        console.log("获取法人信息", res);
        if (res.code === 200) {
          setTableDate(res.data);
          initShareholder(shareholderType, res.data);
          initPersonnel(personnelType, res.data);
          const list2 = ["主要股东", "历史主要股东"];
          const list = ["工商登记", "历史主要人员"];
          if (res.data?.listed_stock_management_his) {
            list.unshift("历史公示");
            setPersonnelType("历史公示");
          }
          if (res.data?.listed_stock_management) {
            list.unshift("最新公示");
            setPersonnelType("最新公示");
          }
          if (res.data?.listed_stock_holder_top10) {
            list2.unshift("最新公示");
            setShareholderType("最新公示");
          }
          setPersonnelTypeList(list);
          setShareholderTypeList(list2);
          setIsAllDataEmpty(res.data?.is_all_data_empty_flag);
        } else {
          message.error(res.message);
          handleCancel();
          setShareholderData([]);
          setPersonnelData([]);
          setShareholderColumns([]);
          setPersonnelColumns([]);
          setIsAllDataEmpty(false);
          setPersonnelTypeList(["工商登记", "历史主要人员"]);
          setShareholderTypeList(["主要股东", "历史主要股东"]);
        }
        setLoading(false);
      });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  useEffect(() => {
    // console.log("主要股东变化", shareholderType);
    initShareholder(shareholderType, tableDate);
  }, [shareholderType]);

  useEffect(() => {
    // console.log("主要人员变化", personnelType);
    initPersonnel(personnelType, tableDate);
  }, [personnelType]);

  const data = notShowShareholderAndPersonnel
    ? tableDate?.company_branch
    : tableDate?.company_basic_information;
  return (
    <Provider store={store}>
      <Drawer
        title={companyName || "法人信息"}
        placement="right"
        width="600px"
        className="companyInfoDrawer"
        onClose={handleCancel}
        visible={visible}
        push={false}
        closable={false}
        destroyOnClose={true}
        // maskClosable={false}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              handleCancel();
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                handleCancel();
              }}
              type="primary"
            >
              关闭
            </Button>
          </div>
        }
      >
        <div className="companyInfoContent">
          {/* 分支机构不显示其他信息 */}
          {/* {!notShowShareholderAndPersonnel && (
            <div className="companyInfoTitle">基本信息</div>
          )} */}
          {isAllDataEmpty ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MyEmpty description="暂无数据"></MyEmpty>
            </div>
          ) : (
            <div className="companyInfoBox">
              {loading ? (
                <MySpin tip="信息正在获取中..."></MySpin>
              ) : (
                <div style={{ paddingBottom: "20px" }}>
                  {/* 工商信息 */}
                  <div className="businessContent">
                    <HeaderTitle title="工商信息"></HeaderTitle>
                    <div className="dateBox">
                      <span>
                        更新时间：
                        {data?.data_update_time || "-"}
                      </span>

                      {tableDate?.company_basic_information?.data?.at(0)
                        ?.screenshots_url?.val && (
                        <VerticalAlignBottomOutlined
                          className="downloadIcon hoverIcon"
                          onClick={() => {
                            let a = document.createElement("a");
                            a.style = "display: none"; // 创建一个隐藏的a标签
                            a.href =
                              tableDate?.company_basic_information?.data?.at(0)
                                ?.screenshots_url?.val +
                              "?response-content-type=application/octet-stream";
                            document.body.appendChild(a);
                            a.click(); // 触发a标签的click事件
                            document.body.removeChild(a);
                          }}
                        />
                      )}
                    </div>

                    {data?.data?.at(0) ? (
                      <Descriptions
                        bordered
                        className="myDescriptions"
                        column={100}
                      >
                        {Object.keys(
                          tableDate?.company_basic_information?.rows || {}
                        )
                          .map((key) => ({
                            key: key,
                            ...(tableDate?.company_basic_information?.rows[
                              key
                            ] || {}),
                          }))
                          .sort((a, b) => a.index - b.index)
                          .map((item) => (
                            <Descriptions.Item
                              label={item?.name}
                              key={item.key}
                              span={item?.width}
                            >
                              <div>
                                {item.need_millimeter
                                  ? data?.data?.at(0)[item.key]?.val
                                    ? addMillimeterAndInit(
                                        data?.data?.at(0)[item.key]?.val,
                                        2
                                      ) +
                                      (item.join_field
                                        ? data?.data?.at(0)[item.join_field]
                                            ?.val
                                        : "")
                                    : "-"
                                  : data?.data?.at(0)[item.key]?.val || "-"}
                              </div>
                              {data?.data?.at(0)[item.key]?.risk_tips && (
                                <Tooltip
                                  placement="topRight"
                                  title={data?.data?.at(0)[item.key]?.risk_tips}
                                  arrowPointAtCenter
                                >
                                  <div className="warnImgBox">
                                    <img src={img4} alt="" />
                                  </div>
                                </Tooltip>
                              )}
                            </Descriptions.Item>
                          ))}
                      </Descriptions>
                    ) : (
                      <MyEmpty description="暂无数据"></MyEmpty>
                    )}
                  </div>

                  {!notShowShareholderAndPersonnel && (
                    <div className="shareholderContent">
                      <HeaderTitle
                        title="主要股东"
                        extra={
                          <div className="typeBox">
                            {shareholderTypeList.map((item) => (
                              <div
                                key={item}
                                className={
                                  item === shareholderType
                                    ? "typeItem activeType"
                                    : "typeItem"
                                }
                                onClick={() => setShareholderType(item)}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        }
                      ></HeaderTitle>
                      <div className="dateBox">
                        更新时间：
                        {shareholderData?.data_update_time || "-"}
                      </div>
                      <Table
                        locale={{
                          emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
                        }}
                        scroll={{ x: "auto" }}
                        rowKey={(record) => record.id.val}
                        className="myFormTable"
                        bordered={true}
                        pagination={false}
                        columns={shareholderColumns}
                        dataSource={shareholderData?.data || []}
                      />
                    </div>
                  )}

                  {!notShowShareholderAndPersonnel && (
                    <div className="personnelContent">
                      <HeaderTitle
                        title="主要人员"
                        extra={
                          <div className="typeBox">
                            {personnelTypeList.map((item) => (
                              <div
                                key={item}
                                className={
                                  item === personnelType
                                    ? "typeItem activeType"
                                    : "typeItem"
                                }
                                onClick={() => setPersonnelType(item)}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        }
                      ></HeaderTitle>
                      <div className="dateBox">
                        更新时间：
                        {personnelData?.data_update_time || "-"}
                      </div>
                      <Table
                        locale={{
                          emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
                        }}
                        scroll={{ x: "auto" }}
                        rowKey={(record) => record.id.val}
                        className="myFormTable"
                        bordered={true}
                        pagination={false}
                        columns={personnelColumns}
                        dataSource={personnelData?.data || []}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </Provider>
  );
};
export default wrapper(CompanyInfoDrawer);
