import { useEffect, useRef, useState } from "react";
import G6 from "@antv/g6";
import "./index.scss";
import { guid } from "../../../../../unit/unit";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { showInfoFunc } from "../../../../../components/MyTable";
import { useParams } from "react-router-dom";
import { Tooltip } from "antd";
import Scrollbars from "react-custom-scrollbars";
const TipContent = (props) => {
  const { info, showDetail } = props;

  return (
    <div className="tipContentBox">
      {info?.map((item) => (
        <div key={item.field}>
          <div>{item.field}</div>
          {item?.desc_info?.map((_) =>
            _?.suffix ? (
              <div className="tipContentText" key={Math.random()}>
                {/* {_.desc?.length > 15 ? _.desc?.slice(0, 15) + "..." : _.desc} */}
                {_.desc}
                <span
                  onClick={() => {
                    showDetail(_?.suffix);
                  }}
                >
                  详情
                </span>
              </div>
            ) : (
              <div className="tipContentText" key={Math.random()}>
                {_.desc}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

const PathDiv = (props) => {
  const { path, startNodeId, showDetail } = props;
  const { id } = useParams();

  //   console.log(path);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [lineNodes, setLineNodes] = useState([]);

  const sTypeColorMap = {
    尽调对象: "#0068B2",
    关联方: "#AAD461",
    供应商: "#C88EED",
    经销商: "#6CAEFF",
    客户: "#FEC054",
    非经营性资金往来: "#C6C0FC",
    其他: "#61D4AD",
  };

  const twoWayTypes = [
    "邮箱",
    "地址",
    "电话",
    "网址",
    "法院公告",
    "法律诉讼",
    "专利",
    "商标",
    "作品著作",
    "软件著作",
  ];

  const contentId = useRef(guid());
  const graph = useRef(null);
  const initPath = async (path) => {
    let _nodes = [];
    let _edges = [];
    path.forEach((item) => {
      const [node1, type, node2] = item;
      let node1Id = guid();
      let node2Id = guid();
      const _node1 = _nodes.find(
        (_) => _.entity_id === node1?.property?.entity_id
      );
      const _node2 = _nodes.find(
        (_) => _.entity_id === node2?.property?.entity_id
      );

      if (!_node1) {
        _nodes.push({
          label: node1?.property?.name,
          _label: node1.label,
          entity_id: node1?.property?.entity_id,
          name: node1?.property?.name,
          id: node1Id,
          cluster: 1,
          s_type: node1?.s_type || [],
        });
      } else {
        node1Id = _node1.id;
      }

      if (!_node2) {
        _nodes.push({
          label: node2?.property?.name,
          _label: node2.label,
          entity_id: node2?.property?.entity_id,
          name: node2?.property?.name,
          id: node2Id,
          cluster: 1,
          s_type: node2?.s_type || [],
        });
      } else {
        node2Id = _node2.id;
      }
      _edges.push({
        source: node1Id,
        target: node2Id,
        label: type.type,
        type_desc: type.type_desc_list,
        property: type.property,
      });
    });
    await setNodes(_nodes);
    await setEdges(_edges);
    // console.log(_edges);
    // console.log(initEdges(_nodes, _edges), startNodeId);
    setLineNodes(initEdges(_nodes, _edges, startNodeId));
    // await initChart({
    //   nodes: _nodes,
    //   edges: _edges,
    // });
  };

  const initEdges = (nodes, edges, startNodeId) => {
    let arr = [];
    const init = (nodes, edges) => {
      //没有关联的 edges列表
      let surplusEdges = [];
      edges.forEach((edge) => {
        //结束node在列表中位置
        let targetIndex = arr.findIndex((_) => _.id === edge.target);
        //开始node在列表中位置
        let sourceIndex = arr.findIndex((_) => _.id === edge.source);

        //左右都没有node
        if (targetIndex === -1 && sourceIndex === -1) {
          //列表中没数据 添加两条到列表中
          if (arr.length === 0) {
            arr.push({
              ...(nodes.find((_) => _.id === edge.source) || {}),
              point: "right",
              right: edge.label,
              rightTip: edge.type_desc,
            });
            arr.push(nodes.find((_) => _.id === edge.target));
          } else {
            //列表中有数据 但是没有关联项 暂存到surplusEdges列表中
            surplusEdges.push(edge);
          }
        } else if (targetIndex !== -1) {
          //结束的node在列表中存在
          //判断 右边是否有节点 有的话在左边添加node,没有的话在右边添加
          if (arr[targetIndex + 1]) {
            arr.splice(targetIndex, 0, {
              ...(nodes.find((_) => _.id === edge.source) || {}),
              point: "right",
              right: edge.label,
              rightTip: edge.type_desc,
            });
          } else {
            arr.splice(targetIndex + 1, 0, {
              ...(nodes.find((_) => _.id === edge.source) || {}),
              point: "left",
              left: edge.label,
              leftTip: edge.type_desc,
            });
          }
        } else if (sourceIndex !== -1) {
          //开始的node在列表中存在
          //判断 右边是否有节点 有的话在左边添加node,没有的话在右边添加
          if (arr[sourceIndex + 1]) {
            if (arr[sourceIndex].point) {
              arr[sourceIndex].point = "all";
              arr[sourceIndex].left = edge.label;
              arr[sourceIndex].leftTip = edge.type_desc;
            } else {
              arr[sourceIndex].point = "left";
              arr[sourceIndex].left = edge.label;
              arr[sourceIndex].leftTip = edge.type_desc;
            }
            arr.splice(sourceIndex, 0, {
              ...(nodes.find((_) => _.id === edge.target) || {}),
            });
          } else {
            if (arr[sourceIndex].point) {
              arr[sourceIndex].point = "all";
              arr[sourceIndex].right = edge.label;
              arr[sourceIndex].rightTip = edge.type_desc;
            } else {
              arr[sourceIndex].point = "right";
              arr[sourceIndex].right = edge.label;
              arr[sourceIndex].rightTip = edge.type_desc;
            }
            arr.splice(sourceIndex + 1, 0, {
              ...(nodes.find((_) => _.id === edge.target) || {}),
            });
          }
        }
      });
      if (surplusEdges.length > 0) {
        init(nodes, surplusEdges);
      }
    };
    init(nodes, edges);
    //路径保持 form -> end
    if (arr.findIndex((_) => _.entity_id === startNodeId) === arr.length - 1) {
      let _arr = JSON.parse(JSON.stringify(arr));
      arr = [];
      _arr.forEach((item) => {
        if (item.point === "right") {
          item.point = "left";
          item.left = item.right;
          item.leftTip = item.rightTip;
          delete item.right;
          delete item.rightTip;
        } else if (item.point === "left") {
          item.point = "right";
          item.right = item.left;
          item.rightTip = item.leftTip;
          delete item.left;
          delete item.leftTip;
        } else if (item.point === "all") {
          let _right = item.right;
          let _rightTip = item.rightTip;
          item.right = item.left;
          item.rightTip = item.leftTip;
          item.left = _right;
          item.leftTip = _rightTip;
        }
        arr.unshift(item);
      });
    }
    return arr;
  };

  const initChart = (data) => {
    // console.log(data);
    if (graph.current) {
      graph.current.destroy();
      graph.current = null;
    }
    const container = document.getElementById(contentId.current);
    if (!container) {
      return;
    }
    const width = container.scrollWidth;
    const height = container.scrollHeight;
    const defaultEdgeStyle = {
      stroke: "#D8D8D8",
      endArrow: {
        fill: "#D8D8D8",
        path: G6.Arrow.triangle(10, 6, 0),
        d: 0,
      },
    };
    const defaultNodeStyle = {
      // fill: "#91d5ff",
      // stroke: "#40a9ff",
      radius: 5,
    };
    const defaultLabelCfg = {
      style: {
        fill: "#000",
        fontSize: 12,
      },
    };
    G6.registerEdge("path-line", {
      draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;
        const { style } = cfg;
        let shape = null;
        group.addShape("path", {
          attrs: {
            stroke: style.stroke,
            endArrow: style.endArrow,
            label: "triangleRect arrow",
            path: [
              ["M", startPoint.x, startPoint.y + 5],
              ["L", endPoint.x - 5, endPoint.y + 5],
            ],
          },
        });
        shape = group.addShape("text", {
          attrs: {
            fill: "#0068B2",
            fontSize: 12,
            text: cfg.label,
            x:
              (endPoint.x - startPoint.x) / 2 +
              startPoint.x -
              (cfg.label.length * 15) / 2,
            y: (endPoint.y - startPoint.y) / 2 + startPoint.y,
          },
          name: "percentText",
        });

        return shape;
      },
    });
    G6.registerNode(
      "path-node",
      {
        options: {
          size: [150, 20],
          stroke: "#91d5ff",
          fill: "#91d5ff",
        },
        draw(cfg, group) {
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;
          styles.width =
            (cfg._label !== "Company" ? 40 : cfg.name.length * 16) + 20;
          const w = styles.width;
          const h = styles.height;
          const keyShape = group.addShape("rect", {
            attrs: {
              ...styles,
              height: 60,
              x: -w / 2,
              y: -h / 2,
            },
          });
          const rectGroup = group.addGroup({
            name: "rect-box",
          });
          if (cfg._label === "Company") {
            rectGroup.addShape("rect", {
              attrs: {
                width: w - 20,
                height: h,
                stroke: "#D8D8D8",
                fill: "#fff",
                x: -w / 2 + 10,
                y: -h / 2 + 10,
              },
            });
            //公司名称
            if (cfg.name) {
              const max = 14;
              const isLineFeed = cfg.name?.length > max;
              const name = isLineFeed
                ? cfg.name?.slice(0, max) + "\n" + cfg.name?.slice(max)
                : cfg.name;
              const len = isLineFeed ? max : cfg.name?.length;
              rectGroup.addShape("text", {
                attrs: {
                  fill: "#313131",
                  fontSize: 14,
                  text: name,
                  align: "center",
                  x: -w / 2 + 20,
                  y: h / 2 - 2,
                },
              });
            }
          } else {
            //图像logo
            rectGroup.addShape("circle", {
              attrs: {
                name: "image-circle",
                x: 0,
                y: h / 2 - 10,
                r: 20,
                fill: "#FEC054",
              },
              name: "image-circle",
            });
            let x = 0;
            if (cfg.name.length === 2) {
              x = -10;
            } else if (cfg.name.length === 3) {
              x = -15;
            }
            rectGroup.addShape("text", {
              attrs: {
                fill: "#FFFFFF",
                fontSize: 10,
                text: cfg.name,
                align: "center",
                x: x,
                y: h / 2 - 5,
              },
            });
          }

          return keyShape;
        },
      },
      "rect"
    );
    graph.current = new G6.Graph({
      container: contentId.current,
      width,
      height,
      fitView: true,
      // modes: {
      //   default: [ 'drag-canvas'],
      // },
      layout: {
        // type: "dagre",
        // rankdir: "LR",
        type: "grid",
        rows: 1,
        sortBy: "cluster",
        preventOverlapPadding: 100,
        preventOverlap: true,
        nodeSize: 100,
      },
      defaultNode: {
        type: "path-node",
        size: [200, 40],
        style: defaultNodeStyle,
        labelCfg: defaultLabelCfg,
      },
      defaultEdge: {
        type: "path-line",
        style: defaultEdgeStyle,
      },
    });
    graph.current.data(data);
    graph.current.render();
  };

  const initStyle = (type) => {
    let styles = {
      border: "1px solid #D8D8D8",
      color: "#535353",
      background: "#FFFFFF",
    };
    if (type) {
      styles.border = `1px solid ${sTypeColorMap[type]}`;
      styles.color = "#FFFFFF";
      styles.background = sTypeColorMap[type];
    }
    return styles;
  };

  const showInfoHandle = (record) => {
    const item = {
      shareholderId: { val: record.entity_id },
      name: { val: record.name },
      s_type: { val: record._label === "Person" ? 2 : 1 },
      click_all: true,
    };
    showInfoFunc(item, item, "主要股东", id, "name");
  };

  const isTwoWayLine = (type) => {
    let flag = false;
    twoWayTypes.forEach((item) => {
      if (type?.includes(item)) {
        flag = true;
      }
    });
    return flag;
  };
  const initDom = (lineNodes) => {
    if (lineNodes.length === 0) {
      return <></>;
    }
    return (
      <div className="lineNodesBox">
        {lineNodes.map((item) => {
          const style = initStyle(item.s_type?.at(0)?.full_name);
          return (
            <div className="nodeItem" key={item.id}>
              {(item.point === "left" || item.point === "all") && (
                <div className="leftLine">
                  <CaretLeftOutlined className="leftLineIcon" />
                  {isTwoWayLine(item.left) && (
                    <CaretRightOutlined className="rightLineIcon" />
                  )}
                  <Tooltip
                    placement="top"
                    title={
                      <TipContent
                        info={item.leftTip}
                        showDetail={showDetail}
                      ></TipContent>
                    }
                    arrowPointAtCenter
                    overlayInnerStyle={{
                      whiteSpace: "pre-wrap",
                    }}
                    overlayStyle={{
                      maxWidth: "calc(100vw - 40px)",
                    }}
                  >
                    <div className="typeTip">{item.left}</div>
                  </Tooltip>
                </div>
              )}

              {item._label === "Person" && (
                <div
                  className="personItem"
                  onClick={() => {
                    showInfoHandle(item);
                  }}
                  style={style}
                >
                  {item.name}
                </div>
              )}
              {item._label === "Company" && (
                <div
                  className="companyItem"
                  onClick={() => {
                    showInfoHandle(item);
                  }}
                  style={style}
                >
                  {item.name?.length > 4 ? (
                    <Tooltip title={item.name}>
                      <div>{item.name?.slice(0, 4)}</div>
                      <div>
                        {item.name?.length > 8
                          ? item.name?.slice(4, 7) + "..."
                          : item.name?.slice(4, 8)}
                      </div>
                    </Tooltip>
                  ) : (
                    item.name
                  )}
                </div>
              )}
              {(item.point === "right" || item.point === "all") && (
                <div className="rightLine">
                  {isTwoWayLine(item.right) && (
                    <CaretLeftOutlined className="leftLineIcon" />
                  )}
                  <CaretRightOutlined className="rightLineIcon" />
                  <Tooltip
                    placement="top"
                    title={
                      <TipContent
                        info={item.rightTip}
                        showDetail={showDetail}
                      ></TipContent>
                    }
                    arrowPointAtCenter
                    overlayInnerStyle={{
                      whiteSpace: "pre-wrap",
                    }}
                    overlayStyle={{
                      maxWidth: "calc(100vw - 40px)",
                    }}
                  >
                    <div className="typeTip">{item.right}</div>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    initPath(path);
  }, [path]);
  return (
    <div className="pathDivContent">
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{ height: "100%", width: "calc(100% - 20px)" }}
      >
        {initDom(lineNodes)}
        {/* <div
        id={contentId.current}
        style={{ width: "100%", height: "100%" }}
      ></div> */}
      </Scrollbars>
    </div>
  );
};
export default PathDiv;
