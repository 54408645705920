import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { Popover, Modal } from "antd";
import { guid } from "../../../../../unit/unit";

const TimeLine = (props) => {
  const { data } = props;
  const ref = useRef();
  const [showRightIcon, setShowRightIcon] = useState(false);
  const [showLeftIcon, setShowLeftIcon] = useState(false);

  const [showData, setShowData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [showContent, setShowContent] = useState("");

  const onScroll = () => {
    setShowLeftIcon(ref.current?.getScrollLeft() > 0);
    setShowRightIcon(
      Math.ceil(ref.current?.getScrollLeft()) !==
        Math.ceil(ref.current?.getScrollWidth() - ref.current?.getClientWidth())
    );
  };

  useEffect(() => {
    setShowData(
      (data || [])?.map((item) => {
        item.id = guid();
        item.visible = false;
        return item;
      })
    );
  }, [data]);

  useEffect(() => {
    if (ref.current) {
      setShowLeftIcon(ref.current?.getScrollLeft() > 0);
      setShowRightIcon(
        Math.ceil(ref.current?.getScrollLeft()) !==
          Math.ceil(
            ref.current?.getScrollWidth() - ref.current?.getClientWidth()
          )
      );
    }
  }, [ref.current, showData]);

  const count = useRef(0);
  const startPosition = useRef(0);
  const goScrollLeft = (left) => {
    if (count.current === 0) {
      startPosition.current = ref.current.getScrollLeft();
    }
    const _left = ref.current.getScrollLeft() + left / 20;
    ref.current.scrollLeft(_left);
    count.current = count.current + 1;
    if (count.current <= 20) {
      setTimeout(() => {
        goScrollLeft(left);
      }, 10);
    } else {
      ref.current.scrollLeft(startPosition.current + left);
      count.current = 0;
    }
  };

  const handleVisibleChange = (item) => {
    if (item.event_type_list?.length <= 6) {
      return;
    }
    setShowData((prev) => {
      prev = prev.map((_) => {
        if (_.id === item.id) {
          _.visible = !_.visible;
        }
        return _;
      });
      return prev;
    });
  };

  const lookUp = (val, info) => {
    setShowContent(val);
    setVisible(true);
  };

  return (
    <div className="TimeLineContent">
      <div className="title">时间轴</div>
      {showData?.length > 8 && showLeftIcon && (
        <LeftCircleFilled
          className="leftIcon"
          onClick={() => {
            let left =
              ref.current.getScrollLeft() - ref.current?.getClientWidth();
            if (left < 0) {
              left = ref.current.getScrollLeft();
            } else {
              left = ref.current?.getClientWidth();
            }
            // ref.current.scrollLeft(left);
            goScrollLeft(0 - left);
          }}
        />
      )}

      {showData?.length > 8 && showRightIcon && (
        <RightCircleFilled
          className="rightIcon"
          onClick={() => {
            let left =
              ref.current?.getClientWidth() + ref.current.getScrollLeft();
            if (left > ref.current?.getScrollWidth()) {
              left =
                ref.current?.getScrollWidth() - ref.current.getScrollLeft();
            } else {
              left = ref.current?.getClientWidth();
            }
            // ref.current.scrollLeft(left);
            goScrollLeft(left);
          }}
        />
      )}
      {/* <div className="dateItemContent" ref={ref}> */}
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        onScroll={onScroll}
        style={{
          height: "calc(100% - 50px)",
          width: "calc(100% - 80px)",
          marginTop: "50px",
        }}
        className="activeScrollBars"
        ref={ref}
        onWheel={(e) => {
          let left = ref.current.getScrollLeft();
          const offset = 40;
          if (e.deltaX > 0) {
            left = left + offset;
          } else if (e.deltaX < 0) {
            left = left - offset;
          } else if (e.deltaY > 0) {
            left = left + offset;
          } else if (e.deltaY < 0) {
            left = left - offset;
          }
          ref.current.scrollLeft(left);

          setShowData((prev) => {
            prev = prev.map((item) => {
              item.visible = false;
              return item;
            });
            return [...prev];
          });
        }}
      >
        <div className="dateItemBox">
          {showData?.map((item, index) => (
            <div
              className={index === 0 ? "firstDateItem dateItem" : "dateItem"}
              key={Math.random()}
            >
              <div className="dateBox">{item.trade_date}</div>
              <div className="lineBox">
                <div
                  className="leftLine"
                  style={{ background: index === 0 ? "#ffffff" : "#0068B2" }}
                ></div>
                <div
                  className="rightLine"
                  style={{
                    background:
                      index === showData?.length - 1 ? "#D8D8D8" : "#0068B2",
                  }}
                ></div>
                <div className="circle">
                  {item.is_black === 1 ? <span></span> : null}
                </div>
              </div>
              <div
                className="fileBox"
                style={{ color: item?.is_black === 1 ? "#535353" : "#9E9E9E" }}
                // onWheel={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();
                //   e.cancelBubble = true;
                // }}
              >
                {((item.event_type_list || [])?.length > 6
                  ? (item.event_type_list || [])?.slice(0, 6)
                  : item.event_type_list || []
                )?.map((_) => {
                  if (_.is_html) {
                    return (
                      <a
                        key={Math.random()}
                        onClick={() => {
                          lookUp(_.content);
                        }}
                      >
                        {_.event_type}
                      </a>
                    );
                  }
                  if (item.is_black === 1 && _.anc_url) {
                    return (
                      <a target="_block" href={_.anc_url} key={Math.random()}>
                        {_.event_type}
                      </a>
                    );
                  }
                  return <span key={Math.random()}>{_.event_type}</span>;
                })}
                {(item.event_type_list || [])?.length > 6 ? (
                  <Popover
                    trigger="click"
                    placement="top"
                    overlayClassName="timeLineContentPopover"
                    visible={item.visible}
                    destroyTooltipOnHide={true}
                    onVisibleChange={() => handleVisibleChange(item)}
                    content={
                      <div
                        className="fileBox"
                        onWheel={(e) => {
                          e.stopPropagation();
                          e.cancelBubble = true;
                        }}
                      >
                        {(item.event_type_list || [])?.map((_) =>
                          item.is_black === 1 && _.anc_url ? (
                            <a
                              target="_block"
                              href={_.anc_url}
                              key={Math.random()}
                            >
                              {_.event_type}
                            </a>
                          ) : (
                            <span key={Math.random()}>{_.event_type}</span>
                          )
                        )}
                      </div>
                    }
                  >
                    <div
                      className="moreDiv"
                      // onClick={() => handleVisibleChange(item)}
                    >
                      更多
                    </div>
                  </Popover>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </Scrollbars>
      {/* </div> */}

      <Modal
        title="详情"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        //   onOk={handleOk}
        wrapClassName="timeLineContentModal"
        centered
        footer={null}
      >
        <div className="showContentBox" key={Math.random()}>
          <span
            dangerouslySetInnerHTML={{
              __html: showContent,
            }}
          ></span>
        </div>
      </Modal>
    </div>
  );
};
export default TimeLine;
