import "./index.scss";
import React, { useRef, useState } from "react";
import { useHover } from "ahooks";
import { Tooltip } from "antd";
import { ReactSVG } from "react-svg";
import lySvg from "../../assets/查看来源.svg";
import CommentsIcon from "../Icons/CommentsIcon";
import InfoIcon from "../Icons/InfoIcon";
import ReviseIcon from "../Icons/ReviseIcon";
import { useSelector } from "react-redux";

const MyDiv = (props) => {
  const { children, info, dataTable, getData, typeName, dataInfo, mode } =
    props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const [show, setShow] = useState(false);
  const mouseEnterDelay = 0.3;
  return (
    <div ref={ref} className="myDivItem">
      <span className="myItem">
        {children}
        {info?.function?.length > 0 && info.id ? (
          <>
            {projectInfo?.project_permission_urls?.includes(
              "CDD_PRJ_COMMENT_VIEW"
            ) && (
              <InfoIcon
                info={info}
                dataTable={dataTable}
                getData={getData}
                typeName={typeName}
                dataInfo={dataInfo}
                projectId={projectInfo.id}
                mode={mode}
                forceRender={true}
                mouseEnterDelay={mouseEnterDelay}
              ></InfoIcon>
            )}

            <span className={isHovering || show ? "" : "noHover"}>
              {/* {info?.comments?.some((_) => _.type === "修订") && (
                <Tooltip placement="top" title="查看来源" arrowPointAtCenter forceRender={true}  mouseEnterDelay={mouseEnterDelay}>
                  <ReactSVG
                    className="sourceIcon hoverIcon"
                    src={lySvg}
                  ></ReactSVG>
                </Tooltip>
              )} */}
              {info?.function?.includes("comments") &&
              projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_COMMENT"
              ) ? (
                <CommentsIcon
                  setShow={setShow}
                  info={info}
                  dataTable={dataTable}
                  getData={getData}
                  typeName={typeName}
                  dataInfo={dataInfo}
                  projectId={projectInfo.id}
                  mode={mode}
                  forceRender={true}
                  mouseEnterDelay={mouseEnterDelay}
                />
              ) : null}
              {info?.function?.includes("revise") &&
              projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CDD_ITEM_EDIT"
              ) ? (
                <ReviseIcon
                  setShow={setShow}
                  info={info}
                  dataTable={dataTable}
                  getData={getData}
                  typeName={typeName}
                  dataInfo={dataInfo}
                  projectId={projectInfo.id}
                  forceRender={true}
                  mouseEnterDelay={mouseEnterDelay}
                />
              ) : null}
              {/* {info?.function?.includes("source") ? (
                <TagOutlined className="cursor-pointer editIcon" />
              ) : null} */}
              {/* <TagsOutlined className="cursor-pointer editIcon" /> */}
            </span>
          </>
        ) : null}
      </span>
    </div>
  );
};

export default MyDiv;
