import "./index.scss";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../../../../../assets/下拉.svg";
import projectApi from "../../../../../apis/project";
import moment from "moment";
import {
  Button,
  Form,
  Select,
  DatePicker,
  message,
  Modal,
  Checkbox,
} from "antd";

import Scrollbars from "react-custom-scrollbars";
import debounce from "lodash/debounce";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const CasePartiesModal = (props) => {
  const [form] = Form.useForm();

  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 初始值 */
    defaultData,
    visible,
  } = props;

  useEffect(() => {
    form.resetFields();
  }, [defaultData]);

  const handleOk = () => {
    onOk && onOk();
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <Modal
      title="提示"
      centered
      visible={visible}
      footer={null}
      wrapClassName="casePartieseModal"
      onCancel={onCancel}
      width={560}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        className="casePartieseModalContent"
        preserve={false}
      >
        <div className="title">
          涉案主体还不是关联方，请选择关联方并确认关联关系
        </div>

        <Form.Item
          name="plaintiffs"
          style={{
            width: "100%",
          }}
          // initialValue={addNew ? null : defaultData?.related_case_no.join()}
          valuePropName="checked"
        >
          <Checkbox onChange={onChange}>原告: xxxxxxxxxxx</Checkbox>
        </Form.Item>
        <Form.Item
          name="关联关系"
          label="关联关系"
          style={{
            width: "45%",
            marginLeft: "22px",
          }}
          rules={[{ required: true, message: "请选择关联关系" }]}
        >
          <Select
            placeholder="请选择"
            allowClear
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </Form.Item>
        <Form.Item
          //   name="plaintiffs"
          style={{
            width: "100%",
          }}
          // initialValue={addNew ? null : defaultData?.related_case_no.join()}
          valuePropName="checked"
        >
          <Checkbox onChange={onChange}>原告: xxxxxxxxxxx</Checkbox>
        </Form.Item>
        <Form.Item
          name="关联关系"
          label="关联关系"
          style={{
            width: "45%",
            marginLeft: "22px",
          }}
          rules={[{ required: true, message: "请选择关联关系" }]}
        >
          <Select
            placeholder="请选择"
            allowClear
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="任职/投资公司名称"
          label="任职/投资公司名称"
          style={{
            width: "45%",
          }}
          rules={[{ required: true, message: "请输入需要查询的企业名称" }]}
        >
          <Select
            placeholder="请输入需要查询的企业名称"
            allowClear
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="defendants"
          style={{
            width: "100%",
          }}
          // initialValue={addNew ? null : defaultData?.related_case_no.join()}
          valuePropName="checked"
        >
          <Checkbox onChange={onChange}>被告: xxxxxxxxxxx</Checkbox>
        </Form.Item>
        <Form.Item
          //   name="defendants"
          style={{
            width: "100%",
          }}
          // initialValue={addNew ? null : defaultData?.related_case_no.join()}
          valuePropName="checked"
        >
          <Checkbox onChange={onChange}>被告: xxxxxxxxxxx</Checkbox>
        </Form.Item>
        <Form.Item className="buttonValue">
          <Button htmlType="button" onClick={onCancel}>
            取消
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#0068B2", marginLeft: "24px" }}
          >
            添加
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CasePartiesModal;
