import "./index.scss";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useEffect } from "react";
import { useUpdate } from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";

const TableContent = (props) => {
  const { id } = useParams();
  const { defaultTableName, typeName } = props;
  const [state, setState] = useUrlState({});

  const update = useUpdate();
  useEffect(() => {
    update();
  }, [defaultTableName, typeName]);

  return (
    <div className="TableContentBox">
      <div className="infoBox">
        <MyTable
          showHeaderTitle={true}
          typeName={typeName}
          dataTable={defaultTableName}
          defaultFilterCriteria={
            state?.defaultFilterCriteria
              ? JSON.parse(state?.defaultFilterCriteria)
              : null
          }
        ></MyTable>
      </div>
    </div>
  );
};
export default TableContent;
