import "./index.scss";
import G6 from "@antv/g6";
import { useEffect } from "react";
import { useGetState } from "ahooks";
import { useRef } from "react";

import insertCss from "insert-css";

insertCss(`
  .g6-component-tooltip {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0px 5px ;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
  }
`);

/* 
  props参数
  onNodeClick: 点击node触发的回调  (node详情)=>{ }
  onCanvasClick: 点击画布触发的回调
  data: 数据列表 
    格式为 [{
      label: 小字部分,
      value: 数字,
      id: 唯一的id,必须为字符串,
      children(可有可无): [
        ...同上
      ]
    },
    ...]

*/
const BubbleBox = (props) => {
  const { onNodeClick, onCanvasClick, data } = props;
  const graph = useRef(null);
  const [level, setLevel, getLevel] = useGetState(1);
  const colorList = [
    "#16D8D8",
    "#6CAEFF",
    "#AAD461",
    "#FE545F",
    "#0EB1CB",
    "#FEC054",
    "#006AB2",
  ];
  const bgColorList = [
    "rgba(22,216,216,0.1500)",
    "rgba(108,174,255,0.1500)",
    "rgba(170,212,97,0.1500)",
    "rgba(254,84,95,0.1500)",
    "rgba(14,177,203,0.1500)",
    "rgba(254,192,84,0.1500)",
    "rgba(0,106,178,0.1000)",
  ];
  const initList = (list, height) => {
    list = list?.sort((a, b) => b.value - a.value);
    list = list?.map((item, index) => {
      if (item.children) {
        item.children = initList(item.children, height);
      }
      if (index === 0) {
        item.size = height / 2;
      } else if (index <= 3) {
        item.size = height / 2 - 20;
      } else if (index <= 5) {
        item.size = height / 2 - 30;
      } else {
        item.size = height / 2 - 30;
      }
      item.type = "myCircle";
      item.bgColor = bgColorList[index % 7];
      item.color = colorList[index % 7];
      return item;
    });
    return list;
  };
  useEffect(() => {
    if (graph.current) {
      graph.current.destroy();
      graph.current = null;
    }
    const container = document.getElementById("bubbleBoxContent");
    const width = container.clientWidth;
    const height = container.clientHeight;
    // 定义数据源
    const _data = {
      // 点集
      nodes: initList(data, height < 240 ? 270 : height),
    };

    G6.registerNode("myCircle", (cfg) => {
      let len = Math.floor(cfg.size / 18);
      let data = cfg?.label;
      if (data?.length > 8) {
        data = data?.substring(0, 8) + "...";
      }
      let arr = [];
      for (let i = 0; i * len <= data?.length; i++) {
        let str = data?.slice(i * len, (i + 1) * len);
        if (str !== "" && i < 2) {
          arr.push(str);
        } else {
          break;
        }
      }
      return `
        <group>
          <circle style={{
            opacity: 0.15,
            stroke: ${cfg.color},
            r: ${cfg.size / 2},
            fill: ${cfg.color},
            cursor: 'pointer',
          }} name="circle" draggable="true">

          <text draggable="true" style={{cursor: 'pointer', fontFamily:'Century Gothic-Regular, Century Gothic', fill: ${
            cfg.color
          },textAlign: 'center',marginTop:-3,fontSize:${
        cfg.size / 3
      },lineHight:-${cfg.size / 2 + (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2)}
        }}>{{value}}</text>

          ${arr.map(
            (
              item,
              index
            ) => `<text draggable="true" style={{cursor: 'pointer', fill: ${
              cfg.color
            },textAlign: 'center',marginTop:-5,lineHight:-${
              cfg.size / 2 + (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2)
            }
            fontFamily:'Century Gothic-Regular, Century Gothic'
          }}>${item}</text>`
          )}
          </circle>

          <circle style={{
            stroke: '#fff',
            r: ${cfg.size / 13},
            fill: '#fff',
            marginLeft: -${
              cfg.size / 2 - (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2) + 10
            },
            marginTop: -${
              cfg.size / 2 + (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2)
            },
            cursor: 'pointer',
            lineHight:-${
              cfg.size / 2 + (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2)
            }
            fontFamily:'Century Gothic-Regular, Century Gothic'
          }} name="circle">

          <circle style={{
            stroke: ${cfg.color},
            r: ${cfg.size / 13 - 2},
            fill: '#fff',
            marginLeft: -${
              cfg.size / 2 - (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2) + 10
            },
            cursor: 'pointer',
            lineHight:-${
              cfg.size / 2 + (Math.pow(2, 1 / 2) - 1) * (cfg.size / 2)
            }
            fontFamily:'Century Gothic-Regular, Century Gothic'
          }} name="circle" draggable="true">
          </circle>
          </circle>
        </group>`;
    });
    const layoutCfg = {
      type: "force",
      nodeSize: (d) => {
        return d.size / 2 + 15;
      },
      nodeStrength: 3000,
      collideStrength: 0.8,
      alphaDecay: 0.3,
      preventOverlap: true,
      onTick: () => {
        const nodeItems = graph.current.getNodes();
        const height = graph.current.get("height");
        const width = graph.current.get("width");
        const padding = 5;
        nodeItems.forEach((item) => {
          const model = item.getModel();
          if (model.x > width - padding) {
            model.x = width - padding;
          } else if (model.x < padding) {
            model.x = padding;
          }

          if (model.y > height - padding) model.y = height - padding;
          else if (model.y < padding) model.y = padding;

          if (model.size === height / 2) {
            model.y = height / 2 + 40;
            model.x = width / 2;
            // graph.focusItem(model.id, true, {
            //   easing: "easeCubic",
            //   duration: 500,
            // });
          }
        });
      },
    };

    const tooltip = new G6.Tooltip({
      getContent(e) {
        const outDiv = document.createElement("div");
        if (e.item._cfg.type == "node") {
          outDiv.style.width = "100px";
          outDiv.innerHTML = `
                  <p>${e?.item?._cfg?.model?.label}(${e?.item?._cfg?.model?.value})</p>
                `;
          return outDiv;
        } else {
          outDiv.style.display = "none";
          return outDiv;
        }
      },
    });

    // 创建 G6 图实例
    graph.current = new G6.Graph({
      container: "bubbleBoxContent", // 指定图画布的容器 id，与第 9 行的容器对应
      // 画布宽高
      width: width,
      height: height,
      layout: layoutCfg,
      // renderer: "svg",
      plugins: [tooltip],
      fitCenter: true,
      fitView: true,
      animate: true,
      defaultNode: {
        type: "myCircle",
      },
      modes: {
        default: ["zoom-canvas", "drag-canvas", "drag-node"],
      },
    });
    // 读取数据
    graph.current.data(_data);
    // 渲染图
    graph.current.render();
    setTimeout(() => {
      graph.current.fitView(20);
    }, 500);

    graph.current.on("canvas:click", () => {
      // console.log("点击画布", getLevel());
      onCanvasClick && onCanvasClick();
      if (getLevel() === 1) {
        return;
      }
      setLevel(1);
      graph.current.changeData(_data);
    });

    graph.current.on("node:click", (e) => {
      // console.log("点击节点", getLevel(),e);
      if (
        e.item._cfg.model?.children &&
        e.item._cfg.model?.children?.length > 0
      ) {
        setLevel(2);
        graph.current.read(_data);
        // graph.current.focusItem(e.item._cfg.model.id, true, {
        //   easing: "easeCubic",
        //   duration: 500,
        // });
        setLevel(2);
        graph.current.changeData({ nodes: e.item._cfg.model.children });
      }
      onNodeClick && onNodeClick(e.item._cfg.model);
    });

    if (typeof window !== "undefined")
      window.onresize = () => {
        if (!graph.current || graph.current.get("destroyed")) return;
        if (!container || !container.clientWidth || !container.clientHeight)
          return;
        graph.current.changeSize(container.clientWidth, container.clientHeight);
      };
  }, [data]);

  // const downloadImage = () => {
  //   // console.log(treeContainer);
  //   const canvasArr = document.documentElement
  //     .querySelector("#bubbleBoxContent")
  //     .getElementsByTagName("canvas");
  //   const { height, width } = canvasArr[0];
  //   canvasArr[0].style.backgroundColor = "#fff";
  //   const saveName = `1.png`;
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   canvas.width = width;
  //   canvas.height = height;
  //   ctx.fillStyle = "#fff";
  //   ctx.fillRect(0, 0, canvas.width, canvas.height);
  //   ctx.drawImage(canvasArr[0], 0, 0);
  //   // 创建并下载图片
  //   const dataURL = canvas.toDataURL("image/png");
  //   const link = document.createElement("a");
  //   link.download = saveName;
  //   link.href = dataURL.replace("image/png", "image/octet-stream");
  //   link.click();
  // };
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className="bubbleBoxContent"
      id="bubbleBoxContent"
    >
      {/* <div
        className="down"
        onClick={() => {
          downloadImage();
        }}
      >
        下载
      </div> */}
    </div>
  );
};
export default BubbleBox;
