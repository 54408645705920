import BusinessInformation from "../BusinessInformation";
import MajorShareholders from "../MajorShareholders";
import Investment from "../Investment";
import EquityRelationship from "../EquityRelationship";
import NotFound from "../../404/index";
import ProjectInfo from "../ProjectInfo";
import { Navigate } from "react-router-dom";
import CardTableContent from "../CardTableContent";
import TableContent from "../TableContent";
import BondReportManagement from "../ReportManagement";

const routers = [
  {
    menu_url: "/bondBusinessInformation",
    element: <BusinessInformation> </BusinessInformation>,
    menu_name: "工商信息",
  },
  {
    menu_url: "/bondMajorShareholders",
    element: <MajorShareholders> </MajorShareholders>,
    menu_name: "主要股东",
    multiple: true,
    menu_name_list: ["主要股东(公示)", "主要股东(历史)", "主要股东"],
  },
  {
    menu_url: "/bondOutboundInvestment",
    element: <Investment> </Investment>,
    menu_name: "对外投资",
    multiple: true,
    menu_name_list: ["对外投资(公示)", "对外投资(历史)", "对外投资"],
  },
  {
    menu_url: "/bondEquityRelationship",
    element: <EquityRelationship> </EquityRelationship>,
    menu_name: "股权关系",
  },
  {
    menu_url: "/relatedPartiesOfTheIssuer",
    element: (
      <TableContent defaultTableName="bond_relation" typeName="发行人关联方"></TableContent>
    ),
    menu_name: "发行人关联方",
  },
  {
    menu_url: "/bondInformation",
    element: (
      <TableContent defaultTableName="bond_info" typeName="债券信息"></TableContent>
    ),
    menu_name: "债券信息",
  },
  {
    menu_url: "/bondBusinessChange",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_BUSINESS_CHANGE"
        typeName="工商变更"
      ></CardTableContent>
    ),
    menu_name: "工商变更",
  },
  {
    menu_url: "/bondOwnershipStructure",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_OWNERSHIP_STRUCTURE"
        typeName="股权变更"
      ></CardTableContent>
    ),
    menu_name: "股权变更",
  },
  {
    menu_url: "/bondPersonnelChange",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_PERSONNEL_CHANGES"
        typeName="人员变动"
      ></CardTableContent>
    ),
    menu_name: "人员变动",
  },
  {
    menu_url: "/bondDisclosureInformation",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_DISCLOSURE_INFORMATION"
        typeName="债券异动事件"
      ></CardTableContent>
    ),
    menu_name: "债券异动事件",
  },
  {
    menu_url: "/bondAdvisoryAnnouncement",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_ADVISORY_ANNOUNCEMENT"
        typeName="债券提示性公告"
      ></CardTableContent>
    ),
    menu_name: "债券提示性公告",
  },
  {
    menu_url: "/bondCreditRating",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_CREDIT_RATING"
        typeName="信用评级"
      ></CardTableContent>
    ),
    menu_name: "信用评级",
  },
  {
    menu_url: "/bondInformationGuarantor",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_INFORMATION_GUARANTOR"
        typeName="担保人情况"
      ></CardTableContent>
    ),
    menu_name: "担保人情况",
  },
  {
    menu_url: "/bondProductionOperation",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_PRODUCTION_OPERATION"
        typeName="生产经营"
      ></CardTableContent>
    ),
    menu_name: "生产经营",
  },
  {
    menu_url: "/bondBusinessAbnormalities",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_BUSINESS_ABNORMALITIES"
        typeName="经营异常"
      ></CardTableContent>
    ),
    menu_name: "经营异常",
  },
  {
    menu_url: "/bondRestrictedAssets",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_RESTRICTED_ASSETS"
        typeName="资产受限"
      ></CardTableContent>
    ),
    menu_name: "资产受限",
  },
  {
    menu_url: "/bondFinancialCondition",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_FINANCIAL_CONDITION"
        typeName="财务状况"
      ></CardTableContent>
    ),
    menu_name: "财务状况",
  },
  {
    menu_url: "/bondAssetTurnover",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_ASSET_TURNOVER"
        typeName="资产异动"
      ></CardTableContent>
    ),
    menu_name: "资产异动",
  },
  {
    menu_url: "/bondDebtStructure",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_DEBT_STRUCTURE"
        typeName="负债异动"
      ></CardTableContent>
    ),
    menu_name: "负债异动",
  },
  {
    menu_url: "/bondProfitDistribution",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_PROFIT_DISTRIBUTION"
        typeName="利润分配"
      ></CardTableContent>
    ),
    menu_name: "利润分配",
  },
  {
    menu_url: "/bondCapitalOperation",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_CAPITAL_OPERATION"
        typeName="资本运作"
      ></CardTableContent>
    ),
    menu_name: "资本运作",
  },
  {
    menu_url: "/bondJudCase",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_JUD_CASE"
        typeName="司法案件"
      ></CardTableContent>
    ),
    menu_name: "司法案件",
  },
  {
    menu_url: "/bondRegulatoryPenalty",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_REGULATORY_PENALTY"
        typeName="监管处罚"
      ></CardTableContent>
    ),
    menu_name: "监管处罚",
  },
  {
    menu_url: "/bondAdministrativePenalty",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_ADMINISTRATIVE_PENALTY"
        typeName="行政处罚"
      ></CardTableContent>
    ),
    menu_name: "行政处罚",
  },
  {
    menu_url: "/bondDishonestDebtor",
    element: (
      <CardTableContent
        defaultTableName="CDD_BOND_DISHONEST_DEBTOR"
        typeName="失信被执行人"
      ></CardTableContent>
    ),
    menu_name: "失信被执行人",
  },
  {
    menu_url: "/bondReportManagement",
    element: <BondReportManagement> </BondReportManagement>,
    menu_name:'底稿管理',
    menu_code:"CDD_FUNCTION_ITEM_GENERATION"
  },
  {
    menu_url: "/404",
    element: (
      <div className="notFoundPage">
        <NotFound></NotFound>
      </div>
    ),
  },
  {
    menu_url: "/",
    element: <ProjectInfo> </ProjectInfo>,
  },
  {
    menu_url: "/*",
    element: (
      <div className="notFoundPage">
        <NotFound></NotFound>
      </div>
    ),
  },
];
export default routers;
