import HeaderTitle from "../../../../../components/HeaderTitle";
import "./index.scss";
import {
  Button,
  message,
  InputNumber,
  Checkbox,
  Radio,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import projectApi from "../../../../../apis/project/index";
import moment from "moment";
import { useParams } from "react-router-dom";
import MyRangePicker from "../../../../../components/MyRangePicker";
import wordIcon from "../../../../../assets/W.svg";
import xIcon from "../../../../../assets/x.svg";
import { ReactSVG } from "react-svg";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";
import {
  getAdminsDom,
  getContactBusinessDom,
  verifyAdmin,
} from "../../../../../unit/unit";

const { Option } = Select;

const CreateReport = (props) => {
  const { onClose, getData } = props;

  const { setIsCreate } = props;
  const { id } = useParams();

  const [setting, setSetting] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const myInfo = useSelector((state) => state.User.userInfo);

  useEffect(() => {
    nProgress.done();
    return () => nProgress.start();
  }, []);

  useEffect(() => {
    getReportSettings();
  }, []);

  const getReportSettings = () => {
    setPageLoading(true);
    projectApi.getBondReportSettings(id).then((res) => {
      if (res.code === 200) {
        const data = res.data;
        initSettingData(data || []);
      } else {
        message.error(res.message);
      }
      setPageLoading(false);
    });
  };

  const initSettingData = (data) => {
    let settingList = [];

    data.forEach((_item) => {
      const item = JSON.parse(JSON.stringify(_item));
      item._conditions = [];
      item.checked = false;
      let obj = settingList.find((a) => a.id === item.parent_type);
      item._conditions = [];
      item.conditions = item.conditions?.map((_) => {
        //单选按钮 不需要自己赋默认值.但是要吧默认值对应的选项放到传给后台的数组里
        if (_.type === "radio") {
          // _.value = _.content?.at(0)?.code;
          const __ = JSON.parse(JSON.stringify(_));
          __.content =
            _.content.filter((__item) => __item.code === _.value) || [];
          item._conditions.push(__);
        }
        //多选框 默认值为全部,通知吧对应的选项全部放到传给后台的数组里
        if (_.type === "multi_select") {
          _.content = _.content?.map((__, index) => {
            __.index = index + 1;
            return __;
          });
          if (!_.value) {
            _.value = _.content?.map((__) => __.code);
          }
          const __ = JSON.parse(JSON.stringify(_));
          __.content = __.content.filter((____) =>
            __.value.includes(____.code)
          );
          item._conditions.push(__);
        }
        //单选框 不需要自己赋默认值.但是要吧默认值对应的选项放到传给后台的数组里
        if (_.type === "select") {
          const __ = JSON.parse(JSON.stringify(_));
          __.content =
            _.content.filter((__item) => __item.code === _.value) || [];
          item._conditions.push(__);
        }
        //时间选择框
        if (_.type === "date") {
          const startDate = moment(new Date()).years() - 5 + "-01-01";
          const ednDate = moment(new Date()).format("YYYY-MM-DD");
          _.value = [moment(startDate), moment(ednDate)];
          const __ = JSON.parse(JSON.stringify(_));
          __.content = [{ from: startDate, to: ednDate }];
          item._conditions.push(__);
        }
        //输入框
        if (_.type === "input") {
          _.value = _.min_percent;
          _.content = [{ code: _.min_percent, desc: _.min_percent }];
        }
        return _;
      });
      if (obj) {
        obj.children.push(item);
      } else {
        obj = {
          id: item.parent_type,
          checked: true,
          indeterminate: false,
          children: [item],
        };
        settingList.push(obj);
      }
    });

    setSetting(data);
    setSettingList(settingList);
  };

  const onCheckChildrenItemChange = (checked, _item, childItem) => {
    setSettingList((prev) => {
      prev = prev.map((item) => {
        if (item.id === _item.id) {
          item.children = item.children.map((child) => {
            if (
              child.parent_code + child.code ===
              childItem.parent_code + childItem.code
            ) {
              if (checked) {
                //方案二 单独底稿数量限制
                if (!child.all_report_num) {
                  let arr = child?.report_num || [];

                  let usedNum = arr?.at(0) || 0;
                  let totalNum = arr?.at(1) || 0;
                  let tipsNum = arr?.at(2) || 0;

                  //授权数量不为无限时判断
                  if (totalNum !== -1) {
                    //不支持 底稿类型
                    if (totalNum === 0) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            当前产品授权不支持生成{child?.type}，请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            当前产品授权不支持生成{child?.type}，可联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                      return child;
                    }
                    //数量达到上限
                    if (usedNum >= totalNum) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            {child?.type}生成次数已达上限（已生成{usedNum}份 /
                            授权
                            {totalNum}份），如需继续使用请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            {child?.type}生成次数已达上限（已生成{usedNum}份 /
                            授权
                            {totalNum}份），如需继续使用请联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                      return child;
                    }
                    //数量达到 提示条件
                    if (totalNum - usedNum <= tipsNum) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            {child?.type}生成次数即将达到上限（已生成{usedNum}份
                            / 授权
                            {totalNum}份），请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            {child?.type}生成次数即将达到上限（已生成{usedNum}份
                            / 授权
                            {totalNum}份），可联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                    }
                  }
                } else {
                  //方案一 底稿 总体数量限制
                  let arr = child?.all_report_num || [];

                  let usedNum = arr?.at(0) || 0;
                  let totalNum = arr?.at(1) || 0;
                  let tipsNum = arr?.at(2) || 0;

                  let checkedNum = (
                    item.children?.filter((__) => __.checked) || []
                  )?.length;

                  //授权数量不为无限时判断
                  if (totalNum !== -1) {
                    //不支持 底稿类型
                    if (totalNum === 0) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            当前产品授权不支持生成{child?.type}，请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            当前产品授权不支持生成{child?.type}，可联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                      return child;
                    }
                    //数量达到上限
                    if (usedNum >= totalNum) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            底稿生成次数已达上限（已生成{usedNum}份 / 授权
                            {totalNum}份），如需继续使用请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            底稿生成次数已达上限（已生成{usedNum}份 / 授权
                            {totalNum}份），如需继续使用请联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                      return child;
                    }
                    //数量达到 提示条件
                    if (totalNum - usedNum - checkedNum <= tipsNum) {
                      message.warning({
                        content: verifyAdmin(myInfo) ? (
                          <span>
                            底稿生成次数即将达到上限（已生成{usedNum}份 / 授权
                            {totalNum}份），请
                            {getContactBusinessDom()}
                            升级订阅方案
                          </span>
                        ) : (
                          <span>
                            底稿生成次数即将达到上限（已生成{usedNum}份 / 授权
                            {totalNum}份），可联系
                            {getAdminsDom()}
                            升级订阅方案
                          </span>
                        ),
                      });
                    }
                    //使用数量和 当前选中数量 大于总和
                    if (checked & (checkedNum + usedNum >= totalNum)) {
                      return child;
                    }
                  }
                }
              }
              child.checked = checked;
            }
            return child;
          });
        }
        return item;
      });

      return [...prev];
    });
  };

  //输入框修改
  const inputChange = (value, _item, childItem, conditionItem) => {
    changeSettingCondition(
      [{ code: value, desc: value }],
      _item,
      childItem,
      conditionItem
    );
  };

  //单选框修改
  const radioChange = (value, _item, childItem, conditionItem) => {
    const _ = conditionItem?.content?.find((item) => item.code === value);
    changeSettingCondition([_], _item, childItem, conditionItem);
  };

  //选择框修改
  const selectChange = (value, _item, childItem, conditionItem) => {
    value = value.map((code) =>
      conditionItem?.content?.find((_) => _.code === code)
    );
    value = value.sort((a, b) => a.index - b.index);
    changeSettingCondition(value, _item, childItem, conditionItem);
  };

  const changeSettingCondition = (value, _item, childItem, conditionItem) => {
    setSettingList((prev) => {
      prev = prev.map((item) => {
        if (item.id === _item.id) {
          item.children = item.children.map((child) => {
            if (
              child.parent_code + child.code ===
              childItem.parent_code + childItem.code
            ) {
              const _conditions = child._conditions || [];
              let index = _conditions.findIndex(
                (b) =>
                  b.title + b.type === conditionItem.title + conditionItem.type
              );
              if (index === -1) {
                child._conditions.push({ ...conditionItem, content: value });
              } else {
                child._conditions[index] = { ...conditionItem, content: value };
              }

              child.conditions = child.conditions.map((_) => {
                if (
                  _.title + _.type ===
                  conditionItem.title + conditionItem.type
                ) {
                  if (_.type === "radio") {
                    _.value = value?.at(0)?.code;
                  }
                  if (_.type === "multi_select") {
                    _.value = value?.map((__) => __.code);
                  }
                  if (_.type === "select") {
                    _.value = value?.at(0)?.code;
                  }
                  if (_.type === "date") {
                    const dateArr = [null, null];
                    if (value?.at(0)?.from) {
                      dateArr[0] = moment(value?.at(0)?.from);
                    }
                    if (value.at(0)?.to) {
                      dateArr[1] = moment(value?.at(0)?.to);
                    }
                    _.value = dateArr;
                  }
                  if (_.type === "input") {
                    _.value = value?.at(0)?.code;
                  }
                }
                return _;
              });
            }
            return child;
          });
        }
        return item;
      });

      return [...prev];
    });
  };

  const dateChange = (value, _item, childItem, conditionItem) => {
    // setStartDate(dateStrings[0]);
    // setEndDate(dateStrings[1]);
    changeSettingCondition(value, _item, childItem, conditionItem);
  };

  const onFinish = () => {
    // if (!startDate || !endDate) {
    //   message.error("请选择生成时间!");
    //   return;
    // }
    let arr = [];
    settingList.forEach((item) => {
      item.children?.forEach((_item) => {
        if (_item.checked) {
          arr.push(JSON.parse(JSON.stringify(_item)));
        }
      });
    });
    if (arr.length <= 0) {
      message.error("请至少选择一份底稿进行生成");
      return;
    }
    setLoading(true);
    // for (let i = 0; i < arr.length; i++) {
    //   let item = arr[i];
    //   if (
    //     item.conditions &&
    //     item.conditions?.length !== item._conditions.length
    //   ) {
    //     message.error("请填写完整信息后再点击生成底稿!");
    //     return;
    //   }
    // }
    arr = arr.map((_) => {
      _.conditions = _?.conditions?.map((__) => {
        const ___ = _._conditions.find(
          (____) => ____.title + ____.type === __.title + __.type
        );
        if (___) {
          ___.content = ___.content?.map((a) => {
            delete a.index;
            return a;
          });
          return ___;
        }
        __.content = __.content?.map((a) => {
          delete a.index;
          return a;
        });
        return __;
      });
      return _;
    });

    let params = {
      detail: arr,
    };
    projectApi
      .creatBondGeneration({
        project_id: 0,
        company_id: id,
        condition_json: JSON.stringify(params),
      })
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          onClose && onClose();
          getData && getData();
        } else {
          setLoading(false);
          message.error(res.message);
        }
      });
  };

  return (
    <div className="bondCreateReportBox">
      <HeaderTitle title="生成报告"></HeaderTitle>
      {pageLoading ? (
        <div className="createReportContent">
          <MySpin></MySpin>
        </div>
      ) : (
        <div className="createReportContent">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{ height: "calc(100% - 60px)", width: "100%" }}
          >
            <div className="createReportList">
              {settingList.map((item) => (
                <div className="settingItem" key={item.id}>
                  <div className="settingTitle">{item.id}</div>
                  {item.children?.map((childItem) => {
                    return (
                      <div className="settingChildrenItem" key={childItem.code}>
                        <div className="checkBox">
                          {!childItem.enable || childItem.disabled ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div style={{ fontSize: "12px" }}>
                                  {verifyAdmin(myInfo) ? (
                                    <span>
                                      当前产品授权不支持该报告，请联系商务升级订阅方案
                                    </span>
                                  ) : (
                                    <span>
                                      当前产品授权不支持该报告，可联系管理员升级订阅方案
                                    </span>
                                  )}
                                </div>
                              }
                              overlayStyle={{ width: "300px" }}
                            >
                              <Checkbox
                                checked={childItem.checked}
                                disabled={
                                  !childItem.enable || childItem.disabled
                                }
                                onChange={(e) => {
                                  onCheckChildrenItemChange(
                                    e.target.checked,
                                    item,
                                    childItem
                                  );
                                }}
                                className="typeCheckBox"
                              >
                                <div className="typeCheckContent">
                                  <ReactSVG
                                    src={
                                      childItem?.file_type === "excel"
                                        ? xIcon
                                        : wordIcon
                                    }
                                    style={{ marginRight: "8px" }}
                                  ></ReactSVG>
                                  {childItem.type}
                                </div>
                              </Checkbox>
                            </Tooltip>
                          ) : (
                            <Checkbox
                              checked={childItem.checked}
                              disabled={!childItem.enable || childItem.disabled}
                              onChange={(e) => {
                                onCheckChildrenItemChange(
                                  e.target.checked,
                                  item,
                                  childItem
                                );
                              }}
                              className="typeCheckBox"
                            >
                              <div className="typeCheckContent">
                                <ReactSVG
                                  src={
                                    childItem?.file_type === "excel"
                                      ? xIcon
                                      : wordIcon
                                  }
                                  style={{ marginRight: "8px" }}
                                ></ReactSVG>
                                {childItem.type}
                              </div>
                            </Checkbox>
                          )}
                        </div>

                        {childItem.checked ? (
                          <div className="settingOptionBoxContent">
                            {childItem.conditions?.map((conditionOptions) => (
                              <div
                                className="settingOptionBox"
                                key={conditionOptions.title}
                              >
                                <div className="label">
                                  {conditionOptions.title}
                                </div>

                                <div className="optionBox">
                                  {/* 输入框 */}
                                  {conditionOptions.type === "input" ? (
                                    <InputNumber
                                      disabled={
                                        !childItem.checked || !childItem.enable
                                      }
                                      style={{
                                        width: "250px",
                                      }}
                                      min={conditionOptions.min_percent || 0}
                                      max={100}
                                      placeholder={`请输入不小于${
                                        conditionOptions.min_percent || 0
                                      }不大于100的数字`}
                                      className="myInput"
                                      type="number"
                                      onChange={(value) => {
                                        inputChange(
                                          value,
                                          item,
                                          childItem,
                                          conditionOptions
                                        );
                                      }}
                                      value={conditionOptions?.value}
                                    />
                                  ) : null}

                                  {/* 单选 */}
                                  {conditionOptions.type === "radio" ? (
                                    <Radio.Group
                                      disabled={
                                        !childItem.checked || !childItem.enable
                                      }
                                      onChange={(e) => {
                                        radioChange(
                                          e.target.value,
                                          item,
                                          childItem,
                                          conditionOptions
                                        );
                                      }}
                                      value={conditionOptions?.value}
                                    >
                                      {conditionOptions.content?.map(
                                        (radioOption) =>
                                          radioOption.desc === "是" &&
                                          conditionOptions?.title ===
                                            "是否需要附带图片" ? (
                                            <Tooltip
                                              key={radioOption.code}
                                              placement="topLeft"
                                              title={
                                                "附带图片报告生成时间较长,请耐心等待"
                                              }
                                              getPopupContainer={(node) =>
                                                node.parentNode
                                              }
                                              open={
                                                conditionOptions?.value ===
                                                radioOption.code
                                              }
                                              arrowPointAtCenter
                                              color="#FE545F"
                                              overlayStyle={{ height: "30px" }}
                                              overlayClassName="sharesTip"
                                            >
                                              <Radio
                                                value={radioOption.code}
                                                key={radioOption.code}
                                                className="myRadio"
                                              >
                                                {radioOption.desc}
                                              </Radio>
                                            </Tooltip>
                                          ) : (
                                            <Radio
                                              value={radioOption.code}
                                              key={radioOption.code}
                                              className="myRadio"
                                            >
                                              {radioOption.desc}
                                            </Radio>
                                          )
                                      )}
                                    </Radio.Group>
                                  ) : null}

                                  {/* 选择框 */}
                                  {conditionOptions.type === "select" ? (
                                    <Select
                                      disabled={
                                        !childItem.checked || !childItem.enable
                                      }
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode || document.body
                                      }
                                      style={{
                                        width: "200px",
                                      }}
                                      value={conditionOptions?.value}
                                      onChange={(value) => {
                                        selectChange(
                                          [value],
                                          item,
                                          childItem,
                                          conditionOptions
                                        );
                                      }}
                                    >
                                      {conditionOptions.content?.map(
                                        (radioOption) => (
                                          <Option
                                            value={radioOption.code}
                                            key={radioOption.code}
                                          >
                                            {radioOption.desc}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  ) : null}

                                  {/* 多选框 */}
                                  {conditionOptions.type === "multi_select" ? (
                                    <Select
                                      disabled={
                                        !childItem.checked || !childItem.enable
                                      }
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode || document.body
                                      }
                                      style={{
                                        width: "200px",
                                      }}
                                      mode="multiple"
                                      showArrow
                                      value={conditionOptions.value}
                                      onChange={(value) => {
                                        selectChange(
                                          value,
                                          item,
                                          childItem,
                                          conditionOptions
                                        );
                                      }}
                                      maxTagCount="responsive"
                                      filterOption={(inputValue, option) =>
                                        option.children.includes(inputValue)
                                      }
                                      dropdownRender={(menu) => (
                                        <div className="reportSubjectScopeSearchPoP">
                                          <Checkbox
                                            style={{
                                              margin: "5px",
                                              marginLeft: "12px",
                                            }}
                                            indeterminate={
                                              conditionOptions?.value &&
                                              conditionOptions?.value?.length >
                                                0 &&
                                              conditionOptions?.content
                                                ?.length !==
                                                conditionOptions?.value?.length
                                            }
                                            checked={
                                              conditionOptions?.value &&
                                              conditionOptions?.content
                                                ?.length ===
                                                conditionOptions?.value?.length
                                            }
                                            onChange={(e) => {
                                              selectChange(
                                                e.target.checked
                                                  ? conditionOptions?.content?.map(
                                                      (_) => _.code
                                                    )
                                                  : [],
                                                item,
                                                childItem,
                                                conditionOptions
                                              );
                                            }}
                                          >
                                            全选
                                          </Checkbox>
                                          {menu}
                                        </div>
                                      )}
                                    >
                                      {conditionOptions.content?.map(
                                        (radioOption) => (
                                          <Option
                                            value={radioOption.code}
                                            key={radioOption.code}
                                          >
                                            {radioOption.desc}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  ) : null}

                                  {/* 时间框 */}
                                  {conditionOptions.type === "date" ? (
                                    <MyRangePicker
                                      style={{
                                        maxWidth: "240px",
                                        minWidth: "240px",
                                      }}
                                      disabled={
                                        !childItem.checked || !childItem.enable
                                      }
                                      format="YYYY-MM-DD"
                                      value={conditionOptions.value}
                                      onChange={(dates, dateStrings) => {
                                        dateChange(
                                          [
                                            {
                                              from: dateStrings[0],
                                              to: dateStrings[1],
                                            },
                                          ],
                                          item,
                                          childItem,
                                          conditionOptions
                                        );
                                      }}
                                      prohibitFuture={true}
                                    ></MyRangePicker>
                                  ) : // <RangePicker
                                  //   style={{
                                  //     width: "300px",
                                  //   }}
                                  //   disabled={!childItem.checked || !childItem.enable}
                                  //   // locale={locale}
                                  //   showTime
                                  //   format="YYYY-MM-DD"
                                  //   value={conditionOptions.value}
                                  //   onChange={(dates, dateStrings) => {
                                  //     dateChange(
                                  //       [
                                  //         {
                                  //           from: dateStrings[0],
                                  //           to: dateStrings[1],
                                  //         },
                                  //       ],
                                  //       item,
                                  //       childItem,
                                  //       conditionOptions
                                  //     );
                                  //   }}
                                  //   disabledDate={(currentDate) =>
                                  //     moment(currentDate).diff(moment(new Date())) > 0
                                  //   }
                                  // ></RangePicker>
                                  null}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </Scrollbars>

          <div className="createReportBtnBox">
            <Button
              style={{ marginRight: "24px" }}
              onClick={() => {
                onClose && onClose();
                getData && getData();
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={() => onFinish()} loading={loading}>
              生成报告
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default CreateReport;
