import "./index.scss";
import wrapper from "./wrapper";
import React, { useEffect, useState, useRef } from "react";
import { Drawer, Button, message, Tooltip } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import {
  CloseOutlined,
  ShareAltOutlined,
  CloseCircleOutlined,
  SendOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import MySpin from "../../../../../components/MySpin";
import CreatCollenctionModal from "../CreatCollenctionModal";
import CreatCollenctionDrawer from "../CreatCollenctionDrawer";
import HeadPortrait from "../../../../../components/HeadPortrait";
import projectApi from "../../../../../apis/project";
import MessageModal from "../../../../../components/MessageModal";
import { useSelector } from "react-redux";
import MyEmpty from "../../../../../components/Empty";
import { downloadBase64Img, getLicenseValid } from "../../../../../unit/unit";
import { ReactSVG } from "react-svg";
import svg1 from "../../../../../assets/复制链接.svg";
import svg2 from "../../../../../assets/下载二维码.svg";
import svg3 from "../../../../../assets/再次发送.svg";
import svg4 from "../../../../../assets/关闭.svg";

const CollenctionInfoCard = (props) => {
  const { info, projectId, UpDataFunc, myInfo, projectInfo, sendAgainFunc } =
    props;
  const [latestProcess, setLatestProcess] = useState({});
  const [open, setOpen] = useState(false);

  const colorRef = useRef({
    待授权: "#6CAEFF",
    采集中: "#FEC054",
    采集完成: "#AAD461",
    采集失败: "#FE545F",
  });

  const initSpan = () => {
    const statusInfo = info?.process_list?.at(-1);
    let colorList = [];
    if (statusInfo?.process_status === 10) {
      colorList = ["#61D4AD", "#0068B2", "#DDDDDD"];
    } else if (statusInfo?.process_status === 20) {
      colorList = ["#61D4AD", "#61D4AD", "#0068B2"];
    } else if (statusInfo?.process_status === 30) {
      colorList = ["#61D4AD", "#61D4AD", "#61D4AD"];
    } else if (statusInfo?.process_status === 40) {
      colorList = ["#61D4AD", "#61D4AD", "#FE545F"];
    }
    return colorList?.map((color, index) => (
      <span style={{ background: color }} key={Math.random()}></span>
    ));
  };

  const initLine = () => {
    const statusInfo = info?.process_list?.at(-1);
    let colorList = [];
    if (statusInfo?.process_status === 10) {
      colorList = ["#DDDDDD", "#DDDDDD"];
    } else if (statusInfo?.process_status === 20) {
      colorList = ["#61D4AD", "#DDDDDD"];
    } else if (statusInfo?.process_status === 30) {
      colorList = ["#61D4AD", "#61D4AD"];
    } else if (statusInfo?.process_status === 40) {
      colorList = ["#61D4AD", "#FE545F"];
    }
    return colorList?.map((color) => (
      <div
        className="line"
        style={{ background: color }}
        key={Math.random()}
      ></div>
    ));
  };

  useEffect(() => {
    const statusInfo = info?.process_list?.at(-1) || {};
    setLatestProcess(statusInfo);
  }, [info]);

  const deleteFunc = () => {
    MessageModal({
      type: "warning",
      description: "是否已经和授权人沟通取消授权操作？",
      message: "请确保已与授权人沟通不再执行授权操作",
      title: "取消数据采集",
      cancelText: "未沟通",
      okText: "已沟通",
      onOk: () => {
        projectApi
          .deleteAuthorizationProcess(projectId, info.id)
          .then((res) => {
            if (res.code === 200) {
              UpDataFunc && UpDataFunc();
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };

  const sendAgain = () => {
    sendAgainFunc && sendAgainFunc(info);
  };

  const initTipDom = () => {
    const item1 =
      info?.process_list?.filter((_) => _.process_status === 10)?.at(-1) ||
      null;

    const item2 =
      info?.process_list?.filter((_) => _.process_status === 20)?.at(-1) ||
      null;

    const item3 =
      info?.process_list?.filter((_) => _.process_status === 30)?.at(-1) ||
      null;

    const item4 =
      info?.process_list?.filter((_) => _.process_status === 40)?.at(-1) ||
      null;

    return (
      <div className="scheduleTipContent">
        <div className="scheduleTipItem">
          <div>法人姓名</div>
          <div>{info?.legal_person_name}</div>
        </div>
        <div className="scheduleTipItem">
          <div>法人身份证号</div>
          <div>{info?.legal_person_identity}</div>
        </div>
        {info?.auth_name && (
          <div className="scheduleTipItem">
            <div>授权人</div>
            <div>{info?.auth_name}</div>
          </div>
        )}
        {info?.phone && (
          <div className="scheduleTipItem">
            <div>授权人联系电话</div>
            <div>{info?.phone}</div>
          </div>
        )}

        <div className="scheduleTipItem" style={{ marginTop: "12px" }}>
          <div>发起授权</div>
          <div>{item1?.finish_time + `（完成）`}</div>
        </div>

        <div className="scheduleTipItem">
          <div>被尽调公司授权</div>
          <div>{item2 ? item2?.finish_time + `（完成）` : "（未完成）"}</div>
        </div>

        <div className="scheduleTipItem">
          <div>采集数据完成</div>

          {item4 ? (
            <div>
              {item4?.finish_time}
              <span>（ 采集失败）</span>
            </div>
          ) : (
            <div>
              {item3
                ? item3?.finish_time + `（完成）`
                : "（未完成，预计1-5天）"}
            </div>
          )}
        </div>

        {item4 && (
          <div className="scheduleErrorTipItem">
            提示：{item4?.process_name}，如需继续采集请重新发起数据采集
          </div>
        )}
      </div>
    );
  };

  const initOverTimeText = () => {
    const items = info?.process_list?.filter((_) => _.message) || [];
    const item = items?.at(-1) || null;
    if (item) {
      return <div className="overtimeBox">{item.message}</div>;
    }
    return null;
  };

  const copyFunc = (type) => {
    if (type === "复制") {
      navigator.clipboard.writeText(info.link_url);
      message.success("复制成功");
    } else {
      downloadBase64Img(
        info.qr_code_binary,
        `${info.corp_name}_财税数据采集二维码.png`
      );
    }
  };

  return (
    <div className="collenctionInfoCard">
      <div className="topBox">
        <h3>{info?.corp_name}</h3>
        <div className="textBox">
          {info?.create_time}
          <HeadPortrait
            key={info?.pf_photo}
            size={20}
            title={
              info.show_name + (info.assist_name ? "/" + info.assist_name : "")
            }
            src={info?.pf_photo}
            style={{ marginRight: "4px", marginLeft: "10px" }}
          ></HeadPortrait>
          {info?.name}
        </div>
      </div>
      <div className="bottomBox">
        <div className="scheduleBox">
          <div
            style={{
              background:
                colorRef?.current[latestProcess?.process_name] || "#0068B2",
            }}
            className="tag"
          >
            {latestProcess?.process_name}
          </div>

          <Tooltip title={initTipDom()} overlayClassName="scheduleContentTip">
            <div className="scheduleContent">
              {initSpan()}
              {initLine()}
            </div>
          </Tooltip>

          {initOverTimeText()}
        </div>

        <div className="iconBox">
          {latestProcess?.process_status === 10 && (
            <Tooltip
              title={<div style={{ fontSize: "12px" }}>复制授权链接</div>}
            >
              <ReactSVG
                src={svg1}
                className="icon"
                onClick={() => copyFunc("复制")}
              ></ReactSVG>
            </Tooltip>
          )}
          {latestProcess?.process_status === 10 && (
            <Tooltip
              title={<div style={{ fontSize: "12px" }}>下载授权二维码</div>}
            >
              <ReactSVG
                src={svg2}
                className="icon"
                onClick={() => copyFunc("二维码")}
              ></ReactSVG>
            </Tooltip>
          )}

          {(info?.creator_id === myInfo?.co_user_id ||
            projectInfo?.project_permission_urls?.includes(
              "CDD_PRJ_CDD_ITEM_EDIT"
            )) &&
            latestProcess?.process_status === 10 && (
              <Tooltip
                title={<div style={{ fontSize: "12px" }}>再次发送</div>}
                open={open}
                onOpenChange={(open) => {
                  setOpen(open);
                }}
              >
                <ReactSVG
                  src={svg3}
                  className="icon"
                  onClick={() => {
                    getLicenseValid().then((res) => {
                      if (res) {
                        projectApi
                          .getLicenseSupport("ADV_TAX_ANALYSIS")
                          .then((res2) => {
                            if (res2.code === 200 && res2.data) {
                              setTimeout(() => {
                                setOpen(false);
                              }, 200);
                              sendAgain();
                            }
                          });
                      }
                    });
                  }}
                ></ReactSVG>
              </Tooltip>
            )}
          {(info?.creator_id === myInfo?.co_user_id ||
            projectInfo?.project_permission_urls?.includes(
              "CDD_PRJ_CDD_ITEM_EDIT"
            )) &&
            (latestProcess?.process_status === 10 ||
              latestProcess?.process_status === 20) && (
              <Tooltip
                title={<div style={{ fontSize: "12px" }}>取消数据采集</div>}
                placement="topRight"
              >
                <ReactSVG
                  src={svg4}
                  className="icon"
                  onClick={() => deleteFunc()}
                ></ReactSVG>
              </Tooltip>
            )}
        </div>
      </div>
    </div>
  );
};

const CollenctionInfoDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const [creatVisible, setCreatVisible] = useState(false);
  const [data, setData] = useState([]);
  const [defaultCreatInfo, setDefaultCreatInfo] = useState(null);
  const {
    /* 关闭触发的回调 */
    onCancel,
    closeDialog,
    projectId,
    myInfo,
    projectInfo,
  } = props;

  const [loading, setLoading] = useState(true);

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const getInfo = () => {
    setLoading(true);
    const params = {
      page: 1,
      page_size: 10000,
    };
    projectApi.getAuthorizationProcess(projectId, params).then((res) => {
      console.log("采集数据:----", res);
      if (res.code === 200) {
        setData(res.data.data || []);
      } else {
        message.error(res.message);
        setData([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setVisible(true);
    getInfo();
  }, []);

  const handleCreatFunc = () => {
    getLicenseValid().then((res) => {
      if (res) {
        projectApi.getLicenseSupport("ADV_TAX_ANALYSIS").then((res2) => {
          if (res2.code === 200 && res2.data) {
            //关闭
            if(projectInfo?.status === 'CDD_PRJ_STATUS_CLS'){
              message.warning('项目已关闭，该功能暂时无法使用，请重新启动项目后进行采集')
              return
            }
            CreatCollenctionModal({
              onOk: () => {
                setDefaultCreatInfo(null);
                setTimeout(() => {
                  setCreatVisible(true);
                }, 100);
              },
            });
            return;
          }
          message.error(res2.msg);
        });
      }
    });
  };

  const successFunc = () => {
    setCreatVisible(false);
    getInfo();
  };

  const sendAgainFunc = (info) => {
    setDefaultCreatInfo(info);
    setTimeout(() => {
      setCreatVisible(true);
    }, 100);
  };
  return (
    <Drawer
      title="财税数据采集"
      placement="right"
      width="600px"
      className="collenctionInfoDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              handleCancel();
            }}
            type="primary"
          >
            关闭
          </Button>
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <>
          <div className="btnBox">
            <Button
              type="primary"
              icon={<ShareAltOutlined />}
              onClick={handleCreatFunc}
            >
              发起数据采集
            </Button>
          </div>
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{
              height: `calc(100% - 60px)`,
              width: "100%",
            }}
          >
            {data?.length === 0 ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MyEmpty description="暂无数据"></MyEmpty>
              </div>
            ) : (
              <div className="creatCollenctionDrawerContent">
                {data?.map((item, index) => (
                  <CollenctionInfoCard
                    info={item}
                    key={index}
                    projectId={projectId}
                    UpDataFunc={getInfo}
                    myInfo={myInfo}
                    projectInfo={projectInfo}
                    sendAgainFunc={sendAgainFunc}
                  ></CollenctionInfoCard>
                ))}
              </div>
            )}
          </Scrollbars>
          <CreatCollenctionDrawer
            projectId={projectId}
            visible={creatVisible}
            onCancel={() => {
              setCreatVisible(false);
            }}
            userInfo={myInfo}
            defaultCreatInfo={defaultCreatInfo}
            onSuccess={successFunc}
          ></CreatCollenctionDrawer>
        </>
      )}
    </Drawer>
  );
};
export default wrapper(CollenctionInfoDrawer);
