import "./index.scss";
import { Button, Result } from "antd";
import React from "react";
import { useEffect } from "react";
import projectApi from "../../apis/project/index";
import { useState } from "react";
import { useGetState } from "ahooks";
import { useNavigate } from "react-router-dom";

const Invitation = (props) => {
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [time, setTime, getTime] = useGetState(5);
  const [id, setId, getId] = useGetState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const data = window.location.search.split("=")?.at(1);
    projectApi.addInvite(data).then((res) => {
      setSuccess(res.code === 200);
      setMsg(res.message);
      setId(res.data?.project_id);
      const timer = setInterval(() => {
        const time = getTime() - 1;
        setTime(time);
        console.log("定时器触发", time);
        if (time === 0) {
          clearInterval(timer);
          console.log("定时器删除");
          if (
            res.code === 200 ||
            res.message === "您已经在此项目中，无需邀请"
          ) {
            navigate(`/company/${res.data?.project_id}`);
          } else {
            navigate("/home");
          }
        }
      }, 1000);
    });
  }, []);

  return (
    <div className="invitationContent">
      <Result
        status={success ? "success" : "info"}
        title={msg}
        extra={
          <div>
            将在 <strong>{time}</strong>秒 后
            {success || msg === "您已经在此项目中，无需邀请"
              ? "进入项目"
              : "返回首页"}
          </div>
        }
      />
    </div>
  );
};

export default Invitation;
