import "./index.scss";
import {
  VerticalAlignBottomOutlined,
  CloseOutlined,
  RedoOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { message, Tooltip, Progress } from "antd";
import wordIcon from "../../../../../assets/W.svg";
import xIcon from "../../../../../assets/x.svg";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../apis/project/index";
import { useRef, useState } from "react";
import { useHover, useClickAway } from "ahooks";

const TooltipInfo = (props) => {
  return (
    <div className="reportIconContent">
      {props.info?.conditions?.map((item, index) => {
        if (!item.content) {
          return <div key={item.title + index}></div>;
        }
        return (
          <div className="itemBox" key={item.title + index}>
            <div className="keyBox">{item.title}</div>
            <div className="valueBox">
              {/* <Paragraph
                ellipsis={{
                  tooltip: (
                    <div className="editParagraphTip">
                      {item.type === "date"
                        ? item.content?.at(0)?.from +
                          " ~ " +
                          item.content?.at(0)?.to
                        : item.content.map((a) => a.desc).join("；")}
                    </div>
                  ),
                  rows: 3,
                }}
                style={{
                  width: `100%`,
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              > */}
              {item.type === "date"
                ? item.content?.at(0)?.from + " ~ " + item.content?.at(0)?.to
                : item.content.map((a) => a.desc).join("；")}
              {/* + (item.type === "input" ? "%" : "") */}
              {/* </Paragraph> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
const ReportCard = (props) => {
  const { reportInfo, downloadItem, item, deleteItem, getData, processInfo } =
    props;

  const { id } = useParams();

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const userInfo = useSelector((state) => state.User.userInfo) || {};

  const ref = useRef(null);
  // const isHovering = useHover(ref);
  const [show, setShow] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [tipTitle, setTipTitle] =
    useState("附带图片报告生成时间较长,请耐心等待");

  useClickAway(() => {
    setShow(false);
  }, ref);

  const initFileSize = (size) => {
    let num = Number(size) / 1024;
    if (num / 1024 / 1024 > 1) {
      return (num / 1024 / 1024).toFixed(2) + "GB";
    } else if (num / 1024 > 0.01) {
      return (num / 1024).toFixed(2) + "MB";
    } else {
      return num + "KB";
    }
  };

  //重试
  const retry = (item, reportItem) => {
    let params = {
      project_id: id,
      generation_id: item.generation_id,
      report_id: reportItem.id,
    };
    projectApi.retryGeneration(params).then((res) => {
      if (res.code === 200) {
        getData();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <Tooltip
      title={<TooltipInfo info={reportInfo}></TooltipInfo>}
      arrowPointAtCenter
      overlayClassName="ReportInfoTooltip"
      open={show}
      // getPopupContainer={(node) => node.parentNode}
      overlayStyle={{
        display: reportInfo?.conditions?.length > 0 ? "block" : "none",
      }}
    >
      <Tooltip
        placement="topLeft"
        title={tipTitle}
        getPopupContainer={(node) => node.parentNode}
        open={showTip}
        arrowPointAtCenter
        color="#FE545F"
        overlayStyle={{ height: "30px" }}
        onOpenChange={(open) => {
          if (open) {
            if (reportInfo.status === 0) {
              setTipTitle("生成失败，请重新生成");
              setShowTip(true);
            } else if (reportInfo.pic === 1 && reportInfo.status === 1) {
              setTipTitle("附带图片报告生成时间较长,请耐心等待");
              setShowTip(true);
            }
          } else {
            setShowTip(false);
          }
        }}
        overlayClassName="sharesTip"
      >
        <div className="reportCard">
          <div className="reportIcon">
            <ReactSVG
              src={reportInfo?.file_type === "excel" ? xIcon : wordIcon}
            ></ReactSVG>
          </div>
          <div
            className="reportType"
            style={{ color: reportInfo.status === 0 ? "#FE545F" : "#313131" }}
          >
            {reportInfo.type}
          </div>
          {reportInfo.status === 2 ? (
            <div className="reportSize">{initFileSize(reportInfo.size)}</div>
          ) : reportInfo.status === 1 ? (
            <div className="progressBox">
              <Progress
                size="small"
                percent={processInfo[reportInfo.manu_id]}
                showInfo={false}
                status="active"
              />
            </div>
          ) : null}

          <div className="bgBox">
            {reportInfo.status === 2 &&
              projectInfo?.project_permission_urls.includes(
                "CDD_PRJ_REPORT_DOWNLOAD"
              ) && (
                <Tooltip title="下载" placement="top" arrowPointAtCenter>
                  <div
                    className="bgBtnBox"
                    onClick={() => {
                      if (reportInfo.status === 1) {
                        return;
                      }
                      downloadItem(item, reportInfo);
                    }}
                  >
                    <VerticalAlignBottomOutlined />
                  </div>
                </Tooltip>
              )}
            {reportInfo.status === 0 &&
              projectInfo?.project_permission_urls.includes(
                "CDD_PRJ_REPORT_GENERATE"
              ) && (
                <Tooltip title="重新生成" placement="top" arrowPointAtCenter>
                  <div
                    className="bgBtnBox"
                    onClick={() => {
                      retry(item, reportInfo);
                    }}
                  >
                    <RedoOutlined style={{ transform: "rotate(240deg)" }} />
                  </div>
                </Tooltip>
              )}

            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_REPORT_GENERATE"
            ) &&
              (projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CLOSE"
              ) ||
                item.creator_id === userInfo.co_user_id) && (
                <Tooltip title="删除" placement="top" arrowPointAtCenter>
                  <div
                    className="bgBtnBox"
                    onClick={() => {
                      deleteItem(item, reportInfo);
                    }}
                  >
                    <CloseOutlined />
                  </div>
                </Tooltip>
              )}
            {reportInfo?.conditions?.length > 0 && (
              <Tooltip title="详情" placement="top" arrowPointAtCenter>
                <div
                  className="bgBtnBox"
                  ref={ref}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <InfoOutlined />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </Tooltip>
    </Tooltip>
  );
};
export default ReportCard;
