import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './user/user'
import Project from './project/project'
import BondProject from './bond/project';
let all = combineReducers({
  User: User.reducer,
  Project: Project.reducer,
  BondProject: BondProject.reducer
});
export default configureStore({
  reducer: all,
});
