import "./index.scss";
import { useParams } from "react-router-dom";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { Popover, Tooltip, message, Image, Avatar } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useGetState, useUnmount, useLocalStorageState } from "ahooks";
import MySpin from "../../../components/MySpin";
import MyEmpty from "../../../components/Empty";
import projectApi from "../../../apis/project";
import RecordCard from "./components/RecordCard";
import icon2 from "../../../assets/02.svg";
import icon1 from "../../../assets/01.svg";
import startIcon from "../../../assets/开始核查.svg";
import Slider from "react-slick";
import moment from "moment/moment";
import useUrlState from "@ahooksjs/use-url-state";
import RecordDetails from "./components/RecordDetails";
import CreateContent from "./components/CreateContent";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import {
  getAdminsDom,
  getContactBusinessDom,
  getLicenseValid,
  verifyAdmin,
} from "../../../unit/unit";

const RelatedPartyManagement = (props) => {
  const { id } = useParams();
  const typeName = "疑似关联方分析";
  const bgColor = ["#829DFF", "#C88EED", "#FF9E82", "#00A5AF"];

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const carouselRef = useRef(null);

  const [data, setData, getData] = useGetState([]);
  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(true);
  const pageLoading = useRef(false);

  const [notHasPrev, setNotHasPrev] = useState(true);
  const [notHasNext, setNotHasNext] = useState(false);
  const [reportTypeList, setReportTypeList] = useState([]);

  const [state, setState] = useUrlState({});

  const [isCreate, setIsCreate] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [defaultRules, setDefaultRules] = useState(null);

  const [defaultType, setDefaultType] = useLocalStorageState(
    "suspectedRelatedPartyDefaultType",
    {
      defaultValue: "核查对象",
    }
  );

  const myInfo = useSelector((state) => state.User.userInfo);
  const admins = useSelector((state) => state.User.admins);

  const timer = useRef();

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  useEffect(() => {
    if (isCreate) {
      nProgress.start();
    }
    nProgress.done();
  }, [isCreate]);

  const getReportTypes = () => {
    projectApi.getSuspectPic().then((res) => {
      if (res.code === 200) {
        setReportTypeList(res.data || []);
      } else {
        message.error(res.message);
      }
    });
  };

  const getDataFunc = () => {
    setLoading(true);
    setDataTimeout();
  };

  const setDataTimeout = () => {
    projectApi.getCheckList(id).then((res) => {
      console.log("---------核查列表:", res);
      if (res.code === 200) {
        const list = res.data.map((item, index) => {
          item.id = item.suspect_id;
          return item;
        });
        setData(list);
      } else {
        setData([]);
        message.error(res.message);
      }
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDataTimeout();
      }, 5000);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (state.pathId) {
      setDefaultType("核查结果");
      pageLoading.current = true;
      getVerificationRecords(state.pathId);
      return;
    }
    if (state.id) {
      clearTimeout(timer.current);
      timer.current = null;
      setLoading(false);
      return;
    }
    getDataFunc();
    getReportTypes();
  }, []);

  //根据核查结果id获取对应的核查任务id
  const getVerificationRecords = (id) => {
    projectApi.getSuspectId(id).then((res) => {
      if (res.code === 200) {
        setState({
          pathId: undefined,
          id: res.data,
        });
      } else {
        message.error(res.message);
        setState({
          pathId: undefined,
        });
      }
      pageLoading.current = false;
    });
  };
  const reVerificationFunc = (defaultData = [], defaultRules = null) => {
    projectApi.getLicenseSupport("ADV_SUS_REL_PARTY").then((res) => {
      if (res.code === 200 && res.data) {
        projectApi
          .getLicenseNums('["SUS_REL_PARTY_CHECK_NUM"]', id)
          .then((res2) => {
            if (res2.code === 200) {
              const [nowNum, maxNum, tipNum] = res2.data
                ?.SUS_REL_PARTY_CHECK_NUM || [0, 0, 0];

              const remaining = maxNum - nowNum;

              if (remaining <= 0) {
                message.warning({
                  content: verifyAdmin(myInfo) ? (
                    <span>
                      核查次数已达上限（已核查{nowNum || 0}次 / 授权
                      {maxNum || 0}次），如需支持更多核查次数请
                      {getContactBusinessDom()}
                      升级订阅方案
                    </span>
                  ) : (
                    <span>
                      核查次数已达上限（已核查{nowNum || 0}次 / 授权
                      {maxNum || 0}
                      次），如需支持更多核查次数请联系
                      {getAdminsDom()}
                      升级订阅方案
                    </span>
                  ),
                });
                return;
              } else if (remaining <= (tipNum || 0)) {
                message.warning({
                  content: verifyAdmin(myInfo) ? (
                    <span>
                      核查次数即将达到上限（已核查{nowNum || 0}次 / 授权
                      {maxNum || 0}次），请
                      {getContactBusinessDom()}
                      升级订阅方案
                    </span>
                  ) : (
                    <span>
                      核查次数即将达到上限（已核查{nowNum || 0}次 / 授权
                      {maxNum || 0}次），可联系
                      {getAdminsDom()}
                      升级订阅方案
                    </span>
                  ),
                });
              } else {
                message.info(`剩余可核查次数${remaining}次`);
              }
              setDefaultData(defaultData);
              setDefaultRules(defaultRules);
              setTimeout(() => {
                setIsCreate(true);
              }, 100);
              return;
            }
            message.error(res2.msg);
          });
        return;
      }
      message.error(res.msg);
    });
  };

  if (isCreate) {
    return (
      <CreateContent
        setIsCreate={setIsCreate}
        getDataFunc={getDataFunc}
        defaultRules={defaultRules}
        defaultData={defaultData}
        isFirst={
          getData()?.filter(
            (_) => _.suspect_status === "CDD_SUSPECT_STATUS_FINISH"
          )?.length === 0
        }
      ></CreateContent>
    );
  }
  if (state?.id) {
    return (
      <RecordDetails
        defaultType={defaultType}
        loading={loading}
        reVerificationFunc={reVerificationFunc}
      ></RecordDetails>
    );
  }

  return (
    <div className="suspectedRelatedPartyContent">
      <CommentsHeaderTitle
        title={typeName}
        dataTable="cdd_suspect_record"
        typeName={typeName}
      ></CommentsHeaderTitle>
      {pageLoading.current ? (
        <div className="content">
          <MySpin></MySpin>
        </div>
      ) : (
        <div className="content">
          {/* 生成报告按钮 */}
          {projectInfo?.project_permission_urls.includes(
            "CDD_PRJ_CDD_ITEM_EDIT"
          ) && (
            <div
              className="createBtnBox"
              onClick={() => {
                getLicenseValid().then((res) => {
                  if (res) {
                    reVerificationFunc();
                  }
                });
              }}
            >
              <div className="btnContent">
                <ReactSVG className="wordIcon" src={startIcon}></ReactSVG>{" "}
                开始核查
              </div>
              <div className="btnIcon">
                <PlusOutlined />
              </div>
            </div>
          )}
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{
              height: `calc(100% - ${
                projectInfo?.project_permission_urls.includes(
                  "CDD_PRJ_CDD_ITEM_EDIT"
                )
                  ? "100px"
                  : "16px"
              })`,
              width: "100%",
              marginTop: "16px",
            }}
          >
            {/* 报告模板标题 */}
            <div
              className="orderTitle"
              style={{
                marginTop: "8px",
              }}
            >
              <ReactSVG className="icon" src={icon1}></ReactSVG>功能简介
            </div>
            {/* 报告模板介绍 */}
            <div className="introduceBox">
              <div style={{ display: "block", height: "100%", width: "100%" }}>
                <Slider
                  dots={true}
                  infinite={false}
                  speed={500}
                  slidesToShow={4}
                  slidesToScroll={1}
                  // autoplay={true}
                  // autoplaySpeed={5000}
                  ref={carouselRef}
                  beforeChange={(current, next) => {
                    setNotHasPrev(next === 0);
                    setNotHasNext(next + 4 === reportTypeList?.length);
                  }}
                >
                  {reportTypeList.map((item, _index) => (
                    <Tooltip
                      key={_index}
                      title={item.details}
                      arrowPointAtCenter
                    >
                      <div
                        key={_index}
                        className="introduceReportBox"
                        style={{ backgroundColor: bgColor[_index % 4] }}
                      >
                        <div className="introduceReportItem">
                          <div className="reportType">{item.type}</div>
                          <div className="reportContent">
                            {/* <img src={item.img} /> */}
                            <Image
                              width={"100%"}
                              style={{
                                display: "block",
                              }}
                              src={item.img}
                            />
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                </Slider>
              </div>

              {reportTypeList?.length > 4 && !notHasPrev && (
                <LeftOutlined
                  className="carouselIcon LeftOutlined"
                  onClick={() => carouselRef.current?.slickPrev()}
                />
              )}

              {reportTypeList?.length > 4 && !notHasNext && (
                <RightOutlined
                  className="carouselIcon RightOutlined"
                  onClick={() => carouselRef.current?.slickNext()}
                />
              )}
            </div>
            {/* 全部报告标题 */}
            <div className="orderTitle">
              <ReactSVG className="icon" src={icon2}></ReactSVG>核查任务
            </div>
            {/* 报告列表 */}
            <div
              className="recordItemBox"
              style={{
                minHeight: `calc(100% - ${
                  projectInfo?.project_permission_urls.includes(
                    "CDD_PRJ_REPORT_GENERATE"
                  )
                    ? 430
                    : 345
                }px)`,
                height:
                  data?.length === 0 || loading
                    ? `calc(100% - ${
                        projectInfo?.project_permission_urls.includes(
                          "CDD_PRJ_REPORT_GENERATE"
                        )
                          ? 430
                          : 345
                      }px)`
                    : "auto",
              }}
            >
              {loading ? (
                <MySpin></MySpin>
              ) : data?.length === 0 ? (
                <div className="noData">
                  <MyEmpty
                    description={
                      projectInfo?.project_permission_urls.includes(
                        "CDD_PRJ_REPORT_GENERATE"
                      )
                        ? "暂无核查任务"
                        : "暂无核查任务，请联系项目成员生成核查任务"
                    }
                  ></MyEmpty>
                </div>
              ) : (
                // <Scrollbars
                //   autoHide={true}
                //   autoHideTimeout={5000}
                //   className="recordItemContent"
                //   style={{ height: "100%", width: "100%" }}
                // >
                <div className="recordItemContent">
                  {data?.length > 0 ? (
                    <div className="recordItemList">
                      {data?.map((item, index) => (
                        <RecordCard
                          key={item.id}
                          info={item}
                          style={{
                            marginTop: index < 2 ? "0px" : "24px",
                            marginRight: index % 2 === 0 ? "24px" : "0px",
                          }}
                          successFunc={() => getDataFunc()}
                          setDefaultType={setDefaultType}
                        ></RecordCard>
                      ))}
                    </div>
                  ) : (
                    <div className="noData">
                      <MyEmpty description="暂无核查任务"></MyEmpty>
                    </div>
                  )}
                </div>
                // </Scrollbars>
              )}
            </div>
          </Scrollbars>
        </div>
      )}
    </div>
  );
};
export default RelatedPartyManagement;
