import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import { Select, message, Tooltip } from "antd";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetState } from "ahooks";
import {
  addMillimeter,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import moment from "moment";
import TableModal from "../../../TableModal";

const { Option } = Select;

const AmountTrend = (props) => {
  const { data, checkCompanyList, searchTableFunc, notHasData } = props;
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const [allData, setAllData, getAllData] = useGetState({});
  const [yearsObj, setYearsObj] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const [selectTop, setSelectTop, getSelectTop] = useGetState("top5");
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const myChart = useRef(null);
  const colorMap = useRef([
    "#6CAEFF",
    "#FE545F",
    "#FEC054",
    "#AAD461",
    "#16D8D8",
    "#6CAEFF",
    "#FE545F",
    "#FEC054",
    "#AAD461",
    "#16D8D8",
  ]);
  const initEchartsData = (yearsObj, year, type, notInitEcharts) => {
    let list = yearsObj[year]?.sort((a, b) => b.amount - a.amount) || [];
    if (type === "top5") {
      list = list.slice(0, 5);
    } else if (type === "top3") {
      list = list.slice(0, 3);
    }
    const provinceArr = list.map((_) => _.name);
    //默认五年
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    const provinceData = provinceArr.map((name) => {
      return {
        name,
        data: yearArr.map((_year) => getAllData()[name][_year] || 0),
      };
    });
    if (notInitEcharts) {
      return {
        yearArr,
        provinceData,
      };
    }
    initECharts(yearArr, provinceData);
  };

  const initECharts = (data, seriesData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let maxNum = 0;
    seriesData.forEach((item) => {
      item?.data?.forEach((_) => {
        if (_ > maxNum) {
          maxNum = _;
        }
      });
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("amountTrendBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (list) => {
          let str =  `<div>${list?.at(0)?.axisValue}</div>`
          list?.forEach((item) => {
            let name = item.seriesName;
            if (name.length > 30) {
              name = name?.slice(0, 30) + "...";
            }
            str += `<div>${item.marker} ${name} <span style='float:right;margin-left: 20px;font-weight: bold;'>${
              addMillimeter(item.value, 2) + "元"
            }</span></div>`
          })
          return str;
        },
      },
      legend: {
        // x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        data: seriesData?.map((_) => _.name),
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        top: "18px",
        left: "210px",
        type: "scroll", // 显示分页
        selectedMode: true,
        height: 24,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },
      },
      grid: {
        top: "60px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: data,
        axisLabel: {
          interval: 0,
        },
        axisTick: {
          alignWithLabel: true,
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisPointer: {
          type: "shadow",
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: {
        type: "value",
        // name: maxNum < 10000 ? "(元)" : "(万元)",
        nameTextStyle: {
          align: "center",
          // padding: [0, 50, 0, 0],
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        max: maxNum < 5 ? 5 : null,
        nameGap: 10,
        axisLabel: {
          formatter: function (value, index) {
            // let num = parseInt(value / 10000).toLocaleString("en-US");
            // if (maxNum < 10000) {
            //   num = parseInt(value).toLocaleString("en-US");
            // }
            // return num;
            if (value >= 100000000) {
              return value / 100000000 + "亿";
            } else if (value >= 10000) {
              return value / 10000 + "万";
            } else {
              return value;
            }
          },
        },
      },
      series: seriesData?.map((item, index) => ({
        ...item,
        type: "line",
        // tooltip: {
        //   valueFormatter: function (value) {
        //     return addMillimeter(value, 2) + "元";
        //   },
        // },
        itemStyle: {
          color: colorMap.current[index],
        },
      })),
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let filterOptions = {
        采购人: a?.seriesName,
        年份: a?.name,
      };
      TableModal({
        title: "中标详情",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initData = (_data) => {
    let data = {};
    _data.forEach((item) => {
      if (data[item.tenderee]) {
        if (data[item.tenderee][item.anc_date]) {
          data[item.tenderee][item.anc_date] =
            data[item.tenderee][item.anc_date] + item.bid_winning_amo;
        } else {
          data[item.tenderee][item.anc_date] = item.bid_winning_amo;
        }
      } else {
        let obj = {};
        obj[item.anc_date] = item.bid_winning_amo;
        data[item.tenderee] = obj;
      }
    });
    let yearsObj = {};
    for (const key in data) {
      Object.keys(data[key])?.forEach((year) => {
        if (yearsObj[year]) {
          yearsObj[year].push({
            name: key,
            amount: data[key][year],
          });
        } else {
          yearsObj[year] = [
            {
              name: key,
              amount: data[key][year],
            },
          ];
        }
      });
    }
    let keys = Object.keys(yearsObj);
    keys = keys.sort((a, b) => Number(b) - Number(a));
    setAllData(data);
    setYearsObj(yearsObj);
    setSelectOptions(keys);
    setSelectYear(new Date()?.getFullYear());
    setTimeout(() => {
      initEchartsData(yearsObj, new Date()?.getFullYear(), getSelectTop());
    }, 50);
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "TOP客户中标金额趋势.png");
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "TOP客户中标金额趋势",
        bid_list: checkCompanyList,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="amountTrendContent">
      {!noData && (
        <div className="selectBox">
          {[
            new Date().getFullYear(),
            moment().subtract(1, "years")?.format("YYYY"),
          ]?.map((year) => (
            <div
              className={getSelectYear() === year ? "activeSelectItem" : null}
              key={year}
              onClick={() => {
                setSelectYear(year);
                setTimeout(() => {
                  initEchartsData(yearsObj, year, getSelectTop());
                }, 50);
              }}
            >
              {year + "年TOP5客户"}
            </div>
          ))}
        </div>
      )}
      <div className="headerBox">
        TOP客户中标金额趋势
        {!noData && (
          <div>
            {/* <Select
              value={selectYear}
              style={{ minWidth: "100px", marginRight: "10px" }}
              onChange={(value) => {
                setSelectYear(value);
                initEchartsData(yearsObj, value, getSelectTop());
              }}
            >
              {selectOptions.map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}
            {/* <Select
              value={selectTop}
              style={{ minWidth: "100px", marginRight: "10px" }}
              onChange={(value) => {
                setSelectTop(value);
                initEchartsData(yearsObj, getSelectYear(), value);
              }}
            >
              {["top5", "top3"].map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}

            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  />
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div id="amountTrendBox">{noData && <MyEmpty></MyEmpty>}</div>
    </div>
  );
};
export default AmountTrend;
