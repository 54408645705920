import "./index.scss";
import React, { useState, useEffect, useContext } from "react";
import { FormOutlined } from "@ant-design/icons";
import { Popover, Input, message, Button, Tooltip, Select, Form } from "antd";
import projectApi from "../../../apis/project";
import PersonAndCompanySelect from "../../PersonAndCompanySelect";
import { getLicenseValid, isExceedDigit, isNumberStr } from "../../../unit/unit";
const { TextArea } = Input;
const { Option, OptGroup } = Select;

export const Content = (props) => {
  const {
    info,
    dataTable,
    getData,
    typeName,
    defaultData,
    remarkId,
    dataInfo,
    projectId,
    mode,
  } = props;
  const [text, setText] = useState(info?.val || null);
  const [amount, setAmount] = useState(info?.val || null);
  const [company, setCompany] = useState(
    dataInfo[info?.join_field]?.val || null
  );
  const [personId, setPersonId] = useState(null);
  // console.log(info, dataInfo);

  const [form] = Form.useForm();

  const reviseHandle = (value, field) => {
    let params = {
      content: JSON.stringify({ modify_content: value }),
      data_field: field,
      data_table: dataTable,
      data_id: info.id,
    };
    if (personId) {
      params.person_id = personId;
    }
    projectApi.reviseHandle(projectId, params).then((res) => {
      if (res.code === 200) {
        getData();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
  };

  const onFinish = () => {
    if (info.join_field) {
      form.validateFields().then(res=>{
        if(!company){
          message.error("请填写完整信息");
          return;
        }
        reviseHandle(parseFloat(Number(amount)) + company, info.key);
      })

      return
    }
    if (!text) {
      message.error("请填写修订内容");
      return;
    }
    if (info.key === "legalPersonName" && !personId) {
      message.error("请填写自然人");
      return;
    }
    reviseHandle(text, info.key);
  };

  const debounceSelectChange = (info) => {
    setText(info?.name);
    setPersonId(info?.person_id);
  };

  async function fetchComList(username) {
    if (!username || username?.length < 2) {
      return [];
    }
    return projectApi
      .getCompanyAndPerson(username, projectId, 1)
      .then((res) => {
        if(res.code !== 200) {
          message.error(res.message)
          return []
        }
        return res.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.company_id,
        }));
      });
  }

  useEffect(() => {
    let obj = {
      amount: info?.val || null,
    };
    form.setFieldsValue(obj);
  }, []);
  const initDom = () => {
    if (info.join_field) {
      return (
        <Form form={form} autoComplete="off" layout="vertical">
          <Input.Group compact>
            <Form.Item
              name="amount"
              label={""}
              key="amount"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === "" || value === undefined || value === null) {
                      return Promise.resolve();
                    }
                    value = value?.toString();
                    
                    if (!isNumberStr(value)) {
                      return Promise.reject(new Error("请输入大于0的数字"));
                    }
                    if (isExceedDigit(value, 2)) {
                      return Promise.reject(
                        new Error(`输入的小数位数不超过${2}位`)
                      );
                    }
                    if (value?.split(".")?.at(0)?.length > 14) {
                      return Promise.reject(
                        new Error(`输入的数字不超过${14}位`)
                      );
                    }
                    value = Number(value);
                    if (value <= 0) {
                      return Promise.reject(new Error("请输入大于0的数字"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{ margin: "0px", width: "calc(100% - 100px" }}
            >
              <Input
                style={{ width: "100%" }}
                value={amount}
                maxLength={info.max_length}
                type="text"
                // onKeyDown={(e) => {
                //   const prohibitInput = ["-", "e", "+", "E"];
                //   if (prohibitInput.includes(e.key)) {
                //     e.preventDefault();
                //     return false
                //   }
                // }}
                placeholder="请输入不小于0的数字"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </Form.Item>
            <Select
              value={company}
              onChange={(value) => {
                setCompany(value);
              }}
              dropdownMatchSelectWidth={110}
              style={{ width: "100px" }}
            >
              {(info.join_options || []).map((_) => (
                <Option value={_.value} key={_.label}>
                  {_.label}
                </Option>
              ))}
            </Select>
          </Input.Group>
        </Form>
      );
    }
    if (info.options) {
      return (
        <Select
          showSearch
          placeholder="请选择"
          optionFilterProp="children"
          value={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            setText(value);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {info.options.map((_) => (
            <Option value={_.value} key={_.value}>
              {_.label}
            </Option>
          ))}
        </Select>
      );
    }
    if (info.key === "legalPersonName") {
      return (
        <div>
          {/* <Input
            maxLength={info.max_length}
            placeholder="请输入自然人名称"
            style={{ marginBottom: "10px", width: "100%" }}
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
          ></Input> */}
          <PersonAndCompanySelect
            selectChange={debounceSelectChange}
            showSearch={true}
            placeholder="请输入自然人名称搜索"
            fetchOptions={fetchComList}
            changeClear={true}
            allowClear
            notInForm={true}
            style={{
              width: "100%",
            }}
          />
        </div>
      );
    }
    return (
      <TextArea
        rows={4}
        showCount={!!info.max_length}
        maxLength={info.max_length}
        placeholder="请输入修订内容"
        style={{ marginBottom: "10px", width: "100%" }}
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
      />
    );
  };

  return (
    <div className="reviseContent">
      <div className="title">修订</div>
      {initDom()}
      <div className="btnBox drawerFooterBtnBox">
        <Button
          className="noBg"
          onClick={() => {
            props.onClose();
          }}
        >
          取消
        </Button>

        <Button
          type="primary"
          onClick={() => {
            onFinish();
          }}
        >
          确定
        </Button>
      </div>
    </div>
  );
};
const ReviseIcon = (props) => {
  const {
    /* 修订对象(内含 comments,id 字段) */
    info,
    /* icon是否显示回调 */
    setShow,
    /* 表名 */
    dataTable,
    /* 回调函数 */
    getData,
    /* 模块中文名称 */
    typeName,
    /* 默认值(修改) */
    defaultData,
    /* 批注id(修改) */
    remarkId,
    /* 原数据 */
    dataInfo,
    /* 单个字段还是整条(单个字段为空,整条为20,整个模块为30) */
    mode,
    /* 样式 */
    style,
    /* 项目id */
    projectId,
    /* 是否提前渲染 */
    forceRender = false,
    /* 鼠标移入后延时多少才显示 */
    mouseEnterDelay = 0.2,
  } = props;

  const [visible, setVisible] = useState(false);

  const iconClick = () => {
    getLicenseValid().then((res) => {
      if (res) {
        setVisible(true);
      }
    });
  };

  const handleVisibleChange = (newVisible) => {
    if (!newVisible) {
      setVisible(newVisible);
    }
  };

  useEffect(() => {
    setShow && setShow(visible);
  }, [visible]);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      overlayClassName="myCardPopover"
      visible={visible}
      destroyTooltipOnHide={true}
      onVisibleChange={handleVisibleChange}
      content={
        <Content
          onClose={() => {
            setVisible(false);
          }}
          defaultData={defaultData}
          info={info}
          getData={getData}
          dataTable={dataTable}
          typeName={typeName}
          remarkId={remarkId}
          dataInfo={dataInfo}
          mode={mode}
          projectId={projectId}
        ></Content>
      }
    >
      <Tooltip
        placement="top"
        title="修订"
        arrowPointAtCenter
        forceRender={forceRender}
        mouseEnterDelay={mouseEnterDelay}
      >
        <FormOutlined
          onClick={(e) => {
            e.stopPropagation();
            iconClick();
          }}
          className="cursor-pointer editIcon hoverIcon"
        ></FormOutlined>
      </Tooltip>
    </Popover>
  );
};
export default ReviseIcon;
