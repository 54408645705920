import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
const IncreaseAndLossSituation = (props) => {
  const { increaseData = {}, leaveData = {}, dataRange, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);
  const [downLoading2, setDownLoading2] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [type, setType, getType] = useGetState("H1");
  const [tableData, setTableData, getTableData] = useGetState([]);
  const [tableData2, setTableData2, getTableData2] = useGetState([]);

  const [columns1, setColumns1, getColumns1] = useGetState([]);
  const [columns2, setColumns2, getColumns2] = useGetState([]);

  const initColumns = () => {
    const columns1 = [
      {
        title: "TOP10新增供应商",
        dataIndex: "trading_company_name",
        key: "trading_company_name",
        align: "center",
      },
      {
        title: getSelectYear() + "年" + "开票金额",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        render: (text) => (
          <span>{text ? addMillimeter(text, 2) + "元" : "-"}</span>
        ),
      },
      {
        title: "占比",
        dataIndex: "total_amount",
        key: "total_amount",
        width: "90px",
        align: "center",
        render: (a, info) => {
          return ((info?.amount / info?.total_amount) * 100)?.toFixed(2) + "%";
        },
      },
    ];
    const columns2 = [
      {
        title: "TOP10流失供应商",
        dataIndex: "trading_company_name",
        key: "trading_company_name",
        align: "center",
      },
      {
        title: getSelectYear() - 1 + "年开票金额",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        render: (text) => (
          <span>{text ? addMillimeter(text, 2) + "元" : "-"}</span>
        ),
      },
      {
        title: "占比",
        dataIndex: "total_amount",
        key: "total_amount",
        width: "90px",
        align: "center",
        render: (a, info) => {
          return ((info?.amount / info?.total_amount) * 100)?.toFixed(2) + "%";
        },
      },
    ];

    setColumns1(columns1);
    setColumns2(columns2);
  };

  const initData = (increaseData, leaveData) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }

    let year =
      diff >= 0
        ? moment(time)?.format("YYYY")
        : moment(time).subtract(1, "year")?.format("YYYY");
    setSelectYear(year);
    //数据范围为下半年 取上半年的数据展示
    setType(diff >= 0 ? "H1" : null);

    setTimeout(() => {
      initColumns();
      setTableData(
        (increaseData[year] || [])?.sort((a, b) => b.amount - a.amount)
      );
      setTableData2(
        (leaveData[year - 1] || [])?.sort((a, b) => b.amount - a.amount)
      );
    }, 100);
  };

  useEffect(() => {
    if (
      Object?.keys(increaseData)?.length === 0 &&
      Object?.keys(leaveData)?.length === 0
    ) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(increaseData, leaveData);
  }, [increaseData, leaveData]);

  const downloadFile = (type) => {
    if (type === "新增") {
      setDownLoading(true);
    } else {
      setDownLoading2(true);
    }
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: `供应商${type}情况`,
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
        setDownLoading2(false);
      });
  };

  return (
    <div className="increaseAndLossSituationContent">
      <div className="headerBox">
        供应商新增及流失情况
        {!noData && (
          <div className="downloadIcon1">
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={() => downloadFile("新增")}
                />
              ))}
          </div>
        )}
        {!noData && (
          <div className="downloadIcon2">
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading2 ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={() => downloadFile("流失")}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="increaseAndLossSituationContentBox">
          <div className="increaseAndLossSituationTableBox">
            <Table
              scroll={{
                y: tableData?.length > 0 ? "235px" : undefined,
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowKey={() => Math.random()}
              className="myFormTable"
              bordered={true}
              pagination={false}
              columns={columns1}
              dataSource={tableData?.slice(0, 10)}
            ></Table>
          </div>
          <div className="increaseAndLossSituationTableBox">
            <Table
              scroll={{
                y: tableData2?.length > 0 ? "235px" : undefined,
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowKey={() => Math.random()}
              className="myFormTable"
              bordered={true}
              pagination={false}
              columns={columns2}
              dataSource={tableData2?.slice(0, 10)}
            ></Table>
          </div>
          <div className="increaseAndLossSituationTextBox">
            <div>
              {getSelectYear()}年新增供应商
              {Number(tableData?.length || 0)?.toLocaleString()}家，流失供应商
              {Number(tableData2?.length || 0)?.toLocaleString()}家。
            </div>
            {tableData2?.length > 0 && (
              <div className="lostBox">
                <h3>流失供应商及历史开票金额为：</h3>
                <div>
                  {tableData2?.slice(0, 3)?.map((item, index) => (
                    <i key={index}>
                      {index === 0 ? null : "、"}
                      <span>{item.trading_company_name}</span>（
                      {addMillimeter(item.amount)}元）
                    </i>
                  ))}
                </div>
              </div>
            )}

            {tableData?.length > 0 && (
              <div className="addBox">
                <h3>新增供应商及开票金额为：</h3>
                <div>
                  {tableData?.slice(0, 3)?.map((item, index) => (
                    <i key={index}>
                      {index === 0 ? null : "、"}
                      <span>{item.trading_company_name}</span>（
                      {addMillimeter(item.amount)}元）
                    </i>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default IncreaseAndLossSituation;
