import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  calcCompanyNameFunc,
  isCurrentYear,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
const SalesRevenueTrend = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let amountMax = 0;
    let hasCurrYear = false;
    echartsData?.forEach((item) => {
      if (item?.amount >= amountMax) {
        amountMax = item?.amount;
      }
      if (isCurrentYear(item.name)) {
        hasCurrYear = true;
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("salesRevenueTrendBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    let series = [
      {
        name: "开票金额",
        type: "bar",
        tooltip: {
          valueFormatter: function (value) {
            return addMillimeter(value, 2) + "元";
          },
        },
        itemStyle: {
          color: "#0068B2",
          barBorderRadius: [4, 4, 0, 0],
        },
        barMaxWidth: "40px",
        data: echartsData?.map((item) => item?.amount),
      },
    ];
    let tooltip = {
      trigger: "axis",
      confine: true,
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    };
    let legendData = ["开票金额"];
    let legendShow = true;
    if (echartsData?.some((_) => _.predictAmount)) {
      if (getInitType() === "年") {
        legendData = ["开票金额", "预测开票金额"];
        series.push({
          name: "预测开票金额",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#FEC054",
            barBorderRadius: [4, 4, 0, 0],
          },
          barMaxWidth: "40px",
          data: echartsData?.map((item) => item?.predictAmount),
        });
        tooltip = {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (info) {
            const [item1, item2] = info || [];
            let str = `<div>${item1?.name}</div><div>${item1.marker} ${
              item1.seriesName
            }  <span style='font-weight:bold;float:right;margin-left:20px'>${addMillimeter(
              item1?.value,
              2
            )}元</span></div>`;

            if (isCurrentYear(item1?.name)) {
              str =
                str +
                `<div>${item2.marker} ${
                  item2.seriesName
                }  <span style='font-weight:bold;float:right;margin-left:20px'>${addMillimeter(
                  item2?.value,
                  2
                )}元</span></div>`;
            }
            return str;
          },
        };
      } else {
        legendShow = false;
        tooltip = {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (info) {
            const [item] = info;
            let str = `<div>${item?.name}</div><div>${item.marker} ${
              item?.data?.predictAmount ? "预测开票金额" : "开票金额"
            }  <span style='font-weight:bold;float:right;margin-left:20px'>${addMillimeter(
              item?.value,
              2
            )}元</span></div>`;
            return str;
          },
        };
        series = [
          {
            name: "开票金额",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return addMillimeter(value, 2) + "元";
              },
            },
            itemStyle: {
              color: "#0068B2",
              barBorderRadius: [4, 4, 0, 0],
            },
            barMaxWidth: "40px",
            data: echartsData?.map((item) => ({
              ...(item || {}),
              value: item?.amount,
              itemStyle: {
                color: item?.predictAmount ? "#FEC054" : "#0068B2",
              },
            })),
          },
        ];
      }
    }
    const option = {
      tooltip: tooltip,
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: legendData,
        show: legendShow,
      },
      xAxis: [
        {
          type: "category",
          data: echartsData?.map((item) => item?.name),
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            rotate: getInitType() === "年" ? 0 : -30,
            // width: 34,
            // overflow: 'breakAll'
            formatter: function (value) {
              let name = value;
              if (name?.includes("第一季度")) {
                name = name?.slice(0, 4) + "Q1";
              }
              if (name?.includes("第二季度")) {
                name = name?.slice(0, 4) + "Q2";
              }
              if (name?.includes("第三季度")) {
                name = name?.slice(0, 4) + "Q3";
              }
              if (name?.includes("第四季度")) {
                name = name?.slice(0, 4) + "Q4";
              }
              return name;
              // return calcCompanyNameFunc(value, echartsData?.length, "salesRevenueTrendBox");
            },
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          max: amountMax <= 5 ? 5 : null,
          interval: amountMax <= 5 ? 1 : null,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: getInitType() === "年" ? 0 : "25px",
        bottom: getInitType() === "年" ? "30px" : "10px",
        containLabel: true,
      },
      series: series,
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    const arr = [];
    const keys = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      .sort((a, b) => Number(a) - Number(b));

    if (getInitType() === "年") {
      keys?.forEach((key) => {
        let yearItems = data[key]?.filter((_) => _.Q) || [];
        let item = data[key]?.find((_) => _.is_predict_amount) || null;
        arr.push({
          name: key,
          amount: yearItems
            ?.filter((_) => !_.is_predict_amount)
            ?.reduce((a, b) => a + b?.amount, 0),
          predictAmount: item
            ? yearItems?.reduce((a, b) => a + b?.amount, 0)
            : 0,
        });
      });
    } else {
      keys?.forEach((key) => {
        data[key]?.forEach((item) => {
          if (!item.Q) {
            return;
          }
          let name = item?.Q;
          if (name?.includes("Q1")) {
            name = name?.slice(0, 4) + "第一季度";
          }
          if (name?.includes("Q2")) {
            name = name?.slice(0, 4) + "第二季度";
          }
          if (name?.includes("Q3")) {
            name = name?.slice(0, 4) + "第三季度";
          }
          if (name?.includes("Q4")) {
            name = name?.slice(0, 4) + "第四季度";
          }
          arr.push({
            name: name,
            amount: item?.amount,
            predictAmount: item?.is_predict_amount ? item.amount : 0,
          });
        });
      });
    }
    initEcharts(arr);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "开票金额趋势",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="salesRevenueTrendContent">
      <div className="headerBox">
        开票金额趋势
        {!noData && (
          <div className="selectBox2">
            <div
              className={
                initType === "年"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "年") {
                  return;
                }
                setInitType("年");
                setTimeout(() => {
                  initData(data);
                }, 50);
              }}
            >
              年
            </div>
            <div
              className={
                initType === "季"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "季") {
                  return;
                }
                setInitType("季");
                setTimeout(() => {
                  initData(data);
                }, 50);
              }}
            >
              季
            </div>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="salesRevenueTrendContentBox">
          <div id="salesRevenueTrendBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default SalesRevenueTrend;
