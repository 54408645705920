import "./index.scss";
import HeaderTitle from "../../components/HeaderTitle";
import { HistoryOutlined, DeleteOutlined } from "@ant-design/icons";
import MySpin from "../../components/MySpin";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { message, Tabs, Tooltip, Divider } from "antd";
import FollowCard from "./component/FollowCard";
import FollowDrawer from "./component/FollowDrawer";
import projectApi from "../../apis/project";
import { useGetState } from "ahooks";
import Empty from "../../components/Empty";
import MessageModal from "../../components/MessageModal";
import { ReactSVG } from "react-svg";
import icon from "../../assets/删除.svg";
import DetailsDrawer from "../../components/DetailsDrawer";
const { TabPane } = Tabs;

const Follow = (props) => {
  const { hasHeader, defaultType, setActiveType, projectId, isHome } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData, getData] = useGetState([]);
  const [visible, setVisible] = useState(false);
  const [type, setType, getType] = useGetState(defaultType);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal, getTotal] = useGetState(0);

  const [nextLoading, setNextLoading, getNextLoading] = useGetState(false);

  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState({});

  const scrollbarsRef = useRef(null);
  const getAllDataFunc = () => {
    getDataFunc(getType(), 1, getPageNumber() * getPageSize());
  };

  const getDataFunc = (type, page, page_size) => {
    if (!type) {
      type = getType();
    }
    setLoading(true);
    const params = {
      page,
      page_size,
    };
    if (type === "我的") {
      params.range = 1;
    } else if (type === "推荐") {
      params.range = 2;
    } else if (type === "项目组") {
      params.range = 3;
    }
    if (projectId) {
      params.project_id = projectId;
    }
    projectApi.getFocus(params).then((res) => {
      if (res.code === 200) {
        if (type === getType()) {
          setData(res.data?.data || []);
          setTotal(res.data?.total);
        }
      } else {
        setData([]);
        setTotal(0);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getDataFunc(getType(), getPageNumber(), getPageSize());
  }, []);

  const tabs = isHome ? ["我的", "推荐"] : ["我的", "推荐", "项目组"];

  const tabChange = async (key) => {
    setPageNumber(1);
    scrollbarsRef.current?.view?.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
    await setType(key);
    setActiveType && (await setActiveType(key));
    setTimeout(() => {
      getDataFunc(getType(), getPageNumber(), getPageSize());
    }, 100);
  };

  const clearAll = () => {
    MessageModal({
      type: "warning",
      description: "确定要清除所有推荐吗？",
      title: "清除",
      onOk: () => {
        projectApi.clearSysFocus(projectId).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setPageNumber(1);
            setTimeout(() => {
              getDataFunc(getType(), getPageNumber(), getPageSize());
            }, 100);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const loadMore = () => {
    if (getNextLoading() || getData()?.length >= getTotal() || loading) {
      return;
    }
    let page = getPageNumber();
    page = page + 1;
    setPageNumber(page);
    setNextLoading(true);
    const params = {
      page: page,
      page_size: getPageSize(),
    };
    if (type === "我的") {
      params.range = 1;
    } else if (type === "推荐") {
      params.range = 2;
    } else if (type === "项目组") {
      params.range = 3;
    }
    if (projectId) {
      params.project_id = projectId;
    }
    projectApi.getFocus(params).then((res) => {
      console.log("获取更多!!!", page, res);
      if (res.code === 200) {
        if (type === getType()) {
          setData((prev) => {
            return [...prev, ...(res.data?.data || [])];
          });
          setTotal(res.data?.total);
        }
      } else {
        setData([]);
        setTotal(0);
        message.error(res.message);
      }
      setNextLoading(false);
    });
  };

  //滚动事件 监听滚动
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      scrollbarsRef.current.getValues();
    if (scrollHeight - scrollTop <= clientHeight + 30) {
      loadMore();
    }
  };

  //查看详情回调
  const showDetailsFunc = (item) => {
    if(!item.cdd_composite_id){
      return
    }
    setChoiceItem(item);
    setTimeout(() => {
      setDetailsDrawerVisible(true);
    }, 50);
  };

  return (
    <div className="followContent">
      <div style={{ position: "relative" }}>
        {hasHeader && (
          <HeaderTitle
            title="关注事项"
            extra={
              // isHome ? null : (
              <div className="iconBox">
                {type === "推荐" && projectId && data.length > 0 && !loading ? (
                  <Tooltip placement="top" title="一键清除" arrowPointAtCenter>
                    {/* <DeleteOutlined
                      className="hoverIcon"
                      onClick={() => clearAll()}
                    /> */}
                    <ReactSVG
                      src={icon}
                      className="hoverIcon iconSvg"
                      onClick={() => clearAll()}
                    ></ReactSVG>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title="历史关注" arrowPointAtCenter>
                    <HistoryOutlined
                      className="hoverIcon"
                      onClick={() => setVisible(true)}
                    />
                  </Tooltip>
                )}
              </div>
              // )
            }
          ></HeaderTitle>
        )}
        <div className="headerTabPaneBox">
          {tabs.map((key) => (
            <div
              key={key}
              className={
                key === type
                  ? "headerTabPane activeHeaderTabPane"
                  : "headerTabPane"
              }
              onClick={() => {
                tabChange(key);
              }}
            >
              {key}
            </div>
          ))}
        </div>
      </div>
      <div style={{ height: "calc(100% - 39px)", marginTop: "14px" }}>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%", zIndex: 11 }}
          onScroll={handleScroll}
          ref={scrollbarsRef}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : data.length > 0 ? (
            <div className="cardBox">
              {data.map((item) => (
                <FollowCard
                  getData={getAllDataFunc}
                  data={item}
                  type={type}
                  key={item.id}
                  showDetailsFunc={showDetailsFunc}
                ></FollowCard>
              ))}
            </div>
          ) : (
            <div className="noData">
              <Empty></Empty>
            </div>
          )}
          {nextLoading && (
            <Divider className="loadingMoreDivider" dashed>
              加载中...
            </Divider>
          )}
        </Scrollbars>
      </div>

      <FollowDrawer
        projectId={projectId}
        visible={visible}
        onClose={() => setVisible(false)}
      ></FollowDrawer>

      {type === "推荐" && (
        <DetailsDrawer
          title={(choiceItem?.event_type_code_name || "") + "详情"}
          visible={detailsDrawerVisible}
          item={
            {
              company_id: choiceItem.company_id,
              composite_id: choiceItem.cdd_composite_id,
              id: { val: '0000' },
            }
          }
          dataTable={choiceItem?.data_table}
          typeName={choiceItem?.event_type_code_name}
          projectId={choiceItem?.project_id}
          onCancel={() => {
            setDetailsDrawerVisible(false);
          }}
        ></DetailsDrawer>
      )}
    </div>
  );
};
export default Follow;
