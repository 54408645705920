import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { message, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../../../components/MySpin";
import { useGetState } from "ahooks";
import projectApi from "../../../../../apis/project";
import moment from "moment";
import SalesRevenueTrend from "./SalesRevenueTrend";
import SalesAndCustomerAnalysisPopulationContent from "./SalesAndCustomerAnalysisPopulationContent";
import CompositionOfSalesRevenue from "./CompositionOfSalesRevenue";
import MarketDistribution from "./MarketDistribution";
import SalesFrequency from "./SalesFrequency";
import QuarterlyDistribution from "./QuarterlyDistribution";
import TopCustomers from "./TopCustomers";
import SalesContribution from "./SalesContribution";
import IncreaseAndLossSituation from "./IncreaseAndLossSituation";
import TaxRateSituation from "./TaxRateSituation";

const { Option } = Select;

const SalesAndCustomerAnalysis = (props) => {
  const { collectionCompanyList, selectCompany, setSelectCompany } = props;

  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const [dataRange, setDataRange] = useState(null);

  // const [selectCompany, setSelectCompany, getSelectCompany] = useGetState(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getDataFunc = () => {
    setLoading(true);
    projectApi?.getBusinessSaleAnalysis(id, selectCompany).then((res) => {
      console.log("-----销项票据及客户分析:", res);
      if (res.code === 200) {
        setData(res?.data || {});
        setDataRange(res?.data?.data_date_scope);
        setUpdateTime(res?.data?.data_update_time);
      } else {
        message.error(res.message);
        setData({});
        setDataRange(null);
        setUpdateTime(null);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (collectionCompanyList?.length === 0) {
      return;
    }
    // setSelectCompany(collectionCompanyList?.at(0)?.company_id);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  }, [collectionCompanyList, selectCompany]);

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="salesAndCustomerAnalysisContent">
      <div className="dataInput">
        <span>
          采集时间：
          {updateTime ? moment(updateTime)?.format("YYYY-MM-DD hh:mm:ss") : "-"}
        </span>
        <span style={{ marginLeft: "24px" }}>数据范围：{dataRange || "-"}</span>
        <div className="inputBox">
          <div className="label">分析主体</div>
          <Select
            value={selectCompany}
            style={{ width: "100%" }}
            showArrow={true}
            bordered={false}
            onChange={(value) => {
              setSelectCompany(value);
              // setTimeout(() => {
              //   getDataFunc();
              // }, 50);
            }}
          >
            {collectionCompanyList?.map((item) => (
              <Option key={item.company_id} value={item.company_id}>
                {item.company_name}
              </Option>
            ))}
          </Select>
        </div>
        <Tooltip
          placement="left"
          title={
            <div className="collenctionTip">
              <h3>说明：</h3>
              <span>
                1、财税数据是通过百望股份有限公司从国家税务总局进行数据采集。
              </span>
              <span>
                2、采集的数据为最近36个月（最近1个月的数据可能会因为数据延迟无法采集到），数据包括：纳税、纳税申报、销项发票和进项发票等数据。
              </span>
              <span>
                3、财税数据分析功能依据纳税及发票数据进行统计分析，由于红冲、实际入账情况和无发票等原因，不能代表公司实际经营情况，仅供参考。
              </span>
            </div>
          }
          overlayClassName="collenctionTooltip"
        >
          <ExclamationCircleOutlined className="infoIcon" />
        </Tooltip>
      </div>
      <div className="chartsContentBox">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          <div className="chartsContent">
            {/* 总体情况 */}
            <div className="wholeRowBox">
              <SalesAndCustomerAnalysisPopulationContent
                data={data?.sale_summary}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></SalesAndCustomerAnalysisPopulationContent>
            </div>
            {/* 开票金额趋势 */}
            <div className="halfRowBox">
              <SalesRevenueTrend
                data={data?.sale_trend || {}}
                selectCompany={selectCompany}
              ></SalesRevenueTrend>
            </div>
            {/* 销售商品构成 */}
            <div className="halfRowBox">
              <CompositionOfSalesRevenue
                data={data?.sale_form || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></CompositionOfSalesRevenue>
            </div>
            {/* 市场分布情况 */}
            <div className="wholeRowBox">
              <MarketDistribution
                data={data?.spread_info || {}}
                selectCompany={selectCompany}
              ></MarketDistribution>
            </div>
            {/* 主营商品开票次数 */}
            <div className="halfRowBox">
              <SalesFrequency
                data={data?.sales_frequency || {}}
                selectCompany={selectCompany}
              ></SalesFrequency>
            </div>
            {/* 主营商品开票金额季度分布 */}
            <div className="halfRowBox">
              <QuarterlyDistribution
                data={data?.sales_quarter || {}}
                selectCompany={selectCompany}
              ></QuarterlyDistribution>
            </div>
            {/* TOP客户 */}
            <div className="halfRowBox">
              <TopCustomers
                data={data?.sales_top_trading || {}}
                selectCompany={selectCompany}
              ></TopCustomers>
            </div>
            {/* 存量和新增客户开票金额贡献 */}
            <div className="halfRowBox">
              <SalesContribution
                data={data?.stock_add_per || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></SalesContribution>
            </div>
            {/* 客户新增及流失情况 */}
            <div className="wholeRowBox">
              {/* 新增: data?.increase_customer_info || {} */}
              {/* 流失: data?.leave_customer_info || {}*/}
              <IncreaseAndLossSituation
                increaseData={data?.increase_customer_info || {}}
                leaveData={data?.leave_customer_info || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></IncreaseAndLossSituation>
            </div>
            {/* 销售商品税率情况 */}
            <div className="wholeRowBox">
              <TaxRateSituation
                data={data?.sales_tax_rate || {}}
                selectCompany={selectCompany}
              ></TaxRateSituation>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};
export default SalesAndCustomerAnalysis;
