import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
const ComparisonOfExpensesAndIncome = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);

  const myChart = useRef(null);

  const initEcharts = (years, echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let amountMax = 0;
    let perMax = 0;

    years?.forEach((year) => {
      echartsData[year]?.forEach((item) => {
        if (item?.buy_amount >= amountMax) {
          amountMax = item?.buy_amount;
        }
        if (item?.sale_amount >= amountMax) {
          amountMax = item?.sale_amount;
        }

        if (item?.per >= perMax) {
          perMax = item?.per;
        }
      });
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("comparisonOfExpensesAndIncomeBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const interval = 5;

    if (amountMax <= 10) {
      amountMax = 10;
    } else {
      amountMax =
        (Number(amountMax?.toString()[0]) + 1) *
        Math.pow(10, parseInt(amountMax)?.toString()?.length - 1);
    }

    if (perMax <= 1) {
      perMax = 1;
    } else {
      perMax =
        (Number(perMax?.toString()[0]) + 1) *
        Math.pow(10, parseInt(perMax)?.toString()?.length - 1);
    }

    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: ["销项金额", "进项金额", "进项占销项比"],
      },
      xAxis: [
        {
          type: "category",
          data: years,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            // rotate: -15,
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          max: amountMax,
          interval: amountMax / interval,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
        {
          type: "value",
          // name: "Temperature",
          min: 0,
          max: perMax,
          interval: perMax / interval,
          axisPointer: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            formatter: (value) => {
              return value * 100 + "%";
            },
          },
        },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: "0%",
        bottom: "30px",
        containLabel: true,
      },
      series: [
        {
          name: "销项金额",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#0068B2",
            barBorderRadius: [4, 4, 0, 0],
          },
          barMaxWidth: "40px",
          data: years?.map((year) => {
            return echartsData[year]?.reduce((a, b) => a + b?.sale_amount, 0);
          }),
        },
        {
          name: "进项金额",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#61D4AD",
            barBorderRadius: [4, 4, 0, 0],
          },
          barMaxWidth: "40px",
          data: years?.map((year) => {
            return echartsData[year]?.reduce((a, b) => a + b?.buy_amount, 0);
          }),
        },
        {
          name: "进项占销项比",
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value * 100) + "%";
            },
          },
          itemStyle: {
            color: "#FEC054",
          },
          data: years?.map((year) => {
            const sum1 = echartsData[year]?.reduce(
                (a, b) => a + b?.buy_amount,
                0
              ),
              sum2 = echartsData[year]?.reduce((a, b) => a + b?.sale_amount, 0);
            if (sum2 === 0) {
              return 0;
            }
            return sum1 / sum2;
          }),
        },
      ],
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    const keys = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      .sort((a, b) => Number(a) - Number(b));

    initEcharts(keys, data);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "进项与销项开票金额对比",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="comparisonOfExpensesAndIncomeContent">
      <div className="headerBox">
        进项与销项开票金额对比
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="comparisonOfExpensesAndIncomeContentBox">
          <div id="comparisonOfExpensesAndIncomeBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default ComparisonOfExpensesAndIncome;
