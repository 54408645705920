import "./index.scss";
import { Select, Divider, message, AutoComplete, Tooltip } from "antd";
import { SearchOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import projectApi from "../../apis/project";
import HeaderTitle from "../../components/HeaderTitle";
import { useGetState, useSize } from "ahooks";
import Scrollbars from "react-custom-scrollbars";
import MyEmpty from "../../components/Empty";
import { getAdminsDom, getContactBusinessDom, getLicenseValid, uniqueArray, verifyAdmin } from "../../unit/unit";
import { ReactSVG } from "react-svg";
import svg1 from "../../assets/收藏.svg";
import { useHover } from "ahooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { Option } = Select;

const Card = (props) => {
  const { info, index, isActive, changeFocus } = props;
  const ref = useRef(null);
  const isHovering = useHover(ref);

  const goUrl = () => {
    window.open(info.tool_url, "_blank");
  };

  const collectHandle = (flag, id) => {
    changeFocus(flag, id);
  };

  return (
    <div
      className={isActive ? "toolCard active" : "toolCard"}
      style={{ marginRight: index % 4 === 3 ? "0px" : "20px" }}
      onClick={() => {
        goUrl();
      }}
    >
      <div className="cardIcon">
        {info.image_url ? (
          <img src={info.image_url} alt="" loading="lazy" />
        ) : info._image_url ? (
          <img src={info._image_url} alt="" loading="lazy" />
        ) : null}
      </div>

      <div className="infoBox">
        <div className="title">{info.tool_name}</div>
        <a className="info" title={info.tool_describe}>
          {info.tool_describe}
        </a>
      </div>

      <div className="focusBox" ref={ref}>
        {info?.is_focus ? (
          <Tooltip
            placement="top"
            title="取消收藏"
            open={isHovering}
            arrowPointAtCenter
          >
            <StarFilled
              className="hoverIcon activeIcon"
              onClick={(e) => {
                e.stopPropagation();
                collectHandle(false, info.id);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title="收藏"
            open={isHovering}
            arrowPointAtCenter
          >
            <StarOutlined
              className="hoverIcon"
              onClick={(e) => {
                e.stopPropagation();
                collectHandle(true, info?.id);
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const ToolCabinet = (props) => {
  const [toolList, setToolList] = useState([]);
  const [choiceItem, setChoiceItem] = useState({});

  const [allList, setAllList] = useState([]);
  const [value, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [focusList, setFocusList] = useState([]);

  const [isSearch, setIsSearch] = useState(false);

  const [focusLoading, setFocusLoading, getFocusLoading] = useGetState(false);
  const myInfo = useSelector((state) => state.User.userInfo);

  const ref = useRef(null);
  const size = useSize(ref);
  const scrollRef = useRef(null);
  const history = useNavigate();

  const initAllList = (list, first) => {
    let arr = [];
    let focusList = [];
    list?.forEach((typeItem) => {
      typeItem?.children.forEach((item) => {
        item?.children.forEach((_item) => {
          _item.grandparent_id = typeItem.id;
          _item.parent_tool_name = item.tool_name;

          if (_item.is_focus) {
            focusList.push(_item);
          }
          arr.push(_item);
        });
      });
    });
    focusList = focusList?.sort(
      (a, b) =>
        new Date(b?.focus_create_time)?.getTime() -
        new Date(a?.focus_create_time)?.getTime()
    );
    if (first) {
      if (focusList?.length > 0) {
        setChoiceItem({ id: "我的收藏", children: focusList });
      } else {
        setChoiceItem(list?.at(0));
      }
    } else {
      if (choiceItem.id === "我的收藏") {
        if (focusList?.length > 0) {
          setChoiceItem({
            id: "我的收藏",
            children: focusList,
          });
        } else {
          setChoiceItem(list?.at(0));
        }
      } else {
        const item = list?.find((_) => _.id === choiceItem.id);
        setChoiceItem(item);
      }
    }
    setFocusList(focusList);
    setToolList(list);
    setAllList(arr);
    setFocusLoading(false);
  };

  const getDate = (first) => {
    projectApi.getTool().then((res) => {
      if (res.code === 200) {
        const arr = res.data.data?.sort((a, b) => a.sort_index - b.sort_index);
        // setToolList(arr);
        // setChoiceItem(arr?.at(0));
        initAllList(arr, first);
      } else {
        message.error(res.message);
        setToolList([]);
        setChoiceItem({});
        setFocusLoading(false);
      }
    });
  };

  useEffect(() => {
    // getLicenseValid(true).then(res=>{
    //   if(res === false){
    //     message.warning({
    //     content: verifyAdmin(myInfo) ? (
    //       <span>
    //         您的订阅已经过期，请
    //         {getContactBusinessDom()}
    //         续订！
    //       </span>
    //     ) : (
    //       <span>
    //         您的订阅已经过期，请联系
    //         {getAdminsDom()}
    //         续订！
    //       </span>
    //     ),
    //   })
    //   }
    // })
    getDate(true);
  }, []);

  const choiceType = (tool) => {
    setChoiceItem(tool);
    scrollRef.current.scrollTop(0);
  };

  const handleSearch = (newValue) => {
    if (!newValue) {
      setOptions([]);
      return;
    }
    const filterAllList = allList.filter((item) => {
      return item.tool_name?.toUpperCase()?.includes(newValue?.toUpperCase());
    });
    setOptions(uniqueArray(filterAllList, ["tool_url", "tool_name"]));
  };
  const timer = useRef(null);
  const debounceSearchHandle = (newValue, delay) => {
    // 取消已有的定时器（其实可以理解为，取消了上一次的函数调用）
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleSearch(newValue);
    }, delay);
  };

  const handleChange = (newValue) => {
    if (!newValue) {
      setIsSearch(false);
      setChoiceItem(
        focusList?.length > 0
          ? { id: "我的收藏", children: focusList }
          : toolList?.at(0)
      );
      return;
    }
    setIsSearch(true);
    let children = allList.filter((_) => _.tool_name?.includes(newValue));
    children = uniqueArray(children, ["tool_url", "tool_name"]);
    choiceType({
      children:
        children.length > 0
          ? [
              {
                id: Math.random(),
                tool_name: "",
                children: children,
              },
            ]
          : [],
    });
  };

  //收藏操作
  const changeFocus = (is_focus, id) => {
    if (getFocusLoading()) {
      return;
    }
    setFocusLoading(true);
    projectApi
      ?.setFocusWebsite(id, is_focus ? "CDD_FOCUS_ING" : "CDD_FOCUS_CLS")
      .then((res) => {
        if (res.code === 200) {
          getDate();
        } else {
          message.error(res.message);
          setFocusLoading(false);
        }
      });
  };
  return (
    <div className="toolCabinetContent">
      <div className="searchInput">
        <AutoComplete
          placeholder="输入需要搜索的工具或网址名"
          className="toolInput"
          value={value}
          showSearch
          suffixIcon={<SearchOutlined />}
          allowClear
          options={options.map((d) => {
            return {
              ...d,
              key: d.tool_name + d.id,
              value: d.tool_name,
              label: d.tool_name,
            };
          })}
          onSearch={(value) => {
            debounceSearchHandle(value, 500);
          }}
          onChange={(value) => {
            setValue(value);
          }}
          onInputKeyDown={(e) => {
            if (e.key === "Enter") {
              handleChange(value);
            }
          }}
          onSelect={(_) => {
            handleChange(_);
            handleSearch(_);
          }}
          onClear={() => {
            setIsSearch(false);
            setChoiceItem(
              focusList?.length > 0
                ? { id: "我的收藏", children: focusList }
                : toolList?.at(0)
            );
          }}
        ></AutoComplete>
      </div>

      {!isSearch && (
        <div className="typeBox" ref={ref}>
          {focusList?.length > 0 && (
            <div
              className="typeItemBox"
              key={"收藏"}
              onClick={() => {
                choiceType({ id: "我的收藏", children: focusList });
              }}
            >
              <div className="icon">
                <ReactSVG
                  className={choiceItem.id === "我的收藏" ? "toolIcon" : null}
                  src={svg1}
                ></ReactSVG>
              </div>
              <div
                className="title"
                style={{
                  color: choiceItem.id === "我的收藏" ? "#0068B2" : "#313131",
                }}
              >
                我的收藏
              </div>
            </div>
          )}
          {toolList?.map((tool, index) => (
            <div
              className="typeItemBox"
              key={tool.id}
              onClick={() => {
                choiceType(tool);
              }}
            >
              <div className="icon">
                <ReactSVG
                  className={choiceItem.id === tool.id ? "toolIcon" : null}
                  src={tool.image_url}
                ></ReactSVG>
              </div>
              <div
                className="title"
                style={{
                  color: choiceItem.id === tool.id ? "#0068B2" : "#313131",
                }}
              >
                {tool.tool_name}
              </div>
            </div>
          ))}
        </div>
      )}

      <Divider
        style={{
          width: "calc(100% - 40px)",
          minWidth: "calc(100% - 40px)",
          marginLeft: "20px",
        }}
      />

      <div
        className="itemContentBox"
        id="itemContentBox"
        style={{
          width: "100%",
          height: `calc(100% - ${!isSearch ? size?.height + 140 : 120}px)`,
        }}
      >
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
          ref={scrollRef}
        >
          {choiceItem?.children?.length > 0 ? (
            choiceItem?.id === "我的收藏" ? (
              <div
                style={{
                  width: "100%",
                  padding: " 10px 20px 0 20px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {choiceItem?.children?.map((item, index) => (
                  <Card
                    // isActive={info?.split(",")?.at(1) == _item.id}
                    changeFocus={changeFocus}
                    key={item.id}
                    info={{ ...item, _image_url: choiceItem.image_url }}
                    index={index}
                  ></Card>
                ))}
              </div>
            ) : (
              <div style={{ width: "100%", padding: " 0 20px" }}>
                {choiceItem?.children?.map((item) => (
                  <div
                    className="itemContent"
                    key={item.id}
                    id={item.tool_name + item.id}
                  >
                    {item.tool_name && (
                      <HeaderTitle title={item.tool_name}></HeaderTitle>
                    )}
                    <div className="itemCardBox">
                      {item?.children?.map((_item, index) => (
                        <Card
                          // isActive={info?.split(",")?.at(1) == _item.id}
                          changeFocus={changeFocus}
                          key={_item.id}
                          info={{ ..._item, _image_url: choiceItem.image_url }}
                          index={index}
                        ></Card>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )
          ) : (
            <div className="emptyBox">
              <MyEmpty description="暂无数据"></MyEmpty>
            </div>
          )}
        </Scrollbars>
      </div>
    </div>
  );
};
export default ToolCabinet;
