import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useState, useEffect, useRef } from "react";
import MyDiv from "../../../components/MyDiv/index";
import { guid } from "../../../unit/unit.js";
import { Tabs, message } from "antd";
import MySpin from "../../../components/MySpin";
import useUrlState from "@ahooksjs/use-url-state";

const { TabPane } = Tabs;

const BondInvestment = (props) => {
  const { id } = useParams()

  const [dataInfo, setDataInfo] = useState({});
  const [showHeader, setShowHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("对外投资");
  const [state, setState] = useUrlState({});


  const tableRef = useRef(null);
  const tabs = useRef({
    对外投资: "company_investment_information",
    历史对外投资: "company_investment_history",
  });
  const typeNameList = {
    对外投资: "对外投资",
    历史对外投资: "对外投资(历史)",
  };

  useEffect(() => {
    let _type = "对外投资";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
    }
    setType(_type);
  }, []);

  const tabChange = (key) => {
    setType(key);
    setDataInfo({});
  };

  return (
    <div className="bondInvestmentContent">
      <HeaderTitle title="对外投资"></HeaderTitle>

      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        id: null,
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        tableRef.current?.getData();
                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "calc(100% + 10px)", width: "100%" }}
                >
                  <div className="infoBox">
                    <MyTable
                      isBond={true}
                      typeName={typeNameList[key]}
                      dataTable={tabs.current[key]}
                      dataChange={setDataInfo}
                      ref={tableRef}
                    ></MyTable>
                  </div>
                </Scrollbars>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
    </div>
  );
};
export default BondInvestment;
