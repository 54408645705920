import HeadPortrait from "../HeadPortrait";
import "./index.scss";
import { Popover } from "antd";
import React, { useState } from "react";

const HeadPortraitLine = (props) => {
  const { data = [], size = 20, onClick, num = 3, inBody = false } = props;
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <div className="headPortraitLineBox">
      {data?.slice(0, num)?.map((item, index) => (
        <HeadPortrait
          key={item?.pf_photo}
          size={size}
          title={
            item.show_name + (item.assist_name ? "/" + item.assist_name : "")
          }
          src={item?.pf_photo}
          onClick={() => onClick && onClick()}
          style={{ marginRight: "3px", cursor: "pointer" }}
        ></HeadPortrait>
      ))}
      {data?.length > num && (
        <Popover
          trigger="click"
          placement="bottomLeft"
          overlayClassName="headPortraitLineBoxPopover"
          visible={visible}
          destroyTooltipOnHide={true}
          onVisibleChange={handleVisibleChange}
          getPopupContainer={inBody ? null : (node) => node.parentNode}
          content={
            <div>
              {data?.slice(num)?.map((item) => (
                <HeadPortrait
                  key={item?.pf_photo}
                  size={size}
                  title={
                    item.show_name +
                    (item.assist_name ? "/" + item.assist_name : "")
                  }
                  src={item?.pf_photo}
                  onClick={() => onClick && onClick()}
                  style={{ marginRight: "3px", cursor: "pointer" }}
                ></HeadPortrait>
              ))}
            </div>
          }
        >
          <span className="moreSpan">+{data?.length - num}</span>
        </Popover>
      )}
    </div>
  );
};

export default HeadPortraitLine;
