import MyEmpty from "../../../../../../components/Empty";
import "./index.scss";
import { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useGetState } from "ahooks";
import {
  addMillimeter,
  addMillimeterAndInit,
  isCurrentYear,
} from "../../../../../../unit/unit";
import moment from "moment";
import { message } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { isArray } from "lodash";

const BillDetailsPopulationContent = (props) => {
  const { data, dataRange } = props;
  const [noData, setNoData] = useState(false);
  const [selectYear, setSelectYear, getSelectYear] = useGetState(false);
  const [type, setType, getType] = useGetState("H1");
  const [dataInfo, setDataInfo, getDataInfo] = useGetState({});

  const myChart1 = useRef(null);
  const myChart2 = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart1.current) {
      myChart1.current.dispose();
      myChart1.current = null;
    }
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom1 = document.getElementById("billDetailsPopulationChartBox1");
    const dom2 = document.getElementById("billDetailsPopulationChartBox2");
    if (!dom1 || !dom2) {
      return;
    }
    myChart1.current = echarts.init(dom1);
    myChart2.current = echarts.init(dom2);

    const income_valid_number = Math.abs(
      echartsData?.income_valid_number ? echartsData?.income_valid_number : 0
    );
    const income_negative_number = Math.abs(
      echartsData?.income_negative_number
        ? echartsData?.income_negative_number
        : 0
    );
    const income_invalid_number = Math.abs(
      echartsData?.income_invalid_number
        ? echartsData?.income_invalid_number
        : 0
    );
    const income_number =
      Math.abs(echartsData?.income_invalid_number) +
      Math.abs(echartsData?.income_negative_number) +
      Math.abs(echartsData?.income_valid_number);

    const outlay_valid_number = Math.abs(
      echartsData?.outlay_valid_number ? echartsData?.outlay_valid_number : 0
    );
    const outlay_negative_number = Math.abs(
      echartsData?.outlay_negative_number
        ? echartsData?.outlay_negative_number
        : 0
    );
    const outlay_invalid_number = Math.abs(
      echartsData?.outlay_invalid_number
        ? echartsData?.outlay_invalid_number
        : 0
    );
    const outlay_number =
      Math.abs(echartsData?.outlay_invalid_number) +
      Math.abs(echartsData?.outlay_negative_number) +
      Math.abs(echartsData?.outlay_valid_number);

    const income_valid_amount = Math.abs(
      echartsData?.income_valid_amount
        ? echartsData?.income_valid_amount?.toFixed(2)
        : 0
    );
    const income_negative_amount = Math.abs(
      echartsData?.income_negative_amount
        ? echartsData?.income_negative_amount?.toFixed(2)
        : 0
    );
    const income_invalid_amount = Math.abs(
      echartsData?.income_invalid_amount
        ? echartsData?.income_invalid_amount?.toFixed(2)
        : 0
    );
    const income_amount = (
      Math.abs(echartsData?.income_valid_amount) +
      Math.abs(echartsData?.income_negative_amount) +
      Math.abs(echartsData?.income_invalid_amount)
    )?.toFixed(2);

    const outlay_valid_amount = Math.abs(
      echartsData?.outlay_valid_amount
        ? echartsData?.outlay_valid_amount?.toFixed(2)
        : 0
    );
    const outlay_negative_amount = Math.abs(
      echartsData?.outlay_negative_amount
        ? echartsData?.outlay_negative_amount?.toFixed(2)
        : 0
    );
    const outlay_invalid_amount = Math.abs(
      echartsData?.outlay_invalid_amount
        ? echartsData?.outlay_invalid_amount?.toFixed(2)
        : 0
    );
    const outlay_amount = (
      echartsData?.outlay_valid_amount +
      echartsData?.outlay_negative_amount +
      echartsData?.outlay_invalid_amount
    )?.toFixed(2);

    const initSize = (num, isOne) => {
      if (num === 0) {
        return 0;
      }
      if (isOne) {
        if (num < 0.1) {
          return 0.1;
        } else if (num > 0.9) {
          return 0.9;
        }
      } else {
        if (num < 0.05) {
          return 0.05;
        } else if (num > 0.95) {
          return 0.95;
        }
      }
      return num;
    };
    const option1 = {
      tooltip: {
        formatter: function (info) {
          if (!isArray(info?.value)) {
            return;
          }
          const value = info?.value || [];
          return `<div>${info?.name}:${Number(value?.at(1) || 0)?.toLocaleString()}</div>`;
        },
      },
      series: [
        {
          type: "treemap",
          name: "发票数",
          top: 0,
          width: "100%",
          height: "100%",
          roam: false,
          nodeClick: false,
          breadcrumb: {
            show: false,
          },
          label: {
            overflow: "breakAll",
            formatter: (info) => {
              return info?.name + ' ' + Number(info?.value?.at(1) || 0)?.toLocaleString();
            },
          },
          labelLayout(params) {
            const width = parseInt(params.rect.width / 12);
            const height = parseInt(params.rect.height / 2);
            const min = Math.min(width, height);
            let max = 12;
            if (min <= max) {
              max = min < 6 ? 6 : min;
            }
            return {
              fontSize: max,
            };
          },
          itemStyle: {
            borderColor: "#fff",
          },
          levels: [
            {
              itemStyle: {
                borderWidth: 0,
                gapWidth: 5,
              },
            },
            {
              itemStyle: {
                gapWidth: 1,
              },
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          data: [
            {
              name: `进项发票数`,
              color: ["#61D4AD"],
              value: [
                initSize(
                  income_number /
                    (Number(income_number) + Number(outlay_number)),
                  true
                ),
                income_number,
              ],
              children: [
                {
                  name: `有效发票数`,
                  value: [
                    initSize(income_valid_number / income_number),
                    income_valid_number,
                  ],
                  itemStyle: {
                    color: "#3ec899",
                  },
                },
                {
                  name: `红冲发票数`,
                  value: [
                    initSize(income_negative_number / income_number),
                    income_negative_number,
                  ],
                  itemStyle: {
                    color: "#61D4AD",
                  },
                },
                {
                  name: `作废发票数`,
                  value: [
                    initSize(income_invalid_number / income_number),
                    income_invalid_number,
                  ],
                  itemStyle: {
                    color: "#89EDCB",
                  },
                },
              ],
            },
            {
              name: `销项发票数`,
              color: ["#0068B2"],
              value: [
                initSize(
                  outlay_number /
                    (Number(income_number) + Number(outlay_number)),
                  true
                ),
                outlay_number,
              ],
              children: [
                {
                  name: `有效发票数`,
                  value: [
                    initSize(outlay_valid_number / outlay_number),
                    outlay_valid_number,
                  ],
                  itemStyle: {
                    color: "#0068B2",
                  },
                },
                {
                  name: `红冲发票数`,
                  value: [
                    initSize(outlay_negative_number / outlay_number),
                    outlay_negative_number,
                  ],
                  itemStyle: {
                    color: "#2187c2",
                  },
                },
                {
                  name: `作废发票数`,
                  value: [
                    initSize(outlay_invalid_number / outlay_number),
                    outlay_invalid_number,
                  ],
                  itemStyle: {
                    color: "#6bbadb",
                  },
                },
              ],
            },
          ],
        },
      ],
    };
    const option2 = {
      tooltip: {
        formatter: function (info) {
          if (!isArray(info?.value)) {
            return;
          }
          const value = info?.value || [];
          return `<div>${info?.name}:${addMillimeter(value?.at(1))}元</div>`;
        },
      },
      series: [
        {
          type: "treemap",
          name: "发票金额",
          top: 0,
          width: "100%",
          height: "100%",
          roam: false,
          nodeClick: false,
          breadcrumb: {
            show: false,
          },
          label: {
            overflow: "breakAll",
            formatter: (info) => {
              return (
                info?.name + ' ' + addMillimeter(info?.value?.at(1)) + "元"
              );
            },
          },
          labelLayout(params) {
            const width = parseInt(params.rect.width / 10);
            const height = parseInt(params.rect.height / 2);
            const min = Math.min(width, height);
            let max = 12;
            if (min <= max) {
              max = min < 4 ? 4 : min;
            }
            return {
              fontSize: max,
            };
          },
          itemStyle: {
            borderColor: "#fff",
          },
          levels: [
            {
              itemStyle: {
                borderWidth: 0,
                gapWidth: 5,
              },
            },
            {
              itemStyle: {
                gapWidth: 1,
              },
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          data: [
            {
              name: `进项发票金额`,
              // color: ["#61D4AD"],
              value: [
                initSize(
                  income_amount /
                    (Number(outlay_amount) + Number(income_amount)),
                  true
                ),
                income_amount,
              ],
              children: [
                {
                  name: `有效发票金额`,
                  value: [
                    initSize(income_valid_amount / income_amount),
                    income_valid_amount,
                  ],
                  itemStyle: {
                    color: "#3ec899",
                  },
                },
                {
                  name: `红冲发票金额`,
                  value: [
                    initSize(income_negative_amount / income_amount),
                    income_negative_amount,
                  ],
                  itemStyle: {
                    color: "#61D4AD",
                  },
                },
                {
                  name: `作废发票金额`,
                  value: [
                    initSize(income_invalid_amount / income_amount),
                    income_invalid_amount,
                  ],
                  itemStyle: {
                    color: "#89EDCB",
                  },
                },
              ],
            },
            {
              name: `销项发票金额`,
              color: ["#0068B2"],
              value: [
                initSize(
                  outlay_amount /
                    (Number(outlay_amount) + Number(income_amount)),
                  true
                ),
                outlay_amount,
              ],
              children: [
                {
                  name: `有效发票金额`,
                  value: [
                    initSize(outlay_valid_amount / outlay_amount),
                    outlay_valid_amount,
                  ],
                  itemStyle: {
                    color: "#0068B2",
                  },
                },
                {
                  name: `红冲发票金额`,
                  value: [
                    initSize(outlay_negative_amount / outlay_amount),
                    outlay_negative_amount,
                  ],
                  itemStyle: {
                    color: "#2187c2",
                  },
                },
                {
                  name: `作废发票金额`,
                  value: [
                    initSize(outlay_invalid_amount / outlay_amount),
                    outlay_invalid_amount,
                  ],
                  itemStyle: {
                    color: "#6bbadb",
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    myChart1.current.setOption(option1);
    myChart2.current.setOption(option2);
    window.addEventListener("resize", () => {
      myChart1.current && myChart1.current.resize();
      myChart2.current && myChart2.current.resize();
    });
  };

  const initData = (data, dataRange) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }

    let year =
      diff >= 0
        ? moment(time)?.format("YYYY")
        : moment(time).subtract(1, "year")?.format("YYYY");
    setSelectYear(year);
    const info = data[year] || null;
    if (!info) {
      setNoData(true);
      return;
    } else {
      setNoData(false);
    }
    //数据范围为下半年且是今年 取上半年的数据展示
    if (diff >= 0 && isCurrentYear(year)) {
      setType("H1");
      const _h1 = info["h1"] || {};
      const lastYear = moment(time).subtract(1, "year")?.format("YYYY");
      const lastInfo = data[lastYear] || {};
      _h1.last_income_valid_amount = lastInfo["h1"]?.income_valid_amount || 0;
      _h1.last_outlay_valid_amount = lastInfo["h1"]?.outlay_valid_amount || 0;
      initEcharts(_h1);
      setDataInfo(_h1);
    } else {
      //数据范围为上半年 取前一年的数据展示
      setType(null);
      const obj = {};
      const _h1 = info["h1"] || {};
      const _h2 = info["h2"] || {};
      const lastYear = moment(time).subtract(2, "year")?.format("YYYY");
      const lastInfo = data[lastYear] || {};

      Object?.keys(info["h1"] || info["h2"] || {})?.forEach((key) => {
        obj[key] = ((_h1 || {})[key] || 0) + ((_h2 || {})[key] || 0);
      });
      obj.last_income_valid_amount =
        (lastInfo["h1"]?.income_valid_amount || 0) +
        (lastInfo["h2"]?.income_valid_amount || 0);
      obj.last_outlay_valid_amount =
        (lastInfo["h1"]?.outlay_valid_amount || 0) +
        (lastInfo["h2"]?.outlay_valid_amount || 0);
      initEcharts(obj);
      setDataInfo(obj);
    }
  };

  useEffect(() => {
    if (Object.keys(data || {})?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data, dataRange);
  }, [data, dataRange]);

  const initTip = () => {
    return (
      <>
        <div className="billDetailsPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年{getType() === "H1" ? "上半年" : ""}
            销项票据：有效金额为
            <span className="numberSpan">
              {addMillimeter(getDataInfo()?.outlay_valid_amount)}
            </span>
            元
            {getDataInfo()?.last_outlay_valid_amount ? (
              <>
                ，同比（较{getSelectYear() - 1}年
                {getType() === "H1" ? "上半年" : ""}）
                {getDataInfo()?.outlay_valid_amount >
                getDataInfo()?.last_outlay_valid_amount
                  ? "增长"
                  : "下降"}
                <span
                  style={{
                    color:
                      getDataInfo()?.outlay_valid_amount >
                      getDataInfo()?.last_outlay_valid_amount
                        ? "#61D4AD"
                        : "#FE545F",
                    fontWeight: "bold",
                  }}
                >
                  {getDataInfo()?.last_outlay_valid_amount
                    ? addMillimeter(
                        (Math.abs(
                          getDataInfo()?.outlay_valid_amount -
                            getDataInfo()?.last_outlay_valid_amount
                        ) /
                          Math.abs(getDataInfo()?.outlay_valid_amount)) *
                        100
                      ) + "%"
                    : "-%"}
                </span>
                {getDataInfo()?.outlay_valid_amount >
                getDataInfo()?.last_outlay_valid_amount ? (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            ；红冲金额为
            <span className="numberSpan">
              {addMillimeter(Math.abs(getDataInfo()?.outlay_negative_amount))}
            </span>
            元，作废金额为
            <span className="numberSpan">
              {addMillimeter(getDataInfo()?.outlay_invalid_amount)}
            </span>
            元。有效票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.outlay_valid_number)?.toLocaleString()}
            </span>
            张，红冲票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.outlay_negative_number)?.toLocaleString()}
            </span>
            张
            {getDataInfo()?.outlay_negative_number ? (
              <>
                （占比
                <span className="numberSpan">
                  {(
                    (getDataInfo()?.outlay_negative_number /
                      Math.abs(
                        getDataInfo()?.outlay_valid_number +
                          getDataInfo()?.outlay_negative_number +
                          getDataInfo()?.outlay_invalid_number
                      )) *
                    100
                  )?.toFixed(2)}
                  %
                </span>
                ）
              </>
            ) : null}
            ，作废票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.outlay_invalid_number)?.toLocaleString()}
            </span>
            张
            {getDataInfo()?.outlay_invalid_number ? (
              <>
                （占比
                <span className="numberSpan">
                  {(
                    (getDataInfo()?.outlay_invalid_number /
                      Math.abs(
                        getDataInfo()?.outlay_valid_number +
                          getDataInfo()?.outlay_negative_number +
                          getDataInfo()?.outlay_invalid_number
                      )) *
                    100
                  )?.toFixed(2)}
                  %
                </span>
                ）
              </>
            ) : null}
            。
          </div>
        </div>
        <div className="billDetailsPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年{getType() === "H1" ? "上半年" : ""}
            进项票据：有效金额为
            <span className="numberSpan">
              {addMillimeter(getDataInfo()?.income_valid_amount)}
            </span>
            元
            {getDataInfo()?.last_income_valid_amount ? (
              <>
                ，同比（较{getSelectYear() - 1}年
                {getType() === "H1" ? "上半年" : ""}）
                {getDataInfo()?.income_valid_amount >
                getDataInfo()?.last_income_valid_amount
                  ? "增长"
                  : "下降"}
                <span
                  style={{
                    color:
                      getDataInfo()?.income_valid_amount >
                      getDataInfo()?.last_income_valid_amount
                        ? "#61D4AD"
                        : "#FE545F",
                    fontWeight: "bold",
                  }}
                >
                  {getDataInfo()?.last_income_valid_amount
                    ? addMillimeter(
                        (Math.abs(
                          getDataInfo()?.income_valid_amount -
                            getDataInfo()?.last_income_valid_amount
                        ) /
                          Math.abs(getDataInfo()?.income_valid_amount)) *
                        100
                      ) + "%"
                    : "-%"}
                </span>
                {getDataInfo()?.income_valid_amount >
                getDataInfo()?.last_income_valid_amount ? (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            ；红冲金额为
            <span className="numberSpan">
              {addMillimeter(Math.abs(getDataInfo()?.income_negative_amount))}
            </span>
            元，作废金额为
            <span className="numberSpan">
              {addMillimeter(getDataInfo()?.income_invalid_amount)}
            </span>
            元。有效票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.income_valid_number)?.toLocaleString()}
            </span>
            张，红冲票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.income_negative_number)?.toLocaleString()}
            </span>
            张
            {getDataInfo()?.income_negative_number ? (
              <>
                （占比
                <span className="numberSpan">
                  {(
                    (getDataInfo()?.income_negative_number /
                      Math.abs(
                        getDataInfo()?.income_valid_number +
                          getDataInfo()?.income_negative_number +
                          getDataInfo()?.income_invalid_number
                      )) *
                    100
                  )?.toFixed(2)}
                  %
                </span>
                ）
              </>
            ) : null}
            ，作废票据张数为
            <span className="numberSpan">
              {Number(getDataInfo()?.income_invalid_number)?.toLocaleString()}
            </span>
            张
            {getDataInfo()?.income_invalid_number ? (
              <>
                （占比
                <span className="numberSpan">
                  {(
                    (getDataInfo()?.income_invalid_number /
                      Math.abs(
                        getDataInfo()?.income_valid_number +
                          getDataInfo()?.income_negative_number +
                          getDataInfo()?.income_invalid_number
                      )) *
                    100
                  )?.toFixed(2)}
                  %
                </span>
                ）
              </>
            ) : null}
            。
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="billDetailsPopulationContent">
      <div className="headerBox">总体情况</div>
      {!noData && (
        <div className="billDetailsPopulationBox">
          <div className="billCountBox">
            <div className="titleBox">
              {selectYear}年{type}票据数量
            </div>
            <div id="billDetailsPopulationChartBox1"></div>
          </div>
          <div className="billAmountBox">
            <div className="titleBox">
              {selectYear}年{type}票据金额
            </div>
            <div id="billDetailsPopulationChartBox2"></div>
          </div>
          <div className="billTypeBox">
            <div>
              <i></i>销项
            </div>
            <div>
              <i></i>进项
            </div>
          </div>
        </div>
      )}
      {!noData && (
        <div className="billDetailsPopulationTipBox">{initTip()}</div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default BillDetailsPopulationContent;
