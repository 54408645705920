import "./index.scss";
import { useState, useEffect, useRef } from "react";
import { Popover, message } from "antd";
import MyEmpty from "../Empty";
import { addMillimeter } from "../../unit/unit";

const IconBar = (props) => {
  const { data, type, icon, title } = props;
  const [noData, setNoData] = useState(false);
  const [interval, setInterval] = useState(1);
  const [keys, setKeys] = useState([]);
  const colorList = useRef(["#0068B2", "#61D4AD", "#FEC054", "#6CAEFF"]);

  const initData = (data) => {
    const amountKeys = Object.keys(data)
      ?.filter((_) => !_?.includes("H1") && !_?.includes("H2"))
      ?.sort((a, b) => b - a);
    setKeys(amountKeys);
    let max = 0;
    amountKeys?.forEach((key) => {
      let item = data[key];
      //率 乘以100
      if (type === "rate") {
        item = item * 100;
      }
      if (item > max) {
        max = item;
      }
    });
    let diff = 10;
    if (max > diff) {
      setInterval(max / diff);
    } else {
      setInterval(1);
    }
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  return (
    <div className="iconBarContent">
      {noData ? (
        <MyEmpty></MyEmpty>
      ) : (
        <Popover
          trigger="hover"
          placement="rightBottom"
          overlayClassName="iconBarPopover"
          content={
            <div className="iconBarPopoverBox">
              <div className="title">{title}</div>
              {keys?.map((key, index) => {
                return (
                  <div key={key} className="textBox">
                    <i
                      className="circleI"
                      style={{ backgroundColor: colorList.current[index % 4] }}
                    ></i>
                    <span className="textLeft">{key}</span>
                    <span className="textRight">
                      {type === "amount"
                        ? addMillimeter(data[key], 2) + "元"
                        : type === "rate"
                        ? (data[key] * 100)?.toFixed(2) + "%"
                        : Number(data[key] || 0)?.toLocaleString()}
                    </span>
                  </div>
                );
              })}
            </div>
          }
        >
          <div className="iconBarContentBox">
            {keys?.map((key, index) => {
              let num =
                Math.ceil(
                  (data[key] * (type === "rate" ? 100 : 1)) / interval
                ) || 1;
              return (
                <div className="iconBarItemBox" key={key}>
                  {new Array(num)?.fill(null)?.map((item) => (
                    <div
                      className="iconBarItemIcon"
                      key={Math.random()}
                      style={{ "--icon-color": colorList.current[index % 4] }}
                    >
                      {icon}
                    </div>
                  ))}
                  <div style={{ marginLeft: "6px" }}>
                    {type === "amount"
                      ? addMillimeter(data[key] / 10000, 2)
                      : type === "rate"
                      ? (data[key] * 100)?.toFixed(2) + "%"
                      : Number(data[key] || 0)?.toLocaleString()}
                  </div>
                </div>
              );
            })}
          </div>
        </Popover>
      )}
    </div>
  );
};
export default IconBar;
