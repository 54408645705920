import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Select, Table, message } from "antd";
import { addMillimeter, saveFile } from "../../../../../../../unit/unit";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../../../apis/project";
import { useParams } from "react-router-dom";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";

const { Option } = Select;

const WinningCompany = (props) => {
  const { data } = props;
  const { id } = useParams();
  const [showData, setShowData] = useState([]);
  const [yearsObj, setYearsObj] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectYear, setSelectYear] = useState(null);
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const columns = useRef([
    {
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      align: "center",
      width: "10%",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      key: "anc_date",
      title: "年份",
      dataIndex: "anc_date",
      align: "center",
      width: "10%",
    },
    {
      key: "tenderee",
      title: "采购人",
      dataIndex: "tenderee",
      align: "center",
      width: "35%",
    },
    {
      key: "bid_count",
      title: "中标项目数",
      dataIndex: "bid_count",
      align: "center",
      width: "15%",
    },
    {
      key: "bid_amount",
      title: "中标金额（元）",
      dataIndex: "bid_amount",
      align: "center",
      width: "25%",
      render: (text, record, index) => (
        <>{text ? addMillimeter(text, 2) : "-"}</>
      ),
    },
  ]);
  const initData = (data) => {
    let yearsObj = {};
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date].push(item);
      } else {
        yearsObj[item.anc_date] = [item];
      }
    });
    let keys = Object.keys(yearsObj);
    keys = keys.sort((a, b) => Number(b) - Number(a));
    setYearsObj(yearsObj);
    setSelectOptions(keys);
    let _data = keys?.at(0) ? yearsObj[keys?.at(0)] : [];
    _data = _data.sort((a, b) => b.bid_amount - a.bid_amount);
    setShowData(_data);
    setSelectYear(keys?.at(0) || null);
  };
  useEffect(() => {
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    const jsonData = showData.map((item) => ({
      年份: item.anc_date,
      采购人: item.tenderee,
      中标项目数:
        item.bid_count && parseInt(item.bid_count).toLocaleString("en-US"),
      "中标金额（元）": item.bid_amount && addMillimeter(item.bid_amount, 2),
    }));
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "历年中标公司列表",
        data: jsonData,
        rows: ["年份", "采购人", "中标项目数", "中标金额（元）"],
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  return (
    <div className="winningCompanyContent">
      <div className="headerBox">
        历年中标公司列表
        <div>
          <Select
            value={selectYear}
            style={{ minWidth: "100px", marginRight: "10px" }}
            onChange={(value) => {
              setSelectYear(value);
              let _data = yearsObj[value] || [];
              _data = _data.sort((a, b) => b.bid_amount - a.bid_amount);
              setShowData(_data);
            }}
          >
            {selectOptions.map((item, index) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          {projectInfo?.project_permission_urls.includes(
            "CDD_PRJ_CDD_ITEM_EXPORT"
          ) &&
            (downLoading ? (
              <LoadingOutlined className="downloadIcon" />
            ) : (
              <VerticalAlignBottomOutlined
                className="downloadIcon hoverIcon"
                onClick={downloadFile}
              />
            ))}
        </div>
      </div>
      <div id="winningCompanyBox">
        <Table
          rowKey={() => Math.random()}
          className="myFormTable2"
          bordered={false}
          pagination={false}
          columns={columns.current}
          dataSource={showData}
          scroll={{
            y: "562px",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
        ></Table>
      </div>
    </div>
  );
};
export default WinningCompany;
