import "./index.scss";
import { Button, Drawer, Input, message, Select, Divider } from "antd";
import { CloseOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import MySpin from "../../../../components/MySpin";
import Scrollbars from "react-custom-scrollbars";
import FollowCard from "../FollowCard";
import projectApi from "../../../../apis/project";
import { useGetState, useDebounce } from "ahooks";
import Empty from "../../../../components/Empty";
import { useRef } from "react";

const { Option } = Select;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        type="primary"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const FollowDrawer = (props) => {
  const { visible, projectId } = props;
  const [data, setData, getData] = useGetState([]);

  const [typeList, setTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [type, setType, getType] = useGetState(null);
  const [status, setStatus, getStatus] = useGetState(null);
  const [keyword, setKeyword, getKeyword] = useGetState(null);

  const [loading, setLoading] = useState(true);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(20);
  const [total, setTotal, getTotal] = useGetState(0);

  const [nextLoading, setNextLoading, getNextLoading] = useGetState(false);

  const scrollbarsRef = useRef(null);

  const debouncedValue = useDebounce(keyword, { wait: 500 });

  const getAllDataFunc = () => {
    getDataFunc(1, getPageNumber() * getPageSize());
  };

  const getOnePageDataFunc = () => {
    scrollbarsRef.current?.view?.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
    setPageNumber(1);
    getDataFunc(1, getPageSize());
  };

  useEffect(() => {
    if (getKeyword() !== null) {
      getOnePageDataFunc();
    }
  }, [debouncedValue]);

  const getDataFunc = (page, page_size) => {
    setLoading(true);
    const params = {
      page,
      page_size,
    };
    if (getType()) {
      params.event_type_code = getType();
    }
    if (getStatus()) {
      params.status = getStatus();
    }
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    if (projectId) {
      params.project_id = projectId;
    }
    projectApi.getFocus(params).then((res) => {
      if (res.code === 200) {
        setData(res.data?.data || []);
        setTotal(res.data?.total);
      } else {
        setData([]);
        setTotal(0);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_CONCERN_TYPE").then((res) => {
      if (res.code === 200) {
        setTypeList(res.data);
      } else {
        setTypeList([]);
      }
    });
    projectApi.getDicCodeInfo("CDD_FOCUS_STAT").then((res) => {
      if (res.code === 200) {
        setStatusList(res.data);
      } else {
        setStatusList([]);
      }
    });
  };

  const clearAll = () => {
    setTypeList([]);
    setData([]);
    setStatusList([]);
    setType(null);
    setStatus(null);
    setKeyword(null);
    setLoading(true);
    setTotal(0);
    setPageNumber(1);
    setPageSize(20);
  };

  useEffect(() => {
    if (visible) {
      getCodeInfo();
      getOnePageDataFunc();
    } else {
      clearAll();
    }
  }, [visible]);

  const typeChange = async (value) => {
    await setType(value);
    getOnePageDataFunc();
  };

  const statusChange = async (value) => {
    await setStatus(value);
    getOnePageDataFunc();
  };

  const keywordChange = async (e) => {
    await setKeyword(e.target.value);
  };

  const loadMore = () => {
    if (getNextLoading() || getData()?.length >= getTotal() || loading) {
      return;
    }
    let page = getPageNumber();
    page = page + 1;
    setPageNumber(page);
    setNextLoading(true);

    const params = {
      page: page,
      page_size: getPageSize(),
    };

    if (getType()) {
      params.event_type_code = getType();
    }
    if (getStatus()) {
      params.status = getStatus();
    }
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    if (projectId) {
      params.project_id = projectId;
    }
    projectApi.getFocus(params).then((res) => {
      console.log("获取更多!!!", page, res);
      if (res.code === 200) {
        if (type === getType()) {
          setData((prev) => {
            return [...prev, ...(res.data?.data || [])];
          });
          setTotal(res.data?.total);
        }
      } else {
        setData([]);
        setTotal(0);
        message.error(res.message);
      }
      setNextLoading(false);
    });
  };

  //滚动事件 监听滚动
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      scrollbarsRef.current.getValues();
    if (scrollHeight - scrollTop <= clientHeight + 30) {
      loadMore();
    }
  };

  const typeRef = useRef(null);
  const statusRef = useRef(null);

  return (
    <Drawer
      className="followDrawer"
      width="600px"
      closable={false}
      title="历史关注事项"
      placement="right"
      onClose={props.onClose}
      visible={visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={<BtnBox onClose={props.onClose}></BtnBox>}
    >
      <div className="searchBox">
        <Input
          allowClear={true}
          placeholder="输入关键字搜索"
          value={keyword}
          style={{ width: "calc(50% - 10px)", borderRadius: "30px" }}
          onChange={keywordChange}
        />

        <div className="iconBox">
          <div className="iconItem">
            <Select
              ref={typeRef}
              className="selectItem"
              style={{ width: "120px" }}
              bordered={false}
              placeholder="事项类型"
              suffixIcon={
                <CaretDownOutlined style={{ pointerEvents: "none" }} />
              }
              value={type}
              onChange={typeChange}
              allowClear
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
            >
              {typeList.map((item) => (
                <Option key={item.dic_item_code} value={item.dic_item_code}>
                  {item.dic_item_name}
                </Option>
              ))}
            </Select>
            {/* 事项类型 <CaretDownOutlined /> */}
          </div>
          <div className="iconItem">
            <Select
              ref={statusRef}
              className="selectItem"
              style={{ width: "100px" }}
              bordered={false}
              placeholder="状态"
              suffixIcon={
                <CaretDownOutlined style={{ pointerEvents: "none" }} />
              }
              value={status}
              onChange={statusChange}
              allowClear
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
            >
              {statusList.map((item) => (
                <Option key={item.dic_item_code} value={item.dic_item_code}>
                  {item.dic_item_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ height: "calc(100% - 45px)", width: "100%" }}>
          <MySpin></MySpin>
        </div>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: "calc(100% - 45px)",
            width: "100%",
            marginTop: "10px",
          }}
          onScroll={handleScroll}
          ref={scrollbarsRef}
        >
          {data.length > 0 ? (
            <div className="cardBox">
              {data.map((item) => (
                <FollowCard
                  getData={getAllDataFunc}
                  data={item}
                  key={item.id}
                  showStatus={true}
                ></FollowCard>
              ))}
            </div>
          ) : (
            <div className="noData">
              <Empty></Empty>
            </div>
          )}
          {nextLoading && (
            <Divider className="loadingMoreDivider" dashed>
              加载中...
            </Divider>
          )}
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default FollowDrawer;
