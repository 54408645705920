import { useState } from "react";
import "./index.scss";
import moment from "moment";
const YearSelect = (props) => {
  const { value, onChange, defaultOptions = undefined } = props;
  const [options, setOptions] = useState([
    moment(new Date()).subtract(4, "years")?.format("YYYY"),
    moment(new Date()).subtract(3, "years")?.format("YYYY"),
    moment(new Date()).subtract(2, "years")?.format("YYYY"),
    moment(new Date()).subtract(1, "years")?.format("YYYY"),
    moment(new Date())?.format("YYYY"),
  ]);
  return (
    <div className="yearSelect">
      {(defaultOptions || options)?.map((year) => {
        return (
          <div
            className={
              value?.includes(year) ? "yearItem activeYearItem" : "yearItem"
            }
            key={Math.random()}
            onClick={() => {
              // let newValue = [...value];
              // if (value?.includes(year)) {
              //   newValue = newValue?.filter((_) => _ !== year);
              // } else {
              //   newValue.push(year);
              // }
              onChange && onChange([year]);
            }}
          >
            <span></span>
            {year}
          </div>
        );
      })}
    </div>
  );
};
export default YearSelect;
