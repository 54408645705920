import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
  unifyNumber,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
import { innerCircleMap } from "../../../../../../setting";

const ProductProcurementSituation = (props) => {
  const { frequencyData = {}, timeData = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);

  const [circleSelectKey, setCircleSelectKey, getCircleSelectKey] =
    useGetState("");

  const [initType, setInitType, getInitType] = useGetState("开票次数");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);
  const [dataInfo, setDataInfo, getDataInfo] = useGetState([]);

  const [circleCompanyList, setCircleCompanyList, getCircleCompanyList] =
    useGetState([]);
  const [circleCompanyIndex, setCircleCompanyIndex, getCircleCompanyIndex] =
    useGetState(0);

  const myChart = useRef(null);
  const myChart2 = useRef(null);
  const circleInfo = useRef(null);

  const colorRef = useRef([
    "#0068B2",
    "#AAD461",
    "#FEC054",
    "#FE545F",
    "#6CAEFF",
  ]);
  const colorRef2 = useRef(["#6CAEFF", "#AAD461", "#FEC054", "#FE545F"]);

  const initCircleEchartsData = (key) => {
    if (!myChart.current) {
      return;
    }
    let options = myChart.current.getOption();
    let list = options?.series?.at(0)?.data || [];
    let item = JSON.parse(JSON.stringify(list))?.find(
      (item) => item[3] === key
    );
    list = (list?.filter((_) => _[0] === item[0] && _[1] === item[1]) || [])
      ?.sort((a, b) => b[2] - a[2])
      ?.map((_) => _[3]);
    setCircleCompanyList(list);
    setCircleCompanyIndex(0);
    // console.log(list, key, item);
    initCircleEcharts(list[0]);
  };

  const initCircleEcharts = (key) => {
    // if (getCircleSelectKey() === key) {
    //   return;
    // }
    setCircleSelectKey(key);
    const list = timeData[getSelectYear()]?.filter(
      (item) => item.product_category === key
    );

    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom = document.getElementById(
      "productProcurementSituationContentBox2"
    );
    if (!dom) {
      return;
    }

    const dataObj = {};
    list?.forEach((item) => {
      if (dataObj[item?.trading_company_name]) {
        dataObj[item?.trading_company_name] =
          dataObj[item?.trading_company_name] + item.amount;
      } else {
        dataObj[item?.trading_company_name] = item.amount;
      }
    });

    const keys = Object.keys(dataObj)?.sort((a, b) => dataObj[b] - dataObj[a]);
    myChart2.current = echarts.init(dom);

    const option = {
      // title: {
      //   text: `${key}采购供应商构成`,
      //   left: "center",
      // },
      tooltip: {
        trigger: "item",
        formatter: (info) => {
          return `<div>${
            info?.marker
          }<span style='font-weight:bold'>${key}</span></div><div><span style='font-weight:bold'>${
            info?.name
          }</span>: ${addMillimeterAndInit(info?.value + "元")}</div>
          <div><span style='font-weight:bold'>占比</span>: ${
            info?.percent
          }%</div>`;
        },
      },
      legend: {
        orient: "vertical",
        left: "left",
        show: false,
      },
      grid: {
        top: "60px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: [
        {
          // name: 'Access From',
          type: "pie",
          radius: "60%",
          // tooltip:{
          //   formatter: (value) => {
          //     return value + '11'
          //   }
          // },
          data: keys?.map((key, index) => {
            const item = dataObj[key];
            return {
              name: key,
              value: item,
              itemStyle: {
                color: innerCircleMap[index % innerCircleMap?.length],
              },
            };
          }),
          labelLine: {
            length: 10,
          },
          label: {
            width: 180,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    myChart2.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart2.current && myChart2.current.resize();
    });
  };

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const dom = document.getElementById(
      "productProcurementSituationContentBox"
    );
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    let option = {};
    if (getInitType() === "开票次数") {
      let obj = {};
      echartsData.forEach((item) => {
        if (obj[item.product_category]) {
          obj[item.product_category]["amount"] =
            obj[item.product_category]["amount"] + item.amount;
          obj[item.product_category]["number"] =
            obj[item.product_category]["number"] + item.number;
          if (
            !obj[item.product_category]["items"]?.includes(
              item.trading_company_name
            )
          ) {
            obj[item.product_category]["items"]?.push(
              item.trading_company_name
            );
          }
        } else {
          let _ = {
            amount: item.amount,
            items: [item.trading_company_name],
            number: item.number,
          };
          obj[item.product_category] = _;
        }
      });
      let amountMax = 0;
      let numberMax = 0;
      let itemLenMax = 0;
      Object.keys(obj)?.forEach((key) => {
        if (obj[key]?.amount > amountMax) {
          amountMax = obj[key]?.amount;
        }
        if (obj[key]?.number > numberMax) {
          numberMax = obj[key]?.number;
        }
        if (obj[key]?.items.length > itemLenMax) {
          itemLenMax = obj[key]?.items.length;
        }
      });
      const interval = amountMax / 50;
      option = {
        tooltip: {
          trigger: "axis",
          appendToBody: true,
          axisPointer: {
            type: "none",
            snap: true,
            axis: "x", // y轴选项
            show: false,
            label: {
              show: false,
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, .55)",
            },
          },

          formatter: (info) => {
            // console.log(info);
            // console.log(info, circleInfo.current);
            if (!circleInfo.current) {
              return "";
            }
            const list = info
              ?.filter((item) => {
                return (
                  circleInfo.current[0] === item.data[0] &&
                  circleInfo.current[1] === item.data[1]
                );
              })
              ?.sort((a, b) => b?.data?.at(2) - a?.data?.at(2))
              ?.slice(0, 5);

            if (list?.length === 0) {
              return "";
            }
            var htmlF =
              "<div style='display:flex;max-width: 600px;flex-wrap:wrap'>";
            list.forEach((item) => {
              htmlF += `<div style='margin-left: 10px'><div>${
                item?.marker
              }<span style='font-weight:bold'>${
                item?.data[3]
              }</span></div><div><span style='font-weight:bold'>金额</span>: ${
                addMillimeter(item?.data[2]) + "元"
              }</div><div><span style='font-weight:bold'>供应商数量</span>: ${
                Number(item?.data[0] || 0)?.toLocaleString()
              }个</div><div><span style='font-weight:bold'>开票次数</span>: ${
                Number(item?.data[1] || 0)?.toLocaleString()
              }次</div></div>`;
            });
            return htmlF + "</div>";

            // return `<div>${info?.marker}<span style='font-weight:bold'>${
            //   info?.data[3]
            // }</span></div><div><span style='font-weight:bold'>金额</span>: ${addMillimeterAndInit(
            //   info?.data[2] + "元"
            // )}</div></div><div><span style='font-weight:bold'>供应商数量</span>: ${
            //   info?.data[0]
            // }个</div></div><div><span style='font-weight:bold'>开票次数</span>: ${
            //   info?.data[1]
            // }次</div>
            // `;
          },
        },
        legend: {
          right: "10%",
          top: "3%",
          data: ["xxx"],
          show: false,
        },
        grid: {
          top: "20px",
          left: "0%",
          right: "70px",
          bottom: "30px",
          containLabel: true,
        },
        xAxis: {
          name: "供应商数量",
          nameLocation: "end",
          // nameRotate: -90,
          max: itemLenMax <= 5 ? 5 : undefined,
          nameGap: 4,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            formatter: function (value, index) {
              return unifyNumber(value);
            },
          },
        },
        yAxis: {
          name: "开票次数",
          nameLocation: "end",
          nameGap: 8,
          nameTextStyle: {
            padding: [0, 0, 0, 20],
          },
          max: numberMax <= 5 ? 5 : undefined,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            formatter: function (value, index) {
              return unifyNumber(value);
            },
          },
          // scale: true,
        },
        // dataZoom: [
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            name: "xxx",
            data: Object.keys(obj)?.map((key, index) => {
              const item = obj[key];
              return [item.items.length, item.number, item.amount, key, "xxx"];
            }),
            type: "scatter",
            symbolSize: function (data) {
              const size = Math.ceil(data[2] / interval);
              return size < 4 ? 4 : size;
            },
            itemStyle: {
              // shadowBlur: 10,
              // shadowColor: "rgba(25, 100, 150, 0.5)",
              // shadowOffsetY: 5,
              color: "#FE545F",
            },
          },
        ],
      };
      setTimeout(() => {
        initCircleEchartsData(
          Object.keys(obj)
            ?.sort((a, b) => obj[b]?.amount - obj[a]?.amount)
            ?.at(0)
        );
      }, 200);
    } else {
      let obj = {};
      let obj2 = {};
      let sumAmount = 0;
      echartsData.forEach((item) => {
        const key = item?.date?.slice(-2);
        if (obj[item.product_category]) {
          obj[item.product_category] = obj[item.product_category] + item.amount;
        } else {
          obj[item.product_category] = item.amount;
        }

        if (obj2[item.product_category]) {
          obj2[item.product_category][key] =
            obj2[item.product_category][key] + item.amount;
        } else {
          let _ = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
          _[key] = item.amount;
          obj2[item.product_category] = _;
        }

        sumAmount = sumAmount + item.amount;
      });

      let list = Object?.keys(obj)?.sort((a, b) => obj[b] - obj[a]);
      let diff = 0.75;
      let keys = [];
      list.forEach((key, index) => {
        if (index <= 4) {
          keys.push(key);
        } else {
          if (diff >= 0) {
            keys.push(key);
          }
        }
        diff = diff - obj[key] / sumAmount;
      });

      option = {
        tooltip: {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          // orient: "vertical",
          x: "center", //可设定图例在左、右、居中
          // y: "bottom", //可设定图例在上、下、居中
          bottom: "20px",
          height: 24,
          type: "scroll", // 显示分页
          // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: Object.keys(obj),
        },
        xAxis: [
          {
            type: "category",
            data: ["第一季度", "第二季度", "第三季度", "第四季度"],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              interval: 0,
              // rotate: -15,
            },
            axisTick: {
              alignWithLabel: true,
              interval: 0,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        yAxis: [
          {
            type: "value",
            // max: amountMax <= 5 ? 5 : null,
            // interval: amountMax <= 5 ? 1 : null,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisPointer: {
              show: false,
            },
            axisLabel: {
              formatter: function (value, index) {
                if (value >= 100000000) {
                  return value / 100000000 + "亿";
                } else if (value >= 10000) {
                  return value / 10000 + "万";
                } else {
                  return value;
                }
              },
            },
          },
        ],
        grid: {
          top: "20px",
          left: "0%",
          right: "0%",
          bottom: "50px",
          containLabel: true,
        },
        series: keys?.map((key, index) => {
          const data = [
            obj2[key]["Q1"] || 0,
            obj2[key]["Q2"] || 0,
            obj2[key]["Q3"] || 0,
            obj2[key]["Q4"] || 0,
          ];
          return {
            name: key,
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return addMillimeter(value, 2) + "元";
              },
            },
            itemStyle: {
              color: colorRef2.current[index % 4],
              barBorderRadius: [4, 4, 0, 0],
            },
            data: data,
          };
        }),
      };
      setTimeout(() => {
        initCircleEcharts(
          Object.keys(obj)
            ?.sort((a, b) => obj[b] - obj[a])
            ?.at(0)
        );
      }, 200);
    }
    myChart.current.setOption(option);

    if (getInitType() === "开票次数") {
      myChart.current.on("mouseover", function (params) {
        circleInfo.current = params.data;
      });
      myChart.current.on("mouseout", function (params) {
        circleInfo.current = null;
      });
    }

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (params) => {
      if (getInitType() === "开票次数") {
        initCircleEchartsData(params?.data[3] || params.seriesName);
      } else {
        initCircleEcharts(params.seriesName);
      }
    });
  };

  const initData = () => {
    // const data = getInitType() === "开票次数" ? frequencyData : timeData;
    const data = timeData;
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      ?.map((item) => {
        return item?.slice(0, 4);
      })
      .sort((a, b) => Number(a) - Number(b));
    yearArr = [...new Set(yearArr)];
    const nowYear = yearArr?.at(-1);
    setYearList(yearArr);
    setSelectYear(nowYear);
    setDataInfo(data[nowYear]);

    setTimeout(() => {
      initEcharts(data[nowYear]);
    }, 100);
  };

  useEffect(() => {
    if (
      Object?.keys(frequencyData)?.length === 0 &&
      Object?.keys(timeData)?.length === 0
    ) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(frequencyData, timeData);
  }, [frequencyData, timeData]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: `主营商品采购及供应商情况（按${getInitType()}）`,
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const circleChange = (type) => {
    let index = getCircleCompanyIndex();
    if (type === "next") {
      index = index + 1;
    } else {
      index = index - 1;
    }
    setCircleCompanyIndex(index);
    initCircleEcharts(getCircleCompanyList()?.at(index));
  };

  return (
    <div className="productProcurementSituationContent">
      <div className="headerBox">
        主营商品采购及供应商情况
        {!noData && (
          <div className="selectBox2">
            <div
              className={
                initType === "开票次数"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "开票次数") {
                  return;
                }
                setInitType("开票次数");
                setTimeout(() => {
                  initData();
                }, 50);
              }}
            >
              开票次数
            </div>
            <div
              className={
                initType === "开票时间"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "开票时间") {
                  return;
                }
                setInitType("开票时间");
                setTimeout(() => {
                  initData();
                }, 50);
              }}
            >
              开票时间
            </div>
          </div>
        )}
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(timeData[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="productProcurementSituationContentBox">
          <div id="productProcurementSituationContentBox"></div>
          <div className="circleTitle">
            {circleCompanyList?.length > 1 && circleCompanyIndex !== 0 ? (
              <LeftOutlined
                className="prevIcon"
                onClick={() => circleChange("prev")}
              />
            ) : null}
            {circleSelectKey}采购供应商构成
            {circleCompanyList?.length > 1 &&
            circleCompanyIndex !== circleCompanyList?.length - 1 ? (
              <RightOutlined
                className="nextIcon"
                onClick={() => circleChange("next")}
              />
            ) : null}
          </div>
          <div id="productProcurementSituationContentBox2"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default ProductProcurementSituation;
