/* 
  @FileDescription: 网页使用监测,
  @
  @params:{
    url: 后台接口地址 [String],
    projectName: 项目名称 [String],
    moduleList: 模块列表 [Array[Object]]  -->  moduleName:模块名称  modulePaths:路由列表  modulePath:路由地址 
    getToken: 获取token方法 [Function]
    let moduleList = [
      {
        moduleName: "首页",
        modulePaths: ["/home"],
      },
      {
        moduleName: "工商信息",
        modulePath: "/businessInformation",
      }
    ]
  }
*/

export default function observationFunc(
  url,
  projectName,
  moduleList,
  getToken
) {
  // 记录上报时间->时间戳
  let timeStr = new Date().getTime();
  // 定时器超时时间
  let interval = 10 * 60 * 1000;
  // 定时器
  let timeoutTimer = null;
  // 鼠标移动更新定时器间隔时间
  let moveInterval = 1 * 60 * 1000;
  // 记录更新定时器时间->时间戳
  let timerTimeStr = null;
  // 模块名字
  let moduleName = null;

  // 获取当前模块名称
  let getModuleName = () => {
    let pathname = window.location.pathname;
    let module = moduleList?.find((_) => {
      if (_?.modulePaths) {
        return _?.modulePaths?.includes(pathname);
      }
      //互相包含
      return (
        _?.modulePath?.includes(pathname) || pathname?.includes(_?.modulePath)
      );
    });
    return module?.moduleName;
  };

  // 清除超时定时器
  const clearTimer = () => {
    clearTimeout(timeoutTimer);
    timeoutTimer = null;
  };

  // 设置超时定时器
  let setTimer = () => {
    if (timeoutTimer) {
      clearTimer();
    }
    timerTimeStr = new Date().getTime();
    timeoutTimer = setTimeout(() => {
      reportFunc("timeOut");
      clearTimer();
    }, interval);
  };

  // 上报函数
  let reportFunc = (type, notUp) => {
    //退出或者隐藏当前窗口时 超时定时器已经触发则不上报
    if (
      (type === "onbeforeunload" || type === "visibilitychange:hidden") &&
      !timeoutTimer
    ) {
      //不上报
      return;
    }
    let t = new Date().getTime() - timeStr;
    timeStr = new Date().getTime();
    let prevModuleName = moduleName;
    moduleName = getModuleName();
    setTimer();
    if (notUp) {
      //不上报
      return;
    }
    // TODO: 上报操作
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        project_name: projectName,
        module_name: prevModuleName,
        time: t / 1000,
      }),
      headers: {
        authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
  };

  // 对原函数做一个拓展
  let rewriteHis = function (type) {
    let origin = window.history[type];
    return function () {
      let rs = origin.apply(this, arguments);
      let e = new Event(type.toLocaleLowerCase());
      e.arguments = arguments;
      window.dispatchEvent(e);
      return rs;
    };
  };

  window.history.pushState = rewriteHis("pushState");

  window.history.replaceState = rewriteHis("replaceState");

  //设置定时器
  setTimer();
  moduleName = getModuleName();

  window.addEventListener("popstate", () => {
    reportFunc("popstate");
  });

  // 监听鼠标移动事件 若长时间不移动视为待机 1分钟内移动过则刷新超时定时器
  window.addEventListener("mousemove", (e) => {
    let t = new Date().getTime() - timerTimeStr;
    if (t >= moveInterval) {
      setTimer();
    }
    if (!timeoutTimer) {
      reportFunc("tomeout", true);
    }
  });

  // 监听自定义事件， pushstate事件是在rewriteHis时注册的，不是原生事件
  // 当点击router-link 或者 window.history.pushState 或者 this.$router.push 时都会被该事件监听到
  window.addEventListener("pushstate", () => {
    reportFunc("pushstate");
  });

  // 监听自定义事件， replacestate事件是在rewriteHis时注册的，不是原生事件
  // 当点击window.history.replaceState 或者 this.$router.replace 时都会被该事件监听到
  window.addEventListener("replacestate", () => {
    reportFunc("replacestate");
  });

  // 浏览器切换窗口
  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState == "hidden") {
      reportFunc("visibilitychange:hidden");
      clearTimer();
    } else if (document.visibilityState == "visible") {
      reportFunc("visibilitychange:visible", true);
    }
  });

  // 浏览器关闭或离开
  window.onbeforeunload = (e) => {
    reportFunc("onbeforeunload");
    // e = e || window.event;
    // // 兼容IE8和Firefox 4之前的版本
    // if (e) {
    //   e.returnValue = "关闭提示";
    // }
    // // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    // return "关闭提示";
  };
}
