import "./index.scss";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useEffect, useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";

const LegalInstrument = (props) => {
  const { id } = useParams();

  const [tableName, setTableName] = useState(null);
  const [typeName, setTypeName] = useState(null);
  const [state, setState] = useUrlState({});
  const [defaultFilterCriteria, setDefaultFilterCriteria] = useState(null);

  const typeObj = {
    立案信息: "put_on_record",
    开庭公告: "court_session_announcement",
    法院公告: "court_announcement",
    裁判文书: "legal_action",
  };

  useEffect(() => {
    let _type = "立案信息";
    if (state.type) {
      _type = state.type;
    }
    if (!typeObj[_type]) {
      _type = "立案信息";
    }
    if (state.defaultFilterCriteria) {
      setDefaultFilterCriteria(JSON.parse(state.defaultFilterCriteria));
    }
    setTypeName(_type);
    setTableName(typeObj[_type]);
  }, []);

  const changeType = (type) => {
    // setState({ type: undefined, caseNo: undefined });
    setDefaultFilterCriteria(null);
    setTableName(typeObj[type]);
    setTypeName(type);
  };

  return (
    <div className="TableContentBox">
      <div className="infoBox">
        <MyTable
          showHeaderTitle={true}
          typeName={typeName}
          dataTable={tableName}
          isLegalInstrument={true}
          typeObj={typeObj}
          changeType={changeType}
          defaultFilterCriteria={defaultFilterCriteria}
        ></MyTable>
      </div>
    </div>
  );
};
export default LegalInstrument;
