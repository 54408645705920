import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from "antd";
import {
  SwapRightOutlined,
  CalendarOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useHover, useGetState } from "ahooks";
import moment from "moment";

/* 
  suffixIcon: 后缀图标 ReactNode
  prohibitFuture: 是否可以选择未来时间(默认不可以) Boolean 
  format: 返回值 第二项返回的格式 默认 YYYY-MM-DD
  disabled: 禁用
  allowClear: 是否显示清除按钮
  style
*/

const MyRangePicker = (props) => {
  const {
    suffixIcon,
    prohibitFuture,
    onChange,
    format = "YYYY-MM-DD",
    value,
    disabled,
    allowClear,
    style = {},
    notInitValue
  } = props;
  /* 所选时间 */
  const [dateArr, setDateArr, getDateArr] = useGetState([]);
  /* 聚焦元素 */
  const [focusPicker, setFocusPicker] = useState(null);
  /* 元素Ref */
  const pickerContentRef = useRef(null);
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  /* 否有鼠标悬停 */
  const isHovering = useHover(pickerContentRef);
  /* 随机值 */
  const [key, setKey] = useState(Math.random());
  /* 根据外部value实时变化 */
  useEffect(() => {
    if(notInitValue){
      return
    }
    if (value) {
      setDateArr([...value]);
    }
  }, [value]);
  /* 失去焦点方法 */
  const onBlur = (type) => {
    //修改随机值,保证每次弹出的日期表格是最新的 确保 日期组件的 defaultPickerValue 为最新值. (antd日期组件的defaultPickerValue属性是决定日期表格弹窗的日期起始位置的,只有第一次初始化的时候有效,所以需要更新随机值,确保日期组件每次都要重新生成)
    setKey(Math.random());
    //只选择了其中一项,没有选择第二项 清空
    if (type === "startDatePicker" && !getDateArr()[0] && getDateArr()[1]) {
      setDateArr([]);
    }
    if (type === "endDatePicker" && !getDateArr()[1] && getDateArr()[0]) {
      setDateArr([]);
    }
    //清空聚焦元素
    setFocusPicker(null);
  };
  /* 获得焦点 */
  const onFocus = (type) => {
    //修改聚焦元素
    setFocusPicker(type);
  };
  /* 值发生变化 */
  const dateChange = async (date, type) => {
    //开始时间
    if (type === "startDatePicker") {
      await setDateArr([date, getDateArr()[1]]);
      //若结束时间还未选择,自动聚焦结束时间组件,否则取消聚焦元素
      if (!getDateArr()[1]) {
        await startDatePickerRef.current.blur();
        await endDatePickerRef.current.focus();
      } else {
        startDatePickerRef.current.blur();
        setFocusPicker(null);
      }
    }
    //结束时间
    if (type === "endDatePicker") {
      await setDateArr([getDateArr()[0], date]);
      //若开始时间还未选择,自动聚焦开始时间组件,否则取消聚焦元素
      if (!getDateArr()[0]) {
        await endDatePickerRef.current.blur();
        await startDatePickerRef.current.focus();
      } else {
        endDatePickerRef.current.blur();
        setFocusPicker(null);
      }
    }
    //如果开始 结束时间都选择 调取 onDateChange 方法
    if (getDateArr()[0] && getDateArr()[1]) {
      onDateChange();
    }
  };

  /* 日期发生变化的回调 */
  const onDateChange = () => {
    onChange &&
      onChange(
        getDateArr(),
        getDateArr().map((_) => moment(_).format(format))
      );
  };

  return (
    <div
      className={
        disabled
          ? "MyRangePickerContent disMyRangePickerContent"
          : focusPicker
          ? "MyRangePickerContent MyRangePickerContentFocus"
          : "MyRangePickerContent"
      }
      style={{ borderColor: focusPicker ? "#0068B2" : "#d9d9d9", ...style }}
      ref={pickerContentRef}
    >
      {/* 开始时间 */}
      <DatePicker
        key={key + "startDatePicker"}
        ref={startDatePickerRef}
        onBlur={() => {
          onBlur("startDatePicker");
        }}
        onFocus={() => {
          onFocus("startDatePicker");
        }}
        onChange={(date) => {
          dateChange(date, "startDatePicker");
        }}
        disabledDate={(currentDate) => {
          let max = null;
          if (prohibitFuture) {
            max = moment(new Date());
          }
          if (getDateArr()[1]) {
            max = moment.min(moment(new Date()), moment(getDateArr()[1]));
          }
          if (max) {
            return moment(currentDate).diff(moment(max)) > 0;
          }
          return null;
        }}
        className={
          focusPicker === "startDatePicker"
            ? "startDatePicker activePicker"
            : "startDatePicker"
        }
        disabled={disabled}
        placement="bottomLeft"
        open={focusPicker === "startDatePicker"}
        allowClear={false}
        bordered={false}
        suffixIcon={null}
        placeholder="开始时间"
        value={getDateArr()[0]}
        defaultPickerValue={
          getDateArr()[0]
            ? moment(getDateArr()[0])
            : getDateArr()[1]
            ? moment(getDateArr()[1])
            : null
        }
        showToday={false}
        getPopupContainer={triggerNode => (triggerNode.parentNode || document.body)}
      />
      {/* 间隔符号 */}
      <div className="spaceCharacter">
        <SwapRightOutlined />
      </div>
      {/* 结束时间 */}
      <DatePicker
        key={key + "endDatePicker"}
        ref={endDatePickerRef}
        onBlur={() => {
          onBlur("endDatePicker");
        }}
        onFocus={() => {
          onFocus("endDatePicker");
        }}
        onChange={(date) => {
          dateChange(date, "endDatePicker");
        }}
        disabledDate={(currentDate) => {
          let max = null;
          let min = null;
          if (prohibitFuture) {
            max = moment(new Date());
          }
          if (getDateArr()[0]) {
            min = moment(getDateArr()[0]);
          }
          if (max && min) {
            return (
              moment(currentDate).diff(moment(max)) > 0 ||
              moment(currentDate).diff(moment(min)) <= 0
            );
          }
          if (max) {
            return moment(currentDate).diff(moment(max)) > 0;
          }
          if (min) {
            return moment(currentDate).diff(moment(min)) < 0;
          }
          return null;
        }}
        className={
          focusPicker === "endDatePicker"
            ? "endDatePicker activePicker"
            : "endDatePicker"
        }
        disabled={disabled}
        open={focusPicker === "endDatePicker"}
        placement="bottomRight"
        allowClear={false}
        bordered={false}
        suffixIcon={null}
        placeholder="结束时间"
        value={getDateArr()[1]}
        showToday={false}
        defaultPickerValue={
          getDateArr()[1]
            ? moment(getDateArr()[1])
            : getDateArr()[0]
            ? moment.max(moment(getDateArr()[0]), moment(new Date()))
            : null
        }
        getPopupContainer={triggerNode => (triggerNode.parentNode || document.body)}
      />
      {/* 后缀图标 */}
      <div className="suffixIcon">
        {allowClear &&
        isHovering &&
        (getDateArr()[0] || getDateArr()[1]) &&
        !disabled ? (
          <CloseCircleFilled
            className="closeIcon"
            onClick={async () => {
              await setDateArr([]);
              await onDateChange();
            }}
          />
        ) : (
          suffixIcon || <CalendarOutlined />
        )}
      </div>
    </div>
  );
};
export default MyRangePicker;
