// import axios from 'axios'
import axios from "axios";
import {
  message
} from 'antd';
import {
  getToken,
  removeToken,
  setRedirectUrl
} from '../unit/index'
import {
  getLinkValue
} from "../unit/unit";

console.log('---------环境变量:', process.env);

const service = axios.create({
  //默认地址
  baseURL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '/cdd', // process.env.REACT_APP_TEST_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
});


// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 需要token的接口默认添加上token
  if (!config.headers.withOutToken) {
    // 不在header中去掉withOutToken会引发CORS错误
    delete config.headers.withOutToken;
    config.headers['authorization'] = `Bearer ${getToken()}`;
    // config.headers['token'] = `Bearer ${getToken()}`;
  } else {
    delete config.headers.withOutToken;
  }

  // if (config.method === 'get' && config.data) {
  //   config.params = config.data
  // } else {
  //   // config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
  //   // if (Object.prototype.toString.call(config.data) != '[object FormData]') {
  //   //   config.data = qs.stringify(config.data);
  //   // }
  // }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
});

// 添加响应拦截器
service.interceptors.response.use((response) => {
  // 对响应数据做点什么
  if (!response) {
    return Promise.reject(response)
  }
  if (response.data.code === 401 || response.data.code === 501) {
    response.data.message = getLinkValue(response.data.message || '')
    return response.data
  }
  if (response.data.code === -10 || response.data.code === 3) {
    if (response.data.msg) {
      message.error(response.data.msg)
    } else {
      message.error('登录已失效，请重新登录。')
    }
    setTimeout(()=>{
      removeToken();
      if (window.location.pathname !== '/login') {
        setRedirectUrl(window.location.href);
      }
      window.location.href = window.location.origin + `/login?next_url=${window.location.href}`
    },1000)
    return Promise.reject({}) 
  }
  //流文件
  if (response.data instanceof Blob || response.data instanceof ArrayBuffer) {
    return response
  }
  return response.data
}, (error) => {
  if (error && error.response) {
    if (error.response.status === 401) {
      return message.info('当前用户不能查看或操作！');
    } else if (error.response.status >= 500 && error.response.status < 600) {
      message.error({
        content: `服务器错误，请稍后重试，若仍有问题请联系客服！`
      })
    }
  } else if (error && error.request) {
    message.error({
      content: `网络错误，请检查网络连接后重试，若仍有问题请联系客服！`
    })
  } else {
    message.error({
      content: `发生错误，请联系客服!`
    })
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default service