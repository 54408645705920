import "./index.scss";
import { Modal, Table, Button } from "antd";
import { useEffect } from "react";
import MyEmpty from "../../../../components/Empty";
import { useState } from "react";
import { useParams } from "react-router-dom";

const UploadModal = (props) => {
  const { visible, onCancel, onOk, upData } = props;
  const { id } = useParams();

  const [upLoading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "行号",
      dataIndex: "idx",
      key: "idx",
      width: 70,
    },
    {
      title: "发行人名称",
      dataIndex: "company",
      key: "company",
    },

    {
      title: "校验结果",
      key: "fail_msg",
      render: (_, record) => (
        <div style={{ color: "red" }}>{record.fail_msg}</div>
      ),
    },
  ];

  const uploadSuccess = () => {
    onOk();
  };
  return (
    <Modal
      title="上传数据校验"
      wrapClassName="uploadModal"
      visible={visible}
      width={900}
      maskClosable={false}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
      footer={null}
      centered
    >
      <div className="uploadFileModalContent">
        <div className="uploadInfo">
          共读取到
          <span style={{ fontSize: "16px", color: "#313131" }}>
            {upData?.total}
          </span>
          条数据，校验通过
          <span style={{ fontSize: "16px", color: "#61D4AD" }}>
            {upData?.success}
          </span>
          条，校验失败
          <span style={{ fontSize: "16px", color: "#FE545F" }}>
            {upData?.fail}
          </span>
          条
        </div>
        {upData?.fail_data?.length > 0 ? (
          <div className="uploadTip">
            以下数据校验失败，请修改后再重新上传，如果继续上传将忽略以下数据。
          </div>
        ) : null}
        {upData?.fail_data?.length > 0 ? (
          <Table
            className="myFormTable"
            rowClassName="upLoadFileModalTableRow"
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            rowKey={(record) => record.idx}
            bordered={true}
            pagination={false}
            scroll={{
              y: "50vh",
            }}
            columns={columns}
            dataSource={upData?.fail_data || []}
          />
        ) : null}

        <div className="uploadBtnBox">
          <Button className="noBg" onClick={() => onCancel()}>
            暂不上传
          </Button>
          <Button
            type="primary"
            onClick={() => {
              uploadSuccess();
            }}
            loading={loading}
          >
            继续上传
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default UploadModal;
