import "./indes.scss";
import MyEmpty from "../../../../../../components/Empty";
import { useGetState } from "ahooks";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../../apis/project";
import { useParams } from "react-router-dom";
import { Table, message } from "antd";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { isNumber } from "lodash";

const CorporateIncome = (props) => {
  const { yearData, qData, selectCompany } = props;
  const [noData, setNoData] = useState(false);
  const [onlyOneData, setOnlyOneData] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [downLoading, setDownLoading] = useState(false);
  const { id } = useParams();
  const [tableData, setTableData, getTableData] = useGetState([]);
  const [columns, setColumns, getColumns] = useGetState([]);
  const [initType, setInitType, getInitType] = useGetState("年");

  const initData = (data) => {
    const keys = Object.keys(data)
      ?.filter((item) => item !== "name")
      ?.sort((a, b) => {
        if (getInitType() === "年") {
          return b - a;
        }
        const aYear = a?.slice(0, 4);
        const aQ = a?.slice(5, 6);
        const bYear = b?.slice(0, 4);
        const bQ = b?.slice(5, 6);
        if (aYear === bYear) {
          return aQ - bQ;
        }
        return bYear - aYear;
      });
    const columns = [
      {
        title: "项目",
        dataIndex: "xmmc",
        key: "xmmc",
        align: "center",
        fixed: "left",
      },
    ];
    let companysObj = {};

    keys.forEach((year) => {
      let title = year;
      if (getInitType() === "季") {
        title = year?.slice(0, 4);
        if (year?.slice(4, 6) === "Q1") {
          title = title + "第一季度";
        } else if (year?.slice(4, 6) === "Q2") {
          title = title + "第二季度";
        } else if (year?.slice(4, 6) === "Q3") {
          title = title + "第三季度";
        } else if (year?.slice(4, 6) === "Q4") {
          title = title + "第四季度";
        }
      }
      columns.push({
        title,
        dataIndex: year,
        key: year,
        align: "center",
        render: (text) => {
          if (isNumber(text)) {
            return (
              <span>
                {text
                  ? text > 0
                    ? addMillimeter(text, 2)
                    : "-" + addMillimeter(Math.abs(text), 2)
                  : "-"}
              </span>
            );
          } else {
            return text ? text : "-";
          }
        },
      });

      data[year]?.forEach((item) => {
        if (companysObj[item.xmmc]) {
          if (companysObj[item.xmmc][year]) {
            companysObj[item.xmmc][year] =
              companysObj[item.xmmc][year] + item.sum_bys;
          } else {
            companysObj[item.xmmc][year] = item.sum_bys;
          }
        } else {
          let _ = {
            index: item.xmmc_index,
          };
          _[year] = item.sum_bys;
          companysObj[item.xmmc] = _;
        }
      });
    });
    setColumns(columns);
    const list = Object.keys(companysObj)
      ?.map((key) => ({
        ...(companysObj[key] || {}),
        xmmc: key,
      }))
      ?.sort((a, b) => a.index - b.index);
    setTableData(list);
  };

  useEffect(() => {
    if (
      Object.keys(yearData || {})?.length === 0 &&
      Object.keys(qData || {})?.length === 0
    ) {
      setNoData(true);
      return;
    }
    let data = yearData;
    let onlyOneData = false;
    if (Object.keys(yearData || {})?.length === 0) {
      data = qData;
      onlyOneData = true;
    }
    if (Object.keys(qData || {})?.length === 0) {
      data = yearData;
      onlyOneData = true;
    }
    setOnlyOneData(onlyOneData);
    setNoData(false);
    initData(data);
  }, [qData, yearData]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: `企业所得税申报表（${getInitType()}度）`,
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="corporateIncomeContent">
      <div className="headerBox">
        企业所得税申报表
        {!noData && <div className="unitBox">单位：元</div>}
        {!noData &&
          (onlyOneData ? null : (
            <div className="selectBox2">
              <div
                className={
                  initType === "年"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "年") {
                    return;
                  }
                  setInitType("年");
                  setTimeout(() => {
                    initData(yearData);
                  }, 50);
                }}
              >
                年
              </div>
              <div
                className={
                  initType === "季"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "季") {
                    return;
                  }
                  setInitType("季");
                  setTimeout(() => {
                    initData(qData);
                  }, 50);
                }}
              >
                季
              </div>
            </div>
          ))}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>
      {!noData && (
        <div className="corporateIncomeTableBox">
          <Table
            scroll={{
              x: "100%",
            }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            rowKey={() => Math.random()}
            className="myFormTable"
            bordered={true}
            pagination={false}
            columns={columns}
            dataSource={tableData}
          ></Table>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default CorporateIncome;
