import "./index.scss";
import { AutoComplete, Tag, message } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import projectApi from "../../apis/project";
import { useParams } from "react-router-dom";

function NameSearchInput({
  debounceTimeout = 500,
  changeClear = false,
  notInForm,
  placeholder,
  selectChange,
  ...props
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const ref = useRef(null);
  const valueChange = (info) => {
    const [name, id, company_id] = info?.split("&&&&&&&") || [];
    let item = { name };
    if (id) {
      item = options.find((_) => {
        return _.person_id === Number(id) && _.name === name;
      });
    }
    item.value = item.name;
    props.onChange && props.onChange(item);
    if (changeClear) {
      setOptions([]);
    }
  };
  const showCode = (row) => {
    if (row.data_type === 2) {
      return (
        row?.company_name_list?.join("、") +
        (row?.company_name_list.length > 1 ? "等" : "")
      );
    } else if (row.credit_code) {
      return (
        "统一信用号:" +
        row.credit_code +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.org_number) {
      return (
        "统一信用号:" +
        row.org_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.reg_number) {
      return (
        "统一信用号:" +
        row.reg_number +
        "    " +
        "法人:" +
        (row.legal_person_name ? row.legal_person_name : "")
      );
    } else if (row.legal_person_name) {
      return "法人:" + (row.legal_person_name ? row.legal_person_name : "");
    } else {
      return "无详细信息";
    }
  };

  const initTag = (row) => {
    const color = row.data_type === 2 ? "#52c41a" : "#096dd9",
      text = row.data_type === 2 ? "自然人" : "公司";
    return (
      <Tag className="nameTag" color={color}>
        {text}
      </Tag>
    );
  };
  const fetchOptions = (username) => {
    projectApi.getCompanyAndPerson(username, id, 1).then((res) => {
      setLoading(false);
      let list = [];
      if (res.code === 200) {
        list = res.data.map((item) => ({
          ...item,
          label: (
            <div className="addComp">
              <a className="name" title={item.name}>
                {initTag(item)}
                {item.name}
              </a>
              <a className="info" title={showCode(item)}>
                {showCode(item)}
              </a>
            </div>
          ),
          value:
            item.name +
            "&&&&&&&" +
            item.person_id +
            "&&&&&&&" +
            item.company_id,
        }));
      }else{
        message.error(res.message)
      }
      setOptions(list);
    });
  };

  const loadOptions = (value) => {
    if (value?.length < 1) {
      setOptions([]);
      return;
    }
    setLoading(true);
    fetchOptions(value);
  };

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);
  return (
    <>
      <AutoComplete
        className="nameSearchInput"
        ref={ref}
        loading={loading}
        {...props}
        placeholder={placeholder}
        onSearch={debounceFetcher}
        onSelect={(value) => {
          const [name, person_id, company_id] = value?.split("&&&&&&&") || [];
          selectChange &&
            selectChange({
              name,
              person_id,
              company_id,
            });
          setTimeout(() => {
            ref.current.blur();
          }, 300);
        }}
        options={options}
        onChange={valueChange}
        onClear={() => {
          setOptions([]);
        }}
        onFocus={(e) => {
          loadOptions(e.target.value);
        }}
        onBlur={() => {
          setLoading(false);
        }}
        style={{
          width: "100%",
        }}
      ></AutoComplete>
    </>
  );
}

export default NameSearchInput;
