import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  addMillimeter,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message, Select, Typography, Tooltip, Popover } from "antd";
import { useGetState, useUnmount } from "ahooks";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import YearSelect from "../../../YearSelect";
import Scrollbars from "react-custom-scrollbars";
import empPng from "../../../../../../../assets/empC.png";
import TableModal from "../../../TableModal";
import moment from "moment";

const { Paragraph } = Typography;

const PieChart = (props) => {
  const {
    domId,
    companyName,
    initType,
    data,
    style,
    selectYear,
    searchTableFunc,
  } = props;
  const myChart = useRef(null);
  const { id } = useParams();
  const initECharts = (companyName, initType, data) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById(domId);
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const option = {
      title: null,
      // initType === "合并展示"
      //   ? {
      //       text: data.bid_winner,
      //       left: "center",
      //       bottom: "0px",
      //       textStyle: {
      //         color: "#999",
      //         fontWeight: "normal",
      //         fontSize: 14,
      //       },
      //     }
      //   : null,
      legend: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        // confine: true,
        appendToBody: true,
        formatter: (a) => {
          if (initType === "合并展示") {
            return `<div class='pieChartTip'>
              <div class='leftBox'>
                <div>客户</div>
                <div>中标金额</div>
                <div>总招标金额</div>
                <div>份额占比</div>
              </div>
              <div class='rightBox'>
                <div>${a?.data?.info?.bid_winner}</div>
                <div>${
                  addMillimeter(a?.data?.info?.bid_winning_amo, 2) + "元"
                }</div>
                <div>${addMillimeter(a?.data?.info?.total_amo, 2) + "元"}</div>
                <div>${
                  a?.data?.info?.bid_winning_amo
                    ? (
                        (a?.data?.info?.bid_winning_amo /
                          a?.data?.info?.total_amo) *
                        100
                      )?.toFixed(2) + "%"
                    : "-"
                }</div>
              </div>
            </div>`;
          } else {
            return `<div class='pieChartTip'>
            <div class='leftBox'>
              <div>客户</div>
              <div>中标金额</div>
              <div>总招标金额</div>
              <div>份额占比</div>
            </div>
            <div class='rightBox'>
              <div>${a?.data?.item?.bid_winner}</div>
              <div>${
                addMillimeter(a?.data?.item?.bid_winning_amo, 2) + "元"
              }</div>
              <div>${addMillimeter(a?.data?.info?.total_amo, 2) + "元"}</div>
              <div>${
                a?.data?.item?.bid_winning_amo
                  ? (
                      (a?.data?.item?.bid_winning_amo /
                        a?.data?.info?.total_amo) *
                      100
                    )?.toFixed(2) + "%"
                  : "-"
              }</div>
            </div>
          </div>`;
          }
        },
      },
      series: [
        {
          type: "pie",
          // top: "20px",
          center: ["50%", "50%"],
          radius: ["0%", "80%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },

          labelLine: {
            show: false,
          },
          data: [
            {
              value: data?.bid_winning_amo || 0,
              info: data,
              item: data,
              name: "中标金额",
              itemStyle: {
                color: "#61D4AD",
              },
            },
            {
              value: (data?.total_amo || 0) - (data?.bid_winning_amo || 0),
              info: data,
              item: data,
              name: "未中标金额",
              itemStyle: {
                color: "#0068B2",
              },
            },
          ],
        },
      ],
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let filterOptions = {};
      if (initType === "按公司展示") {
        filterOptions = {
          采购人: a?.data?.info?.bid_winner,
          年份: selectYear,
          供应商: companyName,
        };
      } else {
        filterOptions = {
          采购人: a?.data?.info?.bid_winner,
          年份: selectYear,
        };
      }
      TableModal({
        title: "中标详情",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  useEffect(() => {
    initECharts(companyName, initType, data);
  }, [companyName, initType, data]);

  useUnmount(() => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
  });

  if (!data) {
    return (
      <div
        className={
          initType === "合并展示"
            ? "typesProportionItemCanvasBoxBgActive"
            : "typesProportionItemCanvasBoxBg"
        }
        style={{ height: style?.chartSize, width: style?.chartSize }}
      >
        <Popover
          trigger="hover"
          placement="topRight"
          overlayClassName="empPopover"
          content={<div>暂无数据</div>}
        >
          <img src={empPng} alt="暂无数据" />
        </Popover>
      </div>
    );
  }
  return (
    <div
      className="typesProportionItemCanvasBox"
      style={{ height: style?.chartSize, width: style?.chartSize }}
    >
      <div
        id={domId}
        style={{
          width: "100%",
          height: initType === "合并展示" ? "calc(100% - 20px)" : "100%",
        }}
      ></div>
      {initType === "合并展示" && (
        <div className="pieChartTitleBox">
          <Paragraph
            ellipsis={{
              tooltip: data.bid_winner,
              rows: 1,
              expandable: false,
            }}
            style={{
              width: `100%`,
            }}
          >
            {data.bid_winner}
          </Paragraph>
        </div>
      )}
    </div>
  );
};

const TypesProportion = (props) => {
  const { data, checkCompanyList, searchTableFunc, notHasData } = props;
  const { id } = useParams();
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [noData, setNoData] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [initType, setInitType, getInitType] = useGetState("按公司展示");
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const [chartData, setChartData, getChartData] = useGetState([]);

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (notHasData) {
      return;
    }
    setTimeout(() => {
      initData(data);
    }, 100);
  }, [checkCompanyList]);

  const getDataFunc = () => {
    const dataList = [];
    if (getInitType() === "按公司展示") {
      getSelectType()?.forEach((item) => {
        const list = getYearsObj()[getSelectYear()]?.filter(
          (_) => _.bid_winner === item
        );
        if (list?.length === 0) {
          dataList.push([]);
          return;
        }
        let _obj = {};
        list?.forEach((_item) => {
          if (_obj[_item?.tenderee]) {
            _obj[_item?.tenderee].bid_winning_amo =
              _obj[_item?.tenderee].bid_winning_amo + _item.bid_winning_amo;
            _obj[_item?.tenderee].total_amo =
              _obj[_item?.tenderee].total_amo + _item.total_amo;
          } else {
            _obj[_item?.tenderee] = {
              bid_winning_amo: _item.bid_winning_amo,
              total_amo: _item.total_amo,
            };
          }
        });
        let arr = Object.keys(_obj)
          ?.map((key) => ({
            bid_winner: key,
            bid_winning_amo: _obj[key]?.bid_winning_amo,
            total_amo: _obj[key]?.total_amo,
          }))
          ?.sort(
            (a, b) => Number(b.bid_winning_amo) - Number(a.bid_winning_amo)
          )
          ?.slice(0, 3);
        dataList.push(arr);
      });
    } else {
      const list = getYearsObj()[getSelectYear()];
      const _obj = {};
      list?.forEach((_item) => {
        if (_obj[_item?.tenderee]) {
          _obj[_item?.tenderee].bid_winning_amo =
            _obj[_item?.tenderee].bid_winning_amo + _item.bid_winning_amo;
          _obj[_item?.tenderee].total_amo =
            _obj[_item?.tenderee].total_amo + _item.total_amo;
        } else {
          _obj[_item?.tenderee] = {
            bid_winning_amo: _item.bid_winning_amo,
            total_amo: _item.total_amo,
          };
        }
      });
      let _list = Object?.keys(_obj)?.map((key) => ({
        bid_winner: key,
        bid_winning_amo: _obj[key]?.bid_winning_amo,
        total_amo: _obj[key]?.total_amo,
      }));
      _list = _list
        ?.sort((a, b) => Number(b.bid_winning_amo) - Number(a.bid_winning_amo))
        ?.slice(0, 3);
      dataList.push(_list);
    }
    setChartData(dataList);
  };

  const initData = (data) => {
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    let yearsObj = {};
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date]?.push(item);
      } else {
        yearsObj[item.anc_date] = [JSON.parse(JSON.stringify(item))];
      }
    });
    let keys = Object.keys(yearsObj);
    const nowYear = moment(new Date())?.format("YYYY");
    keys = keys.sort((a, b) => Number(b) - Number(a));
    setYearsObj(yearsObj);
    setSelectOptions(keys);
    setSelectYear(nowYear);
    // setSelectType(Object.keys(keyObj));
    // initEchartsData(yearsObj, Object.keys(keyObj));
    setTimeout(() => {
      getDataFunc();
    }, 50);
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "TOP客户中标份额占比情况",
        bid_list: checkCompanyList,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const initStyle = () => {
    const len = (getInitType() === "按公司展示" ? getChartData() : ["全部"])
      ?.length;
    let style = {};
    if (getInitType() === "合并展示") {
      style = {
        width: "calc(100% - 6px)",
        height: "calc(100% - 46px)",
        chartSize: "220px",
      };
    } else {
      style = {
        width: "calc(100% - 6px)",
        height: "calc(100% - 46px)",
        chartSize: "200px",
      };
      if (len === 2) {
        style.width = "calc(50% - 6px)";
        style.height = "calc(100% - 100px)";
        style.chartSize = "124px";
      } else if (len === 3) {
        style.width = "calc(33.3% - 6px)";
        style.height = "calc(100% - 150px)";
        style.chartSize = "76px";
      } else if (len === 4) {
        style.width = "calc(50% - 6px)";
        style.height = "calc(50% - 6px)";
        style.chartSize = "90px";
      } else if (len > 4) {
        style.width = "calc(33.3% - 6px)";
        style.height = "calc(33.3% - 6px)";
        style.chartSize = "66px";
      }
    }

    return style;
  };

  return (
    <div className="typesProportionContent">
      <div className="headerBox">
        <span>
          TOP客户中标份额占比情况
          <Tooltip placement="top" title="未中标金额=客户中标总金额-中标金额">
            <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
          </Tooltip>
        </span>
        {!noData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="selectBox2">
              <div
                className={
                  initType === "按公司展示"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "按公司展示") {
                    return;
                  }
                  setInitType("按公司展示");
                  setTimeout(() => {
                    getDataFunc();
                  }, 50);
                }}
              >
                按公司展示
              </div>
              <div
                className={
                  initType === "合并展示"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "合并展示") {
                    return;
                  }
                  setInitType("合并展示");
                  setTimeout(() => {
                    getDataFunc();
                  }, 50);
                }}
              >
                合并展示
              </div>
            </div>
            <YearSelect
              value={selectYear}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  getDataFunc();
                }, 50);
              }}
              options={selectOptions?.sort((a, b) => Number(a) - Number(b))}
            ></YearSelect>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  />
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div className="typesProportionContentBox">
        {noData ? (
          <MyEmpty></MyEmpty>
        ) : (
          <div className="typesProportionItemBox">
            <div className="echartTipBox">
              <div>
                <i></i>中标金额
              </div>
              <div>
                <i></i>未中标金额
              </div>
            </div>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
              className="typesProportionItemContent"
              // key={Math.random()}
            >
              {(getInitType() === "按公司展示"
                ? getSelectType()
                : ["全部"]
              ).map((item, index) => {
                const style = initStyle();
                return (
                  <div
                    key={index}
                    style={style}
                    className="typesProportionItem"
                  >
                    <div className="pieChartBoxContent">
                      {item !== "全部" && (
                        <div className="pieChartTitleBox">
                          <Paragraph
                            ellipsis={{
                              tooltip: item,
                              rows: 1,
                              expandable: false,
                            }}
                            style={{
                              width: `100%`,
                            }}
                          >
                            {item}
                          </Paragraph>
                        </div>
                      )}

                      <div
                        className="pieChartBox"
                        style={{
                          height: `calc(100% - ${item !== "全部" ? 20 : 0}px)`,
                        }}
                      >
                        {[1, 2, 3]?.map((_item, _index) => (
                          <PieChart
                            key={_index}
                            companyName={item}
                            data={getChartData()?.at(index)?.at(_index)}
                            initType={initType}
                            domId={"typesProportionItem_" + item + "_" + _index}
                            style={style}
                            selectYear={getSelectYear()}
                            searchTableFunc={searchTableFunc}
                          ></PieChart>
                        ))}
                      </div>

                      {/* <PieChart
                      companyName={item}
                      data={getChartData()?.at(index) || {}}
                      initType={initType}
                      id={"typesProportionItem_" + item}
                    ></PieChart> */}
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        )}
      </div>
    </div>
  );
};
export default TypesProportion;
