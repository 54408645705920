import "./index.scss";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Charts from "./components/charts";
import StockRightTable from "./components/table";
import { useParams } from "react-router-dom";
import HeaderTitle from "../../../components/HeaderTitle";

const { TabPane } = Tabs;

const BondEquityRelationship = (props) => {
  const { id } = useParams();

  const tabs = ["股权穿透图", "股权结构"]; //, "主要股东", "对外投资"
  const [selectKey, setSelectKey] = useState("股权穿透图");

  const [uploadDate, setUploadDate] = useState("-");

  const tabChange = (key) => {
    setSelectKey(key);
  };
  return (
    <div className="bondEquityRelationshipContent">
      <HeaderTitle title="股权关系" dataTable='company_investment_information' typeName='股权关系'></HeaderTitle>
      <div className="tabBox">
        <Tabs
          className="myTabs"
          onChange={tabChange}
          destroyInactiveTabPane={true}
          activeKey={selectKey}
          // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
        >
          {tabs.map((key) => (
            <TabPane tab={key} key={key}>
              <div className="dateBox">更新时间：{uploadDate || '-'}</div>
              {key === "股权穿透图" ? (
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "calc(100% - 35px)", width: "100%" }}
                >
                  <div className="infoBox">
                    <Charts setUploadDate={setUploadDate}></Charts>
                  </div>
                </Scrollbars>
              ) : (
                <div style={{ height: "calc(100% - 35px)", width: "100%" }}>
                  <div className="infoBox">
                    <StockRightTable setUploadDate={setUploadDate}></StockRightTable>
                  </div>
                </div>
              )}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};
export default BondEquityRelationship;
