import "./index.scss";
import { Modal, Button, Tooltip } from "antd";
import { VerticalAlignBottomOutlined, MessageFilled } from "@ant-design/icons";
import wrapper from "./wrapper";
import { useState } from "react";
import svg1 from "../../../../../assets/采集数据.svg";
import svg2 from "../../../../../assets/查看数据.svg";
import svg3 from "../../../../../assets/公司授权.svg";
import svg4 from "../../../../../assets/沟通采集授权.svg";
import svg5 from "../../../../../assets/授权链接.svg";

const CreatCollenctionModal = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    onOk && onOk();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <Modal
      title="财税数据采集"
      visible={visible}
      onCancel={handleCancel}
      //   onOk={handleOk}
      wrapClassName="creatCollenctionModal"
      centered
      footer={null}
      width={900}
    >
      <div className="messageModalContent">
        <h3>说明：</h3>
        <span className="textSpan">
          1、财税数据是通过百望股份有限公司从国家税务总局进行数据采集，数据包括：纳税、纳税申报、销项发票和进项发票等数据
        </span>
        <span className="textSpan">2、数据采集需要经过被尽调公司法人或办税人员授权 </span>
        <span className="textSpan">
          3、采集的数据一般为最近24个月（最近的数据可能会因为数据延迟无法采集到，具体以实际采集的数据为准）
        </span>
        <span className="textSpan">
          4、财税数据分析功能依据纳税及发票数据进行统计分析，由于红冲、实际入账情况和无发票等原因，不能代表公司实际经营情况，仅供参考
        </span>
        <h3>采集流程：</h3>
        <div className="flowBox">
          <Tooltip
            title={
              <div className="creatCollenctionModalTip">
                请先与被尽调公司授权人沟通并确认后再发起采集授权
              </div>
            }
            overlayClassName="creatCollenctionModalTooltip"
          >
            <div className="flow1 flowItem">
              <div className="icon">
                <img src={svg4} alt="与被尽调公司沟通采集授权" />
              </div>
              <div className="contentBox">
                <div className="text">与被尽调公司沟通采集授权</div>
                <div className="tip">（线下）</div>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            title={
              <div className="creatCollenctionModalTip">
                采集授权链接可以通过短信直接发送给被尽调公司授权人，也可以下载二维码或复制链接通过微信、邮件等其他方式发送
              </div>
            }
            overlayClassName="creatCollenctionModalTooltip"
          >
          <div className="flow2 flowItem">
            <div className="icon">
              <img src={svg5} alt="发送采集授权链接或二维码" />
            </div>
            <div className="contentBox">
              <div className="text">发送采集授权链接或二维码</div>
              <div className="tip">（线上/线下）</div>
            </div>
          </div>
          </Tooltip>
          
          <Tooltip
            title={
              <div className="creatCollenctionModalTip">
                被尽调公司授权人收到授权链接或二维码后按页面指示完成采集授权
              </div>
            }
            overlayClassName="creatCollenctionModalTooltip"
          >
          <div className="flow3 flowItem">
            <div className="icon">
              <img src={svg3} alt="被尽调公司授权" />
            </div>
            <div className="contentBox">
              <div className="text">被尽调公司授权</div>
            </div>
          </div>
          </Tooltip>

          <Tooltip
            title={
              <div className="creatCollenctionModalTip">
                授权完成后由百望自动开始进行数据采集，采集数据为最近24个月，采集时长一般为1~5天
              </div>
            }
            overlayClassName="creatCollenctionModalTooltip"
          >
          <div className="flow4 flowItem">
            <div className="icon">
              <img src={svg1} alt="采集数据" />
            </div>
            <div className="contentBox">
              <div className="text">采集数据</div>
            </div>
          </div>
          </Tooltip>

          <Tooltip
            title={
              <div className="creatCollenctionModalTip">
                采集完成后对数据进行处理和统计，并在系统面展示数据分析结果
              </div>
            }
            overlayClassName="creatCollenctionModalTooltip"
          >
          <div className="flow5 flowItem">
            <div className="icon">
              <img src={svg2} alt="查看数据分析结果" />
            </div>
            <div className="contentBox">
              <div className="text">查看数据分析结果</div>
            </div>
          </div>
          </Tooltip>
          {/* <a className="downloadBox">
            <VerticalAlignBottomOutlined />
            下载采集授权说明
          </a> */}
          <div className="flowLineBox">
            {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => (
              <li key={item}></li>
            ))}
            <div className="flowLine"></div>
          </div>
        </div>
        <span className="tipSpan">
          重要提醒：数据采集需要被尽调公司法人或办税人员授权，请发起采集授权前与授权人进行沟通并确认同意
        </span>
        <div className="btnBox">
          <Button style={{ marginRight: "24px" }} onClick={handleCancel}>
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            已沟通，立即发起采集授权
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default wrapper(CreatCollenctionModal);
