import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import RegionMap from "./components/RegionMap";
import BidWinningSituation from "./components/BidWinningSituation";
import WinningCompany from "./components/WinningCompany";
import AmountTrend from "./components/AmountTrend";
import ShareProportion from "./components/ShareProportion";
import IncomeSituation from "./components/IncomeSituation";
import projectApi from "../../../../../apis/project";
import { message, Select, Tag, Input, Checkbox, Popover } from "antd";
import { useGetState } from "ahooks";
import {
  ArrayIsEqual,
  arrayContainsAnotherArray,
} from "../../../../../unit/unit";
import MySpin from "../../../../../components/MySpin";
import TypesProportion from "./components/TypesProportion";
import comSvg from "../../../../../assets/公司列表.svg";
import CompanyCard from "../CompanyCard";
import Scrollbars from "react-custom-scrollbars";
import { SearchOutlined, CheckOutlined } from "@ant-design/icons";
import MyEmpty from "../../../../../components/Empty";
import { CaretDownOutlined } from "@ant-design/icons";

const { Option } = Select;

const FasterSelectContent = (props) => {
  const {
    subjectScopeOptions,
    checkCompanyList,
    dataList,
    setCheckCompanyList,
  } = props;

  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([]);
  const [
    relatedPartiesCountObj,
    setRelatedPartiesCountObj,
    getRelatedPartiesCountObj,
  ] = useGetState({});

  const initData = (dataList, checkCompanyList) => {
    let obj = {};
    dataList?.forEach((item) => {
      item?.relatedPartiesType?.forEach((codeItem) => {
        if (obj[codeItem?.r_type]) {
          obj[codeItem?.r_type].push(item?.bid_winner);
        } else {
          obj[codeItem?.r_type] = [item?.bid_winner];
        }
      });
    });
    setRelatedPartiesCountObj(obj);
    let arr = [];
    subjectScopeOptions?.forEach((item) => {
      if (
        arrayContainsAnotherArray(
          obj[item?.dic_item_code] || [],
          checkCompanyList
        )
      ) {
        arr.push(item?.dic_item_code);
      }
    });
    setSubjectScopeList(arr);
  };

  useEffect(() => {
    initData(dataList, checkCompanyList);
  }, [checkCompanyList]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {subjectScopeOptions.map((item, index) => (
        <div
          key={item.dic_item_code}
          value={item.dic_item_code}
          className={
            getSubjectScopeList()?.includes(item.dic_item_code)
              ? "select-item-option-content select-active-item-option-content"
              : "select-item-option-content"
          }
          onClick={() => {
            if (!getRelatedPartiesCountObj()[item.dic_item_code]) {
              return;
            }
            let arr = getSubjectScopeList();
            if (arr?.includes(item.dic_item_code)) {
              arr = arr?.filter((_) => _ !== item.dic_item_code);
            } else {
              arr.push(item.dic_item_code);
            }
            let list = [];
            arr.forEach((code) => {
              list = list.concat(getRelatedPartiesCountObj()[code] || []);
            });
            list = [...new Set(list)];
            if (list?.length === 0) {
              return;
            }
            setCheckCompanyList(list);
          }}
        >
          <div className="selectOptionBox">
            <a className="textItem" title={item.dic_item_name}>
              {index + ". " + item.dic_item_name}
            </a>
            <Tag
              style={
                !relatedPartiesCountObj[item.dic_item_code]?.length > 0
                  ? {
                      color: "#535353",
                      background: "#EFEFEF",
                      borderColor: "#EFEFEF",
                    }
                  : {
                      color: "#fff",
                      background: "#0068B2",
                      borderColor: "#0068B2",
                    }
              }
            >
              {relatedPartiesCountObj[item.dic_item_code]?.length || 0}
            </Tag>
          </div>

          <CheckOutlined className="selectIcon" />
        </div>
      ))}
    </div>
  );
};

const BiddingAnalysis = (props) => {
  const { searchTableFunc } = props;
  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [data, setData] = useState({});
  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dicCodeLoading, setDicCodeLoading] = useState(false);
  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([]);
  const [subjectScopeOptions, setSubjectScopeOptions, getSubjectScopeOptions] =
    useGetState([]);
  const [oldSubjectScopeList, setOldSubjectScopeList, getOldSubjectScopeList] =
    useGetState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllIndeterminate, setCheckAllIndeterminate] = useState(false);
  const [relatedPartiesCountObj, setRelatedPartiesCountObj] = useState({});
  const [checkCompanyList, setCheckCompanyList, getCheckCompanyList] =
    useGetState([]);
  const [searchValue, setSearchValue, getSearchValue] = useGetState(null);

  const initCheckCompanyList = (list) => {
    // 默认筛选1. 尽调公司、控股子公司、控股股东及实际控制人
    let arr = list?.filter((item) =>
      item?.relatedPartiesType?.some((_) =>
        [
          "CDD_SUB_DD_CORP",
          "CDD_SUB_CTRL_SUB_CORP",
          "CDD_SUB_CTRL_SHRHLDR",
        ]?.includes(_?.r_type)
      )
    );
    // 默认筛选2. 法人股东、自然人股东、董监高及其相关人员信息、参股公司
    if (arr?.length === 0) {
      arr = list?.filter((item) =>
        item?.relatedPartiesType?.some((_) =>
          [
            "CDD_SUB_MJR_SHRHLDR_CORP",
            "CDD_SUB_MJR_SHRHLDR_PER",
            "CDD_SUB_SENIOR_MGT",
            "CDD_SUB_INV_CORP",
          ]?.includes(_?.r_type)
        )
      );
    }
    // 默认筛选3. 兄弟公司、控股子公司股东、控股股东及实际控制人董监高、投资及担任董/高
    if (arr?.length === 0) {
      arr = list?.filter((item) =>
        item?.relatedPartiesType?.some((_) =>
          [
            "CDD_SUB_CTRL_OTH_CORP",
            "CDD_SUB_CTRL_SUB_CORP_SHRHLDR",
            "CDD_SUB_CTRL_SHRHLDR_SENIOR_MGT",
            "CDD_SUB_RELATED_PER_CORP",
          ]?.includes(_?.r_type)
        )
      );
    }
    // 默认筛选4. 实质重于形式关联方、历史及未来关联方
    if (arr?.length === 0) {
      arr = list?.filter((item) =>
        item?.relatedPartiesType?.some((_) =>
          [
            "CDD_SUB_FACT_RELATED_PARTY",
            "CDD_SUB_HIS_FUTR_RELATED_PARTY",
          ]?.includes(_?.r_type)
        )
      );
    }
    setCheckCompanyList(arr?.map((_) => _.bid_winner));
  };
  const getData = () => {
    setLoading(true);
    projectApi
      .getBiddingDevelopmentAnalysis({
        project_id: id,
        name: "listed_bid_winner",
        company_id: projectInfo.company_id,
        sub_type:
          getSubjectScopeList()?.length > 0
            ? JSON.stringify(getSubjectScopeList())
            : null,
      })
      .then((res) => {
        if (res.code === 200) {
          console.log("业务发展分析----", res.data);
          setData(res.data);
          setNoData(res.data?.bid_list?.length === 0);
          setUpdateTime(res.data.data_update_time);
          if (checkCompanyList?.length === 0) {
            initCheckCompanyList(res.data.bid_list);
          }
        } else {
          setData({});
          setNoData(true);
          setUpdateTime(null);
          message.error(res.message);
        }
        setLoading(false);
      });
  };

  //获取关联方对应的数量
  const getCodeNumber = () => {
    projectApi
      .getOptionsNumber(id, {
        name: "listed_bid_winner",
        companyId: projectInfo.company_id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelatedPartiesCountObj(res.data);
        } else {
          setRelatedPartiesCountObj({});
        }
      });
  };

  //获取code枚举属性
  const getCodeInfo = () => {
    setLoading(true);
    setDicCodeLoading(true);
    projectApi.getDicCodeInfo("CDD_SUB").then((res) => {
      if (res.code === 200) {
        setSubjectScopeOptions(res.data);
        getCodeNumber();
      } else {
        setSubjectScopeOptions([]);
      }
      setDicCodeLoading(false);
      getData();
    });
  };

  useEffect(() => {
    getCodeInfo();
  }, []);

  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label, value, closable } = props;
    const onClose = async () => {
      await setSubjectScopeList((prev) => {
        prev = prev.filter((_) => _ !== value);
        if (prev.length === 0) {
          setCheckAll(false);
          setCheckAllIndeterminate(false);
        }
        return [...prev];
      });
      await getData(true);
    };
    return (
      <Tag
        // onClose={onClose}
        // closable={closable}
        style={{
          marginRight: 3,
        }}
      >
        {label?.at(0)}
      </Tag>
    );
  };

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="biddingAnalysisContent">
      <div className="dataInput">
        更新时间：{updateTime || "-"}{" "}
        {/* <div className="inputBox">
          <div className="label">主体范围</div>
          <Select
            mode="multiple"
            value={dicCodeLoading ? [] : subjectScopeList}
            allowClear
            style={{ width: "100%" }}
            placeholder="全部"
            showArrow={true}
            bordered={false}
            maxTagCount="responsive"
            tagRender={tagRender}
            filterOption={(inputValue, option) =>
              option.children.includes(inputValue)
            }
            onChange={async (value) => {
              if (value.length === subjectScopeOptions.length) {
                await setCheckAll(true);
                await setCheckAllIndeterminate(false);
              } else {
                await setCheckAllIndeterminate(true);
                await setCheckAll(false);
              }
              if (value.length === 0) {
                await setCheckAllIndeterminate(false);
              }
              await setSubjectScopeList(value);
              // getData(true);
            }}
            onFocus={() => {
              setOldSubjectScopeList(getSubjectScopeList());
            }}
            onBlur={() => {
              if (
                !ArrayIsEqual(getOldSubjectScopeList(), getSubjectScopeList())
              ) {
                getData(true);
              }
            }}
            onClear={async () => {
              setCheckAll(false);
              setCheckAllIndeterminate(false);
              await setSubjectScopeList([]);
              await getData(true);
            }}
            dropdownRender={(menu) => (
              <div className="subjectScopeSearchPoP">
                <Checkbox
                  style={{ margin: "5px", marginLeft: "12px" }}
                  indeterminate={checkAllIndeterminate}
                  checked={checkAll}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSubjectScopeList(
                        subjectScopeOptions.map((_) => _.dic_item_code)
                      );
                    } else {
                      setSubjectScopeList([]);
                    }
                    setCheckAll(e.target.checked);
                    setCheckAllIndeterminate(false);
                    e.stopPropagation();
                  }}
                >
                  全选
                </Checkbox>
                {menu}
              </div>
            )}
          >
            {subjectScopeOptions.map((item, index) => (
              <Option key={item.dic_item_code} value={item.dic_item_code}>
                <a className="textItem" title={item.dic_item_name}>
                  {index + ". " + item.dic_item_name}
                </a>{" "}
                <Tag
                  style={
                    !relatedPartiesCountObj[item.dic_item_code]
                      ? {
                          color: "#535353",
                          background: "#EFEFEF",
                          borderColor: "#EFEFEF",
                        }
                      : {
                          color: "#fff",
                          background: "#0068B2",
                          borderColor: "#0068B2",
                        }
                  }
                >
                  {relatedPartiesCountObj[item.dic_item_code] || 0}
                </Tag>
              </Option>
            ))}
          </Select>
        </div> */}
      </div>

      {/* <div className="lineDiv">
        <i></i>
        <span>业务发展分析</span>
        <i></i>
      </div> */}

      <div className="chartsBox">
        <div className="chartsLeftBox">
          {/* 历年中标公司列表 */}
          <div className="chartBox">
            {/* <WinningCompany
              data={data?.history_bid_list || []}
            ></WinningCompany> */}
            <div className="headerTitleBox">
              <div className="imgTitleBox">
                <img src={comSvg}></img> 公司列表
              </div>
            </div>
            <div className="tableContentBox">
              <div className="searchBox">
                <Input
                  placeholder="输入公司名称搜索"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  prefix={<SearchOutlined />}
                  style={{
                    width: "calc(100% - 106px)",
                    borderRadius: "18px",
                    margin: "0 0px 0px 16px",
                  }}
                ></Input>
                <Popover
                  destroyTooltipOnHide={true}
                  placement="bottomRight"
                  content={
                    <FasterSelectContent
                      subjectScopeOptions={subjectScopeOptions}
                      checkCompanyList={checkCompanyList}
                      dataList={data?.bid_list || []}
                      setCheckCompanyList={setCheckCompanyList}
                    ></FasterSelectContent>
                  }
                  trigger="click"
                  overlayClassName="searchOptionsPopover"
                >
                  <div className="searchItemBox">
                    快速选择
                    <CaretDownOutlined />
                  </div>
                </Popover>
              </div>
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "calc(100% - 50px)", width: "100%" }}
              >
                <div
                  style={{ padding: "0 16px", width: "100%", height: "100%" }}
                >
                  {(data?.bid_list || [])?.filter((_) => {
                    if (!getSearchValue()) {
                      return true;
                    }
                    return _?.bid_winner?.includes(getSearchValue());
                  })?.length !== 0 ? (
                    (data?.bid_list || [])
                      ?.filter((_) => {
                        if (!getSearchValue()) {
                          return true;
                        }
                        return _?.bid_winner?.includes(getSearchValue());
                      })
                      ?.map((_) => ({
                        bid_winner: _.bid_winner,
                        competitive_amount: _.bid_amount,
                        competitive_count: _.bid_count,
                        type: _.relatedPartiesType,
                      }))
                      ?.map((item, index) => (
                        <CompanyCard
                          key={Math.random()}
                          info={item}
                          index={index + 1}
                          type={item.type}
                          noDelete={true}
                          checkCompanyList={checkCompanyList}
                          dataList={(data?.bid_list || [])?.map((_) => ({
                            bid_winner: _.bid_winner,
                            competitive_amount: _.bid_amount,
                            competitive_count: _.bid_count,
                            type: _.relatedPartiesType,
                          }))}
                          setCheckCompanyList={setCheckCompanyList}
                        ></CompanyCard>
                      ))
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MyEmpty></MyEmpty>
                    </div>
                  )}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>

        <div className="chartsRightBox">
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{ height: "100%", width: "100%" }}
          >
            {/* 历年中标情况 */}
            <div className="chartBox">
              <BidWinningSituation
                data={(data?.history_bid_list || [])?.filter((item) =>
                  getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
                notHasData={noData}
              ></BidWinningSituation>
            </div>

            {/* 中标项目按区域分布 */}
            <div className="chartBox">
              <RegionMap
                data={(data?.bid_area_list || [])?.filter((item) =>
                  getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
                notHasData={noData}
              ></RegionMap>
            </div>

            {/* TOP客户中标金额趋势 */}
            <div className="chartBox">
              <AmountTrend
                data={(data?.top_bid_dic || [])?.filter((item) =>
                  getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
                notHasData={noData}
              ></AmountTrend>
            </div>

            {/* 历年中标金额项目类型占比 */}
            <div className="chartBox">
              {/* <IncomeSituation
              data={data?.history_bid_list || []}
            ></IncomeSituation> */}
              <TypesProportion
                data={(data?.big_custom_list || [])?.filter((item) =>
                  getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
                notHasData={noData}
              ></TypesProportion>
            </div>

            {/* TOP3中标项目 */}
            <div className="chartBox">
              <ShareProportion
                data={(data?.big_top3_list || [])?.filter((item) =>
                  getCheckCompanyList()?.includes(item?.bid_winner)
                )}
                checkCompanyList={checkCompanyList}
                searchTableFunc={searchTableFunc}
                notHasData={noData}
              ></ShareProportion>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};
export default BiddingAnalysis;
