import "./index.scss";
import { Descriptions } from "antd";
import MyDiv from "../MyDiv";
import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useParams } from "react-router-dom";
import projectApi from "../../apis/project";
import { message, Tooltip } from "antd";
import MySpin from "../MySpin";
import { showInfoFunc } from "../MyTable";
import { addMillimeter, addMillimeterAndInit } from "../../unit/unit.js";
import img4 from "../../assets/警告@3x.png";
import bondApi from "../../apis/bond/index.js";
const MyDescriptions = (props, ref) => {
  const { dataTable, typeName, dataChange, isBond = false } = props;
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState({});
  const [info, setInfo] = useState([]);
  const [updateTime, setUpdateTime] = useState(null);

  const getData = () => {
    setLoading(true);
    let api = projectApi;
    if(isBond){
      api = bondApi
    }
    api.getDataInfo(dataTable, id).then((res) => {
      if (res.code === 200) {
        setRows(res.data.rows);
        setInfo(res.data.data?.at(0) || {});
        setUpdateTime(res.data.data_update_time);
        dataChange && dataChange(res.data);
      } else {
        message.error(res.message);
        setRows({});
        setInfo([]);
        setUpdateTime(null);
        dataChange && dataChange({});
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  //将 getData方法 暴露给父组件，父组件可以通过ref拿到
  useImperativeHandle(ref, () => ({
    getData,
  }));

  if (loading) {
    return <MySpin></MySpin>;
  }

  console.log(info);
  return (
    <div className="myDescriptionsContent">
      <div className="dateBox">更新时间：{updateTime || "-"}</div>
      <Descriptions bordered className="myDescriptions" column={100}>
        {Object.keys(rows)
          .map((key) => ({ key: key, ...rows[key] }))
          .sort((a, b) => a.index - b.index)
          .map((item) => (
            <Descriptions.Item
              label={item?.name}
              key={item.key}
              span={item?.width}
            >
              <MyDiv
                dataInfo={info}
                info={{
                  ...item,
                  ...info[item.key],
                  id: info.id?.val,
                  key: item.key,
                }}
                getData={getData}
                dataTable={dataTable}
                typeName={typeName}
              >
                {item.click_legal_request ||
                item.click_natural_request ||
                item.click_all ? (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      showInfoFunc(item, info, typeName, id, item.key);
                    }}
                  >
                    {info[item.key]?.val}
                  </a>
                ) : item.need_millimeter ? (
                  info[item.key]?.val ? (
                    addMillimeterAndInit(info[item.key]?.val, 2) +
                    (item.join_field ? info[item.join_field]?.val : "")
                  ) : (
                    "-"
                  )
                ) : (
                  info[item.key]?.val || "-"
                )}
              </MyDiv>
              {info[item.key]?.risk_tips && (
                <Tooltip
                  placement="topRight"
                  title={info[item.key]?.risk_tips}
                  arrowPointAtCenter
                >
                  <div className="warnImgBox">
                    <img src={img4} alt="" />
                  </div>
                </Tooltip>
              )}
            </Descriptions.Item>
          ))}
      </Descriptions>
    </div>
  );
};

export default forwardRef(MyDescriptions);
