import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  isCurrentYear,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
const SalesContribution = (props) => {
  const { data = {}, dataRange, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(false);
  const [type, setType, getType] = useGetState("H1");
  const [dataInfo, setDataInfo, getDataInfo] = useGetState([]);

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const dom = document.getElementById("salesContributionContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: "item",
        formatter: (info) => {
          return `<div><span style='font-weight:bold'>${
            info?.name
          }</span>: ${addMillimeterAndInit(info?.value + "元")}</div>
          <div><span style='font-weight:bold'>占比</span>: ${
            info?.percent
          }%</div>`;
        },
      },
      legend: {
        show: true,
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "10px",
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        data: ["新增客户开票金额", "存量客户开票金额"],
      },
      grid: {
        top: "20px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: [
        {
          // name: "新增客户开票金额",
          type: "pie",
          radius: [0, "80%"],
          labelLine: {
            length: 4,
          },
          label: {
            show: false,
            formatter: (info) => {
              return `{b|${info?.name}}`;
            },
            width: 200,
            rich: {
              b: {
                color: "#4C5058",
                fontSize: 12,
                fontWeight: "bold",
              },
            },
          },

          data: [
            {
              name: "新增客户开票金额",
              value:
                echartsData?.find((item) => item.customer_type === "增量")
                  ?.amount || 0,
              itemStyle: {
                color: "#61D4AD",
              },
            },
            {
              name: "存量客户开票金额",
              value:
                echartsData?.find((item) => item.customer_type === "存量")
                  ?.amount || 0,
              itemStyle: {
                color: "#0068B2",
              },
            },
          ],
        },
      ],
    };
    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data, dataRange) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;

    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }
    let year = moment(time)?.format("YYYY");
    let info2 = [];
    //数据范围为下半年 取上半年的数据展示
    if (diff >= 0) {
      setType("H1");
      if (isCurrentYear(year)) {
        info2 = data[year + "H1"] || [];
      } else {
        info2 = data[year] || [];
      }
    } else {
      year = moment(time).subtract(1, "year")?.format("YYYY");
      info2 = data[year];
      //数据范围为上半年 取前一年的数据展示
      setType(null);
    }

    setSelectYear(year);
    const info = data[year];

    initEcharts(info);

    setDataInfo(info2);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data, dataRange);
  }, [data, dataRange]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "存量和新增客户开票金额贡献",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const initTextDom = () => {
    const increment =
      getDataInfo()?.find((item) => item.customer_type === "增量") || {};
    const stock =
      getDataInfo()?.find((item) => item.customer_type === "存量") || {};

    const incrementAmount = increment?.amount || 0;
    const stockAmount = stock?.amount || 0;
    return (
      <div className="salesContributionTextBox">
        <div className="textBox">
          {selectYear}年
          {type === "H1" && isCurrentYear(selectYear) ? "上半年" : null}
          开票金额中
          <span>
            {incrementAmount > stockAmount ? "增量" : "存量"}
            客户
          </span>
          为主要开票来源，贡献
          <span>
            {incrementAmount > stockAmount
              ? addMillimeterAndInit(incrementAmount + "元")
              : addMillimeterAndInit(stockAmount + "元")}
          </span>
          {(
            incrementAmount > stockAmount
              ? increment?.total_amount
              : stock?.total_amount
          ) ? (
            <>
              占比
              <span>
                {incrementAmount > stockAmount
                  ? (
                      (incrementAmount / Math.abs(increment?.total_amount)) *
                      100
                    ).toFixed(2)
                  : (
                      (stockAmount / Math.abs(stock?.total_amount)) *
                      100
                    ).toFixed(2)}
                %
              </span>
            </>
          ) : null}
          。
        </div>
        <div className="stockBox">
          <div className="title">存量客户</div>
          <div className="text">
            开票金额同比（较{selectYear - 1}年
            {type === "H1" && isCurrentYear(selectYear) ? "上半年" : null}）
            {stock.pct_change < 0 ? "降低" : "增长"}
            <span className={stock.pct_change < 0 ? "lost" : "add"}>
              {stock.pct_change
                ? addMillimeter(Math.abs(stock.pct_change) * 100)
                : "-"}
              %
            </span>
          </div>
        </div>
        <div className="incrementBox">
          <div className="title">增量客户</div>
          <div className="text">
            开票金额同比（较{selectYear - 1}年
            {type === "H1" && isCurrentYear(selectYear) ? "上半年" : null}）
            {increment.pct_change < 0 ? "降低" : "增长"}
            <span className={increment.pct_change < 0 ? "lost" : "add"}>
              {increment.pct_change
                ? addMillimeter(Math.abs(increment.pct_change) * 100)
                : "-"}
              %
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="salesContributionContent">
      <div className="headerBox">
        存量和新增客户开票金额贡献
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="salesContributionContentBox">
          <div id="salesContributionContentBox"></div>
          {initTextDom()}
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default SalesContribution;
