import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  isCurrentYear,
  saveFile,
} from "../../../../../../unit/unit";
import { Popover, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import IconBar from "../../../../../../components/IconBar";

const ExpenditureAnalysisPopulationContent = (props) => {
  const { data = {}, dataRange } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [type, setType, getType] = useGetState("H1");

  const myChart = useRef(null);
  const colorList = useRef(["#0068B2", "#61D4AD", "#FEC054", "#6CAEFF"]);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }

    const dom = document.getElementById("expenditureAnalysisPopulationBox");

    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const amountObj = echartsData?.total_amount || {};
    // amountObj['2023'] = 123123.23;
    // amountObj['2024'] = 23233.23;
    const amountKeys = Object.keys(amountObj)
      ?.filter((_) => !_?.includes("H1") && !_?.includes("H2"))
      ?.sort((a, b) => b - a);

    const option = {
      legend: {
        data: amountKeys,
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: (info) => {
          let str = "<div>进项开票金额</div>";
          info?.forEach((item) => {
            str += `<div>${item?.marker} ${item.seriesName}年   ${Number(
              item.value || 0
            ).toLocaleString("en-US")}元</div>`;
          });
          return str;
        },
      },
      grid: {
        containLabel: true,
        left: 0,
        top: 0,
        right: "60px",
        bottom: 0,
      },
      yAxis: {
        data: ["金额"],
        show: false,
        inverse: false,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 0,
          fontSize: 0,
        },
        axisPointer: {
          label: {
            show: false,
            margin: 0,
          },
        },
      },
      xAxis: {
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
      },
      series: amountKeys?.map((key, index) => ({
        name: key,
        type: "pictorialBar",
        symbolRepeat: true,
        barGap: "28px",
        label: {
          show: true,
          position: "right",
          align: "center",
          // offset: [16, 0],
          distance:
            (Number(amountObj[key] || 0) / 10000)
              .toFixed(2)
              .toLocaleString("en-US")?.length * 5,
          fontSize: 14,
          // width: 1800,
          // borderColor: 'red',
          // borderWidth: 1,
          formatter: (info) => {
            return (Number(info.value || 0) / 10000)
              .toFixed(2)
              .toLocaleString("en-US");
          },
        },
        symbolSize: 22,
        barCategoryGap: "80%",
        // symbolMargin: 2,
        // symbolClip: true,
        // symbolBoundingData: 10000000,
        itemStyle: {
          color: colorList.current[index % 4],
        },
        data: [
          {
            value: amountObj[key],
            symbol:
              "path://M539.428571 539.428571h137.142858a27.428571 27.428571 0 0 1 0 54.857143h-137.142858V731.428571a27.428571 27.428571 0 0 1-54.857142 0v-137.142857H347.428571a27.428571 27.428571 0 0 1 0-54.857143h137.142858v-54.857142h-109.714286a27.428571 27.428571 0 0 1 0-54.857143h70.948571l-66.194285-66.194286a27.794286 27.794286 0 0 1-7.68-26.697143 28.16 28.16 0 0 1 19.748571-19.748571 27.794286 27.794286 0 0 1 26.697143 7.68L512 418.377143l93.622857-93.622857a27.794286 27.794286 0 0 1 26.697143-7.68 28.16 28.16 0 0 1 19.748571 19.748571 27.794286 27.794286 0 0 1-7.68 26.697143l-66.194285 66.194286h70.948571a27.428571 27.428571 0 0 1 0 54.857143h-109.714286zM512 896A384 384 0 1 1 896 512 384 384 0 0 1 512 896z m0-54.857143a329.142857 329.142857 0 1 0-329.142857-329.142857 329.142857 329.142857 0 0 0 329.142857 329.142857z",
          },
        ],
      })),
    };

    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }

    let year =
      diff >= 0
        ? moment(time)?.format("YYYY")
        : moment(time).subtract(1, "year")?.format("YYYY");
    setSelectYear(year);
    //数据范围为下半年 取上半年的数据展示
    if (diff >= 0) {
      setType("H1");
    } else {
      //数据范围为上半年 取前一年的数据展示
      setType(null);
    }
    // initEcharts(data);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "总体情况",
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const initTip = () => {
    if (!data?.total_amount) {
      return;
    }

    const yearKey =
      getSelectYear() +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");
    const lastYearkey =
      getSelectYear() -
      1 +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");

    const lostAmount = (data?.total_amount || {})[lastYearkey] || 0;
    const amount = (data?.total_amount || {})[yearKey];
    const isLost = lostAmount > amount;
    const allAmount = (data?.sale_total_amount || {})[yearKey];
    const lostAllAmount = (data?.sale_total_amount || {})[lastYearkey] || 0;

    const commodityObj = (data?.year_product_amount || {})[yearKey] || {};
    const lostCommodityObj =
      (data?.year_product_amount || {})[lastYearkey] || {};

    const commodityObj2 = (data?.year_product_count || {})[yearKey] || {};

    const commodityTypeObj =
      (data?.year_product_category_amount || {})[yearKey] || {};

    let commodityList = [];
    Object.keys(commodityObj).forEach((key) => {
      commodityList.push({
        name: key,
        amount: commodityObj[key] || 0,
        lostAmount: lostCommodityObj[key] || 0,
        diff: (commodityObj[key] || 0) - (lostCommodityObj[key] || 0),
        number: commodityObj2[key] || 0,
      });
    });

    let commodityTypeList = [];
    Object.keys(commodityTypeObj).forEach((key) => {
      if (commodityTypeObj[key] === 0) {
        return;
      }
      commodityTypeList.push({
        name: key,
        amount: commodityTypeObj[key] || 0,
      });
    });

    let commodityItem =
      commodityList
        ?.sort((a, b) => (isLost ? a.diff - b.diff : b.diff - a.diff))
        ?.at(0) || {};

    const filterZeroCommodityList = commodityList?.filter(
      (item) => item.amount !== 0 && item.number !== 0
    );

    return (
      <>
        <div className="expenditureAnalysisPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            采购支出（进项开票金额）为
            <span className="numberSpan">{addMillimeter(amount)}</span>元
            {lostAmount ? (
              <>
                ，同比（较
                {getSelectYear() - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{isLost ? "下降" : "增长"}
                <span
                  style={{
                    color: isLost ? "#FE545F" : "#61D4AD",
                    fontWeight: "bold",
                  }}
                >
                  {addMillimeter(
                    (Math.abs(amount - lostAmount || 0) /
                      Math.abs(lostAmount)) *
                      100
                  )}
                  %
                </span>
                {isLost ? (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            。其中，{commodityItem?.name}（按开票项目）
            {commodityItem?.diff > 0 ? "增加" : "减少"}
            <span className="numberSpan">
              {addMillimeter(Math.abs(commodityItem?.diff))}
            </span>
            元
            {commodityItem?.lostAmount ? (
              <>
                ，{commodityItem?.diff > 0 ? "增幅" : "降幅"}
                <span className="numberSpan">
                  {commodityItem?.lostAmount
                    ? addMillimeter(
                        (Math.abs(commodityItem?.diff) /
                          Math.abs(commodityItem?.lostAmount)) *
                          100
                      )
                    : "100"}
                  %
                </span>
              </>
            ) : null}
            。
          </div>
        </div>
        {allAmount && (
          <div className="expenditureAnalysisPopulationTipItem">
            <i></i>
            <div>
              {getSelectYear()}年
              {getType() === "H1" && isCurrentYear(getSelectYear())
                ? "上半年"
                : ""}
              采购支出（进项开票金额）占销项开票金额比例
              <span className="numberSpan">
                {addMillimeter((amount / Math.abs(allAmount)) * 100)}%
              </span>
              {lostAmount ? (
                <>
                  ，同比（较
                  {getSelectYear() - 1}年
                  {getType() === "H1" && isCurrentYear(getSelectYear())
                    ? "上半年"
                    : ""}
                  ）
                  {lostAmount / Math.abs(lostAllAmount) -
                    amount / Math.abs(allAmount) >
                  0
                    ? "下降"
                    : "增长"}
                  <span
                    style={{
                      color:
                        lostAmount / Math.abs(lostAllAmount) -
                          amount / Math.abs(allAmount) >
                        0
                          ? "#FE545F"
                          : "#61D4AD",
                      fontWeight: "bold",
                    }}
                  >
                    {addMillimeter(
                      Math.abs(
                        lostAmount / Math.abs(lostAllAmount) -
                          amount / Math.abs(allAmount)
                      ) * 100
                    )}
                    %
                  </span>
                  {lostAmount / Math.abs(lostAllAmount) -
                    amount / Math.abs(allAmount) >
                  0 ? (
                    <>
                      <ArrowDownOutlined
                        style={{ color: "#FE545F", fontSize: "14px" }}
                      />
                    </>
                  ) : (
                    <>
                      <ArrowUpOutlined
                        style={{ color: "#61D4AD", fontSize: "14px" }}
                      />
                    </>
                  )}
                </>
              ) : null}
              。
            </div>
          </div>
        )}

        <div className="expenditureAnalysisPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            公司采购商品（按开票项目）
            <span className="numberSpan">{commodityTypeList?.length}</span>
            种，其中，
            {commodityTypeList
              ?.sort((a, b) => b?.amount - a.amount)
              ?.slice(0, 3)
              ?.map((item, index) => (
                <span key={item.name}>
                  {index === 0 ? null : "、"}
                  {item.name}采购支出
                  <span className="numberSpan">
                    {addMillimeter(item.amount)}
                  </span>
                  元 （占比
                  <span className="numberSpan">
                    {((item.amount / Math.abs(amount)) * 100).toFixed(2) ===
                    "0.00"
                      ? "不足0.01"
                      : ((item.amount / Math.abs(amount)) * 100).toFixed(2)}
                    %
                  </span>{" "}
                  ）
                </span>
              ))}
            。采购次数（按进项开票张数）最高的商品为：
            {filterZeroCommodityList
              ?.sort((a, b) => b?.number - a.number)
              ?.slice(0, 3)
              ?.map((item, index) => (
                <span key={item.name}>
                  {index === 0 ? null : "、"}
                  {item.name}采购次数
                  <span className="numberSpan">{item?.number}</span>次/年
                  （采购支出
                  <span className="numberSpan">
                    {addMillimeter(item.amount)}
                  </span>
                  元 占比
                  <span className="numberSpan">
                    {((item.amount / Math.abs(amount)) * 100).toFixed(2) ===
                    "0.00"
                      ? "不足0.01"
                      : ((item.amount / Math.abs(amount)) * 100).toFixed(2)}
                    %{" "}
                  </span>
                  ）
                </span>
              ))}
            。
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="expenditureAnalysisPopulationContent">
      <div className="headerBox">
        总体情况
        {/* {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )} */}
      </div>

      {!noData && (
        <div className="expenditureAnalysisPopulationContentBox">
          <div className="expenditureAnalysisPopulationBox">
            <div className="expenditureAnalysisPopulationItem">
              <div className="titleBox">进项开票金额（万元）</div>
              {/* <div id="expenditureAnalysisPopulationBox"></div> */}
              <IconBar
                data={data?.total_amount || {}}
                type="amount"
                title="进项开票金额"
                icon={
                  <svg
                    t="1695022393787"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    p-id="4051"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M544 544h160a32 32 0 0 1 0 64h-160v160a32 32 0 0 1-64 0v-160h-160a32 32 0 0 1 0-64h160v-64h-128a32 32 0 0 1 0-64h82.752l-77.248-77.248a32 32 0 1 1 45.248-45.248L512 402.752l109.248-109.248a32 32 0 1 1 45.248 45.248L589.248 416H672a32 32 0 0 1 0 64h-128v64z m-32 416C264.576 960 64 759.424 64 512S264.576 64 512 64s448 200.576 448 448-200.576 448-448 448z m0-64c212.064 0 384-171.936 384-384S724.064 128 512 128 128 299.936 128 512s171.936 384 384 384z"
                      fill="#333333"
                      p-id="4052"
                    ></path>
                  </svg>
                }
              ></IconBar>
            </div>

            <div className="expenditureAnalysisPopulationItem">
              <div className="titleBox">供应商数量</div>

              <div className="numberBox">
                <Popover
                  trigger="hover"
                  placement="rightBottom"
                  overlayClassName="expenditureAnalysisPopulationPopover"
                  content={
                    <div className="expenditureAnalysisPopulationPopoverBox">
                      <div className="title">供应商数量</div>
                      {Object?.keys(data?.total_custom || {})
                        ?.sort((a, b) => b - a)
                        ?.map((key) => (
                          <div key={key} className="text">
                            <span>{key}</span>
                            {"  "}
                            {Number(
                              data?.total_custom[key] || 0
                            )?.toLocaleString() + "家"}
                          </div>
                        ))}
                    </div>
                  }
                >
                  {data?.total_custom &&
                    Number(
                      data?.total_custom[getSelectYear()] || 0
                    )?.toLocaleString()}
                </Popover>
              </div>
            </div>

            <div className="expenditureAnalysisPopulationItem">
              <div className="titleBox">采购商品种类</div>
              <div className="numberBox">
                <Popover
                  trigger="hover"
                  placement="rightBottom"
                  overlayClassName="expenditureAnalysisPopulationPopover"
                  content={
                    <div className="expenditureAnalysisPopulationPopoverBox">
                      <div className="title">采购商品种类</div>
                      {Object?.keys(data?.total_product || {})
                        ?.sort((a, b) => b - a)
                        ?.map((key) => (
                          <div key={key} className="text">
                            <span>{key}</span>
                            {"  "}
                            {Number(
                              data?.total_product[key] || 0
                            )?.toLocaleString() + "种"}
                          </div>
                        ))}
                    </div>
                  }
                >
                  {(
                    data?.total_product &&
                    Number(data?.total_product[getSelectYear()] || 0)
                  )?.toLocaleString()}
                </Popover>
              </div>
            </div>
          </div>
          {initTip()}
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default ExpenditureAnalysisPopulationContent;
