import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MehFilled } from "@ant-design/icons";
import { useEffect, useState, useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { menuContext } from "../../Home";
import routers from "../../router/router";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

const MySidebar = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState();

  let menuList = useSelector((state) => state.Project.menuList) || [];

  const findMenu = (url) => {
    for (let i = 0; i < menuList.length; i++) {
      let item = menuList[i];
      if (item.menu_url === url) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        let obj = item.children.find((_item) => _item.menu_url === url);
        if (obj) {
          return obj;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    let obj = findMenu(location.pathname);
    setSelectedMenu(obj);
  }, [location, menuList]);

  const initMenuList = (menuList) => {
    menuList = JSON.parse(JSON.stringify(menuList));
    menuList =
      menuList?.filter((item) => {
        if (item.function_name === "概览") {
          return true;
        } else {
          return (item.children?.filter((_) => !_?.disable) || [])?.length > 0;
        }
      }) || [];
    menuList =
      menuList?.map((item) => {
        item.children = item.children?.filter((_) => !_?.disable) || [];
        return item;
      }) || [];
    return menuList;
  };
  return (
    <div className="mySidebarContent">
      <Scrollbars
        className="scrollbars"
        autoHide={true}
        autoHideTimeout={5000}
        style={{ height: "calc(100% - 40px)", width: "100%", margin: "20px 0" }}
      >
        <div className="sidebarBox">
          {initMenuList(menuList).map((item) => (
            <div key={item.id} className="sidebarItem">
              <div
                key={item.id}
                className="sidebarTitle"
                onClick={() => {
                  item.menu_url && navigate(item.menu_url);
                }}
              >
                <span className="titleIcon">
                  <ReactSVG src={item.full_logo_url} />
                  {/* {item.icon} */}
                </span>
                {item.function_name}
              </div>
              {item.children &&
                item.children.map((item) => (
                  <div
                    key={item.id}
                    className={
                      item.disable
                        ? "disChildren"
                        : selectedMenu?.id === item.id
                        ? "sidebarChildren activeMenu"
                        : "sidebarChildren"
                    }
                    onClick={() => {
                      !item.disable && navigate(item.menu_url);
                    }}
                  >
                    {item.function_name}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};
export default MySidebar;
