import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import { guid, showTipFunc } from "../../../unit/unit.js";
import { useState, useEffect, useRef } from "react";
import { Button, message, Tabs } from "antd";
import { useSelector } from "react-redux";
import MySpin from "../../../components/MySpin";
import { useGetState } from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";
import projectApi from "../../../apis/project";
import MyDiv from "../../../components/MyDiv";
import CreateCollection from "../CreateCollection";
import SalesAndCustomerAnalysis from "./components/SalesAndCustomerAnalysis";
import ExpenditureAnalysis from "./components/ExpenditureAnalysis";
import BillDetails from "./components/BillDetails";
import { SearchOutlined } from "@ant-design/icons";
import CollenctionInfoDrawer from "../CreateCollection/components/CollenctionInfoDrawer";

const { TabPane } = Tabs;

const BusinessAnalysis = (props) => {
  const { id } = useParams();
  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );
  const myInfo = useSelector((state) => state.User.userInfo);
  const projectInfo = useSelector((state) => state.Project.projectInfo);
  const [loading, setLoading] = useState(false);
  const [type, setType, getType] = useGetState("");
  const [state, setState] = useUrlState({});
  const [dataInfo, setDataInfo] = useState({});
  const [showHeader, setShowHeader] = useState(true);
  const [noData, setNoData] = useState(true);

  const [collectionCompanyList, setCollectionCompanyList] = useState([]);
  const [selectCompany, setSelectCompany, getSelectCompany] = useGetState(null);

  const tabs = useRef({
    销项票据及客户分析: "sale_cus_analysis",
    进项票据及供应商分析: "buy_supper_analysis",
    票据详情: "bill_details",
  });

  const codeRef = useRef({
    销项票据及客户分析: "CDD_CONCERN_SALE_CUS_ANA",
    进项票据及供应商分析: "CDD_CONCERN_BUY_SUP_ANA",
    票据详情: "CDD_CONCERN_BILL_INFO",
  });

  const typeNameList = {
    销项票据及客户分析: "销项票据及客户分析",
    进项票据及供应商分析: "进项票据及供应商分析",
    票据详情: "票据详情",
  };

  useEffect(() => {
    let _type = "销项票据及客户分析";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
    }
    setType(_type);
    getSpecialFocusRemark(_type);
    getDataFunc();
  }, []);

  //获取是否有采集信息
  const getDataFunc = () => {
    setLoading(true);

    projectApi.getCollectionCompanyList(id).then((res) => {
      if (res.code === 200) {
        setCollectionCompanyList(res.data?.analysis_company_list);
        setNoData(res.data?.analysis_company_list?.length === 0);
        setSelectCompany(res.data?.analysis_company_list?.at(0)?.company_id);
      } else {
        message.error(res.message);
        collectionCompanyList([]);
        setNoData(true);
        setSelectCompany(null);
      }
      setLoading(false);
    });
  };

  //获取批注关注接口
  const getSpecialFocusRemark = (type) => {
    projectApi
      .getSpecialFocusRemark(id, {
        table_name: tabs.current[type],
        event_type_code: codeRef.current[type],
      })
      .then((res) => {
        if (res.code === 200) {
          setDataInfo({
            event_type_remark: res.data?.event_type_remark,
          });
        } else {
          message.error(res.message);
          setDataInfo({});
        }
      });
  };

  //tab窗口变化回调
  const tabChange = (key) => {
    setType(key);
    getSpecialFocusRemark(key);
  };

  //查看进度
  const handleFunc = () => {
    CollenctionInfoDrawer({
      projectId: id,
      myInfo,
      projectInfo,
    });
  };

  //是否显示 尽调中数据不全提示信息
  const showTip = showTipFunc(projectProgressCode, "经营分析");

  return (
    <div className="businessAnalysisContent">
      <HeaderTitle title="经营分析" showTip={showTip}></HeaderTitle>
      {loading ? (
        <MySpin></MySpin>
      ) : noData ? (
        <CreateCollection urlType='经营分析'></CreateCollection>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            <TabPane
              tab={
                type === "销项票据及客户分析" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["销项票据及客户分析"]}
                    typeName={typeNameList["销项票据及客户分析"]}
                    getData={() => {
                      getSpecialFocusRemark("销项票据及客户分析");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"销项票据及客户分析"}
                  </MyDiv>
                ) : (
                  "销项票据及客户分析"
                )
              }
              key={"销项票据及客户分析"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <SalesAndCustomerAnalysis
                    selectCompany={selectCompany}
                    setSelectCompany={setSelectCompany}
                    collectionCompanyList={collectionCompanyList}
                  ></SalesAndCustomerAnalysis>
                </div>
              </Scrollbars>
            </TabPane>
            <TabPane
              tab={
                type === "进项票据及供应商分析" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["进项票据及供应商分析"]}
                    typeName={typeNameList["进项票据及供应商分析"]}
                    getData={() => {
                      getSpecialFocusRemark("进项票据及供应商分析");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"进项票据及供应商分析"}
                  </MyDiv>
                ) : (
                  "进项票据及供应商分析"
                )
              }
              key={"进项票据及供应商分析"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <ExpenditureAnalysis
                    selectCompany={selectCompany}
                    setSelectCompany={setSelectCompany}
                    collectionCompanyList={collectionCompanyList}
                  ></ExpenditureAnalysis>
                </div>
              </Scrollbars>
            </TabPane>
            <TabPane
              tab={
                type === "票据详情" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["票据详情"]}
                    typeName={typeNameList["票据详情"]}
                    getData={() => {
                      getSpecialFocusRemark("票据详情");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"票据详情"}
                  </MyDiv>
                ) : (
                  "票据详情"
                )
              }
              key={"票据详情"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <BillDetails
                    selectCompany={selectCompany}
                    collectionCompanyList={collectionCompanyList}
                    setSelectCompany={setSelectCompany}
                  ></BillDetails>
                </div>
              </Scrollbars>
            </TabPane>
            {/* {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        function: ["comments", "remark"],
                        id: guid(),
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        getSpecialFocusRemark(key);
                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="infoBox">
                    {type === "销项票据及客户分析" && (
                      <SalesAndCustomerAnalysis></SalesAndCustomerAnalysis>
                    )}
                    {type === "进项票据及供应商分析" && (
                      <ExpenditureAnalysis></ExpenditureAnalysis>
                    )}
                    {type === "票据详情" && <BillDetails></BillDetails>}
                  </div>
                </Scrollbars>
              </TabPane>
            ))} */}
          </Tabs>
          <div className="topBtnBox">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleFunc}
            >
              查看采集进度
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default BusinessAnalysis;
