import "./index.scss";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MyTable from "../../../components/MyTable";
import projectApi from "../../../apis/project";
import { message } from "antd";
import MySpin from "../../../components/MySpin";
import Scrollbars from "react-custom-scrollbars";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import MyEmpty from "../../../components/Empty";

const HistoricalEvolution = (props) => {
  const dataTable = "listed_comphistory_original";
  const typeName = "历史沿革";
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const getDataFunc = () => {
    setLoading(true);
    projectApi.getDataInfo(dataTable, id).then((res) => {
      if (res.code === 200) {
        setData(res.data);
      } else {
        message.error(res.message);
        setData({});
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getDataFunc();
  }, []);

  const dataChange = (res) => {
    setTableData(res.data);
  };

  useEffect(() => {
    setIsEmpty(data?.data?.length === 0 && tableData?.length === 0);
  }, [data, tableData]);
  
  //js实现复制到剪贴板，带格式复制
  const selectElementContents = (el) => {
    var body = document.body,
      range,
      sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();

      sel = window.getSelection();

      sel.removeAllRanges();

      try {
        range.selectNodeContents(el);

        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);

        sel.addRange(range);
      }
      document.execCommand("copy");
      //取消文本选中状态
      window.getSelection().empty();
      message.success("成功复制到剪贴板！");
      // alert("成功复制到剪贴板");
    }
  };

  return (
    <div className="HistoricalEvolutionContent">
      <div className="infoBox">
        <CommentsHeaderTitle
          title={typeName}
          dataTable={dataTable}
          typeName={typeName}
        ></CommentsHeaderTitle>
        {isEmpty ? (
          <div
            style={{
              width: "100%",
              height: "calc(100% - 30px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MyEmpty></MyEmpty>
          </div>
        ) : (
          <>
            <div className="titleTextBox">
              {loading ? (
                <MySpin></MySpin>
              ) : (
                <>
                  <div>更新时间：{data?.data_update_time || "-"}</div>
                  <div className="titleTextContent">
                    <div className="iconBox">
                      <Tooltip
                        placement="top"
                        title={copySuccess ? "复制成功" : "复制"}
                        arrowPointAtCenter
                      >
                        {copySuccess ? (
                          <CheckOutlined className="copySuccessIcon" />
                        ) : (
                          <CopyOutlined
                            className="copyIcon"
                            onClick={()=>{selectElementContents( document.getElementById('copyDom') );}}
                          />
                        )}
                      </Tooltip>
                    </div>
                    <Scrollbars
                      autoHide={true}
                      autoHideTimeout={5000}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <div style={{ padding: "24px" }} id="copyDom">
                        {(data?.data || [])?.length === 0 ? (
                          <div style={{ color: "#9E9E9E" }}>暂无历史沿革</div>
                        ) : (
                          (data?.data || []).map((item) => (
                            <div key={item.id?.val} className="titleTextItem">
                              <div className="titleTextItemTitle">
                                {item.history_event_title?.val}
                              </div>
                              <div className="titleTextItemText">
                                {item.history_event_original?.val}
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </Scrollbars>
                  </div>
                </>
              )}
            </div>
            <div className="myTableBox">
              <MyTable
                dataChange={dataChange}
                typeName={typeName}
                dataTable={"listed_comphistory_res"}
              ></MyTable>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default HistoricalEvolution;
