import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import { guid, showTipFunc } from "../../../unit/unit.js";
import { useState, useEffect, useRef } from "react";
import { message, Tabs, Button } from "antd";
import { useSelector } from "react-redux";
import MySpin from "../../../components/MySpin";
import { useGetState } from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";
import projectApi from "../../../apis/project";
import MyDiv from "../../../components/MyDiv";
import CreateCollection from "../CreateCollection";
import { SearchOutlined } from "@ant-design/icons";
import CollenctionInfoDrawer from "../CreateCollection/components/CollenctionInfoDrawer";
import Overview from "./components/Overview";
import TaxDeclaration from "./components/TaxDeclaration";
import TaxRisk from "./components/TaxRisk";
const { TabPane } = Tabs;

const TaxAnalysis = (props) => {
  const { id } = useParams();
  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );const myInfo = useSelector((state) => state.User.userInfo);
  const projectInfo = useSelector(
    (state) => state.Project.projectInfo
  );
  const [loading, setLoading] = useState(true);
  const [type, setType, getType] = useGetState("");
  const [state, setState] = useUrlState({});
  const [dataInfo, setDataInfo] = useState({});
  const [showHeader, setShowHeader] = useState(true);
  const [noData, setNoData] = useState(true);
  const [collectionCompanyList, setCollectionCompanyList] = useState([]);
  const [selectCompany, setSelectCompany, getSelectCompany] = useGetState(null);

  const tabs = useRef({
    总览: "tax_summary_analysis",
    纳税申报: "tax_return_analysis",
    税务风险: "tax_risk_analysis",
  });

  const codeRef = useRef({
    总览: "CDD_CONCERN_TAX_SUMMARY",
    纳税申报: "CDD_CONCERN_TAX_RETURN",
    税务风险: "CDD_CONCERN_TAX_RISK",
  });

  const typeNameList = {
    总览: "总览",
    纳税申报: "纳税申报",
    税务风险: "税务风险",
  };

  useEffect(() => {
    let _type = "总览";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
      if(state.type === '税务分析总览'){
        _type = "总览"
      }
    }
    setType(_type);
    getSpecialFocusRemark(_type);
    getDataFunc();
  }, []);

  //获取是否有采集信息
  const getDataFunc = () => {
    setLoading(true);

    projectApi.getCollectionCompanyList(id).then((res) => {
      if (res.code === 200) {
        setCollectionCompanyList(res.data?.analysis_company_list);
        setNoData(res.data?.analysis_company_list?.length === 0);
        setSelectCompany(res.data?.analysis_company_list?.at(0)?.company_id);
      } else {
        message.error(res.message);
        collectionCompanyList([]);
        setNoData(true);
        setSelectCompany(null);
      }
      setLoading(false);
    });
  };

  //获取批注关注接口
  const getSpecialFocusRemark = (type) => {
    projectApi
      .getSpecialFocusRemark(id, {
        table_name: tabs.current[type],
        event_type_code: codeRef.current[type],
      })
      .then((res) => {
        if (res.code === 200) {
          setDataInfo({
            event_type_remark: res.data?.event_type_remark,
          });
        } else {
          message.error(res.message);
          setDataInfo({});
        }
      });
  };

  //tab窗口变化回调
  const tabChange = (key) => {
    setType(key);
    getSpecialFocusRemark(key);
  };

  //查看进度
  const handleFunc = () => {
    CollenctionInfoDrawer({
      projectId: id,
      myInfo,
      projectInfo,
    });
  };

  //是否显示 尽调中数据不全提示信息
  const showTip = showTipFunc(projectProgressCode, "税务分析");

  return (
    <div className="taxAnalysisContent">
      <HeaderTitle title="税务分析" showTip={showTip}></HeaderTitle>
      {loading ? (
        <MySpin></MySpin>
      ) : noData ? (
        <CreateCollection
          onSuccess={() => {
            setNoData(false);
          }}
          urlType='税务分析'
        ></CreateCollection>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            <TabPane
              tab={
                type === "总览" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["总览"]}
                    typeName={'税务分析总览'}
                    getData={() => {
                      getSpecialFocusRemark("总览");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"总览"}
                  </MyDiv>
                ) : (
                  "总览"
                )
              }
              key={"总览"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <Overview
                    collectionCompanyList={collectionCompanyList}
                    selectCompany={selectCompany}
                    setSelectCompany={setSelectCompany}
                  ></Overview>
                </div>
              </Scrollbars>
            </TabPane>
            <TabPane
              tab={
                type === "纳税申报" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["纳税申报"]}
                    typeName={typeNameList["纳税申报"]}
                    getData={() => {
                      getSpecialFocusRemark("纳税申报");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"纳税申报"}
                  </MyDiv>
                ) : (
                  "纳税申报"
                )
              }
              key={"纳税申报"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <TaxDeclaration
                    collectionCompanyList={collectionCompanyList}
                    selectCompany={selectCompany}
                    setSelectCompany={setSelectCompany}
                  ></TaxDeclaration>
                </div>
              </Scrollbars>
            </TabPane>
            <TabPane
              tab={
                type === "税务风险" && showHeader ? (
                  <MyDiv
                    info={{
                      ...dataInfo,
                      comments: dataInfo?.event_type_remark,
                      function: ["comments", "remark"],
                      id: guid(),
                    }}
                    dataTable={tabs.current["税务风险"]}
                    typeName={typeNameList["税务风险"]}
                    getData={() => {
                      getSpecialFocusRemark("税务风险");
                      setShowHeader(false);
                      setTimeout(() => {
                        setShowHeader(true);
                      }, 100);
                    }}
                    mode={30}
                    dataInfo={{}}
                  >
                    {"税务风险"}
                  </MyDiv>
                ) : (
                  "税务风险"
                )
              }
              key={"税务风险"}
            >
              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="infoBox">
                  <TaxRisk
                    collectionCompanyList={collectionCompanyList}
                    selectCompany={selectCompany}
                    setSelectCompany={setSelectCompany}
                  ></TaxRisk>
                </div>
              </Scrollbars>
            </TabPane>
            {/* {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        function: ["comments", "remark"],
                        id: guid(),
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        getSpecialFocusRemark(key);
                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="infoBox">
                    {type === "总览" && <Overview collectionCompanyList={collectionCompanyList}></Overview>}
                    {type === "纳税申报" && <TaxDeclaration collectionCompanyList={collectionCompanyList}></TaxDeclaration>}
                    {type === "税务风险" && <TaxRisk collectionCompanyList={collectionCompanyList}></TaxRisk>}
                  </div>
                </Scrollbars>
              </TabPane>
            ))} */}
          </Tabs>
          <div className="topBtnBox">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleFunc}
            >
              查看采集进度
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default TaxAnalysis;
