import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { message, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../../../components/MySpin";
import { useGetState } from "ahooks";
import projectApi from "../../../../../apis/project";
import moment from "moment";
import ProductComposition from "./ProductComposition";
import ComparisonOfExpensesAndIncome from "./ComparisonOfExpensesAndIncome";
import ExpenditureAreaSituation from "./ExpenditureAreaSituation";
import TopPurchase from "./TopPurchase";
import SalesContribution from "./SalesContribution";
import IncreaseAndLossSituation from "./IncreaseAndLossSituation";
import ProductProcurementSituation from "./ProductProcurementSituation";
import ExpenditureAnalysisPopulationContent from "./ExpenditureAnalysisPopulationContent";

const { Option } = Select;

const ExpenditureAnalysis = (props) => {
  const { collectionCompanyList, selectCompany, setSelectCompany } = props;

  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const [dataRange, setDataRange] = useState(null);

  // const [selectCompany, setSelectCompany, getSelectCompany] = useGetState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getDataFunc = () => {
    setLoading(true);
    projectApi?.getBusinessBuyAnalysis(id, selectCompany).then((res) => {
      console.log("-----采购支出及供应商分析:", res);
      if (res.code === 200) {
        setData(res?.data || {});
        setDataRange(res?.data?.data_date_scope);
        setUpdateTime(res?.data?.data_update_time);
      } else {
        message.error(res.message);
        setData({});
        setDataRange(null);
        setUpdateTime(null);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (collectionCompanyList?.length === 0) {
      return;
    }
    // setSelectCompany(collectionCompanyList?.at(0)?.company_id);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  }, [collectionCompanyList, selectCompany]);

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="expenditureAnalysisContent">
      <div className="dataInput">
        <span>
          采集时间：
          {updateTime ? moment(updateTime)?.format("YYYY-MM-DD hh:mm:ss") : "-"}
        </span>
        <span style={{ marginLeft: "24px" }}>数据范围：{dataRange || "-"}</span>
        <div className="inputBox">
          <div className="label">分析主体</div>
          <Select
            value={selectCompany}
            style={{ width: "100%" }}
            showArrow={true}
            bordered={false}
            onChange={(value) => {
              setSelectCompany(value);
              // setTimeout(() => {
              //   getDataFunc();
              // }, 50);
            }}
          >
            {collectionCompanyList?.map((item) => (
              <Option key={item.company_id} value={item.company_id}>
                {item.company_name}
              </Option>
            ))}
          </Select>
        </div>
        <Tooltip
          placement="left"
          title={
            <div className="collenctionTip">
              <h3>说明：</h3>
              <span>
                1、财税数据是通过百望股份有限公司从国家税务总局进行数据采集。
              </span>
              <span>
                2、采集的数据为最近36个月（最近1个月的数据可能会因为数据延迟无法采集到），数据包括：纳税、纳税申报、销项发票和进项发票等数据。
              </span>
              <span>
                3、财税数据分析功能依据纳税及发票数据进行统计分析，由于红冲、实际入账情况和无发票等原因，不能代表公司实际经营情况，仅供参考。
              </span>
            </div>
          }
          overlayClassName="collenctionTooltip"
        >
          <ExclamationCircleOutlined className="infoIcon" />
        </Tooltip>
      </div>
      <div className="chartsContentBox">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          <div className="chartsContent">
            {/* 总体情况 */}
            <div className="wholeRowBox">
              <ExpenditureAnalysisPopulationContent
                data={data?.procure_summary || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></ExpenditureAnalysisPopulationContent>
            </div>
            {/* 进项与销项开票金额对比 */}
            <div className="halfRowBox">
              <ComparisonOfExpensesAndIncome
                data={data?.buy_sale_compare || {}}
                selectCompany={selectCompany}
              ></ComparisonOfExpensesAndIncome>
            </div>
            {/* 采购商品构成 */}
            <div className="halfRowBox">
              <ProductComposition
                dataRange={dataRange}
                data={data?.buy_form || {}}
                selectCompany={selectCompany}
              ></ProductComposition>
            </div>
            {/* 采购支出区域情况 */}
            <div className="wholeRowBox">
              <ExpenditureAreaSituation
                data={data?.spread_info || {}}
                selectCompany={selectCompany}
              ></ExpenditureAreaSituation>
            </div>
            {/* 主营商品采购及供应商情况 */}
            <div className="wholeRowBox">
              <ProductProcurementSituation
                frequencyData={data?.buy_supplier_number || {}}
                timeData={data?.buy_supplier_q || {}}
                selectCompany={selectCompany}
              ></ProductProcurementSituation>
            </div>
            {/* TOP采购供应商 */}
            <div className="halfRowBox">
              <TopPurchase
                data={data?.buy_supplier_number || {}}
                selectCompany={selectCompany}
              ></TopPurchase>
            </div>
            {/* 存量和新增供应商采购支出构成 */}
            <div className="halfRowBox">
              <SalesContribution
                data={data?.stock_add_per || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></SalesContribution>
            </div>
            {/* 供应商新增及流失情况 */}
            <div className="wholeRowBox">
              <IncreaseAndLossSituation
                increaseData={data?.increase_super_info || {}}
                leaveData={data?.leave_super_info || {}}
                dataRange={dataRange}
                selectCompany={selectCompany}
              ></IncreaseAndLossSituation>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};
export default ExpenditureAnalysis;
