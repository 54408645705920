import './index.scss';
import { Empty } from "antd";
import img from "../../assets/暂无icon@2x.png";
const MyEmpty = (props) => {
  const { description } = props;
  return (
    <div className="emptyContent">
      <Empty image={img} description={description} />
    </div>
  );
};

export default MyEmpty;
