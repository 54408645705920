import ProjectInfo from "../ProjectInfo";
import BusinessInformation from "../BusinessInformation";
import KeyPersonnel from "../KeyPersonnel";
import EquityRelationship from "../EquityRelationship";
import JudicialCases from "../JudicialCases";
import ReportManagement from "../ReportManagement";
import MajorShareholders from "../MajorShareholders";
import SuspectedRelatedParty from '../SuspectedRelatedParty';
import TableContent from "../TableContent";
import Investment from "../Investment";
import PublicSentiment from '../PublicSentiment';
import LegalInstrument from '../LegalInstrument';
import HistoricalEvolution from '../HistoricalEvolution';
import CapitalOperation from '../CapitalOperation';
import TenderingAndBidding from '../TenderingAndBidding';
import AnnouncementPublicOpinion from '../AnnouncementPublicOpinion';
import BusinessAnalysis from '../BusinessAnalysis';
import TaxAnalysis from '../TaxAnalysis';
import NotFound from '../../404/index';
const routers = [
  {
    menu_url: "/businessInformation",
    element: <BusinessInformation> </BusinessInformation>,
    menu_name:'工商信息',
  },
  {
    menu_url: "/branch",
    element: <TableContent defaultTableName='company_branch' typeName='分支机构'> </TableContent>,
    menu_name:'分支机构'
  },
  {
    menu_url: "/keyPersonnel",
    element: <KeyPersonnel> </KeyPersonnel>,
    menu_name:'主要人员',
    multiple: true,
    menu_name_list:['主要人员(公示)','主要人员(历史公示)','主要人员(历史工商)','主要人员(工商)']
  },{
    menu_url: "/majorShareholders",
    element: <MajorShareholders> </MajorShareholders>,
    menu_name:'主要股东',
    multiple: true,
    menu_name_list:['主要股东(公示)','主要股东(历史)','主要股东']
  },{
    menu_url: "/outboundInvestment",
    element: <Investment> </Investment>,
    menu_name:'对外投资',
    multiple: true,
    menu_name_list:['对外投资(公示)','对外投资(历史)','对外投资']
  },{
    menu_url: "/equityRelationship",
    element: <EquityRelationship> </EquityRelationship>,
    menu_name:'股权关系'
  },{
    menu_url: "/judicialCases",
    element: <JudicialCases> </JudicialCases>,
    menu_name:'司法案件'
  },{
    menu_url: "/reportManagement",
    element: <ReportManagement> </ReportManagement>,
    menu_name:'底稿管理',
    menu_code:"CDD_FUNCTION_ITEM_GENERATION"
  }
  ,{
    menu_url: "/trademark",
    element: <TableContent defaultTableName='company_trademark' typeName='商标'> </TableContent>,
    menu_name:'商标'
  },{
    menu_url: "/patents",
    element: <TableContent defaultTableName='company_patents' typeName='专利'> </TableContent>,
    menu_name:'专利'
  },{
    menu_url: "/copyrightWorks",
    element: <TableContent defaultTableName='company_copyright_works' typeName='作品著作'>  </TableContent>,
    menu_name:'作品著作'
  },{
    menu_url: "/softwareWorks",
    element: <TableContent defaultTableName='company_software_works' typeName='软件著作'> </TableContent>,
    menu_name:'软件著作'
  },{
    menu_url: "/qualification",
    element: <TableContent  defaultTableName='company_qualification' typeName='资质证书'> </TableContent>,
    menu_name:'资质证书'
  },{
    menu_url: "/administrativeLicensing",
    element: <TableContent defaultTableName='company_administrative_licensing' typeName='行政许可'> </TableContent>,
    menu_name:'行政许可'
  },{
    menu_url: "/punishment",
    element: <TableContent defaultTableName='administrative_punishment' typeName='行政处罚'> </TableContent>,
    menu_name:'行政处罚'
  },{
    menu_url: "/movableProperty",
    element: <TableContent defaultTableName='chattel_mortgage' typeName='动产抵质押'> </TableContent>,
    menu_name:'动产抵质押'
  },{
    menu_url: "/landMortgage",
    element: <TableContent  defaultTableName='land_mortgage' typeName='不动产抵押'> </TableContent>,
    menu_name:'不动产抵押'
  },{
    menu_url: "/relatedPartyManagement",
    element: <TableContent defaultTableName='related_parties_information' typeName='关联方管理'> </TableContent>,
    menu_name:'关联方管理'
  },{
    menu_url: "/suspectedRelatedParty",
    element: <SuspectedRelatedParty> </SuspectedRelatedParty>,
    menu_name:'疑似关联方分析'
  },{
    menu_url: "/judicialAuction",
    element: <TableContent defaultTableName='judicial_auction' typeName='司法拍卖'> </TableContent>,
    menu_name:'司法拍卖'
  },{
    menu_url: "/dishonestPeople",
    element: <TableContent defaultTableName='dishonest_people' typeName='失信被执行人'> </TableContent>,
    menu_name:'失信被执行人'
  },{
    menu_url: "/personSubjectedToExecution",
    element: <TableContent defaultTableName='person_subjected_to_execution' typeName='被执行人'> </TableContent>,
    menu_name:'被执行人'
  },
  {
    menu_url: "/publicSentiment",
    element: <PublicSentiment> </PublicSentiment>,
    menu_name:"新闻舆情"
  },
  {
    menu_url: "/announcementPublicOpinion",
    element: <AnnouncementPublicOpinion> </AnnouncementPublicOpinion>,
    menu_name:"公告舆情"
  },
  {
    menu_url: "/historicalEvolution",
    element: <HistoricalEvolution> </HistoricalEvolution>,
    menu_name:"历史沿革"
  },
  {
    menu_url: "/tenderingAndBidding",
    element: <TenderingAndBidding> </TenderingAndBidding>,
    menu_name:"招投标",
    multiple: true,
    menu_name_list:['中标列表','业务发展分析','竞争力分析']
  },
  {
    menu_url: "/capitalOperation",
    element: <CapitalOperation> </CapitalOperation>,
    menu_name:"资本运作情况"
  },
  {
    menu_url: "/legalInstrument",
    element: <LegalInstrument> </LegalInstrument>,
    menu_name:'法律文书',
    multiple: true,
    menu_name_list:['立案信息','法院公告','裁判文书','开庭公告']
  },
  {
    menu_url: "/businessAnalysis",
    element: <BusinessAnalysis> </BusinessAnalysis>,
    menu_name:"经营分析",
    multiple: true,
    menu_name_list:['销项票据及客户分析','进项票据及供应商分析','票据详情']
  },
  {
    menu_url: "/taxAnalysis",
    element: <TaxAnalysis> </TaxAnalysis>,
    menu_name:"税务分析",
    multiple: true,
    menu_name_list:['税务分析总览','纳税申报','税务风险']
  },
  {
    menu_url: "/404",
    element: <div className="notFoundPage"><NotFound></NotFound></div>,
  },
  {
    menu_url: "/",
    element: <ProjectInfo> </ProjectInfo>,
  },
  {
    menu_url: "/*",
    element: <div className="notFoundPage"><NotFound></NotFound></div>,
  },
];

export default routers;
