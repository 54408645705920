import "./index.scss";
import { Tooltip, Avatar } from "antd";
const HeadPortrait = (props) => {
  const {
    src, //头像地址
    title, //tip标题
    onClick, //点击事件
    style, //样式
    showTip = true, //是否显示tip
    size = 14, //尺寸
    mouseEnterDelay = 0.1, //延迟显示时间
  } = props;
  return showTip ? (
    <Tooltip
      placement="top"
      title={title}
      arrowPointAtCenter
      mouseEnterDelay={mouseEnterDelay}
      transitionName=''
      overlayClassName='headPortraitTip'
    >
      <Avatar
        className="headPortrait"
        size={size}
        src={src}
        style={{ ...style }}
        onClick={() => onClick && onClick()}
      ></Avatar>
    </Tooltip>
  ) : (
    <Avatar
      className="headPortrait"
      size={size}
      src={src}
      style={{ ...style }}
      onClick={() => onClick && onClick()}
    ></Avatar>
  );
};

export default HeadPortrait;
