import Home from "../view/Home";
import ToolCabinet from "../view/ToolCabinet";
import ProjectManagement from "../view/ProjectManagement";
import DraftTemplate from "../view/DraftTemplate";
import Scene from "../view/Scene";
import HomeIcon from '../assets/首页.svg';
import ToolCabinetIcon from '../assets/工具箱.svg';
import ProjectManagementIcon from '../assets/项目管理.svg';
import Bond from '../view/Bond';

const routers = [
  {
    menu_url: "/home",
    element: <Home> </Home>,
    open_mode: "CURRENT_PAGE",
    menu_name: "首页",
    icon: HomeIcon
  },
  {
    menu_url: "/bondManagement",
    element: <Bond> </Bond>,
    open_mode: "BOND_PAGE",
    menu_name: "债券后督",
    icon: HomeIcon
  },
  {
    menu_url: "/projectManagement",
    element: <ProjectManagement> </ProjectManagement>,
    open_mode: "CURRENT_PAGE",
    menu_name: "项目管理",
    icon: ProjectManagementIcon
  },
  {
    menu_url: "/toolCabinet",
    element: <ToolCabinet> </ToolCabinet>,
    open_mode: "CURRENT_PAGE",
    menu_name: "工具箱",
    icon: ToolCabinetIcon
  },
  // {
  //   menu_url: "/draftTemplate",
  //   element: <DraftTemplate> </DraftTemplate>,
  //   open_mode: "CURRENT_PAGE",
  //   menu_name: "底稿模板管理",
  // },
  // {
  //   menu_url: "/scene",
  //   element: <Scene> </Scene>,
  //   open_mode: "CURRENT_PAGE",
  //   menu_name: "尽调场景管理",
  // },
];

export default routers;
