import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
  amountFormats,
  isCurrentYear,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
import moment from "moment";
import { innerCircleMap, outerCircleMap } from "../../../../../../setting";

const CompositionOfSalesRevenue = (props) => {
  const { data = {}, dataRange, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);
  const [dataInfo, setDataInfo, getDataInfo] = useGetState([]);
  const [type, setType, getType] = useGetState("H1");

  const [selectItem, setSelectItem, getSelectItem] = useGetState({});

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const dom = document.getElementById("compositionOfSalesRevenueContentBox");
    if (!dom) {
      return;
    }
    let legendData = {};

    let sumAmount = 0;
    echartsData.forEach((item) => {
      if (legendData[item.category]) {
        legendData[item.category]?.items.push(item);
        legendData[item.category].amount =
          legendData[item.category].amount + item.amount;
      } else {
        legendData[item.category] = {
          items: [item],
          amount: item.amount,
          total_amount: item.total_amount,
        };
      }
      sumAmount += item.amount || 0;
    });
    const list = Object?.keys(legendData)?.sort(
      (a, b) => legendData[b]?.amount - legendData[a]?.amount
    );
    //内圈显示前五 前五占比不足75% 则显示前75%的  其余的合并为其他
    let diff = 0.75;
    let keys = [];
    let otherKeys = [];

    list.forEach((key, index) => {
      if (index <= 4) {
        keys.push(key);
      } else {
        if (diff >= 0) {
          keys.push(key);
        } else {
          otherKeys.push(key);
        }
      }
      diff = diff - legendData[key]?.amount / sumAmount;
    });
    const selectKey = keys?.at(0);
    myChart.current = echarts.init(dom);
    const data1 = [];
    const data2 = [];
    let count = 0;
    (keys || [])?.forEach((key, index) => {
      data1.push({
        value: legendData[key].amount,
        total_amount: legendData[key].total_amount,
        name: key,
        selected: index === 0 ? true : undefined,
        itemStyle: {
          color: innerCircleMap[index % innerCircleMap?.length],
        },
        ...(legendData[key]?.items?.at(0) || {}),
      });

      //内圈显示前五 前五占比不足75% 则显示前75%的
      let _diff = 0.75;
      const _items = (legendData[key]?.items || [])?.sort(
        (a, b) => b.amount - a.amount
      );
      const _sum = _items?.reduce((a, b) => a + b.amount, 0);
      let items = [];
      let otherItems = [];
      _items.forEach((key, index) => {
        if (index <= 4) {
          items.push(key);
        } else {
          if (_diff >= 0) {
            items.push(key);
          } else {
            otherItems.push(key);
          }
        }
        _diff = _diff - _items[key]?.amount / _sum;
      });

      items?.forEach((item) => {
        data2.push({
          value: item.amount,
          name: item.product_name,
          total_amount: item.total_amount,
          itemStyle: {
            color: outerCircleMap[count % outerCircleMap?.length],
          },
        });
        count = count + 1;
      });

      if (otherItems?.length > 0) {
        data2.push({
          value: otherItems?.reduce((a, b) => a + b?.amount, 0),
          name: key + "-其他",
          total_amount: otherItems?.at(0)?.total_amount,
          itemStyle: {
            color: outerCircleMap[count % outerCircleMap?.length],
          },
        });
        count = count + 1;
      }
    });

    if (otherKeys?.length > 0) {
      data1.push({
        value: otherKeys?.reduce((a, b) => a + legendData[b]?.amount, 0),
        total_amount: legendData[otherKeys?.at(0)].total_amount,
        keys: otherKeys,
        name: "其他商品类别",
        selected: undefined,
        itemStyle: {
          color: innerCircleMap[keys?.length % innerCircleMap?.length],
        },
      });

      let list = [];
      otherKeys.forEach((key) => {
        list = [...list, ...(legendData[key]?.items || [])];
      });
      data2.push({
        value: list?.reduce((a, b) => a + b?.amount, 0),
        name: "其他商品类别",
        total_amount: list?.at(0)?.total_amount,
        itemStyle: {
          color: outerCircleMap[count % outerCircleMap?.length],
        },
      });
      count = count + 1;
    }

    //外圈 前五个占比不足5%则不显示外圈
    const top5List = JSON.parse(JSON.stringify(data2))
      ?.sort((a, b) => b?.value - a?.value)
      ?.slice(0, 5);
    const topSum = top5List?.reduce((a, b) => a + b.value, 0);
    let showPeripheralCircle = true;
    if (topSum / sumAmount < 0.05) {
      showPeripheralCircle = false;
    }
    let series = [
      {
        name: "Access From",
        type: "pie",
        selectedMode: "single",
        selectedOffset: 2,
        radius: [0, "80%"],
        startAngle: 135,
        label: {
          position: "inner",
          fontSize: 12,
          color: "#000",
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: data1,
      },
    ];
    if (showPeripheralCircle) {
      series[0].radius = [0, "50%"];
      series.push({
        name: "Access From",
        type: "pie",
        radius: ["65%", "80%"],
        startAngle: 135,
        labelLine: {
          length: 5,
        },
        label: {
          formatter: (info) => {
            let name = info?.name;
            if (name?.length > 5) {
              name = name?.slice(0, 5) + "...";
            }
            return `{b|${name}：}{per|${info.percent}%}`;
          },
          width: 130,
          // backgroundColor: "red",
          // borderColor: "#8C8D8E",
          // borderWidth: 1,
          // borderRadius: 4,
          rich: {
            b: {
              color: "#4C5058",
              fontSize: 12,
              fontWeight: "bold",
              // lineHeight: 33,
            },
            c: {
              formatter: (value) => {
                return value + "元";
              },
            },
            per: {
              color: "#4C5058",
              fontSize: 12,
            },
          },
        },
        data: data2,
      });
    }
    const option = {
      tooltip: {
        trigger: "item",
        formatter: (info) => {
          return `<div style='font-weight:bold'>${
            info?.name
          }</div> <div>金额: ${addMillimeterAndInit(
            info?.value + "元"
          )} </div><div>占比: ${(
            (info?.data?.value / info?.data?.total_amount) *
            100
          )?.toFixed(2)}%</div>`;
        },
      },
      legend: {
        show: false,
        data: (legendData[selectKey]?.items || [])?.map(
          (item) => item.product_name
        ),
      },
      grid: {
        top: "20px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: series,
    };
    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
    myChart.current.off("click");
    myChart.current.on("click", { seriesIndex: 0 }, (info) => {
      let keys = null;
      if (info?.data?.keys) {
        keys = info?.data?.keys;
      }
      selectItemFunc(info?.name, keys);
    });

    selectItemFunc(selectKey);
    // setSelectItem({
    //   dataIndex: 0,
    //   value: legendData[selectKey]?.amount,
    //   name: selectKey,
    //   total_amount: sumAmount,
    //   percent: (legendData[selectKey]?.amount / sumAmount) * 100,
    //   ...(legendData[selectKey]?.items?.at(0) || {}),
    // });
  };

  const selectItemFunc = (category, keys) => {
    let year = getSelectYear();
    let lostYear = year - 1;
    if (getType() === "H1" && isCurrentYear(getSelectYear())) {
      year = year + "H1";
      lostYear = lostYear + "H1";
    }
    const _info =
      data[year]?.filter((item) => {
        if (keys) {
          return keys?.includes(item.category);
        }
        return item.category === category;
      }) || [];
    const _lostInfo =
      data[lostYear]?.filter((item) => {
        if (keys) {
          return keys?.includes(item.category);
        }
        return item.category === category;
      }) || [];

    const amount = _info.reduce((a, b) => a + b.amount, 0);
    const lostAmount = _lostInfo.reduce((a, b) => a + b.amount, 0);

    const info = {
      category: category,
      amount: amount,
      total_amount: _info?.at(0).total_amount || 0,
      pct_change: lostAmount
        ? (amount - lostAmount) / Math.abs(lostAmount)
        : null,
    };
    setSelectItem({
      ...info,
    });
  };

  const initData = (data) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }
    //数据范围为下半年 取上半年的数据展示
    if (diff >= 0) {
      setType("H1");
    } else {
      //数据范围为上半年 取前一年的数据展示
      setType(null);
    }
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      ?.map((item) => {
        return item?.slice(0, 4);
      })
      .sort((a, b) => Number(a) - Number(b));
    yearArr = [...new Set(yearArr)];
    const nowYear = yearArr?.at(-1);
    setYearList(yearArr);
    setSelectYear(nowYear);
    setDataInfo(data[nowYear]);

    setTimeout(() => {
      initEcharts(data[nowYear]);
    }, 100);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "销售商品构成",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="compositionOfSalesRevenueContent">
      <div className="headerBox">
        销售商品构成
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(data[value?.at(0)]);
                  setDataInfo(data[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="compositionOfSalesRevenueContentBox">
          <div id="compositionOfSalesRevenueContentBox"></div>
          <span className="circleTitleBox">{getSelectItem()?.category}</span>
          <div className="compositionOfSalesRevenueTextBox">
            <div className="titleBox">
              {selectYear}年
              {getType() === "H1" && isCurrentYear(getSelectYear())
                ? "上半年"
                : ""}
              {getSelectItem()?.category}
            </div>
            <div className="textBox">
              开票金额为
              <span>
                {addMillimeter(getSelectItem()?.amount, 2)}
                <p>元</p>
              </span>
            </div>
            <div className="textBox">
              占比
              <span>
                {(getSelectItem()?.total_amount
                  ? (
                      (getSelectItem()?.amount /
                        Math.abs(getSelectItem()?.total_amount)) *
                      100
                    )?.toFixed(2)
                  : "-") + "%"}
              </span>
            </div>
            {getSelectItem()?.category !== "其他商品类别" && (
              <div className="textBox">
                同比（较{selectYear - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{getSelectItem()?.pct_change < 0 ? "减" : "增"}幅
                <span
                  className="span1"
                  style={{
                    color:
                      getSelectItem()?.pct_change < 0 ? "#FE545F" : "#61D4AD",
                  }}
                >
                  {getSelectItem()?.pct_change
                    ? addMillimeter(
                        Math.abs(getSelectItem()?.pct_change || 0) * 100
                      )
                    : "-"}
                  %
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default CompositionOfSalesRevenue;
