import "./index.scss";
import { AppleFilled, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Badge, Checkbox, Tooltip } from "antd";
import {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import projectApi from "../../../../apis/project/index";
import { ReactSVG } from "react-svg";
import icon from "../../../../assets/项目管理.svg";

const ContentMap = (props, ref) => {
  const { checkedList, mapType } = props;

  const colorList = useRef(["#AAD461", "#FEC054", "#FE545F", "#6CAEFF"]);
  const [dataList, setDataList] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [all, setAll] = useState(true);

  const checkItemList = useRef([
    "工商信息",
    "主要股东",
    "对外投资",
    "主要人员",
    "分支机构",
  ]);

  const initCheckedData = (data, checked) => {
    data = data.map((item) => {
      item.isCheck = item.is_sup_func ? checked : false;
      item.children = item.children.map((_item) => {
        _item.isCheck = _item.is_sup_func ? checked : false;
        return _item;
      });
      return item;
    });
    setDataList(data);
  };

  const getMenuTreeData = (mapType) => {
    projectApi.getMenuTreeData(mapType).then((res) => {
      if (res.code === 200) {
        initCheckedData(res.data.data, true);
        setIsEdit(res.data.is_edit ? true : false);
      } else {
        setDataList([]);
      }
    });
  };

  useEffect(() => {
    if (mapType) {
      getMenuTreeData(mapType);
    }
  }, [mapType]);

  useEffect(() => {
    let checkedAll = true;
    dataList.forEach((item) => {
      if (!item.isCheck) {
        checkedAll = false;
      }
    });
    setAll(checkedAll);
  }, [dataList]);

  const choiceItem = (checked, item, parentItem) => {
    if (checkItemList.current?.includes(item.function_name)) {
      return;
    }
    setDataList((prev) => {
      prev = prev.map((_item) => {
        if (!parentItem && _item.id === item.id) {
          _item.isCheck = checked;
          _item.children = _item.children.map((__item) => {
            __item.isCheck = checked;
            if (checkItemList.current?.includes(__item.function_name)) {
              __item.isCheck = true;
            }
            return __item;
          });
        } else if (_item.id === parentItem?.id) {
          let parentCheck = true;
          _item.children = _item.children?.map((__item) => {
            if (__item.id === item.id) {
              __item.isCheck = checked;
            }
            if (!__item.isCheck) {
              parentCheck = false;
            }
            return __item;
          });
          _item.isCheck = parentCheck;
        }
        return _item;
      });
      return [...prev];
    });
  };

  const getCheckDate = () => {
    let arr = [];
    dataList.forEach((item) => {
      let list = item.children?.filter((_item) => _item.isCheck);
      if (list?.length > 0) {
        arr.push(item);
      }
      arr = arr.concat(list);
      // item.children?.forEach((_item) => {
      //   if (_item.isCheck) {
      //     arr.push(_item);
      //   }
      // });
    });
    return arr;
  };

  const max = 6;
  const getLength = (length) => {
    if (length > 8) {
      return -length * 3;
    }
    switch (length) {
      case 2:
        return 7;
      case 3:
        return 0;
      case 4:
        return -5;
      case 5:
        return -10;
      case 6:
        return -17;
      case 7:
        return -24;
      case 8:
        return -45;
      default:
        return 0;
    }
  };

  useImperativeHandle(ref, () => ({
    dataList,
    getCheckDate,
  }));

  return (
    <div className="contentMapContent">
      {dataList.map((item) => (
        <div className="cardItem" key={item.id}>
          <div className="topBox">
            <div className="box">
              <div
                className="one"
                style={{
                  backgroundColor: isEdit
                    ? item?.children?.some((_) => _.isCheck)
                      ? "#0068B2"
                      : "rgb(216, 216, 216)"
                    : item.is_sup_func
                    ? "#0068B2"
                    : "rgb(216, 216, 216)",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  isEdit &&
                    item.is_sup_func &&
                    choiceItem(!item.isCheck, item, null);
                }}
              >
                <ReactSVG
                  style={{ width: "22px", height: "22px", lineHeight: "22px" }}
                  src={item.full_logo_url}
                ></ReactSVG>

                {isEdit && item.is_sup_func ? (
                  <Checkbox
                    checked={item.isCheck}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      choiceItem(e.target.checked, item, null);
                    }}
                  ></Checkbox>
                ) : null}
              </div>
              {item.children?.map((_item, index) => (
                <div
                  key={_item.id}
                  className="item"
                  style={{
                    marginLeft: index === 0 ? "-12px" : "2.5px",
                    width: `calc(${100 / max}% - ${86 / max}px)`,
                  }}
                >
                  <div className="lineBox">
                    <Badge color="#D8D8D8"></Badge>
                    <div className="line"></div>
                  </div>
                  {
                    <Tooltip
                      visible={_item.is_sup_func ? false : undefined}
                      title="该功能需要升级产品订阅方案，请联系商务咨询"
                    >
                      <div
                        className="iconBox"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          isEdit &&
                            _item.is_sup_func &&
                            choiceItem(!_item.isCheck, _item, item);
                        }}
                        style={{
                          color:
                            _item.isCheck && _item.is_sup_func
                              ? colorList.current?.at(index % 4)
                              : "#D8D8D8",
                          borderColor:
                            _item.isCheck && _item.is_sup_func
                              ? colorList.current?.at(index % 4)
                              : "#D8D8D8",
                        }}
                      >
                        <ReactSVG
                          style={{
                            width: "16px",
                            height: "16px",
                            lineHeight: "16px",
                          }}
                          src={_item.full_logo_url}
                        ></ReactSVG>
                        {/* {_item.icon ? item.icon : <AppleFilled></AppleFilled>} */}
                      </div>
                      <div
                        className="textBox"
                        style={{
                          right: `${getLength(_item.function_name?.length)}px`,
                        }}
                      >
                        {_item.function_name}
                      </div>
                    </Tooltip>
                  }
                  {isEdit &&
                  _item.is_sup_func &&
                  !checkItemList.current?.includes(_item.function_name) ? (
                    <Checkbox
                      checked={_item.isCheck}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        choiceItem(e.target.checked, _item, item);
                      }}
                    ></Checkbox>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className="textBox">
            <div className="oneText" key={item.id}>
              {item.function_name}
            </div>
          </div>
        </div>
      ))}
      {/* {isEdit && (
        <div
          className="mapHandleAll"
          onClick={() => {
            initCheckedData(dataList, !all);
          }}
        >
          {all ? "全部取消" : "全部选中"}
        </div>
      )} */}
      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default forwardRef(ContentMap);
