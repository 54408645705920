import MyEmpty from "../../../../../../components/Empty";
import "./index.scss";
import { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useGetState } from "ahooks";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { useSelector } from "react-redux";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../../apis/project";
import { useParams } from "react-router-dom";
import { Table, message } from "antd";

const IncomeBox = (props) => {
  const { data = [], selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();

  const [noData, setNoData] = useState(false);
  const [dataInfo, setDataInfo, getDataInfo] = useGetState({});
  const [downLoading, setDownLoading] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("发票金额");
  const [tableData, setTableData, getTableData] = useGetState([]);

  const myChart = useRef(null);
  const columns = useRef([
    {
      title: "月份",
      dataIndex: "key",
      key: "key",
      width: "60px",
    },
    {
      title: "有效发票数",
      dataIndex: "valid_number",
      key: "valid_number",
      render: (text) => (
        <span>{text ? Number(text || 0)?.toLocaleString() : "-"}</span>
      ),
    },
    {
      title: "有效发票金额(万元)",
      dataIndex: "valid_amount",
      key: "valid_amount",
      render: (text) => (
        <span>{text ? Number(text / 10000 || 0)?.toLocaleString() : "-"}</span>
      ),
    },
    {
      title: "红冲发票数",
      dataIndex: "negative_number",
      key: "negative_number",
      render: (text) => (
        <span>{text ? Number(text || 0)?.toLocaleString() : "-"}</span>
      ),
    },
    {
      title: "红冲发票金额(万元)",
      dataIndex: "negative_amount",
      key: "negative_amount",
      render: (text) => (
        <span>
          {text ? Number(Math.abs(text / 10000))?.toLocaleString() : "-"}
        </span>
      ),
    },
    {
      title: "作废发票数",
      dataIndex: "invalid_number",
      key: "invalid_number",
      render: (text) => (
        <span>{text ? Number(text || 0)?.toLocaleString() : "-"}</span>
      ),
    },
    {
      title: "作废发票金额(万元)",
      dataIndex: "invalid_amount",
      key: "invalid_amount",
      render: (text) => (
        <span>{text ? Number(text / 10000 || 0)?.toLocaleString() : "-"}</span>
      ),
    },
  ]);
  const colorMap = useRef(["#0068B2", "#61D4AD", "#FEC054"]);

  const initEchartsDataFunc = (data) => {
    let max = 0;
    const keysList = Object?.keys(data)?.sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );
    let echartsData = [];
    keysList?.forEach((key) => {
      const item = data[key];
      let obj = {};
      if (getInitType() === "发票金额") {
        obj.name = key;
        obj.negative_amount = item?.negative_amount;
        obj.invalid_amount = item?.invalid_amount;
        obj.valid_amount = item?.valid_amount;
        if (max <= item?.negative_amount) {
          max = item?.negative_amount;
        }
        if (max <= item?.invalid_amount) {
          max = item?.invalid_amount;
        }
        if (max <= item?.valid_amount) {
          max = item?.valid_amount;
        }
      } else {
        obj.name = key;
        obj.negative_number = item?.negative_number;
        obj.invalid_number = item?.invalid_number;
        obj.valid_number = item?.valid_number;
        if (max <= item?.negative_number) {
          max = item?.negative_number;
        }
        if (max <= item?.invalid_number) {
          max = item?.invalid_number;
        }
        if (max <= item?.valid_number) {
          max = item?.valid_number;
        }
      }
      echartsData.unshift(obj);
    });
    return {
      max,
      echartsData,
    };
  };

  const initEcharts = (data) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("incomeBoxEchartsBox");
    if (!dom) {
      return;
    }
    const { max, echartsData } = initEchartsDataFunc(data);

    myChart.current = echarts.init(dom);

    let option = {};
    if (getInitType() === "发票金额") {
      option = {
        tooltip: {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "center", //可设定图例在左、右、居中
          y: "bottom", //可设定图例在上、下、居中
          data: ["有效发票金额", "红冲发票金额", "作废发票金额"],
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          bottom: "0px",
          show: true,
          // left: "210px",
          // type: "scroll", // 显示分页
          selectedMode: true,
          height: 24,
          textStyle: {
            rich: {
              title: {
                fontSize: 14,
                lineHeight: 22,
                color: "#595959",
                width: 80,
              },
              value: {
                fontSize: 14,
                lineHeight: 22,
                color: "rgba(0,0,0,0.45)",
                width: 30,
                align: "center",
              },
            },
          },
        },
        grid: {
          top: "10px",
          left: "0px",
          right: "10px",
          bottom: "30px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: echartsData?.map((item) => item?.name),
          axisLabel: {
            interval: 0,
            hideOverlap: true,
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            type: "shadow",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        yAxis: {
          type: "value",
          // name: maxNum < 10000 ? "(元)" : "(万元)",
          nameTextStyle: {
            align: "center",
            // padding: [0, 50, 0, 0],
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          max: max < 5 ? 5 : null,
          nameGap: 10,
          axisLabel: {
            formatter: function (value, index) {
              // let num = parseInt(value / 10000).toLocaleString("en-US");
              // if (maxNum < 10000) {
              //   num = parseInt(value).toLocaleString("en-US");
              // }
              // return num;
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
        series: [
          { name: "有效发票金额", type: "valid_amount" },
          { name: "红冲发票金额", type: "negative_amount" },
          { name: "作废发票金额", type: "invalid_amount" },
        ]?.map((item, index) => ({
          type: "line",
          name: item.name,
          data: echartsData?.map((_item) => Math.abs(_item[item?.type])),
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: colorMap.current[index],
          },
        })),
      };
    } else {
      option = {
        tooltip: {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          // orient: "vertical",
          x: "center", //可设定图例在左、右、居中
          y: "bottom", //可设定图例在上、下、居中
          bottom: "0px",
          // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["有效发票数", "红冲发票数", "作废发票数"],
        },
        xAxis: [
          {
            type: "category",
            data: echartsData?.map((item) => item?.name),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              interval: 0,
              hideOverlap: true,
              // rotate: -15,
            },
            axisTick: {
              alignWithLabel: true,
              interval: 0,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        yAxis: [
          {
            type: "value",
            max: max <= 5 ? 5 : null,
            interval: max <= 5 ? 1 : null,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisPointer: {
              show: false,
            },
            axisLabel: {
              formatter: function (value, index) {
                if (value >= 100000000) {
                  return value / 100000000 + "亿";
                } else if (value >= 10000) {
                  return value / 10000 + "万";
                } else {
                  return value;
                }
              },
            },
          },
        ],
        grid: {
          top: "10px",
          left: "0%",
          right: "0%",
          bottom: "30px",
          containLabel: true,
        },
        series: [
          { name: "有效发票数", type: "valid_number" },
          { name: "红冲发票数", type: "negative_number" },
          { name: "作废发票数", type: "invalid_number" },
        ]?.map((item, index) => ({
          type: "bar",
          name: item.name,
          data: echartsData?.map((_item) => _item[item?.type]),
          tooltip: {
            valueFormatter: function (value) {
              return  Number(value || 0)?.toLocaleString();
            },
          },
          itemStyle: {
            color: colorMap.current[index],
            barBorderRadius: [4, 4, 0, 0],
          },
        })),
      };
    }

    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    let obj = {};
    data?.forEach((item) => {
      obj[item.date] = item;
    });
    initTableData(obj);
    setDataInfo(obj);
    initEcharts(obj);
  };

  useEffect(() => {
    if (data?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "月度（进项）票据情况",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const initTableData = (data) => {
    const keysList = Object?.keys(data)?.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const tableData = keysList?.map((key) => ({
      key,
      ...(data[key] || {}),
    }));
    setTableData(tableData);
  };
  return (
    <div className="incomeBoxContent">
      <div className="headerBox">
        月度（进项）票据情况
        {!noData && (
          <div className="selectBox2">
            <div
              className={
                initType === "发票金额"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "发票金额") {
                  return;
                }
                setInitType("发票金额");
                setTimeout(() => {
                  initEcharts(getDataInfo());
                }, 50);
              }}
            >
              发票金额
            </div>
            <div
              className={
                initType === "发票数"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "发票数") {
                  return;
                }
                setInitType("发票数");
                setTimeout(() => {
                  initEcharts(getDataInfo());
                }, 50);
              }}
            >
              发票数
            </div>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="incomeBoxContentBox">
          <div id="incomeBoxEchartsBox"></div>
          <div className="incomeBoxTableBox">
            <Table
              scroll={{
                y: tableData?.length > 0 ? "252px" : undefined,
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              className="noBorderTable"
              bordered={false}
              pagination={false}
              columns={columns.current}
              dataSource={tableData}
            ></Table>
          </div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default IncomeBox;
