import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  calcCompanyNameFunc,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
const SalesFrequency = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const obj = {};
    echartsData?.forEach((item) => {
      //金额为负数的 直接略过
      if(item.average_amount < 0){
        return
      }
      if (obj[item.product_category]) {
        obj[item.product_category].average_amount =
          obj[item.product_category].average_amount + item.average_amount;
        obj[item.product_category].number =
          obj[item.product_category].number + item.number;
        obj[item.product_category].items.push(item);
      } else {
        const _ = {
          average_amount: item.average_amount,
          number: item.number,
          items: [item],
        };
        obj[item.product_category] = _;
      }
    });

    let countMax = 0;
    let amountMax = 0;
    const keys = Object.keys(obj)?.sort(
      (a, b) => obj[b]?.average_amount - obj[a]?.average_amount
    );
    keys?.forEach((key) => {
      const item = obj[key];
      if (item?.average_amount >= amountMax) {
        amountMax = item?.average_amount;
      }
      if (item?.number >= countMax) {
        countMax = item?.number;
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("salesFrequencyContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const option = {
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: ["平均开票金额", "开票次数"],
      },
      tooltip: {
        trigger: "axis",
        // confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (info) => {
          const one = info.find((_) => _.seriesName === "平均开票金额");
          const two = info.find((_) => _.seriesName === "开票次数");
          return `<div style='font-weight:bold'>${one.name}</div><div>${
            one?.marker
          } ${one?.seriesName} ${addMillimeter(one.value, 2)}元</div><div>${
            two?.marker
          } ${two?.seriesName} ${Number(two.value || 0)?.toLocaleString()}次</div>`;
        },
      },

      grid: [
        {
          show: false,
          left: "2%",
          top: 20,
          bottom: 40,
          containLabel: true,
          width: "48%",
        },
        {
          show: false,
          left: "50.5%",
          top: 60,
          bottom: 60,
          width: "0",
        },
        {
          show: false,
          right: "2%",
          top: 20,
          bottom: 40,
          containLabel: true,
          width: "48%",
        },
      ],
      xAxis: [
        {
          type: "value",
          triggerEvent: true,
          inverse: true,
          max: amountMax <= 5 ? 5 : undefined,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisPointer: {
            show: false,
          },
          position: "bottom",
          axisLabel: {
            show: true,
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else if (value <= -10000) {
                return value / 1000;
              } else if (value <= -100000000) {
                return value / 10000000;
              } else {
                return value;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        {
          max: countMax <= 5 ? 5 : undefined,
          gridIndex: 2,
          type: "value",
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          position: "bottom",
          axisLabel: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "category",
          inverse: true,
          position: "left",
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 8,
            textStyle: {
              color: "#313131",
              fontSize: 12,
            },
            formatter: (value) => {
              return calcCompanyNameFunc(value, 8, "salesFrequencyContentBox");
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          data: keys,
        },

        {
          gridIndex: 2,
          type: "category",
          inverse: true,
          position: "left",
          axisPointer: {
            // show: false,
            type: "shadow",
            label: {
              show: false,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          data: keys,
        },
      ],
      axisPointer: {
        link: [
          {
            yAxisIndex: "all",
          },
        ],
      },
      series: [
        {
          name: "平均开票金额",
          type: "bar",
          stack: "one",
          barGap: 15,
          barMaxWidth: 40,
          itemStyle: {
            color: "#0068B2",
            barBorderRadius: [4, 0, 0, 4],
          },
          data: keys?.map((key) => obj[key]?.average_amount),
        },
        {
          name: "开票次数",
          stack: "right",
          type: "bar",
          barGap: 15,
          barMaxWidth: 40,
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
            color: "#61D4AD",
            barBorderRadius: [0, 4, 4, 0],
          },
          data: keys?.map((key) => obj[key]?.number),
        },
      ],
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      .sort((a, b) => Number(a) - Number(b));
    const nowYear = yearArr?.at(-1);

    setYearList(yearArr);
    setSelectYear(nowYear);

    initEcharts(data[nowYear]);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "主营商品开票次数",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="salesFrequencyContent">
      <div className="headerBox">
        主营商品开票次数
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(data[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="salesFrequencyContentBox">
          <div id="salesFrequencyContentBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default SalesFrequency;
