export const toleranceValue = 0.00005;

export const moduleCodeMap = {
    CDD_FUNCTION_ITEM_BUSINESS_INFO: [], //尽调公司工商信息
    CDD_FUNCTION_ITEM_BUSINESS_SUMMARY: [], //尽调公司公司简介
    CDD_FUNCTION_ITEM_BUSINESS_LOGO: ["工商信息"], //尽调公司LOGO
    CDD_FUNCTION_ITEM_MAJOR_SHAREHOLDERS: ["工商信息", "主要股东"], //尽调公司主要股东
    CDD_FUNCTION_ITEM_OUTBOUND_INVESTMENT: ["工商信息", "主要股东", "对外投资"], //尽调公司对外投资
    CDD_FUNCTION_ITEM_KEY_PERSONNEL: ["工商信息", "主要股东", "对外投资", "主要人员"], //尽调公司主要人员
    CDD_FUNCTION_ITEM_BRANCH: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构"], //尽调公司分支机构
    CDD_FUNCTION_ITEM_BUSINESS_INFO_OTHER: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构"], //其他工商信息

    CDD_FUNCTION_ITEM_CAPITAL_OPER: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "历史沿革", "资本运作情况"], //尽调公司资本运作
    CDD_FUNCTION_ITEM_KNOW_RIGHT: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "商标", "专利", "作品著作", "软件著作", "历史沿革", "资本运作情况"], //尽调公司知识产权
    CDD_FUNCTION_ITEM_MANAGE_STATUS: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "商标", "专利", "作品著作", "软件著作", "资质证书", "行政许可", "招投标", "历史沿革", "资本运作情况"], //尽调公司经营状况
    CDD_FUNCTION_ITEM_MANAGE_RISK: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "商标", "专利", "作品著作", "软件著作", "资质证书", "行政许可", "招投标", "行政处罚", "动产抵质押", "不动产抵押", "司法拍卖", "历史沿革", "资本运作情况"], //尽调公司经营风险
    CDD_FUNCTION_ITEM_PUB_SEN: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "商标", "专利", "作品著作", "软件著作", "资质证书", "行政许可", "招投标", "行政处罚", "动产抵质押", "不动产抵押", "司法拍卖", "新闻舆情", "公告舆情", "历史沿革", "资本运作情况"], //尽调公司舆情
    CDD_FUNCTION_ITEM_LEGAL_LITIGATION: ["工商信息", "主要股东", "对外投资", "主要人员", "分支机构", "商标", "专利", "作品著作", "软件著作", "资质证书", "行政许可", "招投标", "行政处罚", "动产抵质押", "不动产抵押", "司法拍卖", "司法案件", "法律文书", "失信被执行人", "被执行人", "新闻舆情", "公告舆情", "底稿管理", "历史沿革", "资本运作情况"], //尽调公司法律诉讼
}

export const innerCircleMap = [
    "rgb(59, 113, 198)",
    "rgb(97,212,173)",
    "rgb(97,145,212)",
    "rgb(237,189,105)",
    "rgb(135,97,212)",
    "rgb(198,192,252)",
    "rgb(105,189,237)",
    "rgb(235, 237, 105)",
    "rgb(97, 212, 173)",
    "rgb(27,189,105)",
    "rgb(113,126,198)",
    "rgb(67,106,210)",
    "rgb(204,183,195)",
    "rgb(218,105,224)",
    "rgb(198,128,209)",
    "rgb(216,119,220)",
    "rgb(68,136,236)",
    "rgb(87,134,176)",
    "rgb(182,110,155)",
    "rgb(82,164,227)",
    "rgb(146,129,253)",
    "rgb(129,172,234)",
    "rgb(197,101,240)",
    "rgb(157,142,184)",
    "rgb(112,119,202)",
    "rgb(78,111,213)",
    "rgb(107,125,212)",
    "rgb(117,185,250)",
    "rgb(90,181,195)",
    "rgb(106,140,229)",
    "rgb(107,121,243)",
    "rgb(154,157,181)",
    "rgb(170,101,249)",
    "rgb(68,162,181)",
    "rgb(192,141,170)",
    "rgb(85,107,215)",
    "rgb(136,179,209)",
    "rgb(208,127,250)",
    "rgb(116,201,206)",
    "rgb(202,164,211)",
    "rgb(183,202,238)",
    "rgb(150,145,209)",
    "rgb(191,183,241)",
    "rgb(209,101,202)",
    "rgb(165,151,194)",
    "rgb(104,198,176)",
    "rgb(149,205,190)",
    "rgb(206,168,191)",
    "rgb(83,180,230)",
    "rgb(146,208,234)",
    "rgb(213,201,201)",
    "rgb(60,183,190)",
    "rgb(125,149,165)",
    "rgb(110,170,166)",
    "rgb(119,169,245)",
    "rgb(143,162,240)",
    "rgb(63,194,193)",
    "rgb(99,219,219)",
    "rgb(143,119,191)",
    "rgb(220,218,233)",
    "rgb(134,139,175)",
    "rgb(188,172,251)",
    "rgb(115,194,241)",
    "rgb(218,133,200)",
    "rgb(150,124,246)",
    "rgb(176,138,179)",
    "rgb(182,158,255)",
    "rgb(107,202,167)",
    "rgb(100,212,227)",
    "rgb(202,146,252)",
    "rgb(198,219,247)",
    "rgb(83,209,201)",
    "rgb(179,211,189)",
    "rgb(174,153,189)",
    "rgb(203,174,173)",
    "rgb(63,182,205)",
    "rgb(129,127,252)",
    "rgb(67,128,211)",
    "rgb(94,146,180)",
    "rgb(186,165,211)",
    "rgb(63,130,218)",
    "rgb(113,103,168)",
    "rgb(175,183,206)",
    "rgb(78,120,158)",
    "rgb(68,191,159)",
    "rgb(202,189,242)",
    "rgb(165,118,245)",
    "rgb(63,138,189)",
    "rgb(79,123,157)",
    "rgb(135,204,245)",
    "rgb(75,212,184)",
    "rgb(212,119,228)",
    "rgb(147,107,231)",
    "rgb(69,145,225)",
    "rgb(158,219,219)",
    "rgb(197,151,246)",
    "rgb(143,217,194)",
    "rgb(158,101,234)",
    "rgb(62,184,247)",
    "rgb(86,178,253)",
    "rgb(196,109,244)",
    "rgb(211,204,203)",
    "rgb(76,122,174)",
    "rgb(104,165,165)",
    "rgb(148,163,166)",
    "rgb(128,119,239)",
    "rgb(77,212,253)",
    "rgb(125,181,165)",
    "rgb(145,114,241)",
    "rgb(206,137,193)",
    "rgb(63,157,232)",
    "rgb(145,167,188)",
    "rgb(85,187,196)",
    "rgb(144,127,250)",
    "rgb(206,136,155)",
    "rgb(117,185,208)",
    "rgb(168,130,188)",
    "rgb(102,101,193)",
    "rgb(149,182,245)",
    "rgb(68,109,164)",
    "rgb(108,188,203)",
    "rgb(155,147,211)",
    "rgb(143,173,221)",
    "rgb(139,145,161)",
    "rgb(208,154,206)",
    "rgb(100,182,169)",
    "rgb(105,118,184)",
    "rgb(90,175,247)",
    "rgb(66,170,193)",
    "rgb(197,102,205)",
    "rgb(220,128,168)",
    "rgb(62,121,184)",
    "rgb(136,178,212)",
    "rgb(155,118,220)",
    "rgb(179,169,180)",
    "rgb(195,191,215)",
    "rgb(211,188,195)",
    "rgb(196,176,199)",
    "rgb(156,185,188)",
    "rgb(194,215,239)",
    "rgb(166,151,175)",
    "rgb(99,208,155)",
    "rgb(115,122,191)",
    "rgb(83,144,223)",
    "rgb(60,112,175)",
    "rgb(208,152,161)",
    "rgb(166,129,189)",
    "rgb(120,103,157)",
    "rgb(83,210,235)",
    "rgb(96,133,157)",
    "rgb(91,123,231)",
    "rgb(83,129,202)",
    "rgb(199,112,165)",
    "rgb(128,204,199)",
    "rgb(126,195,205)",
    "rgb(88,179,196)",
    "rgb(60,159,173)",
    "rgb(153,220,242)",
    "rgb(135,104,247)",
    "rgb(114,125,251)",
    "rgb(152,189,162)",
    "rgb(192,199,249)",
    "rgb(138,159,219)",
    "rgb(150,203,185)",
    "rgb(102,177,220)",
    "rgb(149,204,189)",
    "rgb(197,218,251)",
    "rgb(126,179,195)",
    "rgb(80,151,213)",
    "rgb(205,157,224)",
    "rgb(141,210,218)",
    "rgb(161,164,246)",
    "rgb(190,186,156)",
    "rgb(152,214,173)",
    "rgb(192,157,212)",
    "rgb(112,131,156)",
    "rgb(78,214,215)",
    "rgb(87,132,172)",
    "rgb(111,166,222)",
    "rgb(190,151,165)",
    "rgb(198,158,156)",
    "rgb(156,130,156)",
    "rgb(172,206,241)",
    "rgb(172,135,216)",
    "rgb(161,213,160)",
    "rgb(153,205,229)",
    "rgb(108,128,213)",
    "rgb(208,165,192)",
    "rgb(184,137,215)",
    "rgb(83,115,252)",
    "rgb(146,150,250)",
    "rgb(137,130,245)",
    "rgb(215,214,160)",
    "rgb(170,142,202)",
    "rgb(88,173,163)",
    "rgb(216,128,166)",
    "rgb(107,141,233)",
    "rgb(183,164,187)",
    "rgb(113,204,219)",
    "rgb(151,111,198)",
    "rgb(122,195,231)",
    "rgb(181,210,199)",
    "rgb(71,141,158)",
    "rgb(107,178,193)",
    "rgb(85,209,253)",
    "rgb(129,153,243)",
    "rgb(152,188,167)",
    "rgb(156,116,239)",
    "rgb(170,159,240)",
    "rgb(79,112,215)",
    "rgb(119,187,192)",
    "rgb(96,101,214)",
    "rgb(95,121,242)",
    "rgb(97,162,233)",
    "rgb(148,139,190)",
    "rgb(149,146,203)",
    "rgb(187,183,235)",
    "rgb(81,161,179)",
    "rgb(103,192,174)",
    "rgb(140,126,211)",
    "rgb(126,171,174)",
    "rgb(135,154,222)",
    "rgb(204,123,180)",
    "rgb(149,115,252)",
    "rgb(186,125,167)",
    "rgb(201,107,226)",
    "rgb(73,207,196)",
    "rgb(209,194,248)",
    "rgb(124,196,158)",
    "rgb(151,113,243)",
    "rgb(91,207,171)",
    "rgb(66,171,167)",
    "rgb(180,144,181)",
    "rgb(67,142,194)",
    "rgb(125,101,241)",
    "rgb(151,172,175)",
    "rgb(145,186,240)",
    "rgb(167,132,193)",
    "rgb(181,197,197)",
    "rgb(175,114,208)",
    "rgb(171,184,239)",
    "rgb(101,218,243)",
    "rgb(201,214,157)",
    "rgb(97,134,179)",
    "rgb(156,150,227)",
    "rgb(77,182,205)",
    "rgb(86,209,186)",
    "rgb(91,128,188)",
    "rgb(121,189,171)",
    "rgb(113,186,209)",
    "rgb(179,144,156)",
    "rgb(161,195,227)",
    "rgb(182,166,198)",
    "rgb(153,113,172)",
    "rgb(146,214,230)",
    "rgb(135,122,210)",
    "rgb(206,129,217)",
    "rgb(102,214,188)",
    "rgb(125,169,239)",
    "rgb(215,166,184)",
    "rgb(152,175,244)",
    "rgb(159,194,156)",
    "rgb(64,163,225)",
    "rgb(175,122,231)",
    "rgb(159,213,180)",
    "rgb(147,129,213)",
    "rgb(83,116,250)",
    "rgb(121,219,171)",
    "rgb(63,145,158)",
    "rgb(106,105,208)",
    "rgb(129,210,158)",
    "rgb(118,198,245)",
    "rgb(155,200,216)",
    "rgb(210,102,249)",
    "rgb(184,198,162)",
    "rgb(132,216,206)",
    "rgb(195,200,253)",
    "rgb(67,134,203)",
    "rgb(212,204,220)",
    "rgb(193,150,193)",
    "rgb(181,148,188)",
    "rgb(160,186,169)",
    "rgb(125,173,163)",
    "rgb(166,108,212)",
    "rgb(193,168,207)",
    "rgb(93,100,188)",
    "rgb(178,168,178)",
    "rgb(78,118,179)",
    "rgb(197,189,248)",
    "rgb(116,137,184)",
    "rgb(65,138,225)",
    "rgb(177,119,175)",
    "rgb(97,208,171)",
    "rgb(75,180,203)",
    "rgb(87,134,218)",
    "rgb(171,160,219)",
    "rgb(203,109,170)",
    "rgb(117,103,158)",
    "rgb(185,107,159)",
    "rgb(134,186,229)",
    "rgb(218,199,226)",
    "rgb(109,201,204)",
    "rgb(170,138,155)",
    "rgb(84,124,255)",
    "rgb(193,177,246)",
    "rgb(178,169,175)",
    "rgb(213,173,195)",
    "rgb(132,204,249)",
    "rgb(61,128,177)",
    "rgb(158,194,198)",
]

export const outerCircleMap = [
    "rgb(59, 113, 198)",
    "rgb(97,212,173)",
    "rgb(97,145,212)",
    "rgb(237,189,105)",
    "rgb(135,97,212)",
    "rgb(198,192,252)",
    "rgb(105,189,237)",
    "rgb(235, 237, 105)",
    "rgb(97, 212, 173)",
    "rgb(27,189,105)",
    "rgb(113,126,198)",
    "rgb(67,106,210)",
    "rgb(204,183,195)",
    "rgb(218,105,224)",
    "rgb(198,128,209)",
    "rgb(216,119,220)",
    "rgb(68,136,236)",
    "rgb(87,134,176)",
    "rgb(182,110,155)",
    "rgb(82,164,227)",
    "rgb(146,129,253)",
    "rgb(129,172,234)",
    "rgb(197,101,240)",
    "rgb(157,142,184)",
    "rgb(112,119,202)",
    "rgb(78,111,213)",
    "rgb(107,125,212)",
    "rgb(117,185,250)",
    "rgb(90,181,195)",
    "rgb(106,140,229)",
    "rgb(107,121,243)",
    "rgb(154,157,181)",
    "rgb(170,101,249)",
    "rgb(68,162,181)",
    "rgb(192,141,170)",
    "rgb(85,107,215)",
    "rgb(136,179,209)",
    "rgb(208,127,250)",
    "rgb(116,201,206)",
    "rgb(202,164,211)",
    "rgb(183,202,238)",
    "rgb(150,145,209)",
    "rgb(191,183,241)",
    "rgb(209,101,202)",
    "rgb(165,151,194)",
    "rgb(104,198,176)",
    "rgb(149,205,190)",
    "rgb(206,168,191)",
    "rgb(83,180,230)",
    "rgb(146,208,234)",
    "rgb(213,201,201)",
    "rgb(60,183,190)",
    "rgb(125,149,165)",
    "rgb(110,170,166)",
    "rgb(119,169,245)",
    "rgb(143,162,240)",
    "rgb(63,194,193)",
    "rgb(99,219,219)",
    "rgb(143,119,191)",
    "rgb(220,218,233)",
    "rgb(134,139,175)",
    "rgb(188,172,251)",
    "rgb(115,194,241)",
    "rgb(218,133,200)",
    "rgb(150,124,246)",
    "rgb(176,138,179)",
    "rgb(182,158,255)",
    "rgb(107,202,167)",
    "rgb(100,212,227)",
    "rgb(202,146,252)",
    "rgb(198,219,247)",
    "rgb(83,209,201)",
    "rgb(179,211,189)",
    "rgb(174,153,189)",
    "rgb(203,174,173)",
    "rgb(63,182,205)",
    "rgb(129,127,252)",
    "rgb(67,128,211)",
    "rgb(94,146,180)",
    "rgb(186,165,211)",
    "rgb(63,130,218)",
    "rgb(113,103,168)",
    "rgb(175,183,206)",
    "rgb(78,120,158)",
    "rgb(68,191,159)",
    "rgb(202,189,242)",
    "rgb(165,118,245)",
    "rgb(63,138,189)",
    "rgb(79,123,157)",
    "rgb(135,204,245)",
    "rgb(75,212,184)",
    "rgb(212,119,228)",
    "rgb(147,107,231)",
    "rgb(69,145,225)",
    "rgb(158,219,219)",
    "rgb(197,151,246)",
    "rgb(143,217,194)",
    "rgb(158,101,234)",
    "rgb(62,184,247)",
    "rgb(86,178,253)",
    "rgb(196,109,244)",
    "rgb(211,204,203)",
    "rgb(76,122,174)",
    "rgb(104,165,165)",
    "rgb(148,163,166)",
    "rgb(128,119,239)",
    "rgb(77,212,253)",
    "rgb(125,181,165)",
    "rgb(145,114,241)",
    "rgb(206,137,193)",
    "rgb(63,157,232)",
    "rgb(145,167,188)",
    "rgb(85,187,196)",
    "rgb(144,127,250)",
    "rgb(206,136,155)",
    "rgb(117,185,208)",
    "rgb(168,130,188)",
    "rgb(102,101,193)",
    "rgb(149,182,245)",
    "rgb(68,109,164)",
    "rgb(108,188,203)",
    "rgb(155,147,211)",
    "rgb(143,173,221)",
    "rgb(139,145,161)",
    "rgb(208,154,206)",
    "rgb(100,182,169)",
    "rgb(105,118,184)",
    "rgb(90,175,247)",
    "rgb(66,170,193)",
    "rgb(197,102,205)",
    "rgb(220,128,168)",
    "rgb(62,121,184)",
    "rgb(136,178,212)",
    "rgb(155,118,220)",
    "rgb(179,169,180)",
    "rgb(195,191,215)",
    "rgb(211,188,195)",
    "rgb(196,176,199)",
    "rgb(156,185,188)",
    "rgb(194,215,239)",
    "rgb(166,151,175)",
    "rgb(99,208,155)",
    "rgb(115,122,191)",
    "rgb(83,144,223)",
    "rgb(60,112,175)",
    "rgb(208,152,161)",
    "rgb(166,129,189)",
    "rgb(120,103,157)",
    "rgb(83,210,235)",
    "rgb(96,133,157)",
    "rgb(91,123,231)",
    "rgb(83,129,202)",
    "rgb(199,112,165)",
    "rgb(128,204,199)",
    "rgb(126,195,205)",
    "rgb(88,179,196)",
    "rgb(60,159,173)",
    "rgb(153,220,242)",
    "rgb(135,104,247)",
    "rgb(114,125,251)",
    "rgb(152,189,162)",
    "rgb(192,199,249)",
    "rgb(138,159,219)",
    "rgb(150,203,185)",
    "rgb(102,177,220)",
    "rgb(149,204,189)",
    "rgb(197,218,251)",
    "rgb(126,179,195)",
    "rgb(80,151,213)",
    "rgb(205,157,224)",
    "rgb(141,210,218)",
    "rgb(161,164,246)",
    "rgb(190,186,156)",
    "rgb(152,214,173)",
    "rgb(192,157,212)",
    "rgb(112,131,156)",
    "rgb(78,214,215)",
    "rgb(87,132,172)",
    "rgb(111,166,222)",
    "rgb(190,151,165)",
    "rgb(198,158,156)",
    "rgb(156,130,156)",
    "rgb(172,206,241)",
    "rgb(172,135,216)",
    "rgb(161,213,160)",
    "rgb(153,205,229)",
    "rgb(108,128,213)",
    "rgb(208,165,192)",
    "rgb(184,137,215)",
    "rgb(83,115,252)",
    "rgb(146,150,250)",
    "rgb(137,130,245)",
    "rgb(215,214,160)",
    "rgb(170,142,202)",
    "rgb(88,173,163)",
    "rgb(216,128,166)",
    "rgb(107,141,233)",
    "rgb(183,164,187)",
    "rgb(113,204,219)",
    "rgb(151,111,198)",
    "rgb(122,195,231)",
    "rgb(181,210,199)",
    "rgb(71,141,158)",
    "rgb(107,178,193)",
    "rgb(85,209,253)",
    "rgb(129,153,243)",
    "rgb(152,188,167)",
    "rgb(156,116,239)",
    "rgb(170,159,240)",
    "rgb(79,112,215)",
    "rgb(119,187,192)",
    "rgb(96,101,214)",
    "rgb(95,121,242)",
    "rgb(97,162,233)",
    "rgb(148,139,190)",
    "rgb(149,146,203)",
    "rgb(187,183,235)",
    "rgb(81,161,179)",
    "rgb(103,192,174)",
    "rgb(140,126,211)",
    "rgb(126,171,174)",
    "rgb(135,154,222)",
    "rgb(204,123,180)",
    "rgb(149,115,252)",
    "rgb(186,125,167)",
    "rgb(201,107,226)",
    "rgb(73,207,196)",
    "rgb(209,194,248)",
    "rgb(124,196,158)",
    "rgb(151,113,243)",
    "rgb(91,207,171)",
    "rgb(66,171,167)",
    "rgb(180,144,181)",
    "rgb(67,142,194)",
    "rgb(125,101,241)",
    "rgb(151,172,175)",
    "rgb(145,186,240)",
    "rgb(167,132,193)",
    "rgb(181,197,197)",
    "rgb(175,114,208)",
    "rgb(171,184,239)",
    "rgb(101,218,243)",
    "rgb(201,214,157)",
    "rgb(97,134,179)",
    "rgb(156,150,227)",
    "rgb(77,182,205)",
    "rgb(86,209,186)",
    "rgb(91,128,188)",
    "rgb(121,189,171)",
    "rgb(113,186,209)",
    "rgb(179,144,156)",
    "rgb(161,195,227)",
    "rgb(182,166,198)",
    "rgb(153,113,172)",
    "rgb(146,214,230)",
    "rgb(135,122,210)",
    "rgb(206,129,217)",
    "rgb(102,214,188)",
    "rgb(125,169,239)",
    "rgb(215,166,184)",
    "rgb(152,175,244)",
    "rgb(159,194,156)",
    "rgb(64,163,225)",
    "rgb(175,122,231)",
    "rgb(159,213,180)",
    "rgb(147,129,213)",
    "rgb(83,116,250)",
    "rgb(121,219,171)",
    "rgb(63,145,158)",
    "rgb(106,105,208)",
    "rgb(129,210,158)",
    "rgb(118,198,245)",
    "rgb(155,200,216)",
    "rgb(210,102,249)",
    "rgb(184,198,162)",
    "rgb(132,216,206)",
    "rgb(195,200,253)",
    "rgb(67,134,203)",
    "rgb(212,204,220)",
    "rgb(193,150,193)",
    "rgb(181,148,188)",
    "rgb(160,186,169)",
    "rgb(125,173,163)",
    "rgb(166,108,212)",
    "rgb(193,168,207)",
    "rgb(93,100,188)",
    "rgb(178,168,178)",
    "rgb(78,118,179)",
    "rgb(197,189,248)",
    "rgb(116,137,184)",
    "rgb(65,138,225)",
    "rgb(177,119,175)",
    "rgb(97,208,171)",
    "rgb(75,180,203)",
    "rgb(87,134,218)",
    "rgb(171,160,219)",
    "rgb(203,109,170)",
    "rgb(117,103,158)",
    "rgb(185,107,159)",
    "rgb(134,186,229)",
    "rgb(218,199,226)",
    "rgb(109,201,204)",
    "rgb(170,138,155)",
    "rgb(84,124,255)",
    "rgb(193,177,246)",
    "rgb(178,169,175)",
    "rgb(213,173,195)",
    "rgb(132,204,249)",
    "rgb(61,128,177)",
    "rgb(158,194,198)",
]