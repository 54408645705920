import "./index.scss";
import React, { useState, useEffect, useContext } from "react";
import { Popover, Input, Button, message, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import projectApi from "../../../apis/project";
import { getLicenseValid } from "../../../unit/unit";
const { TextArea } = Input;

const Content = (props) => {
  const {
    info,
    setShow,
    dataTable,
    getData,
    typeName,
    defaultData,
    remarkId,
    dataInfo,
    mode,
    data,
    projectId,
    focusContent,
    focusId,
  } = props;

  const [remark, setRemark] = useState(null);
  const [code, setCode] = useState(defaultData?.code || null);

  const remarkChange = (e) => {
    setRemark(e.target.value);
  };
  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_CONCERN_TYPE").then((res) => {
      if (res.code === 200) {
        const _code = res.data.find((item) => item.dic_item_name === typeName);
        setCode(_code?.dic_item_code);
      } else {
        setCode("");
      }
    });
  };
  useEffect(() => {
    if (typeName) {
      getCodeInfo();
    }
  }, [typeName]);
  const onFinish = () => {
    if (!remark) {
      message.info("请输入关闭说明");
      return;
    }
    let obj = {};

    Object.keys(dataInfo || {})?.forEach((key) => {
      obj[key] = dataInfo[key]?.val;
    });

    let params = {
      data_table: dataTable,
      data_id: info.id,
      remark: remark,
      data_string: JSON.stringify(obj),
      focus_status: "0",
      event_type_code: code,
      focus_content: focusContent,
      focus_id: focusId,
    };
    if (mode === 20) {
      params.mode = 20;
    } else if (mode === 30) {
      params.mode = 30;
      params.data_id = undefined;
    } else {
      params.mode = 10;
      params.data_field = info.key;
    }
    projectApi.closeFocus(data.id, projectId).then((res2) => {
      if (res2.code === 200) {
        projectApi.setFocusRemark(projectId, params).then((res) => {
          if (res.code === 200) {
            getData && getData();
            message.success("关闭成功");
          } else {
            message.error(res.message);
          }
        });
      } else {
        message.error(res2.message);
      }
    });
  };
  return (
    <div className="deleteIconContent">
      <div className="title">关闭说明</div>
      <TextArea
        rows={4}
        showCount={true}
        placeholder="请输入关闭说明"
        showCount={true}
        maxLength={120}
        style={{ marginBottom: "10px", width: "100%" }}
        onChange={remarkChange}
        value={remark}
      />
      <div className="btnBox drawerFooterBtnBox">
        <Button
          className="noBg"
          onClick={() => {
            props.onClose();
          }}
        >
          取消
        </Button>

        <Button
          type="primary"
          onClick={() => {
            onFinish();
          }}
        >
          关闭
        </Button>
      </div>
    </div>
  );
};

const CloseIcon = (props) => {
  const {
    /* 批注详情对象(内含 comments,id 字段) */
    info,
    /* icon是否显示回调 */
    setShow,
    /* 表名 */
    dataTable,
    /* 回调函数 */
    getData,
    /* 模块中文名称 */
    typeName,
    /* 默认值(修改) */
    defaultData,
    /* 批注id(修改) */
    remarkId,
    /* 单个字段或整条数据的 原数据 */
    dataInfo,
    /* 关注的原数据(关注处才会有关闭icon) */
    data,
    /* 单个字段还是整条(单个字段为空,整条为20,整个模块为30) */
    mode,
    /* 项目id */
    projectId,
    /* 批注内容 */
    focusContent,
    /* 关注ID */
    focusId,
  } = props;

  const [visible, setVisible] = useState(false);

  const iconClick = () => {
    getLicenseValid().then(res=>{
      if(res){
        setVisible(true);
      }
    })
  };

  const handleVisibleChange = (newVisible) => {
    if(!newVisible){
      setVisible(newVisible);
    }
  };

  useEffect(() => {
    setShow && setShow(visible);
  }, [visible]);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      overlayClassName="myCardPopover"
      visible={visible}
      destroyTooltipOnHide={true}
      onVisibleChange={handleVisibleChange}
      content={
        <Content
          info={info}
          setShow={setShow}
          dataTable={dataTable}
          getData={getData}
          typeName={typeName}
          defaultData={defaultData}
          remarkId={remarkId}
          dataInfo={dataInfo}
          data={data}
          mode={mode}
          projectId={projectId}
          focusContent={focusContent}
          focusId={focusId}
          onClose={() => {
            setVisible(false);
          }}
        ></Content>
      }
    >
      <Tooltip placement="top" title="关闭" arrowPointAtCenter>
        <CloseCircleOutlined
          onClick={() => {
            iconClick();
          }}
          className="cursor-pointer editIcon hoverIcon"
        />
      </Tooltip>
    </Popover>
  );
};
export default CloseIcon;
