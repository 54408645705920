import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  calcCompanyNameFunc,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import YearSelect from "../../../../TenderingAndBidding/components/YearSelect";
const TopPurchase = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("开票金额");
  const [dataInfo, setDataInfo, getDataInfo] = useGetState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [yearList, setYearList, getYearList] = useGetState([]);

  const myChart = useRef(null);

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let newEchartsData = echartsData.sort((a, b) => {
      if (getInitType() === "开票金额") {
        return b.amount - a.amount;
      } else {
        return b.number - a.number;
      }
    });
    newEchartsData = newEchartsData?.slice(0, 5);
    let countMax = 0;
    newEchartsData?.forEach((item) => {
      if (item?.number >= countMax) {
        countMax = item?.number;
      }
    });

    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("topPurchaseContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const series = [];
    let map = [];
    if (getInitType() === "开票金额") {
      map = ["开票金额", "开票次数"];
    } else {
      map = ["开票次数", "开票金额"];
    }
    map.forEach((key, index) => {
      if (key === "开票金额") {
        series.push({
          name: "开票金额",
          type: "bar",
          barMaxWidth: 40,
          // stack: "Total",
          xAxisIndex: index === 0 ? undefined : 1,
          yAxisIndex: index === 0 ? undefined : 1,
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },

          itemStyle: {
            color: "#0068B2",
            barBorderRadius: index === 0 ? [4, 0, 0, 4] : [0, 4, 4, 0],
          },
          data: newEchartsData?.map((item) => ({
            value: item?.amount >= 0 ? item?.amount : 0,
            amount: item?.amount,
          })),
        });
      } else {
        series.push({
          name: "开票次数",
          type: "bar",
          barMaxWidth: 40,
          // stack: "Total",
          xAxisIndex: index === 0 ? undefined : 1,
          yAxisIndex: index === 0 ? undefined : 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + "个";
            },
          },
          label: {
            position: "left",
          },
          itemStyle: {
            color: "#61D4AD",
            barBorderRadius: index === 0 ? [4, 0, 0, 4] : [0, 4, 4, 0],
          },
          data: newEchartsData?.map((item) => item?.number),
        });
      }
    });
    const option = {
      tooltip: {
        trigger: "axis",
        // confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (info) => {
          const one = info.find((_) => _.seriesName === "开票金额");
          const two = info.find((_) => _.seriesName === "开票次数");
          let str = `<div style='font-weight:bold'>${one.name}</div>`;

          if (getInitType() === "开票金额") {
            str += `${one?.marker} ${one?.seriesName}   ${addMillimeter(
              one?.data?.amount,
              2
            )}元</div>`;
            str += `<div><div>${two?.marker} ${two?.seriesName}   ${Number(two.value || 0)?.toLocaleString()}次</div>`;
          } else {
            str += `<div><div>${two?.marker} ${two?.seriesName}   ${Number(two.value || 0)?.toLocaleString()}次</div>`;
            str += `${one?.marker} ${one?.seriesName}   ${addMillimeter(
              one?.data?.amount,
              2
            )}元</div>`;
          }

          return str;
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        bottom: "0px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data:
          getInitType() === "开票金额"
            ? ["开票金额", "开票次数"]
            : ["开票次数", "开票金额"],
      },
      axisPointer: {
        link: [
          {
            yAxisIndex: "all",
          },
        ],
      },
      grid: [
        {
          show: false,
          left: "2%",
          top: 20,
          bottom: 40,
          containLabel: true,
          width: "48%",
        },
        {
          show: false,
          left: "50.5%",
          top: 60,
          bottom: 60,
          width: "0",
        },
        {
          show: false,
          right: "2%",
          top: 20,
          bottom: 40,
          containLabel: true,
          width: "48%",
        },
      ],
      xAxis: [
        {
          type: "value",
          triggerEvent: true,
          inverse: true,
          max: (value) => {
            if (value?.max < 5) {
              return 5;
            }
            return undefined;
          },
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisPointer: {
            show: false,
          },
          position: "bottom",
          axisLabel: {
            show: true,
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else if (value <= -10000) {
                return value / 1000 + "万";
              } else if (value <= -100000000) {
                return value / 10000000 + "亿";
              } else {
                return value;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        {
          max: (value) => {
            if (value?.max < 5) {
              return 5;
            }
            return undefined;
          },
          gridIndex: 2,
          type: "value",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisPointer: {
            show: false,
          },
          position: "bottom",
          axisLabel: {
            show: true,
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else if (value <= -10000) {
                return value / 1000 + "万";
              } else if (value <= -100000000) {
                return value / 10000000 + "亿";
              } else {
                return value;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "category",
          inverse: true,
          position: "left",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 8,
            textStyle: {
              color: "#313131",
              fontSize: 12,
            },
            formatter: (value) => {
              return calcCompanyNameFunc(value, 7, "topPurchaseContentBox");
            },
          },
          axisPointer: {
            show: true,
            type: "shadow",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          data: newEchartsData?.map((item) => item?.trading_company_name),
        },
        {
          gridIndex: 2,
          type: "category",
          inverse: true,
          position: "left",
          axisPointer: {
            // show: false,
            type: "shadow",
            label: {
              show: false,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          data: newEchartsData?.map((item) => item?.trading_company_name),
        },
      ],
      series: series,
    };
    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  const initData = (data) => {
    let yearArr = Object?.keys(data)
      ?.filter((_) => _ !== "name")
      ?.map((item) => {
        return item?.slice(0, 4);
      })
      .sort((a, b) => Number(a) - Number(b));
    yearArr = [...new Set(yearArr)];
    const nowYear = yearArr?.at(-1);
    setYearList(yearArr);
    setSelectYear(nowYear);
    setDataInfo(data[nowYear]);
    // setDataInfo(data.data);
    initEcharts(data[nowYear]);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        company_id: selectCompany,
        event_name:
          "TOP采购供应商" +
          (getInitType() === "开票次数" ? "（按开票次数）" : "（按开票金额）"),
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  return (
    <div className="topPurchaseContent">
      <div className="headerBox">
        TOP采购供应商
        {!noData && (
          <div className="selectBox2">
            <div
              className={
                initType === "开票金额"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "开票金额") {
                  return;
                }
                setInitType("开票金额");
                setTimeout(() => {
                  initEcharts(data[getSelectYear()]);
                }, 50);
              }}
            >
              开票金额
            </div>
            <div
              className={
                initType === "开票次数"
                  ? "selectItem2 activeSelectItem2"
                  : "selectItem2"
              }
              onClick={() => {
                if (getInitType() === "开票次数") {
                  return;
                }
                setInitType("开票次数");
                setTimeout(() => {
                  initEcharts(data[getSelectYear()]);
                }, 50);
              }}
            >
              开票次数
            </div>
          </div>
        )}
        {!noData && (
          <div className="yearSelectBox">
            <YearSelect
              value={selectYear}
              defaultOptions={yearList}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initEcharts(data[value?.at(0)]);
                }, 50);
              }}
            ></YearSelect>
          </div>
        )}
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="topPurchaseContentBox">
          <div id="topPurchaseContentBox"></div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TopPurchase;
