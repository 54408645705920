import "./index.scss";
import { Modal, message, Button, Table } from "antd";
import MySpin from "../../../../../components/MySpin";
import { useEffect, useState, useRef } from "react";
import projectApi from "../../../../../apis/project";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import Scrollbars from "react-custom-scrollbars";
import { CheckOutlined } from "@ant-design/icons";
import { useSize } from "ahooks";
import MyEmpty from "../../../../../components/Empty";
import HeadPortrait from "../../../../../components/HeadPortrait";

const CopyModal = (props) => {
  const { visible, onClose, successFunc } = props;

  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const ref = useRef(null);
  const size = useSize(ref);

  const clearAll = () => {
    setLoading(true);
    setData([]);
  };

  const getDataFunc = () => {
    setLoading(true);
    projectApi
      .getCheckList(id, { suspect_status: "CDD_SUSPECT_STATUS_FINISH" })
      .then((res) => {
        if (res.code === 200) {
          const list = res.data.map((item, index) => {
            item.id = item.suspect_id;
            return item;
          });
          setData(list);
          setTableData(list?.at(0) || {});
        } else {
          setData([]);
          message.error(res.message);
        }
        setLoading(false);
      });
  };

  const success = () => {
    successFunc &&
      successFunc(
        tableData?.suspect_info?.map((item) => ({
          key: Math.random(),
          s_type: item.s_type,
          id: item.id,
          name: item.name,
          type: item.type,
        })) || []
      );
  };
  useEffect(() => {
    if (!visible) {
      clearAll();
    } else {
      getDataFunc();
    }
  }, [visible]);

  const columns = useRef([
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: "80px",
      align: "center",
      render: (text, record, index) => <>{record.index}</>,
    },
    {
      title: "核查对象分类",
      dataIndex: "s_type",
      key: "s_type",
      align: "center",
      width: "150px",
    },
    {
      title: "核查对象",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
  ]);
  return (
    <Modal
      title="复制核查对象"
      wrapClassName="copyModal"
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      width={900}
      footer={null}
      centered
    >
      <div className="copyModalContent">
        {loading ? (
          <MySpin></MySpin>
        ) : (
          <div className="copyModalContentBox">
            <div className="copyModalContentTableBox">
              <div className="copyModalContentLeftTable">
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%", width: "100%" }}
                >
                  {data?.map((item) => (
                    <div
                      className={
                        tableData?.id === item.id
                          ? "copyModalContentLeftTableItem activeItem"
                          : "copyModalContentLeftTableItem"
                      }
                      key={item.id}
                      onClick={() => {
                        setTableData(item);
                      }}
                    >
                      <div className="dateItem">{item.update_time}</div>
                      <div className="textItem">
                        <HeadPortrait
                          size={20}
                          title={
                            item.show_name +
                            (item.assist_name ? "/" + item.assist_name : "")
                          }
                          src={item?.pf_photo}
                          style={{ marginRight: "3px", marginLeft: "0px" }}
                        ></HeadPortrait>{" "}
                        {item.show_name}
                      </div>
                      <div className="textItem">
                        {"核查对象"}
                        <span>{item.suspect_info?.length}</span>
                      </div>

                      <div className="checkBox">
                        <CheckOutlined />
                      </div>
                    </div>
                  ))}
                </Scrollbars>
              </div>
              <div className="copyModalContentRightTable" ref={ref}>
                <Table
                  scroll={{
                    y: size?.height ? `${size?.height - 50}px` : null,
                  }}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                  className="myFormTable"
                  bordered={true}
                  pagination={false}
                  columns={columns.current}
                  dataSource={tableData?.suspect_info?.map((_, index) => {
                    _.index = index + 1;
                    return _;
                  })}
                />
              </div>
            </div>
            <div className="copyModalContentBtnBox">
              <Button
                className="noBg"
                onClick={() => onClose()}
                style={{ marginRight: "24px" }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  success();
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default CopyModal;
