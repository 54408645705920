import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import { Popover, Tag, message, Popconfirm, Typography, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import CommentsIcon from "../CommentsIcon";
import projectApi from "../../../apis/project";
import { useSelector } from "react-redux";
import MySpin from "../../MySpin";
import HeadPortrait from "../../HeadPortrait";
import HeadPortraitLine from "../../HeadPortraitLine";
const { Paragraph } = Typography;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 14,
    }}
    spin
  />
);
export const Content = (props) => {
  const {
    info,
    dataTable,
    getData,
    typeName,
    mode,
    projectId,
    dataInfo,
    equityNodeData,
    focusContent,
    focusId,
  } = props;
  const myInfo = useSelector((state) => state.User.userInfo);
  const colorMap = {
    批注: "#6CAEFF",
    修订: "#FE545F",
  };
  const statusColorList = {
    CDD_CONCERN_LEVEL_CHECK: "#FEC054",
    CDD_CONCERN_LEVEL_ATTENTION: "#0068B2",
    CDD_CONCERN_LEVEL_RISK: "#FE545F",
    CDD_CONCERN_LEVEL_CUSTOM: "green",
  };

  const initDefaultData = (data) => {
    let defaultData = {
      isFollow: data.focuses && data.focuses?.length > 0,
      userId: data.focuses?.at(0)?.duty_list?.map((item) => item.duty_user_id),
      type: data.focuses?.at(0)?.event_level_code,
      remark: data.remark,
      code: data.focuses?.at(0)?.event_type_code,
      codeName: data.focuses?.at(0)?.event_level_desc,
    };

    return defaultData;
  };

  const deleteItem = (item) => {
    let data_string = null;
    if (equityNodeData) {
      data_string = equityNodeData;
    }
    projectApi.deleteRemark(item.id, projectId, data_string).then((res) => {
      if (res.code === 200) {
        getData && getData();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
  };

  if (!info.comments) {
    return (
      <div className="infoLoadingBox">
        {/* <Spin indicator={antIcon} /> */}
        <MySpin></MySpin>
      </div>
    );
  }
  return (
    <div
      className="infoIconContent"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {(info.comments || []).map((item) => (
        <div className="item" key={item.type + item.id}>
          <div className="top">
            <div>{item.modify_time}</div>
            <div>{item.name}</div>
          </div>

          {item.type === "批注" ? (
            <>
              <div className="info">
                <Tag color={colorMap[item.type]}>{item.type}</Tag>
                {/* <div className="text">{item.remark}</div> */}

                <Paragraph
                  ellipsis={{ tooltip: item.remark, rows: 3 }}
                  style={{
                    width: `calc(100% - 50px)`,
                    color: "#535353",
                    fontSize: "12px",
                  }}
                >
                  {item.remark}
                </Paragraph>
              </div>
              <>
                {item.focuses && item.focuses?.length > 0 ? (
                  <div className="tag">
                    <div
                      className="status"
                      style={{
                        background:
                          statusColorList[
                            item?.focuses?.at(0)?.event_level_code
                          ],
                      }}
                    >
                      {item?.focuses?.at(0)?.event_level_desc}
                    </div>

                    <div className="user">
                      <span>@</span>
                      <div>
                        <HeadPortraitLine
                          data={item?.focuses?.at(0)?.duty_list || []}
                          size={20}
                          num={6}
                          inBody={true}
                        ></HeadPortraitLine>
                      </div>
                      {/* {item?.focuses?.at(0)?.duty_list?.map((_item) => (
                        <div key={_item.duty_user_id}>
                          <span><HeadPortrait size={20} title={_item.name} src={_item.pf_photo}></HeadPortrait></span>
                        </div>
                      ))} */}
                    </div>
                  </div>
                ) : null}
              </>
            </>
          ) : null}

          {myInfo?.co_user_id === item.creator_id && item.type === "批注" ? (
            <div className="iconBox">
              <Popconfirm
                title="确定要撤回吗?"
                icon={<ExclamationCircleFilled style={{ color: "#FE545F" }} />}
                onConfirm={() => {
                  deleteItem(item);
                }}
                okText="撤回"
                cancelText="取消"
                overlayClassName="deletePopconfirm"
              >
                <Tooltip
                  placement="top"
                  title="撤回"
                  arrowPointAtCenter
                  overlayClassName="deleteTip"
                >
                  <CloseCircleOutlined
                    style={{ marginLeft: "5px" }}
                    className="hoverIcon deleteIcon"
                  />
                </Tooltip>
              </Popconfirm>

              {item?.project_permission_urls.includes("CDD_PRJ_COMMENT") && (
                <CommentsIcon
                  getData={getData}
                  remarkId={item.id}
                  info={info}
                  mode={mode}
                  dataInfo={dataInfo}
                  projectId={projectId}
                  dataTable={dataTable}
                  typeName={typeName}
                  focusContent={focusContent}
                  focusId={focusId}
                  defaultData={initDefaultData(item)}
                ></CommentsIcon>
              )}
            </div>
          ) : null}

          {item.type === "修订" ? (
            <>
              <div className="info">
                <Tag color={colorMap[item.type]}>修订</Tag>

                <Paragraph
                  ellipsis={{
                    tooltip: {
                      title: (
                        <div className="editParagraphTip">
                          {JSON.parse(item.content)?.modify_content}
                        </div>
                      ),
                      overlayClassName: "editParagraphTooltip",
                    },
                    rows: 3,
                  }}
                  style={{
                    width: `calc(100% - 50px)`,
                    color: "#535353",
                    fontSize: "12px",
                  }}
                >
                  {JSON.parse(item.content)?.modify_content}
                </Paragraph>

                {/* <div className="text">
                  {JSON.parse(item.content)?.modify_content}
                </div> */}
              </div>
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};
const InfoIcon = (props) => {
  const {
    /* 批注详情对象(内含 comments,id 字段) */
    info,
    /* 表名 */
    dataTable,
    /* 回调函数 */
    getData,
    /* 模块中文名称 */
    typeName,
    /* icon是否显示回调 */
    setShow,
    /* 单个字段还是整条(单个字段为空,整条为20,整个模块为30) */
    mode,
    /* 样式 */
    style,
    /* 项目id */
    projectId,
    /* 是否取消固定位置(股权穿透处会传true同时带着style) */
    fixed,
    /* 单个字段或整条数据的 原数据 */
    dataInfo,
    /* 批注内容 */
    focusContent,
    /* 关注ID */
    focusId,
    /*股权节点信息 */
    equityNodeData,
    /* 是否提前渲染 */
    forceRender = false,
    /* 鼠标移入后延时多少才显示 */
    mouseEnterDelay = 0.2,
    /* tips显示内容 */
    tipsText,
    /* 触发方式 */
    trigger
  } = props;

  const [visible, setVisible] = useState(false);

  const iconClick = () => {
    setVisible(true);
  };

  useEffect(() => {
    setShow && setShow(visible);
  }, [visible]);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  if ((info?.comments?.length === 0 || !info?.comments) && !fixed) {
    return <></>;
  }

  return (
    <Popover
      trigger={trigger ? trigger : "click"}
      placement="bottomLeft"
      overlayClassName="myCardPopover noPaddingPopover"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={
        <Content
          info={info}
          getData={() => {
            getData();
            setVisible(false);
          }}
          dataTable={dataTable}
          typeName={typeName}
          mode={mode}
          projectId={projectId}
          dataInfo={dataInfo}
          focusId={focusId}
          focusContent={focusContent}
          equityNodeData={equityNodeData}
        ></Content>
      }
    >
      <Tooltip
        placement="top"
        title={tipsText ? tipsText : "历史批注"}
        arrowPointAtCenter
        forceRender={forceRender}
        mouseEnterDelay={mouseEnterDelay}
      >
        <InfoCircleOutlined
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            e.cancelBubble = false;
            iconClick();
          }}
          className="cursor-pointer editIcon hoverIcon"
        />
      </Tooltip>
    </Popover>
  );
};

export default InfoIcon;
