import "./index.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Spin, message } from "antd";
import useUrlState from "@ahooksjs/use-url-state";
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import deleteIcon from "../../../../../assets/delete.svg";
import icon1 from "../../../../../assets/核查对象.svg";
import icon2 from "../../../../../assets/核查中.svg";
import icon3 from "../../../../../assets/核查规则.svg";
import LoadingSvg from "../../../../../assets/loading.svg";
import ContinueSvg from "../../../../../assets/继续核查.svg";
import WaitSvg from "../../../../../assets/排队中.svg";
import terminationIcon from "../../../../../assets/终止.svg";
import WainSvg from "../../../../../assets/终止1.svg";
import MessageModal from "../../../../../components/MessageModal";
import projectApi from "../../../../../apis/project";
import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import { useClickAway } from "ahooks";
import HeadPortrait from "../../../../../components/HeadPortrait";
import { getLicenseValid } from "../../../../../unit/unit";

const Content = (props) => {
  const { info } = props;
  const _info = {
    ...info,
  };
  if (info["核查对象穿透层级"]) {
    _info["核查对象穿透层级"] = {
      value: [`最多${info["核查对象穿透层级"]?.value}层`],
    };
  }
  if (info["核查对象穿透股权比例"]) {
    _info["核查对象穿透股权比例"] = {
      value: [`持股比例不低于${info["核查对象穿透股权比例"]?.value}%`],
    };
  }
  const keyList = Object.keys(info || {}).sort((a, b) => {
    return info[a]?.index - info[b]?.index;
  });
  return (
    <div className="ruleContent">
      {keyList.map((key) => (
        <div key={key} className="ruleItem">
          <div className="spot"></div>
          <div>
            <div className="title">{key}</div>
            <div className="textContent">
              {_info[key]?.value?.map((item) => (
                <div key={Math.random()} className="text">
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RecordCard = (props) => {
  const {
    style,
    info,
    notClick,
    successFunc,
    reVerificationFunc,
    setDefaultType,
  } = props;
  const [state, setState] = useUrlState({});
  const { id } = useParams();
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const userInfo = useSelector((state) => state.User.userInfo) || {};
  const [tipShow, setTipShow] = useState(false);
  const ref = useRef(null);

  useClickAway(() => {
    setTipShow(false);
  }, ref);

  const deleteItem = () => {
    MessageModal({
      type: "warning",
      description: "确定要删除该条核查任务吗？",
      title: "删除核查任务",
      onOk: () => {
        // statusChangeHandle(status, data);
        projectApi.deleteCheck(id, info.id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            successFunc();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const setCheckStatus = (status) => {
    MessageModal({
      type: "warning",
      description: `确定要${
        status === "CDD_SUSPECT_STATUS_WAIT" ? "重启" : "中止"
      }核查任务吗？`,
      title: `${
        status === "CDD_SUSPECT_STATUS_WAIT" ? "重启" : "中止"
      }核查任务`,
      onOk: () => {
        // statusChangeHandle(status, data);
        projectApi.setCheckStatus(id, info.id, status).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            successFunc();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const handleItem = (status) => {
    if (status === "删除") {
      deleteItem();
    } else if (status === "中止") {
      setCheckStatus("CDD_SUSPECT_STATUS_QUIT");
    } else if (status === "继续核查") {
      setCheckStatus("CDD_SUSPECT_STATUS_WAIT");
    } else if (status === "重新核查") {
      MessageModal({
        type: "",
        description: `确定要重新核查该条核查任务吗？`,
        title: `重新核查`,
        okText: status,
        onOk: () => {
          reVerificationFunc(info?.suspect_info || [], info.suspect_rule || {});
        },
      });
    }
  };

  const handleFunc = (type) => {
    if (type === "核查对象" || type === "核查结果") {
      setDefaultType(type);
      setTimeout(() => {
        setState({ id: info.id });
      }, 100);
    } else if (type === "核查规则") {
      setTipShow(true);
    }
  };
  return (
    <div className="recordItem" style={{ ...style }}>
      <div className="recordItemHeader">
        <span>
          {info.create_time}{" "}
          <HeadPortrait
            size={22}
            src={info.pf_photo}
            title={
              info.show_name + (info.assist_name ? "/" + info.assist_name : "")
            }
          ></HeadPortrait>{" "}
          {info?.show_name}
        </span>
        {projectInfo?.project_permission_urls.includes(
          "CDD_PRJ_CDD_ITEM_EDIT"
        ) && (
          <span className="recordItemHeaderBtn">
            {info?.suspect_status === "CDD_SUSPECT_STATUS_QUIT" &&
              (projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CLOSE"
              ) ||
                info.user_id === userInfo.co_user_id) && (
                <div
                  className="recordItemHeaderBtnItem"
                  onClick={(e) => {
                    getLicenseValid().then((res) => {
                      if (res) {
                        handleItem("继续核查");
                      }
                    });
                  }}
                >
                  <ReactSVG className="deleteIcon" src={ContinueSvg}></ReactSVG>
                  继续核查
                </div>
              )}
            {info?.suspect_status === "CDD_SUSPECT_STATUS_QUIT" &&
              (projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CLOSE"
              ) ||
                info.user_id === userInfo.co_user_id) && (
                <div
                  className="recordItemHeaderBtnItem"
                  onClick={(e) => {
                    handleItem("删除");
                  }}
                >
                  <ReactSVG className="deleteIcon" src={deleteIcon}></ReactSVG>
                  删除
                </div>
              )}
            {(info?.suspect_status === "CDD_SUSPECT_STATUS_WAIT" ||
              info?.suspect_status === "CDD_SUSPECT_STATUS_ING") &&
              (projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CLOSE"
              ) ||
                info.user_id === userInfo.co_user_id) && (
                <div
                  className="recordItemHeaderBtnItem"
                  onClick={(e) => {
                    handleItem("中止");
                  }}
                >
                  <ReactSVG
                    className="deleteIcon"
                    src={terminationIcon}
                  ></ReactSVG>
                  中止
                </div>
              )}
            {notClick &&
              info?.suspect_status === "CDD_SUSPECT_STATUS_FINISH" && (
                <div
                  className="recordItemHeaderBtnItem"
                  onClick={(e) => {
                    getLicenseValid().then((res) => {
                      if (res) {
                        handleItem("重新核查");
                      }
                    });
                  }}
                >
                  <ReactSVG className="deleteIcon" src={ContinueSvg}></ReactSVG>
                  重新核查
                </div>
              )}
          </span>
        )}
      </div>
      <div className="recordItemCardContent">
        {/* 核查对象 */}
        <div
          className="recordItemCardContentItem"
          style={{ cursor: notClick ? null : "pointer" }}
          onClick={(e) => {
            !notClick && handleFunc("核查对象");
          }}
        >
          <div className="iconContent" style={{ background: "#92A6FB" }}>
            <ReactSVG className="icon" src={icon1}></ReactSVG>
          </div>
          <div className="textContent">
            <div className="num">{info?.suspect_info?.length}</div>
            <div className="text">核查对象</div>
          </div>
        </div>
        {/* 核查状态 */}
        <div
          className="recordItemCardContentItem"
          style={{ minWidth: "200px", cursor: notClick ? null : "pointer" }}
          onClick={(e) => {
            !notClick && handleFunc("核查结果");
          }}
        >
          <div className="iconContent" style={{ background: "#C88EED" }}>
            <ReactSVG className="icon" src={icon2}></ReactSVG>
          </div>
          <div className="textContent">
            <div className="result">
              {info?.suspect_status === "CDD_SUSPECT_STATUS_FINISH" && (
                <>
                  <span>{info?.nums}</span>个疑似关联关系
                </>
              )}
              {info?.suspect_status === "CDD_SUSPECT_STATUS_ING" && (
                <Spin
                  className="mySpinLoading"
                  tip={props.tip}
                  indicator={
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  }
                ></Spin>
              )}
              {info?.suspect_status === "CDD_SUSPECT_STATUS_WAIT" && (
                <ReactSVG src={WaitSvg} className="waitSvg" />
              )}
              {info?.suspect_status === "CDD_SUSPECT_STATUS_QUIT" && (
                <ReactSVG src={WainSvg} className="waitSvg" />
              )}
            </div>
            <div className="text">
              {info?.suspect_status === "CDD_SUSPECT_STATUS_FINISH"
                ? "核查结果"
                : info?.suspect_status_msg}
              {info?.suspect_status === "CDD_SUSPECT_STATUS_ING" &&
                `(${info?.process_percent}%)`}
            </div>
          </div>
        </div>

        {/* 核查规则 */}
        <Tooltip
          open={tipShow}
          title={<Content info={info.suspect_rule}></Content>}
          placement="top"
          overlayClassName="rulesTooltip"
          arrowPointAtCenter
          // getPopupContainer={(e) => e?.parentNode}
        >
          <div
            className="recordItemCardContentItem"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleFunc("核查规则");
            }}
            ref={ref}
          >
            <div className="iconContent" style={{ background: "#00A5AF" }}>
              <ReactSVG className="icon" src={icon3}></ReactSVG>
            </div>
            <div className="textContent">
              <div className="view">查看</div>
              <div className="text">核查规则</div>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default RecordCard;
