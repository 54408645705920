import "./index.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { message, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../../../components/MySpin";
import projectApi from "../../../../../apis/project";
import { useGetState } from "ahooks";
import BillDetailsPopulationContent from "./PopulationContent";
import OutlayBox from "./OutlayBox";
import IncomeBox from "./IncomeBox";
import BillDetailsPage from "./BillDetailsPage";
import moment from "moment";

const { Option } = Select;

const BillDetails = (props) => {
  const { collectionCompanyList, selectCompany, setSelectCompany } = props;

  const { id } = useParams();
  const [updateTime, setUpdateTime] = useState(null);
  const [dataRange, setDataRange] = useState(null);
  // const [selectCompany, setSelectCompany, getSelectCompany] = useGetState(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getDataFunc = () => {
    setLoading(true);
    projectApi?.getBillDetailAnalysis(id, selectCompany).then((res) => {
      console.log("-----票据详情:", res);
      if (res.code === 200) {
        setData(res?.data || {});
        setDataRange(res?.data?.data_date_scope);
        setUpdateTime(res?.data?.data_update_time);
      } else {
        message.error(res.message);
        setData({});
        setDataRange(null);
        setUpdateTime(null);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (collectionCompanyList?.length === 0) {
      return;
    }
    // setSelectCompany(collectionCompanyList?.at(0)?.company_id);
    setTimeout(() => {
      getDataFunc();
    }, 100);
  }, [collectionCompanyList, selectCompany]);

  return loading ? (
    <MySpin></MySpin>
  ) : (
    <div className="billDetailsContent">
      <div className="dataInput">
        <span>
          采集时间：
          {updateTime ? moment(updateTime)?.format("YYYY-MM-DD hh:mm:ss") : "-"}
        </span>
        <span style={{ marginLeft: "24px" }}>数据范围：{dataRange || "-"}</span>
        <div className="inputBox">
          <div className="label">分析主体</div>
          <Select
            value={selectCompany}
            style={{ width: "100%" }}
            showArrow={true}
            bordered={false}
            onChange={(value) => {
              setSelectCompany(value);
              // setTimeout(() => {
              //   getDataFunc();
              // }, 50);
            }}
          >
            {collectionCompanyList?.map((item) => (
              <Option key={item.company_id} value={item.company_id}>
                {item?.company_name}
              </Option>
            ))}
          </Select>
        </div>
        <Tooltip
          placement="left"
          title={
            <div className="collenctionTip">
              <h3>说明：</h3>
              <span>
                1、财税数据是通过百望股份有限公司从国家税务总局进行数据采集。
              </span>
              <span>
                2、采集的数据为最近36个月（最近1个月的数据可能会因为数据延迟无法采集到），数据包括：纳税、纳税申报、销项发票和进项发票等数据。
              </span>
              <span>
                3、财税数据分析功能依据纳税及发票数据进行统计分析，由于红冲、实际入账情况和无发票等原因，不能代表公司实际经营情况，仅供参考。
              </span>
            </div>
          }
          overlayClassName="collenctionTooltip"
        >
          <ExclamationCircleOutlined className="infoIcon" />
        </Tooltip>
      </div>
      <div className="chartsContentBox">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          <div className="chartsContent">
            {/* 总体情况 */}
            <div className="wholeRowBox">
              <BillDetailsPopulationContent
                data={data?.summary_data || {}}
                dataRange={dataRange}
              ></BillDetailsPopulationContent>
            </div>
            {/* 月度（销项）票据情况 */}
            <div className="wholeRowBox">
              <OutlayBox
                data={data?.outlay_list || []}
                selectCompany={selectCompany}
              ></OutlayBox>
            </div>
            {/* 月度（进项）票据情况 */}
            <div className="wholeRowBox">
              <IncomeBox
                data={data?.income_list || []}
                selectCompany={selectCompany}
              ></IncomeBox>
            </div>
            {/* 票据明细 */}
            <div className="wholeRowBox">
              <BillDetailsPage selectCompany={selectCompany}></BillDetailsPage>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};
export default BillDetails;
