import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
  PayCircleFilled,
} from "@ant-design/icons";
import {
  addMillimeter,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message, Select, Tooltip } from "antd";
import { useGetState } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import MyEmpty from "../../../../../../../components/Empty";
import { ReactSVG } from "react-svg";
import icon1 from "../../../../../../../assets/中标.svg";
import icon2 from "../../../../../../../assets/中标1.svg";
import YearSelect from "../../../YearSelect";
import TableModal from "../../../TableModal";
import moment from "moment";
import png from "../../../../../../../assets/logo-w.png";

const { Option } = Select;

const TypeComparison = (props) => {
  const { data, keyList, checkCompanyList, searchTableFunc, bidCompanyName } =
    props;
  const { id } = useParams();
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [noData, setNoData] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState(null);
  const [chartType, setChartType, getChartType] = useGetState("金额");

  const myChart = useRef(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (!checkCompanyList || checkCompanyList?.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
    setTimeout(() => {
      initEchartsData(getYearsObj(), getSelectYear() || [], checkCompanyList);
    }, 100);
  }, [checkCompanyList]);

  const initECharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let maxNum = 0;
    echartsData?.keyList.forEach((item, index) => {
      let num = 0;
      [
        "其他项目",
        "民营企业项目",
        "国企项目",
        "外资项目",
        "集体企业项目",
        "政府项目",
      ].forEach((_) => {
        num = echartsData[_]?.at(index) + num;
      });
      if (num > maxNum) {
        maxNum = num;
      }
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("typeComparisonBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const typeColor = {
      政府项目: "#6CAEFF",
      集体企业项目: "#FE545F",
      外资项目: "#FEC054",
      国企项目: "#AAD461",
      民营企业项目: "#16D8D8",
      其他项目: "#016DB4",
    };
    const series = [
      "其他项目",
      "民营企业项目",
      "国企项目",
      "外资项目",
      "集体企业项目",
      "政府项目",
    ].map((item) => {
      return {
        name: item,
        type: "line",
        stack: "Total",
        label: {
          show: false,
        },
        areaStyle: {
          color: typeColor[item],
          shadowColor: typeColor[item],
        },
        // barWidth: "20%",
        itemStyle: {
          color: typeColor[item],
        },
        emphasis: {
          focus: "series",
        },
        tooltip: {
          valueFormatter: function (value) {
            return getChartType() === "金额"
              ? addMillimeter(value, 2) + "元"
              : value + "个";
          },
        },
        data: echartsData[item],
      };
    });
    const option = {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (params, ticket, callback) => {
          let name = params?.at(0)?.name;
          if (name.length > 30) {
            name = name?.slice(0, 30) + "...";
          }
          let html = `<div>${name}`;
          [
            "其他项目",
            "民营企业项目",
            "国企项目",
            "外资项目",
            "集体企业项目",
            "政府项目",
          ]
            ?.sort(() => -1)
            ?.forEach((_) => {
              const index = echartsData?.keyList?.findIndex(
                (name) => name === params?.at(0)?.name
              );

              html += `<div><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                typeColor[_]
              };"></span> ${_}  <span style='float:right;font-weight:bold;margin-left:20px'>${
                getChartType() === "金额"
                  ? addMillimeter(echartsData[_]?.at(index), 2) + "元"
                  : echartsData[_]?.at(index) + "个"
              }</span></div>`;
            });
          html += `</div>`;
          return html;
        },
      },
      legend: {
        x: "center", //可设定图例在左、右、居中
        y: "top", //可设定图例在上、下、居中
        itemStyle: {
          borderCap: "round",
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        top: "10px",
        type: "scroll", // 显示分页
        selectedMode: true,
        textStyle: {
          rich: {
            title: {
              fontSize: 14,
              lineHeight: 22,
              color: "#595959",
              width: 80,
            },
            value: {
              fontSize: 14,
              lineHeight: 22,
              color: "rgba(0,0,0,0.45)",
              width: 30,
              align: "center",
            },
          },
        },

        data: [
          "政府项目",
          "集体企业项目",
          "外资项目",
          "国企项目",
          "民营企业项目",
          "其他项目",
        ],
      },
      grid: {
        top: "50px",
        right: "20px",
        left: "0px",
        bottom: "0",
        containLabel: true,
      },
      dataZoom: [
        {
          type: "inside",
        },
      ],
      xAxis: {
        type: "category",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: (params) => {
              let str = params.value;
              if (str.length > 30) {
                str = str?.slice(0, 30) + "...";
              }
              return str;
            },
          },
        },
        axisLabel: {
          interval: 0,
          // width: 60,
          overflow: "truncate",
          // rotate: -20,
          formatter: function (value) {
            return calcCompanyNameFunc(
              value,
              getSelectType()?.length,
              "typeComparisonBox"
            );
          },
        },
        axisTick: {
          alignWithLabel: true,
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        data: echartsData.keyList,
      },
      yAxis: {
        type: "value",
        // name: maxNum < 10000 ? "(元)" : "(万元)",
        nameLocation: "end",
        nameTextStyle: {
          align: "center",
          // padding: [0, 50, 0, 0],
        },
        nameGap: 10,
        // min: 0,
        max: maxNum < 5 ? 5 : null,
        // interval: 50,
        axisLabel: {
          formatter: function (value, index) {
            // let num = parseInt(value / 10000).toLocaleString("en-US");
            // if (maxNum < 10000) {
            //   num = parseInt(value).toLocaleString("en-US");
            // }
            // return num;
            if (value >= 100000000) {
              return value / 100000000 + "亿";
            } else if (value >= 10000) {
              return value / 10000 + "万";
            } else {
              return value;
            }
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisPointer: {
          show: false,
        },
      },
      series: series,
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let isBid = true;
      if (bidCompanyName === a?.name) {
        isBid = undefined;
      }
      let filterOptions = {
        年份: getSelectYear()?.at(0),
        项目类型: a?.seriesName,
        供应商: a?.name,
      };
      TableModal({
        title: "中标详情",
        filterOptions,
        isBid: isBid,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  const initEchartsData = (yearsObj, year, types, onlyData) => {
    //projectInfo.company_name,
    const keyList = [...(getSelectType() || [])];
    const echartsData = {
      keyList: keyList,
      政府项目: [],
      集体企业项目: [],
      外资项目: [],
      国企项目: [],
      民营企业项目: [],
      其他项目: [],
    };
    let data = [];
    year.forEach((_) => {
      data = [...data, ...(yearsObj[_] || [])];
    });
    keyList.forEach((item) => {
      let arr = data?.filter((_) => _.bid_winner === item);
      let obj = {
        政府项目: 0,
        集体企业项目: 0,
        外资项目: 0,
        国企项目: 0,
        民营企业项目: 0,
        其他项目: 0,
      };
      arr.forEach((__) => {
        if (__.bid_info_type) {
          obj[__.bid_info_type] =
            obj[__.bid_info_type] +
            (getChartType() === "金额"
              ? __.bid_winning_amo
              : __.bid_winning_count);
        } else {
          obj["其他项目"] =
            obj["其他项目"] +
            (getChartType() === "金额"
              ? __.bid_winning_amo
              : __.bid_winning_count);
        }
      });

      Object.keys(obj).forEach((key) => {
        echartsData[key]?.push(obj[key]);
      });
    });
    if (onlyData) {
      return echartsData;
    }
    initECharts(echartsData);
  };

  const initData = (data) => {
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    let yearsObj = {};
    let keyObj = {};
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date]?.push(item);
      } else {
        yearsObj[item.anc_date] = [JSON.parse(JSON.stringify(item))];
      }
      if (!keyObj[item.bid_winner] && item.bid_winner !== bidCompanyName) {
        let index = keyList?.findIndex(
          (_) => _?.bid_winner === item.bid_winner
        );
        if (index !== null && index !== undefined) {
          index = index + 1;
        } else {
          index = 999;
        }
        keyObj[item.bid_winner] = index;
      }
    });
    let keys = Object.keys(yearsObj);
    keys = keys.sort((a, b) => Number(b) - Number(a));
    const nowYear = moment(new Date())?.format("YYYY");

    setYearsObj(yearsObj);
    let objKeys = Object.keys(keyObj);
    objKeys = objKeys.sort((a, b) => Number(keyObj[a]) - Number(keyObj[b]));
    setSelectOptions(objKeys);
    // setSelectType(objKeys?.slice(0, 5) || []);
    setSelectYear([nowYear]);
    initEchartsData(yearsObj, [nowYear], getSelectType());
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "竞争对手项目类型对比分析",
        is_bid: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  useEffect(() => {
    initData(data || []);
  }, [data]);

  if (keyList?.length === 0) {
    return (
      <div className="typeComparisonContent">
        <div className="selectBox">
          <div
            style={{
              width: "24px",
              height: "18px",
              background: "#0068B2",
              borderRadius: "2px",
              marginRight: "8px",
            }}
          ></div>
          <div
            style={{
              width: "24px",
              height: "18px",
              // background: "#0068B2",
              borderRadius: "2px",
              border: "1px solid #D8D8D8",
            }}
          ></div>
        </div>
        <div className="headerBox">
          竞争对手历年中标情况
          <Tooltip placement="top" title="下载" arrowPointAtCenter>
            <VerticalAlignBottomOutlined className="downloadIcon hoverIcon" />
          </Tooltip>
        </div>
        <div
          style={{
            width: "100%",
            height: "calc(100% - 68px)",
            background: "#efefef",
            marginTop: "46px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ display: "block", height: "44px", width: "auto" }}
            src={png}
            alt="iWudao魔方全景查"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="typeComparisonContent">
      {!noData && (
        <div className="selectBox">
          <Tooltip placement="top" title="金额" arrowPointAtCenter>
            <div
              className={
                chartType === "金额"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "金额") {
                  return;
                }
                setChartType("金额");
                setTimeout(() => {
                  initEchartsData(getYearsObj(), getSelectYear() || []);
                }, 50);
              }}
            >
              <PayCircleFilled
                style={{
                  color: getChartType() === "金额" ? "#ffffff" : "#999999",
                }}
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" title="项目数" arrowPointAtCenter>
            <div
              className={
                chartType === "数量"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "数量") {
                  return;
                }
                setChartType("数量");
                setTimeout(() => {
                  initEchartsData(getYearsObj(), getSelectYear() || []);
                }, 50);
              }}
            >
              <ReactSVG
                className="selectItemIcon"
                src={getChartType() === "数量" ? icon2 : icon1}
              ></ReactSVG>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="headerBox">
        竞争对手项目类型对比分析
        {!noData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <YearSelect
              value={selectYear}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear([...value]);
                setTimeout(() => {
                  initEchartsData(getYearsObj(), getSelectYear(), value);
                }, 50);
              }}
              options={Object.keys(yearsObj)?.sort(
                (a, b) => Number(a) - Number(b)
              )}
            ></YearSelect>
            {/* <Select
              // size="small"
              mode="multiple"
              maxTagCount="responsive"
              value={selectYear}
              style={{ width: "140px", marginRight: "10px" }}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value);
                initEchartsData(yearsObj, value, getSelectType());
              }}
            >
              {Object.keys(yearsObj)
                ?.sort((a, b) => Number(b) - Number(a))
                .map((item, index) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select> */}

            {/* <Select
              // size="small"
              mode="multiple"
              maxTagCount="responsive"
              value={selectType}
              style={{ minWidth: "160px", marginRight: "10px" }}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectType(value);
                initEchartsData(yearsObj, getSelectYear(), value);
              }}
            >
              {selectOptions.map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}

            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  />
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div
        id="typeComparisonBox"
        style={{ display: noData ? "none" : "block" }}
      ></div>
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TypeComparison;
