import "./index.scss";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import projectApi from "../../../apis/project";
import { message, Descriptions, Typography, Tooltip } from "antd";
import { showInfoFunc } from "../../../components/MyTable";
import { addMillimeter, addMillimeterAndInit } from "../../../unit/unit";
import TimeLine from "./components/TimeLine";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../components/MySpin";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useSize } from "ahooks";
import MyEmpty from "../../../components/Empty";
const { Paragraph } = Typography;

const list = [
  {
    date: "2020-10-30",
    solid: true,
    fileList: [
      {
        label: "备案",
      },
      {
        label: "备案",
      },
    ],
  },
  {
    date: "2020-12-30",
    solid: true,
    fileList: [
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
      {
        label: "法律意见申请稿",
      },
      {
        label: "上市保荐书申请稿",
      },
      {
        label: "招股说明书申请稿",
      },
    ],
  },
  {
    date: "2021-03-21",
    solid: true,
    fileList: [
      {
        label: "中止",
      },
    ],
  },
  {
    date: "2021-04-28",
    solid: true,
    fileList: [
      {
        label: "回复",
      },
    ],
  },
  {
    date: "2021-05-17",
    solid: true,
    fileList: [
      {
        label: "问询和回复",
      },
      {
        label: "问询和回复",
      },
    ],
  },
  {
    date: "2021-06-07",
    solid: true,
    fileList: [
      {
        label: "问询和回复",
      },
      {
        label: "问询和回复",
      },
      {
        label: "问询和回复",
      },
    ],
  },
  {
    date: "2021-06-18",
    solid: true,
    fileList: [
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
      {
        label: "法律意见申请稿",
      },
      {
        label: "上市保荐书申请稿",
      },
      {
        label: "招股说明书申请稿",
      },
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
    ],
  },
  {
    date: "2021-08-18",
    solid: true,
    fileList: [
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
      {
        label: "法律意见申请稿",
      },
      {
        label: "上市保荐书申请稿",
      },
      {
        label: "招股说明书申请稿",
      },
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
    ],
  },
  {
    date: "2021-09-18",
    solid: true,
    fileList: [
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
      {
        label: "法律意见申请稿",
      },
      {
        label: "上市保荐书申请稿",
      },
      {
        label: "招股说明书申请稿",
      },
      {
        label: "审计报告申请稿",
      },
      {
        label: "发行保荐书申请稿",
      },
    ],
  },
  {
    date: "2021-12-18",
    solid: false,
    fileList: [
      {
        label: "审讯会议补充",
      },
      {
        label: "发行保荐书申请稿",
      },
    ],
  },
  {
    date: "2022-12-18",
    solid: true,
    fileList: [
      {
        label: "aaa",
      },
      {
        label: "bbb",
      },
    ],
  },
];
const CapitalOperation = (props) => {
  const dataTable = "listed_ipo_project";
  const typeName = "资本运作情况";
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState({});
  const [info, setInfo] = useState({});
  const ref = useRef();

  const [showRightIcon, setShowRightIcon] = useState(false);
  const [showLeftIcon, setShowLeftIcon] = useState(false);

  const getDataFunc = () => {
    projectApi.getDataInfo(dataTable, id).then((res) => {
      console.log("资本运作情况", res.data);
      if (res.code === 200) {
        setRows(res.data.rows);
        setData(res.data.data || []);
        setInfo(res.data.data?.at(0) || {});
      } else {
        message.error(res.message);
        setRows({});
        setData([]);
        setInfo({});
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getDataFunc();
  }, []);

  const onScroll = () => {
    setShowLeftIcon(ref.current?.getScrollLeft() > 0);
    setShowRightIcon(
      Math.ceil(ref.current?.getScrollLeft()) !==
        Math.ceil(ref.current?.getScrollWidth() - ref.current?.getClientWidth())
    );
  };

  useEffect(() => {
    if (ref.current) {
      setShowLeftIcon(ref.current?.getScrollLeft() > 0);
      setShowRightIcon(
        Math.ceil(ref.current?.getScrollLeft()) !==
          Math.ceil(
            ref.current?.getScrollWidth() - ref.current?.getClientWidth()
          )
      );
    }
  }, [ref.current]);

  const count = useRef(0);
  const startPosition = useRef(0);

  const goScrollLeft = (left) => {
    if (count.current === 0) {
      startPosition.current = ref.current.getScrollLeft();
    }
    const _left = ref.current.getScrollLeft() + left / 20;
    ref.current.scrollLeft(_left);
    count.current = count.current + 1;
    if (count.current <= 20) {
      setTimeout(() => {
        goScrollLeft(left);
      }, 10);
    } else {
      ref.current.scrollLeft(startPosition.current + left);
      count.current = 0;
    }
  };

  return (
    <div className="CapitalOperationContent">
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div className="infoBox">
          <CommentsHeaderTitle
            title={typeName}
            dataTable={dataTable}
            typeName={typeName}
          ></CommentsHeaderTitle>

          {data?.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "calc(100% - 30px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MyEmpty></MyEmpty>
            </div>
          ) : (
            <>
              <div className="scrollbarsBox">
                {showLeftIcon && (
                  <LeftOutlined
                    className="leftIcon"
                    onClick={() => {
                      let left =
                        ref.current.getScrollLeft() -
                        ref.current?.getClientWidth();
                      if (left < 0) {
                        left = ref.current.getScrollLeft();
                      } else {
                        left = ref.current?.getClientWidth();
                      }
                      goScrollLeft(0 - left);
                    }}
                  />
                )}

                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  onScroll={onScroll}
                  style={{
                    height: "100%",
                    width: "calc(100% - 30px)",
                  }}
                  className="activeScrollBars"
                  ref={ref}
                  onWheel={(e) => {
                    let left = ref.current.getScrollLeft();
                    const offset = 20;
                    if (e.deltaX > 0) {
                      left = left + offset;
                    } else if (e.deltaX < 0) {
                      left = left - offset;
                    } else if (e.deltaY > 0) {
                      left = left + offset;
                    } else if (e.deltaY < 0) {
                      left = left - offset;
                    }
                    ref.current.scrollLeft(left);
                  }}
                >
                  <div className="typeListBox">
                    {data?.map((item) => (
                      <div
                        key={item?.id?.val}
                        className={
                          item.id?.val === info.id?.val ? "active" : null
                        }
                        onClick={() => {
                          setInfo(item);
                        }}
                      >
                        {item.curr_year?.val +
                          (item.listing_board?.val || "") +
                          item.project_type?.val}{" "}
                        <span>{item.project_status?.val}</span>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
                {showRightIcon && (
                  <RightOutlined
                    className="rightIcon"
                    onClick={() => {
                      let left =
                        ref.current?.getClientWidth() +
                        ref.current.getScrollLeft();
                      if (left > ref.current?.getScrollWidth()) {
                        left =
                          ref.current?.getScrollWidth() -
                          ref.current.getScrollLeft();
                      } else {
                        left = ref.current?.getClientWidth();
                      }
                      goScrollLeft(left);
                    }}
                  />
                )}
              </div>

              <Scrollbars
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "calc(100% - 80px)", width: "100%" }}
              >
                <Descriptions bordered className="myDescriptions" column={100}>
                  {Object.keys(rows)
                    .map((key) => ({ key: key, ...rows[key] }))
                    .sort((a, b) => a.index - b.index)
                    .map((item) => (
                      <Descriptions.Item
                        label={item?.name}
                        key={item.key}
                        span={item.width}
                      >
                        <div>
                          {item.click_legal_request ||
                          item.click_natural_request ||
                          item.click_all ? (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                showInfoFunc(
                                  { click_legal_request: true },
                                  info,
                                  "工商信息",
                                  id,
                                  item.key
                                );
                              }}
                            >
                              {info[item.key]?.val}
                            </a>
                          ) : item.need_millimeter ? (
                            info[item.key]?.val ? (
                              addMillimeterAndInit(info[item.key]?.val, 2) +
                              (item.join_field
                                ? info[item.join_field]?.val
                                : "")
                            ) : (
                              "-"
                            )
                          ) : item?.show_tips ? (
                            <Tooltip
                              placement="top"
                              title={
                                <div
                                  style={{
                                    wordBreak: "break-all",
                                    whiteSpace: "break-spaces",
                                  }}
                                >
                                  {info[item.tips_field]?.val}
                                </div>
                              }
                              arrowPointAtCenter
                            >
                              {info[item.key]?.val || "-"}
                            </Tooltip>
                          ) : (
                            <Paragraph
                              ellipsis={{
                                tooltip: {
                                  title: info[item.key]?.val,
                                },
                                rows: 2,
                                expandable: false,
                              }}
                              style={{
                                width: "100%",
                                margin: "0",
                              }}
                            >
                              {info[item.key]?.val || "-"}
                            </Paragraph>
                          )}
                        </div>
                      </Descriptions.Item>
                    ))}
                </Descriptions>

                <TimeLine data={info.time_process_list?.val || []}></TimeLine>

                <div className="ipoBox">
                  <div className="title">发行总览</div>
                  <div className="ipoContent">
                    <div className="ipoItem">
                      <div className="ipoKey">募集资金总额</div>
                      {info.lis_res_dic?.val?.data?.total_funds_raised
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.total_funds_raised,
                            2
                          ) + "万元"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">募集资金净额</div>
                      {info.lis_res_dic?.val?.data?.net_funds_raised
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.net_funds_raised,
                            2
                          ) + "万元"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">发行价格</div>
                      {info.lis_res_dic?.val?.data?.issue_price
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.issue_price,
                            2
                          ) + "元/股"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">发行数量</div>
                      {info.lis_res_dic?.val?.data?.stock_issue_quantity
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.stock_issue_quantity,
                            2
                          ) + "万股"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">发行费用</div>
                      {info.lis_res_dic?.val?.data?.issue_fee_amo
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.issue_fee_amo,
                            2
                          ) + "万元"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">网上申购倍数</div>
                      {info.lis_res_dic?.val?.data?.online_purchase_multiples
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data
                              ?.online_purchase_multiples,
                            2
                          )
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">网下申购倍数</div>
                      {info.lis_res_dic?.val?.data?.offline_purchase_multiples
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data
                              ?.offline_purchase_multiples,
                            2
                          )
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">新股占比</div>
                      {info.lis_res_dic?.val?.data?.new_shares_proportion
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.new_shares_proportion * 100,
                            2
                          ) + "%"
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">发行市盈率</div>
                      {info.lis_res_dic?.val?.data?.pe_after_issue
                        ? addMillimeter(
                            info.lis_res_dic?.val?.data?.pe_after_issue,
                            2
                          )
                        : "-"}
                    </div>

                    <div className="ipoItem">
                      <div className="ipoKey">承销方式</div>
                      {info.lis_res_dic?.val?.data?.underwriting_way
                        ? info.lis_res_dic?.val?.data?.underwriting_way
                        : "-"}
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default CapitalOperation;
