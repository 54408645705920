import "./index.scss";
import * as echarts from "echarts";
import { useState, useEffect, useRef } from "react";
import MyEmpty from "../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../apis/project";
import {
  addMillimeter,
  addMillimeterAndInit,
  saveFile,
} from "../../../../../../unit/unit";
import { Table, message } from "antd";
import { useGetState } from "ahooks";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { innerCircleMap } from "../../../../../../setting";
const TaxRateSituation = (props) => {
  const { data = {}, selectCompany } = props;
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const { id } = useParams();
  const [downLoading, setDownLoading] = useState(false);

  const [noData, setNoData] = useState(false);
  const [initType, setInitType, getInitType] = useGetState("年");

  const [circleSelectYear, setCircleSelectYear, getCircleSelectYear] =
    useGetState("");
  const [circleSelectKey, setCircleSelectKey, getCircleSelectKey] =
    useGetState("");

  const myChart = useRef(null);
  const myChart2 = useRef(null);

  const colorRef = useRef([
    "#0068B2",
    "#61D4AD",
    "#FEC054",
    "#FE545F",
    "#6CAEFF",
  ]);
  const colorRef2 = useRef(["#6CAEFF", "#AAD461", "#FEC054", "#FE545F"]);

  const initCircleEcharts = (year, key) => {
    if (getCircleSelectYear() === year && getCircleSelectKey() === key) {
      return;
    }
    setCircleSelectYear(year);
    setCircleSelectKey(key);
    const list = data[year]?.filter((item) => item.taxRate === Number(key));

    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom = document.getElementById("taxRateSituationContentBox2");
    if (!dom) {
      return;
    }

    const obj = {};
    list?.forEach((item) => {
      if (obj[item.product_category]) {
        obj[item.product_category] = obj[item.product_category] + item.amount;
      } else {
        obj[item.product_category] = item.amount;
      }
    });
    myChart2.current = echarts.init(dom);

    const option = {
      title: {
        text: `${year}年税率${parseInt(key * 100)}%的开票金额构成(按商品类型)`,
        // subtext: "商品类型",
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: (info) => {
          return `<div><span style='font-weight:bold'>${
            info?.name
          }</span>: ${addMillimeterAndInit(info?.value + "元")}</div>
          <div><span style='font-weight:bold'>占比</span>: ${
            info?.percent
          }%</div>`;
        },
      },
      legend: {
        orient: "vertical",
        left: "left",
        show: false,
      },
      grid: {
        top: "60px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: [
        {
          // name: 'Access From',
          type: "pie",
          radius: "60%",
          // tooltip:{
          //   formatter: (value) => {
          //     return value + '11'
          //   }
          // },
          data: Object.keys(obj)?.map((key, index) => {
            const item = obj[key];
            return {
              name: key,
              value: item,
              itemStyle: {
                color: innerCircleMap[index % innerCircleMap?.length],
              },
            };
          }),
          labelLine: {
            length: 10,
          },
          label: {
            width: 140,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    myChart2.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart2.current && myChart2.current.resize();
    });
  };

  const initOptions = (echartsData) => {
    const keys = Object?.keys(echartsData)
      ?.filter((item) => item !== "name")
      ?.sort((a, b) => a - b);

    const title = [];
    const singleAxis = [];
    const series = [];
    let singleAxisList = [];
    let taxObj = {};
    let maxAmount = 0;

    keys.forEach((key) => {
      echartsData[key]?.forEach((item) => {
        if (!singleAxisList.includes(item.taxRate)) {
          singleAxisList.push(item.taxRate);
        }

        if (taxObj[key]) {
          if (taxObj[key][item.taxRate]) {
            taxObj[key][item.taxRate] = taxObj[key][item.taxRate] + item.amount;
          } else {
            taxObj[key][item.taxRate] = item.amount;
          }
        } else {
          let obj = {};
          obj[item.taxRate] = item.amount;
          taxObj[key] = obj;
        }

        if (taxObj[key][item.taxRate] >= maxAmount) {
          maxAmount = taxObj[key][item.taxRate];
        }
      });
    });
    singleAxisList = singleAxisList?.sort((a, b) => a - b);

    initCircleEcharts(keys?.at(-1), echartsData[keys?.at(-1)]?.at(0)?.taxRate);

    const len = 4;
    const sizeProportion =
      (parseInt(maxAmount)?.toString().length >= 3
        ? parseInt(maxAmount)?.toString().length
        : 3) - 2;
    keys.forEach((year, idx) => {
      title.push({
        textBaseline: "middle",
        top: ((idx + 0.6) * 100) / len + "%",
        text: year,
      });
      singleAxis.push({
        left: 70,
        type: "category",
        boundaryGap: false,
        data: singleAxisList,
        top: (idx * 97) / len + 5 + "%",
        height: 100 / len - 10 + "%",
        axisLabel: {
          interval: 0,
          formatter: (value) => {
            if (!value) {
              return "0";
            }
            return parseInt(value * 100) + "%";
          },
        },
      });

      let obj = {};
      echartsData[year]?.forEach((item) => {
        if (obj[item.taxRate]) {
          obj[item.taxRate] = obj[item.taxRate] + item.amount; //item.amount
        } else {
          obj[item.taxRate] = item.amount;
        }
      });
      const data = (Object.keys(obj) || [])?.map((key) => {
        const index = singleAxisList?.indexOf(Number(key));
        return [index, obj[key]];
      });
      const seriesObj = {
        singleAxisIndex: idx,
        coordinateSystem: "singleAxis",
        id: year,
        type: "scatter",
        data: data,
        symbolSize: function (dataItem) {
          const size = dataItem[1] / Math.pow(10, sizeProportion);
          return size < 2 ? 2 : size;
        },
        itemStyle: {
          color: colorRef2.current[idx % 4],
        },
        // tooltip: {
        //   formatter: (info) => {
        //     return `${info?.marker} 税率为${
        //       parseInt(info?.name * 100) + "%"
        //     }的总开票金额为${addMillimeterAndInit(info?.value?.at(1) + "元")}`;
        //   },
        // },
      };
      series.push(seriesObj);
    });

    return {
      title,
      singleAxis,
      series,
      taxObj,
      keys,
    };
  };

  const initEcharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    const dom = document.getElementById("taxRateSituationContentBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const keys = Object.keys(echartsData)
      ?.filter((_) => _ !== "name")
      ?.sort((a, b) => b - a);

    const obj = {};
    const taxRateList = [];
    keys.forEach((year) => {
      const _obj = {};

      echartsData[year]?.forEach((_) => {
        if (!taxRateList?.includes(_?.taxRate)) {
          taxRateList.push(_?.taxRate);
        }
        if (_obj[_?.taxRate]) {
          _obj[_?.taxRate] = _obj[_?.taxRate] + _?.amount;
        } else {
          _obj[_?.taxRate] = _?.amount;
        }
      });

      obj[year] = _obj;
    });

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (info) => {
          let str = `<div style='font-weight:bold'>税率${
            info?.at(0)?.name * 100
          }%</div>`;
          info?.forEach((item) => {
            str += `<div>${item?.marker}${
              item?.seriesName
            } <span style='float:right;margin-left:14px;'>${
              item?.data?.amount ? addMillimeter(item?.data?.amount) : 0
            }元</span></div>`;
          });
          return str;
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "none",
        },
      ],
      legend: {
        data: keys,
      },
      grid: {
        top: "30px",
        left: 0,
        right: "20px",
        bottom: 0,
        containLabel: true,
      },

      xAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        axisLabel: {
          formatter: function (value, index) {
            if (value >= 100000000) {
              return value / 100000000 + "亿";
            } else if (value >= 10000) {
              return value / 10000 + "万";
            } else {
              return value;
            }
          },
        },
      },
      yAxis: {
        type: "category",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
        inverse: true,
        data: taxRateList,
        axisLabel: {
          formatter: function (value) {
            return value * 100 + "%";
          },
        },
      },
      series: keys?.map((year, index) => {
        return {
          name: year,
          type: "bar",
          barMaxWidth: 40,
          itemStyle: {
            color: colorRef.current[index % 4],
            barBorderRadius: [0, 4, 4, 0],
          },
          data: taxRateList?.map((item) => {
            let num = obj[year][item] || 0;
            if (num < 0) {
              num = 0;
            }
            return {
              value: num,
              amount: obj[year][item] || 0,
            };
          }),
        };
      }),
    };

    // const { title, singleAxis, series, taxObj, keys } =
    //   initOptions(echartsData);
    // const option = {
    //   tooltip: {
    //     formatter: (info) => {
    //       let str = `<div>${
    //         info?.marker
    //       } <span style='font-weight:bold'>税率为${
    //         parseInt(info?.name * 100) + "%"
    //       }</span></div>`;

    //       keys?.forEach((year) => {
    //         const obj = taxObj[year] || {};

    //         str += `<div><span style='font-weight:bold'>${year}</span> ${addMillimeterAndInit(
    //           (obj[info?.name] || 0) + "元"
    //         )}</div>`;
    //       });
    //       return str;
    //       // return `${info?.marker} 税率为${
    //       //   parseInt(info?.name * 100) + "%"
    //       // }的总开票金额为${addMillimeterAndInit(info?.value?.at(1) + "元")}`;
    //     },
    //   },
    //   title: title,
    //   singleAxis: singleAxis,
    //   series: series,
    // };

    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (params) => {
      initCircleEcharts(params?.seriesName, params?.name);
    });

    setTimeout(() => {
      initCircleEcharts(keys?.at(0), taxRateList?.at(0));
    }, 100);
  };

  const initData = (data) => {
    initEcharts(data);
  };

  useEffect(() => {
    if (Object?.keys(data)?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data);
  }, [data]);

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadFinancialTaxFile({
        project_id: id,
        event_name: "销售商品税率情况",
        company_id: selectCompany,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };

  const initDomFunc = () => {
    let list = data[getCircleSelectYear()] || [];
    // list = list;
    let sum = 0;
    let sum2 = 0;
    let obj = {};
    let obj2 = {};

    list.forEach((item) => {
      sum += item.amount;
      sum2 += item.amount * item.taxRate;

      if (obj[item.taxRate]) {
        obj[item.taxRate] = obj[item.taxRate] + item.amount;
      } else {
        obj[item.taxRate] = item.amount;
      }

      if (obj2[item.product_category]) {
        obj2[item.product_category] = obj2[item.product_category] + item.amount;
      } else {
        obj2[item.product_category] = item.amount;
      }
    });

    const keys = Object.keys(obj)?.sort((a, b) => obj[b] - obj[a]);
    const keys2 = Object.keys(obj2)
      ?.sort((a, b) => obj[b] - obj[a])
      ?.slice(0, 3);
    return (
      <>
        <div>
          {getCircleSelectYear()}年开票金额平均税率约为
          {parseInt((sum2 / sum) * 100)}% 。其中税率为
          {parseInt(keys?.at(0) * 100)}% 的开票金额占比最高 (开票金额
          {addMillimeterAndInit(obj[keys?.at(0)] + "元")}，占比
          {((obj[keys?.at(0)] / Math.abs(sum)) * 100).toFixed(2)}%）。
        </div>
        <div className="tipBox">
          <h3>{getCircleSelectYear()}年主要商品类型为:</h3>
          <div>
            {keys2?.map((item, index) => (
              <i key={index}>
                {index === 0 ? null : "、"}
                <span>{item}</span>（{addMillimeterAndInit(obj2[item] + "元")}）
              </i>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="taxRateSituationContent">
      <div className="headerBox">
        销售商品税率情况
        {!noData && (
          <div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <VerticalAlignBottomOutlined
                  className="downloadIcon hoverIcon"
                  onClick={downloadFile}
                />
              ))}
          </div>
        )}
      </div>

      {!noData && (
        <div className="taxRateSituationContentBox">
          <div id="taxRateSituationContentBox"></div>
          <div id="taxRateSituationContentBox2"></div>
          <div className="taxRateSituationTextBox">{initDomFunc()}</div>
        </div>
      )}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default TaxRateSituation;
