import { Tag, Select, Input, Checkbox, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../../../../../assets/下拉.svg";
import "./index.scss";
import projectApi from "../../../../../apis/project";
import { useParams } from "react-router-dom";
import { debounce } from "../../../../../unit/unit";

import MyRangePicker from "../../../../../components/MyRangePicker";
const { Option } = Select;
const { CheckableTag } = Tag;
const InputGroup = Input.Group;

const MyJudicial = (props, ref) => {
  const { searchInfo } = props;
  const { getValue, loading } = props;
  const [caseTag, setCaseTag] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [subject, setSubject] = useState([]);
  const [parties, setParties] = useState([]);
  const [type, setType] = useState([]);
  const [reason, setReason] = useState([]);
  const [identity, setIdentity] = useState([]);
  const [state, setState] = useState([]);
  const [minAmount, setMinAmount] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [caseNo, setCaseNo] = useState(null);
  const [subjectCheckAll, setSubjectCheckAll] = useState(false);
  const [subjectCheckAllIndeterminate, setSubjectCheckAllIndeterminate] =
    useState(false);
  const [partiesCheckAll, setPartiesCheckAll] = useState(false);
  const [partiesCheckAllIndeterminate, setPartiesCheckAllIndeterminate] =
    useState(false);

  const [typeCheckAll, setTypeCheckAll] = useState(false);
  const [typeCheckAllIndeterminate, setTypeCheckAllIndeterminate] =
    useState(false);
  const [reasonCheckAll, setReasonCheckAll] = useState(false);
  const [reasonCheckAllIndeterminate, setReasonCheckAllIndeterminate] =
    useState(false);

  const [subjectList, setSubjectList] = useState([]);
  const [partiesList, setPartiesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [identityList, setIdentityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  // 获取标签
  const getCaseTag = () => {
    projectApi.getDicCodeInfo("CDD_CASE_TAG").then((res) => {
      if (res.code === 200) {
        setCaseTag(res.data);
      } else {
        setCaseTag([]);
      }
    });
  };

  useEffect(() => {
    getCaseTag();
    setSubjectList(searchInfo?.case_subject_scope);
    setPartiesList(searchInfo?.case_parties);
    setTypeList(searchInfo?.case_type);
    setReasonList(searchInfo?.case_reason);
    setIdentityList(searchInfo?.case_identity);
    setStateList(searchInfo?.case_state);
  }, [searchInfo]);

  // 修改tag
  const handleTagChange = (tag, checked) => {
    // const nextSelectedTags = checked
    //   ? [...selectedTags, tag]
    //   : selectedTags.filter((t) => t !== tag);
    const nextSelectedTags = checked ? [tag] : [];
    getValue({ case_tag: nextSelectedTags.toString() });
    setSelectedTags(nextSelectedTags);
  };
  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag
        style={{
          marginRight: 3,
        }}
      >
        {label?.at(0)}
      </Tag>
    );
  };

  useEffect(() => {
    if (minAmount === "" && maxAmount === "")
      getValue({
        case_amount_min: 0,
        case_amount_max: 0,
      });
  }, [minAmount, maxAmount]);

  // onKeyPress时禁止输入小数点及其他特殊数字字符
  const handleKeyPress = (event) => {
    const invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  };

  // 涉案金额处理（最小值）
  let minTimer = null; // 全局定时器
  const handledMinAmount = (item) => {
    if (item !== "" && maxAmount !== 0 && maxAmount !== "") {
      // Number(item) <= Number(maxAmount)
      //   ?
      getValue({
        case_amount_min: item,
        case_amount_max: maxAmount,
      });
      // : getValue({
      //     case_amount_min: maxAmount,
      //     case_amount_max: item,
      //   });
    } else if ((item === 0 || item === "") && maxAmount !== 0) {
      getValue({
        case_amount_min: 0,
        case_amount_max: maxAmount,
      });
    } else if (item !== "") {
      minTimer = setTimeout(() => {
        getValue({
          case_amount_min: item,
          case_amount_max: 0,
        });
      }, 500);
    }
  };

  // 涉案金额处理（最大值）
  const handledMaxAmount = (item) => {
    if (item !== "") {
      // Number(item) >= Number(minAmount)
      // ?
      getValue({
        case_amount_min: minAmount,
        case_amount_max: item,
      });
      // : getValue({
      //     case_amount_min: item,
      //     case_amount_max: minAmount,
      //   });
    } else if (minAmount !== 0 && item === 0) {
      getValue({
        case_amount_min: minAmount,
        case_amount_max: 0,
      });
    } else if (minAmount !== 0 && item === "") {
      getValue({
        case_amount_min: minAmount,
        case_amount_max: 0,
      });
    }
  };

  return (
    <div className="myJudicialContainer">
      <div className="searchBox">
        {/* 推荐标签 */}
        <div className="searchItem">
          <div className="label">推荐标签</div>
          <div className="value">
            {caseTag.map((item) => {
              if (item.dic_item_code === "CDD_CASE_TAG_MONEY") {
                return (
                  <Tooltip title='涉案金额处于前20%，且超过1万元'>
                    <CheckableTag
                      key={item.dic_item_code}
                      checked={selectedTags.indexOf(item.dic_item_code) > -1}
                      onChange={(checked) =>
                        handleTagChange(item.dic_item_code, checked)
                      }
                    >
                      {item.dic_item_name}
                    </CheckableTag>
                  </Tooltip>
                );
              }
              return (
                <CheckableTag
                  key={item.dic_item_code}
                  checked={selectedTags.indexOf(item.dic_item_code) > -1}
                  onChange={(checked) =>
                    handleTagChange(item.dic_item_code, checked)
                  }
                >
                  {item.dic_item_name}
                </CheckableTag>
              );
            })}
          </div>
        </div>
        {/* 案号 */}
        <div className="searchItem" style={{ width: "100%" }}>
          <div className="label">案号</div>
          <div className="value" style={{ width: "calc(50% + 250px)" }}>
            <Input
              disabled={loading ? true : false}
              onChange={(e) => {
                setCaseNo(e.target.value);
              }}
              allowClear
              value={caseNo}
              placeholder="全部"
              onBlur={(e) => {
                getValue({
                  case_no: e.target.value,
                });
              }}
            ></Input>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">主体范围</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              maxTagCount="responsive"
              value={subject}
              tagRender={tagRender}
              allowClear
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              showArrow={true}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              filterOption={false}
              showSearch
              onSearch={debounce((e) => {
                if (e !== "")
                  setSubjectList(
                    searchInfo?.case_subject_scope.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
              onChange={async (value) => {
                setSubjectList(searchInfo?.case_subject_scope);
                setSubject(value);
                setParties([]);
                setPartiesCheckAll(false);
                setPartiesCheckAllIndeterminate(false);
                if (value.length === searchInfo?.case_subject_scope?.length) {
                  await setSubjectCheckAll(true);
                  await setSubjectCheckAllIndeterminate(false);
                } else {
                  await setSubjectCheckAllIndeterminate(true);
                  await setSubjectCheckAll(false);
                }
                if (value?.length === 0) {
                  await setSubjectCheckAllIndeterminate(false);
                  getValue({
                    case_subject_scope: "",
                    case_parties: "",
                  });
                }
              }}
              disabled={loading ? true : false}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  return subject?.length > 0
                    ? getValue({
                        case_subject_scope: subject.toString(),
                        case_parties: "",
                      })
                    : null;
                }
              }}
              onClear={() => {
                setSubjectCheckAll(false);
                setSubjectCheckAllIndeterminate(false);
                getValue({
                  case_subject_scope: "",
                });
              }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">
                  <Checkbox
                    disabled={false}
                    style={{ margin: "5px", marginLeft: "12px" }}
                    indeterminate={subjectCheckAllIndeterminate}
                    checked={subjectCheckAll}
                    onChange={(e) => {
                      setParties([]);
                      setPartiesCheckAll(false);
                      setPartiesCheckAllIndeterminate(false);
                      setSubjectCheckAll(e.target.checked);
                      setSubjectCheckAllIndeterminate(false);
                      if (e.target.checked) {
                        setSubject(subjectList.map((_) => _.code));
                      } else {
                        setSubject([]);
                      }
                      // e.stopPropagation();
                    }}
                  >
                    全选
                  </Checkbox>
                  {menu}
                </div>
              )}
            >
              {subjectList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">涉案主体</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              maxTagCount="responsive"
              showSearch
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              filterOption={false}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">
                  <Checkbox
                    disabled={false}
                    style={{ margin: "5px", marginLeft: "12px" }}
                    indeterminate={partiesCheckAllIndeterminate}
                    checked={partiesCheckAll}
                    onChange={(e) => {
                      setSubject([]);
                      setSubjectCheckAll(false);
                      setSubjectCheckAllIndeterminate(false);
                      setPartiesCheckAll(e.target.checked);
                      setPartiesCheckAllIndeterminate(false);
                      if (e.target.checked) {
                        setParties(partiesList.map((_) => _.code));
                      } else {
                        setParties([]);
                      }
                      // e.stopPropagation();
                    }}
                  >
                    全选
                  </Checkbox>
                  {menu}
                </div>
              )}
              tagRender={tagRender}
              disabled={loading ? true : false}
              value={parties}
              allowClear
              showArrow="true"
              onChange={async (value) => {
                setPartiesList(searchInfo?.case_parties);
                setParties(value);
                setSubject([]);
                setSubjectCheckAll(false);
                setSubjectCheckAllIndeterminate(false);
                if (value.length === searchInfo?.case_parties?.length) {
                  await setPartiesCheckAll(true);
                  await setPartiesCheckAllIndeterminate(false);
                } else {
                  await setPartiesCheckAllIndeterminate(true);
                  await setPartiesCheckAll(false);
                }
                if (value.length === 0) {
                  await setPartiesCheckAllIndeterminate(false);
                  getValue({
                    case_parties: "",
                    case_subject_scope: "",
                  });
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  return parties.length > 0
                    ? getValue({
                        case_parties: parties.toString(),
                        case_subject_scope: "",
                      })
                    : null;
                }
              }}
              onClear={() => {
                setPartiesCheckAll(false);
                setPartiesCheckAllIndeterminate(false);
                getValue({
                  case_parties: "",
                });
              }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              onSearch={debounce((e) => {
                if (e !== "")
                  setPartiesList(
                    searchInfo?.case_parties.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
            >
              {partiesList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">案件类型</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              maxTagCount="responsive"
              showSearch
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">
                  <Checkbox
                    disabled={false}
                    style={{ margin: "5px", marginLeft: "12px" }}
                    indeterminate={typeCheckAllIndeterminate}
                    checked={typeCheckAll}
                    onChange={(e) => {
                      setTypeCheckAll(e.target.checked);
                      setTypeCheckAllIndeterminate(false);
                      if (e.target.checked) {
                        setType(typeList.map((_) => _.code));
                      } else {
                        setType([]);
                      }
                      // e.stopPropagation();
                    }}
                  >
                    全选
                  </Checkbox>
                  {menu}
                </div>
              )}
              value={type}
              tagRender={tagRender}
              disabled={loading ? true : false}
              filterOption={false}
              allowClear
              showArrow="true"
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              onChange={async (value) => {
                setTypeList(searchInfo?.case_type);
                setType(value);
                if (value.length === searchInfo?.case_type?.length) {
                  await setTypeCheckAll(true);
                  await setTypeCheckAllIndeterminate(false);
                } else {
                  await setTypeCheckAllIndeterminate(true);
                  await setTypeCheckAll(false);
                }
                if (value.length === 0) {
                  await setTypeCheckAllIndeterminate(false);
                  getValue({ case_type: "" });
                }
              }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  return type.length > 0
                    ? getValue({ case_type: type.toString() })
                    : null;
                }
              }}
              onClear={() => {
                setTypeCheckAll(false);
                setTypeCheckAllIndeterminate(false);
                getValue({ case_type: "" });
              }}
              onSearch={debounce((e) => {
                if (e !== "")
                  setTypeList(
                    searchInfo?.case_type.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
            >
              {typeList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">案由</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              maxTagCount="responsive"
              showSearch
              value={reason}
              disabled={loading ? true : false}
              filterOption={false}
              allowClear
              showArrow="true"
              tagRender={tagRender}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              onChange={async (value) => {
                setReasonList(searchInfo?.case_reason);
                setReason(value);
                if (value.length === searchInfo?.case_type?.length) {
                  await setReasonCheckAll(true);
                  await setReasonCheckAllIndeterminate(false);
                } else {
                  await setReasonCheckAllIndeterminate(true);
                  await setReasonCheckAll(false);
                }
                if (value.length === 0) {
                  await setReasonCheckAllIndeterminate(false);
                  getValue({ case_reason: "" });
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  return reason.length > 0
                    ? getValue({ case_reason: reason.toString() })
                    : null;
                }
              }}
              onClear={() => {
                setReasonCheckAll(false);
                setReasonCheckAllIndeterminate(false);
                getValue({ case_reason: "" });
              }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">
                  <Checkbox
                    disabled={false}
                    style={{ margin: "5px", marginLeft: "12px" }}
                    indeterminate={reasonCheckAllIndeterminate}
                    checked={reasonCheckAll}
                    onChange={(e) => {
                      setReasonCheckAll(e.target.checked);
                      setReasonCheckAllIndeterminate(false);
                      if (e.target.checked) {
                        setReason(reasonList.map((_) => _.code));
                      } else {
                        setReason([]);
                      }
                      // e.stopPropagation();
                    }}
                  >
                    全选
                  </Checkbox>
                  {menu}
                </div>
              )}
              onSearch={debounce((e) => {
                if (e !== "")
                  setReasonList(
                    searchInfo?.case_reason.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
            >
              {reasonList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">涉案金额</div>

          <InputGroup compact style={{ marginLeft: "5px" }}>
            <Input
              type="text"
              // onKeyDown={(e) => {
              //   const prohibitInput = ["-", "e", "+", "E"];
              //   if (prohibitInput.includes(e.key)) {
              //     e.preventDefault();
              //     return false
              //   }
              // }}
              min={0}
              style={{ width: 90, textAlign: "center" }}
              disabled={loading ? true : false}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                setMinAmount(
                  e.target.value
                    ?.replace(/[^\d^\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".")
                );
              }}
              value={minAmount}
              onBlur={(e) => handledMinAmount(e.target.value)}
            />
            <Input
              style={{
                width: 33,
                borderLeft: 0,
                pointerEvents: "none",
              }}
              placeholder="~"
              disabled
            />
            <Input
              min={0}
              type="text"
              // onKeyDown={(e) => {
              //   const prohibitInput = ["-", "e", "+", "E"];
              //   if (prohibitInput.includes(e.key)) {
              //     e.preventDefault();
              //     return false;
              //   }
              // }}
              value={maxAmount}
              onKeyPress={handleKeyPress}
              style={{ width: 90, textAlign: "center", borderLeft: 0 }}
              disabled={loading ? true : false}
              onChange={(e) =>
                setMaxAmount(
                  e.target.value
                    ?.replace(/[^\d^\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".")
                )
              }
              onFocus={() => clearTimeout(minTimer)}
              onBlur={(e) => handledMaxAmount(e.target.value)}
            />
            <Input
              style={{
                width: 40,
                borderLeft: 0,
                textAlign: "right",
                pointerEvents: "none",
              }}
              placeholder="元"
              disabled
            />
          </InputGroup>
          {/* </div> */}
        </div>
        <div className="searchItem">
          <div className="label">案中身份</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              showSearch
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">{menu}</div>
              )}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              disabled={loading ? true : false}
              filterOption={false}
              allowClear
              showArrow="true"
              onChange={(value) => {
                setIdentityList(searchInfo?.case_identity);
                setIdentity(value);
                if (value.length === 0) getValue({ case_identity: "" });
              }}
              onBlur={() =>
                identity.length > 0
                  ? getValue({ case_identity: identity.toString() })
                  : null
              }
              tagRender={tagRender}
              onClear={() => getValue({ case_identity: "" })}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              onSearch={debounce((e) => {
                if (e !== "")
                  setIdentityList(
                    searchInfo?.case_identity.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
            >
              {identityList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">案件状态</div>
          <div className="value">
            <Select
              placeholder="全部"
              mode="multiple"
              showSearch
              dropdownRender={(menu) => (
                <div className="subjectScopeSearchPoP">{menu}</div>
              )}
              tagRender={tagRender}
              disabled={loading ? true : false}
              filterOption={false}
              allowClear
              showArrow="true"
              onChange={(value) => {
                setStateList(searchInfo?.case_state);
                setState(value);
                if (value.length === 0) getValue({ case_state: "" });
              }}
              onBlur={() =>
                state.length > 0
                  ? getValue({ case_state: state.toString() })
                  : null
              }
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode || document.body
              }
              onClear={() => getValue({ case_state: "" })}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              onSearch={debounce((e) => {
                if (e !== "")
                  setStateList(
                    searchInfo?.case_state.filter((item) =>
                      item.name.includes(e)
                    )
                  );
              }, 1000)}
            >
              {stateList?.map((item) => {
                return (
                  <Option value={item.code} key={item.code}>
                    <a className="textItem" title={item.name}>
                      {item.name}
                    </a>

                    <Tag
                      style={
                        item.cnt === 0
                          ? {
                              background: "#EFEFEF",
                              borderColor: "#EFEFEF",
                              color: "#535353",
                            }
                          : {
                              background: "#0068B2",
                              borderColor: "#0068B2",
                              color: "#fff",
                            }
                      }
                    >
                      {item.cnt}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="searchItem">
          <div className="label">最新审理程序日期</div>
          <div className="value">
            <MyRangePicker
              allowClear={true}
              format="YYYY-MM-DD"
              disabled={loading ? true : false}
              style={{ width: "250px" }}
              onChange={(time, timeString) => {
                getValue({
                  case_start_time: timeString[0],
                  case_end_time: timeString[1],
                });
              }}
            ></MyRangePicker>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyJudicial;
