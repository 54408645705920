import {
    createSlice
} from '@reduxjs/toolkit';
const ProjectInfo = createSlice({
    name: 'project',
    initialState: {
        projectInfo: {},
        projectProgressInfo:[],
        projectProgressCodeList: [],
        projectProgressCode: undefined,
        projectProgressShowCode: undefined,
        menuList: [],
        projectProgressName:null,
        unreadNoticesTotal: 0,
        first: true
    },
    reducers: {
        setProjectInfo: (state, action) => {
            state.projectInfo = action.payload
        },
        setProjectProgressCodeList: (state, action) => {
            state.projectProgressCodeList = action.payload
        },
        setProjectProgressCode: (state, action) => {
            state.projectProgressCode = action.payload
        },
        setProjectProgressShowCode: (state, action) => {
            state.projectProgressShowCode = action.payload
        },
        setProjectProgressName: (state, action) => {
            state.projectProgressName = action.payload
        },
        setProjectProgressInfo: (state, action) => {
            state.projectProgressInfo = action.payload
        },
        setMenusList: (state, action) => {
            state.menuList = action.payload
        },
        setUnreadNoticesTotal: (state, action) => {
            state.unreadNoticesTotal = action.payload
        },
        setFirst: (state, action) => {
            state.first = action.payload
        },
    },
});
export const {
    setProjectInfo,
    setProjectProgressCodeList,
    setProjectProgressCode,
    setProjectProgressShowCode,
    setProjectProgressName,
    setMenusList,
    setUnreadNoticesTotal,
    setFirst,
    setProjectProgressInfo
} = ProjectInfo.actions;

export default ProjectInfo;