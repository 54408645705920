import MyEmpty from "../../../../../../components/Empty";
import "./index.scss";
import { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useGetState } from "ahooks";
import {
  addMillimeter,
  addMillimeterAndInit,
  isCurrentYear,
} from "../../../../../../unit/unit";
import moment from "moment";
import { message } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { includes } from "lodash";
import IconBar from "../../../../../../components/IconBar";

const OverviewPopulationContent = (props) => {
  const { data, dataRange } = props;
  const [noData, setNoData] = useState(false);
  const [selectYear, setSelectYear, getSelectYear] = useGetState(false);
  const [type, setType, getType] = useGetState("H1");
  const [dataInfo, setDataInfo, getDataInfo] = useGetState({});

  const myChart1 = useRef(null);
  const myChart2 = useRef(null);
  const myChart3 = useRef(null);
  const colorList = useRef(["#0068B2", "#61D4AD", "#FEC054", "#6CAEFF"]);

  const initEcharts = (echartsData) => {
    if (myChart1.current) {
      myChart1.current.dispose();
      myChart1.current = null;
    }
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    if (myChart3.current) {
      myChart3.current.dispose();
      myChart3.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom1 = document.getElementById("overviewPopulationBox1");
    const dom2 = document.getElementById("overviewPopulationBox2");
    // const dom3 = document.getElementById("overviewPopulationBox3");

    if (!dom1 || !dom2) {
      return;
    }
    if (echartsData?.xypj?.length !== 0) {
      myChart1.current = echarts.init(dom1);
    }
    if (Object.keys(echartsData?.total_sjsk || {})?.length > 0) {
      myChart2.current = echarts.init(dom2);
    }
    // if (Object.keys(echartsData?.tex_add_return || {})?.length !== 0) {
    //   myChart3.current = echarts.init(dom3);
    // }

    const yeatKeys = Object.keys(echartsData?.total_sjsk || {})
      ?.filter((_) => !_?.includes("H"))
      ?.sort((a, b) => a - b);

    const amountKeys = Object.keys(echartsData?.tex_add_return || {})
      ?.filter((_) => !_?.includes("H"))
      ?.sort((a, b) => b - a);

    const a = echartsData?.xypj || [];
    const option1 = {
      series: a?.map((item, index) => {
        return {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center:
            index === 0
              ? ["50%", "50%"]
              : index === 1
              ? ["25%", "82%"]
              : ["75%", "82%"],
          radius: index === 0 ? "90%" : "50%",
          min: 0,
          max: 100,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 2,
              color: [
                [0.25, "#FF6E76"],
                [0.5, "#FDDD60"],
                [0.75, "#58D9F9"],
                [1, "#7CFFB2"],
              ],
            },
          },
          pointer: {
            icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
            length: "12%",
            width: index === 0 ? 8 : 4,
            offsetCenter: [0, "-70%"],
            itemStyle: {
              color: "auto",
            },
          },
          axisTick: {
            length: index === 0 ? 4 : 2,
            lineStyle: {
              color: "auto",
              width: 1,
            },
            distance: 2,
          },
          splitLine: {
            length: index === 0 ? 6 : 3,
            lineStyle: {
              color: "auto",
              width: 2,
            },
            distance: 2,
          },
          axisLabel: {
            color: "#464646",
            fontSize: 8,
            distance: -60,
            rotate: "tangential",
            show: false,
          },
          title: {
            offsetCenter: [0, 0],
            fontSize: index === 0 ? 24 : 16,
          },
          detail: {
            fontSize: index === 0 ? 24 : 14,
            offsetCenter: [0, "-45%"],
            valueAnimation: true,
            lineHeight: index === 0 ? 24 : 14,
            formatter: (value) => {
              return item.swzxdj + "\n" + value;
            },
            color: "inherit",
          },
          data: [
            {
              value: Number(item.pjfs),
              name: item.pjnf,
            },
          ],
        };
      }),
    };
    const option2 = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: function (params) {
          return (
            params[0].name + ": " + addMillimeter(params[0].value, 2) + "元"
          );
        },
      },
      xAxis: {
        data: yeatKeys,
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          // color: "#e54035",
        },
      },
      yAxis: {
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
      },
      grid: {
        containLabel: true,
        left: "30px",
        top: 0,
        right: "30px",
        bottom: 0,
      },
      color: ["#e54035"],
      series: [
        {
          name: "金额",
          barMinHeight: 1,
          type: "pictorialBar",
          barCategoryGap: "-130%",
          // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
          symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
          itemStyle: {
            opacity: 0.5,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
          },
          data: yeatKeys?.map((key) => {
            const num = (echartsData?.total_sjsk || {})[key] || 0;
            return (
              num || {
                value: 0,
                symbol: "rect",
              }
            );
          }),
          z: 10,
        },
      ],
    };
    // const option3 = {
    //   legend: {
    //     data: amountKeys,
    //     show: false,
    //   },
    //   tooltip: {
    //     trigger: "axis",
    //     axisPointer: {
    //       type: "none",
    //     },
    //     formatter: (info) => {
    //       let str = "<div>税负率</div>";
    //       info?.forEach((item) => {
    //         str += `<div>${item?.marker} ${item.seriesName}年   ${Number(
    //           item.value || 0
    //         )?.toFixed(2)}%</div>`;
    //       });
    //       return str;
    //     },
    //   },
    //   grid: {
    //     containLabel: true,
    //     left: 0,
    //     top: 0,
    //     right: "60px",
    //     bottom: 0,
    //   },
    //   yAxis: {
    //     data: ["税负率"],
    //     show: false,
    //     inverse: false,
    //     axisLine: { show: false },
    //     axisTick: { show: false },
    //     axisLabel: {
    //       margin: 0,
    //       fontSize: 0,
    //     },
    //     axisPointer: {
    //       label: {
    //         show: false,
    //         margin: 0,
    //       },
    //     },
    //   },
    //   xAxis: {
    //     splitLine: { show: false },
    //     axisLabel: { show: false },
    //     axisTick: { show: false },
    //     axisLine: { show: false },
    //   },
    //   series: amountKeys?.map((key, index) => ({
    //     name: key,
    //     type: "pictorialBar",
    //     symbolRepeat: true,
    //     barGap: "28px",
    //     label: {
    //       show: true,
    //       position: "right",
    //       align: "center",
    //       // offset: [16, 0],
    //       distance: 30,
    //       fontSize: 16,
    //       // width: 1800,
    //       // borderColor: 'red',
    //       // borderWidth: 1,
    //       formatter: (info) => {
    //         return Number(info.value || 0).toFixed(2) + "%";
    //       },
    //     },
    //     symbolSize: 22,
    //     barCategoryGap: "80%",
    //     // symbolMargin: 2,
    //     // symbolClip: true,
    //     // symbolBoundingData: 10000000,
    //     itemStyle: {
    //       color: colorList.current[index % 4],
    //     },
    //     data: [
    //       {
    //         value:
    //           (((echartsData?.tex_add_return || {})[key] || {})?.yb_sfl ||
    //             ((echartsData?.tex_add_return || {})[key] || {})?.xgm_sfl ||
    //             0) * 100,
    //         symbol:
    //           "path://M539.428571 539.428571h137.142858a27.428571 27.428571 0 0 1 0 54.857143h-137.142858V731.428571a27.428571 27.428571 0 0 1-54.857142 0v-137.142857H347.428571a27.428571 27.428571 0 0 1 0-54.857143h137.142858v-54.857142h-109.714286a27.428571 27.428571 0 0 1 0-54.857143h70.948571l-66.194285-66.194286a27.794286 27.794286 0 0 1-7.68-26.697143 28.16 28.16 0 0 1 19.748571-19.748571 27.794286 27.794286 0 0 1 26.697143 7.68L512 418.377143l93.622857-93.622857a27.794286 27.794286 0 0 1 26.697143-7.68 28.16 28.16 0 0 1 19.748571 19.748571 27.794286 27.794286 0 0 1-7.68 26.697143l-66.194285 66.194286h70.948571a27.428571 27.428571 0 0 1 0 54.857143h-109.714286zM512 896A384 384 0 1 1 896 512 384 384 0 0 1 512 896z m0-54.857143a329.142857 329.142857 0 1 0-329.142857-329.142857 329.142857 329.142857 0 0 0 329.142857 329.142857z",
    //       },
    //     ],
    //   })),
    // };
    if (echartsData?.xypj?.length !== 0) {
      myChart1.current.setOption(option1);
    }
    if (Object.keys(echartsData?.total_sjsk || {})?.length > 0) {
      myChart2.current.setOption(option2);
    }

    // if (Object.keys(echartsData?.tex_add_return || {})?.length !== 0) {
    //   myChart3.current.setOption(option3);
    // }

    window.addEventListener("resize", () => {
      myChart1.current && myChart1.current.resize();
      myChart2.current && myChart2.current.resize();
      // myChart3.current && myChart3.current.resize();
    });
  };

  const initData = (data, dataRange) => {
    const time = dataRange?.split("~")?.at(1);
    let diff = null;
    if (time) {
      const time2 = moment(time)?.format("MM-DD");
      diff = moment(time2).diff(moment("06-30"), "days");
    } else {
      message.info("采集数据未获取");
      return;
    }
    let year =
      diff >= 0
        ? moment(time)?.format("YYYY")
        : moment(time).subtract(1, "year")?.format("YYYY");
    setSelectYear(year);

    //数据范围为下半年且是今年 取上半年的数据展示
    if (diff >= 0 && isCurrentYear(year)) {
      setType("H1");
    } else {
      //数据范围为上半年 取前一年的数据展示
      setType(null);
    }

    initEcharts(data);
  };

  useEffect(() => {
    if (Object.keys(data || {})?.length === 0) {
      setNoData(true);
      return;
    }
    setNoData(false);
    initData(data, dataRange);
  }, [data, dataRange]);

  const initTip = () => {
    if (!data?.total_yjsk) {
      return;
    }
    const yearKey =
      getSelectYear() +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");
    const lastYearkey =
      getSelectYear() -
      1 +
      (isCurrentYear(getSelectYear()) ? (getType() === "H1" ? "H1" : "") : "");

    const lostYjAmount = data?.total_yjsk[lastYearkey] || 0;
    const yjAmount = data?.total_yjsk[yearKey] || 0;

    const lostSjAmount = data?.total_sjsk[lastYearkey] || 0;
    const sjAmount = data?.total_sjsk[yearKey] || 0;

    const texAddReturn =
      (data?.tex_add_return[yearKey] || {})?.yb_sfl ||
      (data?.tex_add_return[yearKey] || {})?.xgm_sfl ||
      0;
    const lostTexAddReturn =
      (data?.tex_add_return[lastYearkey] || {})?.yb_sfl ||
      (data?.tex_add_return[lastYearkey] || {})?.xgm_sfl ||
      0;

    let items = data?.pay_tax[yearKey] || [];
    let sum = 0;
    items?.forEach((item) => {
      sum += item.sjsk;
    });
    items = items?.sort((a, b) => b?.sjsk - a?.sjsk)?.slice(0, 3);

    return (
      <>
        <div className="overviewPopulationTipItem">
          <i></i>
          <div>
            {getSelectYear()}年
            {getType() === "H1" && isCurrentYear(getSelectYear())
              ? "上半年"
              : ""}
            应税销售额为
            <span className="numberSpan">{addMillimeter(yjAmount)}</span>元
            {lostYjAmount ? (
              <>
                ，同比（较
                {getSelectYear() - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{lostYjAmount > yjAmount ? "下降" : "增长"}
                <span
                  style={{
                    color: lostYjAmount > yjAmount ? "#FE545F" : "#61D4AD",
                    fontWeight: "bold",
                  }}
                >
                  {lostYjAmount
                    ? addMillimeter(
                        ((yjAmount - lostYjAmount || 0) /
                          Math.abs(lostYjAmount)) *
                          100
                      )
                    : "-"}
                  %
                </span>
                {lostYjAmount > yjAmount ? (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            。纳税总额为
            <span className="numberSpan">{addMillimeter(sjAmount)}</span>元
            {lostSjAmount ? (
              <>
                ，同比（较
                {getSelectYear() - 1}年
                {getType() === "H1" && isCurrentYear(getSelectYear())
                  ? "上半年"
                  : ""}
                ）{lostSjAmount > sjAmount ? "下降" : "增长"}
                <span
                  style={{
                    color: lostSjAmount > sjAmount ? "#FE545F" : "#61D4AD",
                    fontWeight: "bold",
                  }}
                >
                  {lostSjAmount
                    ? addMillimeter(
                        (Math.abs(sjAmount - lostSjAmount || 0) /
                          Math.abs(lostSjAmount)) *
                          100
                      )
                    : "-"}
                  %
                </span>
                {lostSjAmount > sjAmount ? (
                  <>
                    <ArrowDownOutlined
                      style={{ color: "#FE545F", fontSize: "14px" }}
                    />
                  </>
                ) : (
                  <>
                    <ArrowUpOutlined
                      style={{ color: "#61D4AD", fontSize: "14px" }}
                    />
                  </>
                )}
              </>
            ) : null}
            。
            {items?.length > 0 ? (
              <>
                其中，
                {items?.map((item, index) => (
                  <span key={Math.random()}>
                    {index === 0 ? null : "、"}
                    {item.zsxm}-{item.zspm}为
                    <span className="numberSpan">
                      {addMillimeter(item.sjsk)}
                    </span>
                    元 （占比
                    <span className="numberSpan">
                      {((item.sjsk / Math.abs(sum)) * 100).toFixed(2) === "0.00"
                        ? "不足0.01"
                        : ((item.sjsk / Math.abs(sum)) * 100).toFixed(2)}
                      %
                    </span>{" "}
                    ）
                  </span>
                ))}
                。
              </>
            ) : null}
          </div>
        </div>
        {texAddReturn ? (
          <div className="overviewPopulationTipItem">
            <i></i>
            <div>
              {getSelectYear()}年
              {getType() === "H1" && isCurrentYear(getSelectYear())
                ? "上半年"
                : ""}
              税负率为
              <span className="numberSpan">
                {(texAddReturn * 100)?.toFixed(2)}%
              </span>
              {lostTexAddReturn ? (
                <>
                  ，同比（较
                  {getSelectYear() - 1}年
                  {getType() === "H1" && isCurrentYear(getSelectYear())
                    ? "上半年"
                    : ""}
                  ）{lostTexAddReturn > texAddReturn ? "下降" : "增长"}
                  <span
                    style={{
                      color:
                        lostTexAddReturn > texAddReturn ? "#FE545F" : "#61D4AD",
                      fontWeight: "bold",
                    }}
                  >
                    {addMillimeter(
                      Math.abs(lostTexAddReturn - texAddReturn) * 100
                    )}
                    %
                  </span>
                  {lostTexAddReturn > texAddReturn ? (
                    <>
                      <ArrowDownOutlined
                        style={{ color: "#FE545F", fontSize: "14px" }}
                      />
                    </>
                  ) : (
                    <>
                      <ArrowUpOutlined
                        style={{ color: "#61D4AD", fontSize: "14px" }}
                      />
                    </>
                  )}
                </>
              ) : null}
              。
            </div>
          </div>
        ) : null}

        <div className="overviewPopulationTipItem">
          <i></i>
          <div>
            企业欠税总额
            <span className="numberSpan">
              {addMillimeter(data?.total_ybse)}
            </span>
            元 ，税务稽查案件
            <span className="numberSpan">
              {Number(data?.total_aj_count || 0)?.toLocaleString()}
            </span>
            起
          </div>
        </div>
      </>
    );
  };

  const initTexAdd = (info) => {
    const obj = {};

    Object.keys(info)?.forEach((key) => {
      const item = info[key]?.yb_sfl || info[key]?.xgm_sfl || 0;
      obj[key] = item;
    });
    return obj;
  };
  return (
    <div className="overviewPopulationContent">
      <div className="headerBox">总体情况</div>
      {!noData && (
        <div className="overviewPopulationBox">
          <div className="overviewPopulationItem">
            <div className="titleBox">纳税信用评级</div>
            <div id="overviewPopulationBox1">
              {data?.xypj?.length === 0 && (
                <MyEmpty description="暂无评级"></MyEmpty>
              )}
            </div>
          </div>

          <div className="overviewPopulationItem">
            <div className="titleBox">纳税总额（万元）</div>
            <div id="overviewPopulationBox2">
              {Object.keys(data?.total_sjsk || {})?.length === 0 && (
                <MyEmpty description="暂无数据"></MyEmpty>
              )}
            </div>
          </div>

          <div className="overviewPopulationItem">
            <div className="titleBox">
              税负率
              <div className="titleTextBox">（实际纳税总额/应税销售总额）</div>
            </div>
            {/* <div id="overviewPopulationBox3">
              {Object.keys(data?.tex_add_return || {})?.length === 0 && (
                <MyEmpty description="暂无数据"></MyEmpty>
              )}
            </div> */}
            <IconBar
              data={initTexAdd(data?.tex_add_return || {})}
              title="税负率"
              type="rate"
              icon={
                <svg
                  fill="none"
                  version="1.1"
                  width="18"
                  height="18"
                  viewBox="0 0 20.5 20.5"
                >
                  <g>
                    <g>
                      <path
                        d="M16,5.09832L16,10.7375L17,10.7375L17,4.67668L14.8491,2.5795L12.2034,0L2.5,0Q1.464466,0,0.732233,0.732233Q0,1.464466,0,2.5L0,18Q0,19.0355,0.732233,19.7678Q1.464466,20.5,2.5,20.5L16.5,20.5L16.5,19.5L2.5,19.5Q1.87868,19.5,1.43934,19.0607Q1,18.6213,1,18L1,2.5Q1,1.87868,1.43934,1.43934Q1.87868,1,2.5,1L11.7966,1L14.1509,3.2955L16,5.09832Z"
                        fillRule="evenodd"
                        fill="#0068B2"
                        fillOpacity="1"
                      />
                    </g>
                    <g>
                      <ellipse
                        cx="15.5"
                        cy="15.5"
                        rx="4.5"
                        ry="4.5"
                        fillOpacity="0"
                        strokeOpacity="1"
                        stroke="#0068B2"
                        fill="none"
                        strokeWidth="1"
                      />
                    </g>
                    <g>
                      <path
                        d="M18.19222,13.862371L18.19222,15.87457L17.73053,15.87438L17.73053,17.02439C17.73053,17.1718,17.84936,17.293309999999998,18.00242,17.309919999999998L18.03832,17.31186L18.5,17.31186L18.5,17.88676L18.03832,17.88676C17.54657,17.88676,17.14462,17.52776,17.11652,17.07505L17.11495,17.02439L17.11495,15.87438L16.49374,15.87486C16.46482,16.6044,16.32451,17.26851,16.07182,17.86609L16.01281,18L15.44701,17.77353C15.70339,17.21481,15.84737,16.582250000000002,15.87773,15.87454L15.42212,15.87457L15.42212,13.862371L18.19222,13.862371ZM15.06374,13.00390762L15.16493,13.570998C14.88097,13.615194,14.55659,13.649406,14.191559999999999,13.673701L14.19097,14.14964L15.11434,14.14982L15.11434,14.72474L14.19097,14.72455L14.19097,17.88676L13.57539,17.88676L13.57539,14.72455L12.652023,14.72474L12.652023,14.14982L13.57539,14.14964L13.575990000000001,13.704032C13.341979,13.711968,13.09456,13.716658,12.833695,13.718126999999999L12.652023,13.718638L12.652023,13.14372C13.603,13.14372,14.37192,13.10211,14.95633,13.0198076L15.06374,13.00390762ZM13.267591,15.01219C13.267591,15.77607,13.227828,16.53991,13.148318,17.30372L13.111816,17.631050000000002L12.5,17.56756C12.5886716,16.8222,12.638548,16.07689,12.649628,15.3316L12.652023,15.01219L13.267591,15.01219ZM15.11432,15.01219L15.11432,17.59931L14.49875,17.59931L14.49875,15.01219L15.11432,15.01219ZM17.57662,14.43728L16.0377,14.43728L16.0377,15.29965L17.57663,15.29965L17.57662,14.43728ZM16.49937,13L16.49937,13.574905L15.576,13.574905L15.576,13L16.49937,13ZM18.0383,13L18.0383,13.574905L17.11494,13.574905L17.11494,13L18.0383,13Z"
                        fill="#0068B2"
                        fillOpacity="1"
                      />
                    </g>
                    <g>
                      <path
                        d="M8.30429,4.5L9.232140000000001,4.500431359L4.69593,15.4999L3.7675359999999998,15.5L8.30429,4.5ZM7.83929,11.83344C8.76479,11.83344,9.5,12.63771,9.5,13.6128C9.5,14.5879,8.76479,15.3922,7.83929,15.3922C6.9137900000000005,15.3922,6.178570000000001,14.5879,6.178570000000001,13.6128C6.178570000000001,12.63771,6.9137900000000005,11.83344,7.83929,11.83344ZM7.83929,12.69616C7.40386,12.69616,7.03571,13.09894,7.03571,13.6128C7.03571,14.12666,7.40386,14.5294,7.83929,14.5294C8.274709999999999,14.5294,8.642859999999999,14.12666,8.642859999999999,13.6128C8.642859999999999,13.09894,8.274709999999999,12.69616,7.83929,12.69616ZM5.16071,4.608164C6.0862099999999995,4.608163,6.821429999999999,5.412434,6.821429999999999,6.38752C6.821429999999999,7.36261,6.0862099999999995,8.166879999999999,5.16071,8.166879999999999C4.235215,8.166879999999999,3.5,7.36261,3.5,6.38752C3.5,5.412434,4.235214,4.608163,5.16071,4.608164ZM5.16071,5.470883C4.72529,5.470883,4.357143,5.87367,4.357143,6.38752C4.357143,6.90138,4.72529,7.3041599999999995,5.16071,7.3041599999999995C5.59614,7.3041599999999995,5.96429,6.90138,5.96429,6.38752C5.96429,5.87367,5.59614,5.470883,5.16071,5.470883Z"
                        fill="#0068B2"
                        fillOpacity="1"
                      />
                    </g>
                    <g>
                      <path
                        d="M13.5,6L16.5,6L16.5,5L13.5,5Q12.87868,5,12.43934,4.56066Q12,4.12132,12,3.5L12,0.5L11,0.5L11,3.5Q11,4.53553,11.732233,5.26777Q12.464466,6,13.5,6Z"
                        fillRule="evenodd"
                        fill="#0068B2"
                        fillOpacity="1"
                      />
                    </g>
                  </g>
                </svg>
              }
            ></IconBar>
          </div>

          <div className="overviewPopulationItem">
            <div className="titleBox">欠税总额</div>
            <div className="numberBox">
              {((data?.total_ybse || 0) / 10000).toFixed(2)}
              <span>万元</span>
            </div>
            <div className="titleBox">税务稽查案件</div>
            <div className="numberBox">
              {data?.total_aj_count}
              <span>起</span>
            </div>
          </div>
        </div>
      )}
      {!noData && <div className="overviewPopulationTipBox">{initTip()}</div>}
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default OverviewPopulationContent;
