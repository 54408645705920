import "./index.scss";
import { Modal, Table, Tag, Tooltip, Button, message } from "antd";
import { useEffect } from "react";
import MyEmpty from "../Empty";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import projectApi from "../../apis/project";
import { useParams } from "react-router-dom";
import { downloadFile } from "../../unit/unit";

const UploadFileModal = (props) => {
  const { visible, onCancel, onOk, upData } = props;
  const { id } = useParams();

  const [upLoading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "行号",
      dataIndex: "idx",
      key: "idx",
      width: 70,
    },
    {
      title: "关联方类型",
      dataIndex: "type",
      key: "type",
      width: 105,
    },
    {
      title: "关联方名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "自然人关联公司",
      dataIndex: "company",
      key: "company",
      width: 150,
      render: (_, record) => (record.type === "自然人" ? record.company : null),
    },
    {
      title: "关联关系",
      dataIndex: "relation",
      key: "relation",
    },
    {
      title: "关系描述",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "校验结果",
      key: "fail_msg",
      render: (_, record) => (
        <div style={{ color: "red" }}>{record.fail_msg}</div>
      ),
    },
  ];

  const exportFailData = () => {
    console.log("下载失败列表");
    setUpLoading(true);
    projectApi
      .downloadRelatedFailFile(id, JSON.stringify(upData?.fail_data || []))
      .then((res) => {
        if (res.code === 200) {
          downloadFile(res.data.fail_file_path, id);
        } else {
          message.error(res.message);
        }
        setUpLoading(false);
      });
  };

  const uploadSuccess = () => {
    setLoading(true);
    projectApi
      .upRelatedFunc(id, JSON.stringify(upData?.success_data || []))
      .then((res) => {
        if (res.code === 200) {
          onOk();
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setLoading(false);
      });
    // onOk();
  };
  return (
    <Modal
      title="上传数据校验"
      wrapClassName="uploadFileModal"
      visible={visible}
      width={900}
      maskClosable={false}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
      footer={null}
      centered
    >
      <div className="uploadFileModalContent">
        <div className="uploadInfo">
          共读取到
          <span style={{ fontSize: "16px", color: "#313131" }}>
            {" "}
            {upData?.total}{" "}
          </span>
          条数据，校验通过
          <span style={{ fontSize: "16px", color: "#61D4AD" }}>
            {" "}
            {upData?.success}{" "}
          </span>
          条
          <span style={{ fontSize: "12px" }}>
            (新增{upData?.success_new}条，更新{upData?.success_update}条)
          </span>
          ，校验失败
          <span style={{ fontSize: "16px", color: "#FE545F" }}>
            {" "}
            {upData?.fail}{" "}
          </span>
          条
        </div>
        {upData?.fail_data?.length > 0 ? (
          <div className="uploadTip">
            以下数据校验失败，请修改后再重新上传，如果继续上传将忽略以下数据。
            <div>
              {upLoading ? (
                <LoadingOutlined />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    onClick={() => {
                      exportFailData();
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        ) : null}
        {upData?.fail_data?.length > 0 ? (
          <Table
            className="myFormTable"
            rowClassName="upLoadFileModalTableRow"
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            rowKey={(record) => record.idx}
            bordered={true}
            pagination={false}
            scroll={{
              y: "50vh",
            }}
            columns={columns}
            dataSource={upData?.fail_data || []}
          />
        ) : null}

        <div className="uploadBtnBox">
          <Button className="noBg" onClick={() => onCancel()}>
            暂不上传
          </Button>
          <Button
            type="primary"
            onClick={() => {
              uploadSuccess();
            }}
            loading={loading}
          >
            继续上传
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default UploadFileModal;
