import "./index.scss";
import { Button, Drawer, Form, Radio, Select, message, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import DebounceSelect from "../../../../../components/DebounceSelect";
import PersonAndCompanySelect from "../../../../../components/PersonAndCompanySelect";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../apis/project";
import { guid } from "../../../../../unit/unit";

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onFinish();
        }}
        type="primary"
      >
        确定
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const AddDrawer = (props) => {
  const { getVerificationList, getMax } = props;
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [eventType, setEventType] = useState(null);
  const [eventTypeList, setEventTypeList] = useState([]);

  const [eventCompanyList, setEventCompanyList] = useState([]);
  const [eventCompanyOptionsList, setEventCompanyOptionsList] = useState([]);
  const [
    eventCompanyCheckAllIndeterminate,
    setEventCompanyCheckAllIndeterminate,
  ] = useState(false);
  const [eventCompanyCheckAll, setEventCompanyCheckAll] = useState(false);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const clearAll = () => {
    form?.resetFields();
    setType(1);
    setEventType(null);
    setEventTypeList([]);
    setLoading(false);
    setEventCompanyList([]);
    setEventCompanyOptionsList([]);
    setEventCompanyCheckAllIndeterminate(false);
    setEventCompanyCheckAll(false);
  };

  const getOptions = (info) => {
    setLoading(true);
    setEventCompanyOptionsList([]);
    projectApi?.getEventSuspect(id, info?.value).then((res) => {
      const filterList = (getVerificationList() || [])
        ?.filter((_) => _.s_type === form.getFieldValue("s_type"))
        ?.map((_) => _.id);
      let list = Object.keys(res.data)?.map((key) => ({
        label: key,
        value: key + "&&&&&" + res.data[key],
        _id: res.data[key],
      }));
      list = list?.filter((_) => {
        return !filterList.includes(_._id);
      });
      setEventCompanyOptionsList(list);
      setLoading(false);
    });
  };

  const getEventTypeFunc = () => {
    projectApi?.getEventSuspectType(id).then((res) => {
      if (res.code === 200) {
        const list = res.data?.map((item) => ({
          label: item.event_type,
          value: item.table_name,
        }));
        setEventTypeList(list);
        setEventType(list?.at(0)?.value);
        getOptions(list?.at(0));
      } else {
        setEventTypeList([]);
        setEventType(null);
        // getOptions(list?.at(0))
      }
    });
  };

  useEffect(() => {
    if (!props.visible) {
      clearAll();
    } else {
      form.setFieldsValue({
        s_type: "供应商",
        type: 1,
      });
      setType(1);
      getEventTypeFunc();
    }
  }, [props.visible]);

  const onFinish = () => {
    form.validateFields().then((res) => {
      const len = getVerificationList()?.length;
      if (type === 1) {
        let obj = {
          s_type: res.s_type,
          id: res.companyInfo?.company_id,
          name: res.companyInfo.name,
          type: res.companyInfo.data_type || 1,
        };
        props.successFunc && props.successFunc(obj);
        // message.success("添加成功");
        if(len + 1 > getMax()){
          return 
        }
        setTimeout(() => {
          form.resetFields(["companyInfo"]);
          form.setFieldsValue({
            companyInfo: undefined,
          });
        }, 100);
      } else {
        const list = [];
        res.companyInfo?.forEach((item) => {
          const [name, id] = item?.split("&&&&&");
          let obj = {
            s_type: res.s_type,
            id: id,
            name: name,
            type: 1,
            key: guid(),
          };
          list.push(obj);
        });
        props.successFunc && props.successFunc(list, true);
        // message.success("添加成功");
        if(len >= getMax()){
          return 
        }
        setTimeout(() => {
          form.resetFields(["companyInfo"]);
          form.setFieldsValue({
            companyInfo: undefined,
          });
          setEventCompanyList([]);
          setEventCompanyCheckAllIndeterminate(false);
          setEventCompanyCheckAll(false);
          getOptions(eventTypeList?.find((_) => _.value === eventType));
        }, 100);
      }
      // form.setFieldsValue({
      //   companyInfo: undefined,
      // });
      // setEventCompanyList([]);
      // setEventCompanyCheckAll(false);
      // setEventCompanyCheckAllIndeterminate(false);
    });
  };

  async function fetchUserList(username) {
    if (!username || username?.length < 2) {
      return [];
    }
    return projectApi.getCompanyAndPerson(username, id).then((res) => {
      const filterList = (getVerificationList() || [])
        ?.filter((_) => _.s_type === form.getFieldValue("s_type"))
        ?.map((_) => _.id);
      let list = [];
      if (res.code === 200) {
        list = res.data?.filter((_) => {
          return !filterList.includes(_.company_id);
        });
      }else{
        message.error(res.message)
      }
      return list.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  }

  return (
    <Drawer
      className="addDrawer"
      width="600px"
      closable={false}
      title="新增核查对象"
      placement="right"
      push={false}
      maskClosable={false}
      keyboard={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={<BtnBox onClose={props.onClose} onFinish={onFinish}></BtnBox>}
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="addCheckForm"
        >
          <Form.Item
            name="s_type"
            label="核查对象分类"
            key="s_type"
            rules={[
              {
                required: true,
                message: `请填写核查对象分类`,
              },
            ]}
            style={{ width: "100%" }}
          >
            <Radio.Group
              onChange={() => {
                form.resetFields(["companyInfo"]);
                form.setFieldsValue({
                  companyInfo: undefined,
                });
                setEventCompanyList([]);
                setEventCompanyCheckAllIndeterminate(false);
                setEventCompanyCheckAll(false);
                getOptions(eventTypeList?.find((_) => _.value === eventType));
              }}
            >
              {["供应商", "经销商", "客户", "非经营性资金往来", "其他"].map(
                (optionItem) => (
                  <Radio key={Math.random()} value={optionItem}>
                    {optionItem}
                  </Radio>
                )
              )}
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            name="type"
            label="核查对象类型"
            key="type"
            rules={[
              {
                required: true,
                message: `请填写核查对象类型`,
              },
            ]}
            style={{ width: "100%" }}
          >
            <Radio.Group
              onChange={(e) => {
                setType(e.target.value);
                const s_type = form?.getFieldValue('s_type')
                form?.resetFields();
                form.setFieldsValue({
                  's_type':s_type,
                  'type': e.target.value
                })
              }}
            >
              <Radio value={1}>法人</Radio>
              <Radio value={2}>自然人</Radio>
            </Radio.Group>
          </Form.Item> */}
          {/* {type === 1 ? ( */}

          <Form.Item
            name="companyInfo"
            label="核查对象名称"
            key="companyInfo"
            rules={[
              {
                required: true,
                message: `请选择核查对象`,
              },
            ]}
            style={{ width: "100%" }}
            className={type === 2 ? "companyInfoFormItem" : null}
          >
            <Radio.Group
              onChange={(e) => {
                setType(e.target.value);
                form.resetFields(["companyInfo"]);
                form.setFieldsValue({
                  companyInfo: undefined,
                });
                setEventCompanyList([]);
                setEventCompanyCheckAllIndeterminate(false);
                setEventCompanyCheckAll(false);
              }}
              style={{ marginBottom: "10px" }}
              value={type}
            >
              <Radio value={1}>手工输入</Radio>
              {eventTypeList?.length > 0 && (
                <Radio value={2}>从事件中选择</Radio>
              )}
            </Radio.Group>

            {type === 1 && (
              <PersonAndCompanySelect
                showSearch={true}
                placeholder="请输入核查对象名称搜索"
                fetchOptions={fetchUserList}
                changeClear={true}
                allowClear
                style={{
                  width: "100%",
                }}
                notInForm={true}
                onChange={(info) => {
                  form.setFieldsValue({
                    companyInfo: info,
                  });
                }}
              />
            )}

            {type === 2 && (
              <div>
                <Select
                  style={{
                    width: "120px",
                    marginRight: "10px",
                  }}
                  onChange={(value) => {
                    setEventType(value);
                    const info = eventTypeList?.find((_) => _.value === value);
                    setEventCompanyList([]);
                    setEventCompanyCheckAllIndeterminate(false);
                    setEventCompanyCheckAll(false);
                    form.setFieldsValue({
                      companyInfo: undefined,
                    });
                    getOptions(info);
                  }}
                  disabled={loading}
                  value={eventType}
                  options={eventTypeList}
                />
                <Select
                  style={{
                    width: "calc(100% - 134px)",
                  }}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  disabled={loading}
                  loading={loading}
                  filterOption={(inputValue, option) =>
                    option.label?.includes(inputValue)
                  }
                  placeholder={loading ? "正在查询中..." : "请选择核查对象"}
                  onChange={(value) => {
                    setEventCompanyList(value);
                    form.setFieldsValue({
                      companyInfo: value,
                    });
                    if (value.length === eventCompanyOptionsList?.length) {
                      setEventCompanyCheckAll(true);
                      setEventCompanyCheckAllIndeterminate(false);
                    } else {
                      setEventCompanyCheckAllIndeterminate(true);
                      setEventCompanyCheckAll(false);
                    }
                    if (value.length === 0) {
                      setEventCompanyCheckAllIndeterminate(false);
                    }
                  }}
                  dropdownRender={(menu) => (
                    <div className="subjectScopeSearchPoP">
                      {eventCompanyOptionsList?.length > 0 && (
                        <Checkbox
                          disabled={false}
                          style={{ margin: "5px", marginLeft: "12px" }}
                          indeterminate={eventCompanyCheckAllIndeterminate}
                          checked={eventCompanyCheckAll}
                          onChange={(e) => {
                            setEventCompanyCheckAll(e.target.checked);
                            setEventCompanyCheckAllIndeterminate(false);
                            if (e.target.checked) {
                              setEventCompanyList(
                                eventCompanyOptionsList.map((_) => _.value)
                              );
                              form.setFieldsValue({
                                companyInfo: eventCompanyOptionsList.map(
                                  (_) => _.value
                                ),
                              });
                            } else {
                              setEventCompanyList([]);
                              form.setFieldsValue({
                                companyInfo: undefined,
                              });
                            }
                            // e.stopPropagation();
                          }}
                        >
                          全选
                        </Checkbox>
                      )}

                      {menu}
                    </div>
                  )}
                  value={eventCompanyList}
                  options={eventCompanyOptionsList}
                />
              </div>
            )}
          </Form.Item>
          {/* ) : null} */}
          {/* {type === 2 ? (
            <Form.Item
              name="name"
              label="核查对象名称(姓名)"
              key="name"
              rules={[
                {
                  required: true,
                  message: `请填写核查对象名称(姓名)`,
                },
              ]}
              style={{ width: "calc(50% - 10px)" }}
            >
              <Input placeholder="请填写核查对象名称(姓名)"></Input>
            </Form.Item>
          ) : null}
          {type === 2 ? (
            <Form.Item
              name="companyInfo"
              label="关联企业名称"
              key="companyInfo"
              rules={[
                {
                  required: true,
                  message: `请填写关联企业`,
                },
              ]}
              style={{ width: "calc(50% - 10px)" }}
            >
              <DebounceSelect
                showSearch={true}
                placeholder="请输入公司名称搜索"
                fetchOptions={fetchUserList}
                changeClear={true}
                allowClear
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          ) : null} */}
        </Form>
      </Scrollbars>
    </Drawer>
  );
};
export default AddDrawer;
