import "./index.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import HeadPortraitLine from "../../../components/HeadPortraitLine";
import { Radio, message, Typography, Empty } from "antd";
import { guid, hex2Rgb } from "../../../unit/unit";
import bondApi from "../../../apis/bond";
import {
  setBondProjectInfo,
  setBondMenusList,
} from "../../../store/bond/project";
import MySpin from "../../../components/MySpin";
import { useGetState, useSize } from "ahooks";
import { DoubleRightOutlined } from "@ant-design/icons";
import router from "../router/router";
import emptyPng from "../../../assets/empty22.png";
import DetailsDrawer from "../../../components/DetailsDrawer";

const { Paragraph } = Typography;

const BondProjectInfo = (props) => {
  const { id } = useParams();
  // const projectInfo = useSelector((state) => state.BondProject.bondProjectInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOnlySelf, setIsOnlySelf, getIsOnlySelf] = useGetState(1);

  const [projectInfo, setProjectInfo] = useState({});

  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState({});
  
  const projectDate =
    useSelector((state) => state.BondProject.bondProjectInfo) || {};

  const contentRef = useRef(null);
  const size = useSize(contentRef);

  const initDomData = (data) => {
    const maxH = size?.height - 15;
    let list = [
      {
        h: 0,
        l: [],
        i: 1,
      },
      {
        h: 0,
        l: [],
        i: 2,
      },
      {
        h: 0,
        l: [],
        i: 3,
      },
    ];
    const len = data?.length;
    let hTotal = 0;
    let _data = data?.map((item) => {
      let __ = JSON.parse(JSON.stringify(item));
      __.height = (item.summary_desc?.length * 56 || 0) - 10 + 80;
      if (__.height > maxH) {
        __.height = maxH;
        __.isMax = true;
      }
      if (__.isMax) {
        hTotal += maxH;
      } else {
        hTotal += __.height + 15;
      }
      // if (len <= 3) {
      //   __.height = maxH;
      //   __.isMax = true;
      // } else if (len <= 6) {
      //   const halfMaxH = maxH / 2 ;
      //   __.height = __.height > halfMaxH ? maxH : halfMaxH - 10;
      //   __.isMax = true;
      // }
      return __;
    });
    // if (_data?.length <= 3) {
    //   return _data?.map((_, index) => [
    //     {
    //       ..._,
    //       color: _.event_color,
    //       isMax: true,
    //       height: maxH,
    //     },
    //   ]);
    // }
    //未超过一屏幕
    if (hTotal < 3 * maxH) {
      console.log("未超过一屏幕");
      //取出所有满屏的
      const maxHList = _data?.filter((_) => _.isMax) || [];
      //取出所有不满屏的
      const notMaxHList =
        _data?.filter((_) => !_.isMax)?.sort((a, b) => b.height - a.height) ||
        [];
      const [i1, i2, i3] = list;
      //按顺序排放 先把满屏的放置进去
      let sortArr = len <= 6 ? [i1, i2, i3] : [i3, i2, i1];
      maxHList?.forEach((__, __index) => {
        //颜色
        __.color = __.event_color;
        let minItem = sortArr?.at(__index);
        minItem.l.push(__);
        minItem.h = maxH;
      });
      //算出剩余的 可放置元素的列 共3列
      let count = 3 - maxHList?.length;
      let surplusArr = sortArr?.slice(3 - count);
      //不满屏的依次放
      notMaxHList.forEach((item, _index) => {
        item.color = item.event_color;
        let __index = _index % count;
        //按顺序依次放
        let minItem = surplusArr?.at(__index);
        //当前列放不下找放得下的列
        if (
          minItem.h + item.height + (minItem?.l?.length > 0 ? 20 : 0) >=
          maxH
        ) {
          minItem = surplusArr?.find((_) => _.h + item.height + 20 <= maxH);
        }
        if (!minItem) {
          return;
        }
        //放进对应的列里
        minItem?.l?.push(item);
        minItem.h = (minItem.h ? minItem?.h : -20) + item.height + 20;
      });
      //不满瓶的列把剩余的高度求出来 均分给各个元素
      surplusArr.forEach((_) => {
        let h = maxH - _.h;
        let addH = h / _.l?.length;
        _.h = maxH;
        _.l = _.l?.map((item) => {
          item.height = item.height + addH;
          return item;
        });
      });
    } else {
      _data
        ?.sort((a, b) => b.height - a.height)
        ?.forEach((item) => {
          //颜色
          item.color = item.event_color;
          const [i1, i2, i3] = list;
          //按顺序排放
          let sortArr = [i3, i2, i1];
          //
          let minItem = sortArr?.at(0);
          if (sortArr?.at(1)?.h <= minItem.h && minItem?.h !== 0) {
            minItem = sortArr?.at(1);
          }
          if (sortArr?.at(2)?.h <= minItem.h && minItem?.h !== 0) {
            minItem = sortArr?.at(2);
          }
          minItem.l.push(item);
          minItem.h = minItem.h + item.height;
        });
    }

    return list?.map((_) => _.l);
  };

  const renderContainer = () => {
    if (projectInfo?.data?.length === 0) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty
            image={emptyPng}
            imageStyle={{
              height: 120,
            }}
            description={
              <span style={{ color: "#9E9E9E" }}>
                最近7天暂无重要动态
                <br />
                您可以前往各详情页面查看所有信息
              </span>
            }
          ></Empty>
        </div>
      );
    }
    const list = initDomData(projectInfo?.data);
    return (
      <div className="waterfallFlowBox">
        {list?.map((item, index) => (
          <div className="lineBox" key={index}>
            {item?.map((_item, _index) => (
              <div
                key={_index}
                className="card"
                style={{ height: _item.height + "px" }}
              >
                <div
                  className="cardHeader"
                  style={{
                    backgroundColor: hex2Rgb(_item.color, 0.15),
                    borderColor: _item.color,
                  }}
                >
                  <div>
                    {_item.module_name}
                    {" > "}
                    {_item.category_name}
                  </div>
                  <div>
                    <span className="numberSpan">
                      {_item.summary_desc?.length}
                    </span>{" "}
                    条
                    {/* <DoubleRightOutlined
                      className="clickIcon"
                      style={{}}
                      onClick={() => {
                        const routerItem = router.find(
                          (_) => _?.menu_name === _item.category_name
                        );
                        if (!routerItem) {
                          return;
                        }
                        let url =
                          window.location.origin +
                          "/bond/" +
                          id +
                          routerItem.menu_url +
                          "?is_import=1";
                        window.open(url, guid());
                      }}
                    /> */}
                  </div>
                </div>
                <Scrollbars
                  autoHide={false}
                  autoHideTimeout={5000}
                  style={{
                    height: "calc(100% - 70px)",
                    width: "100%",
                    margin: "10px 0",
                  }}
                >
                  <div className="cardContent">
                    {_item.summary_desc?.map((htmlItem, __index) => (
                      // <div
                      //   className="itemLine"
                      //   key={Math.random()}
                      //   dangerouslySetInnerHTML={{
                      //     __html: htmlItem,
                      //   }}
                      // ></div>
                      <div className="cardItem" key={__index}>
                        <span
                          className="colorSpan"
                          style={{ background: _item.color }}
                        ></span>
                        <Paragraph
                          ellipsis={{
                            rows: 2,
                            tooltip: {
                              color: "#fff",
                              title: (
                                <span
                                  className="infoSpan"
                                  dangerouslySetInnerHTML={{
                                    __html: htmlItem?.summary_desc,
                                  }}
                                ></span>
                              ),
                              overlayClassName: "subjectItemParagraphTooltip",
                            },
                          }}
                          style={{
                            marginBottom: "10px",
                            // maxHeight: "47px",
                          }}
                        >
                          <span
                            className="infoSpan"
                            onClick={()=>{
                              setChoiceItem({
                                ...htmlItem,
                                typeName: _item.category_name
                              })
                              setTimeout(()=>{
                                setDetailsDrawerVisible(true)
                              },50)
                            }}
                            dangerouslySetInnerHTML={{
                              __html: htmlItem?.summary_desc,
                            }}
                          ></span>
                        </Paragraph>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const getProjectInfo = () => {
    setLoading(true);
    const params = {
      is_only_self: getIsOnlySelf() === 2 ? 1 : undefined,
    };
    bondApi.getProjectInfo(id, params).then((res) => {
      console.log(res);
      if (res.code === 200) {
        // dispatch(setBondProjectInfo(res.data));
        setProjectInfo(res.data);
      } else {
        message.error(res.message);
        navigate("/");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (id === undefined) {
      // navigate("/projectManagement");
      return;
    }
    getProjectInfo();
  }, []);

  return (
    <div className="bondProjectInfoContent">
      <div className="leftBox">
        {/* 项目信息 */}
        <div className="topBox">
          <div className="leftTop">
            <HeaderTitle title="项目信息"></HeaderTitle>
            <div className="leftTopTitle">
              {projectDate.issuer_name}债券后督项目
            </div>
            <div className="leftTopContent">
              <div className="itemBox">
                <div className="labelBox">尽调场景</div>
                <div className="valueBox">债券后督项目</div>
                <div className="labelBox">关注</div>
                <div className="valueBox">
                  <HeadPortraitLine
                    data={projectDate?.member_list}
                    size={20}
                    num={6}
                    // onClick={() => userChange(info)}
                  ></HeadPortraitLine>
                </div>
              </div>
              <div className="dateBox">
                <div className="labelBox">数据更新时间</div>
                <div className="valueBox">{projectDate?.data_update_time}</div>
              </div>
            </div>
          </div>
        </div>

        {/* 尽调地图 */}
        <div className="leftBottom">
          <HeaderTitle
            title="重要动态"
            extra={
              <Radio.Group
                disabled={loading}
                onChange={(e) => {
                  setIsOnlySelf(e.target.value);
                  setTimeout(() => {
                    getProjectInfo();
                  }, 100);
                }}
                value={isOnlySelf}
              >
                <Radio value={1}>含发行人及关联方</Radio>
                <Radio value={2}>仅发行人</Radio>
              </Radio.Group>
            }
          ></HeaderTitle>
          <div className="leftBottomContent" ref={contentRef}>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{
                height: "calc(100% - 10px)",
                width: "100%",
                margin: "10px 0 0 12px",
              }}
            >
              {loading ? <MySpin></MySpin> : renderContainer()}
            </Scrollbars>
          </div>
        </div>
      </div>

      <DetailsDrawer
        isBond={true}
        title={(choiceItem?.typeName || '') + "详情"}
        visible={detailsDrawerVisible}
        item={choiceItem}
        onCancel={() => {
          setDetailsDrawerVisible(false);
        }}
      ></DetailsDrawer>
    </div>
  );
};
export default BondProjectInfo;
