import {
    createSlice
} from '@reduxjs/toolkit';
const UserInfo = createSlice({
    name: 'user',
    initialState: {
        userInfo: {},
        routers: [],
        userPermission:[],
        admins: []
    },
    reducers: {
        setUserRouters: (state, action) => {
            state.routers = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        setUserPermission: (state, action) => {
            state.userPermission = action.payload
        },
        setAdmins: (state, action) => {
            state.admins = action.payload
        },
    },
});
export const {
    setUserRouters,
    setUserInfo,
    setUserPermission,
    setAdmins
} = UserInfo.actions;

export default UserInfo;