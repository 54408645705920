import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { Card, Typography, Button, Drawer, message, Tooltip } from "antd";
import {
  SettingOutlined,
  MinusCircleOutlined,
  CloseOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import SubscribeSettingDrawer from "../SubscribeSettingDrawer";
import projectApi from "../../../../apis/project";
import bondApi from "../../../../apis/bond";
import Empty from "../../../../components/Empty";
import MySpin from "../../../../components/MySpin";
import MessageModal from "../../../../components/MessageModal";
import icon1 from "../../../../assets/我的订阅.svg";
import moment from "moment";
import { getLicenseValid } from "../../../../unit/unit";
const { Text } = Typography;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const MyCard = ({
  data = {},
  setSettingVisible,
  choiceItem,
  getData,
  isSupport,
  isBond,
}) => {
  const deleteItem = () => {
    MessageModal({
      type: "warning",
      description: "确定要取消订阅吗？",
      message: "取消订阅后将不再推送订阅信息",
      title: "取消订阅",
      onOk: () => {
        // statusChangeHandle(status, data);
        let api = projectApi;
        if (isBond) {
          api = bondApi;
        }
        api.deleteSub(data.id, data.project_id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getData();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };
  return (
    <Card className="settingCard" bordered={false}>
      <div className="settingImg">
        <div className="iconBox">
          {/* <PlusSquareFilled /> */}
          <img src={icon1} alt="" />
        </div>
      </div>
      <div className="settingContent">
        <div className="top">
          <Text
            ellipsis={{ tooltip: data.project_name }}
            style={{
              width: `calc(100% - 40px)`,
              color: "#313131",
              marginRight: "5px",
            }}
          >
            {isBond ? data?.company_name : data.project_name}
          </Text>

          <div className="iconBox">
            <Tooltip placement="top" title="订阅设置" arrowPointAtCenter>
              <SettingOutlined
                className="hoverIcon"
                onClick={() => {
                  getLicenseValid(false, isBond).then((res) => {
                    if (res) {
                      choiceItem && choiceItem(data);
                      setSettingVisible && setSettingVisible(true);
                    }
                  });
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="取消订阅" arrowPointAtCenter>
              <MinusCircleOutlined
                className="hoverIcon"
                onClick={() => {
                  deleteItem();
                }}
                style={{ marginLeft: "10px" }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="bottom">
          <div className="valueItem" style={{ width: "70%" }}>
            <div className="label">订阅有效期</div>
            <div className="value">
              {isSupport ? (
                <>
                  {moment(data.create_time).format("YYYY-MM-DD")} ~
                  {moment(data.valid_limit_time).isBefore(
                    new Date().getTime() + 1000 * 60 * 60 * 24 * 7
                  ) ? (
                    <span style={{ color: "#FE545F" }}>
                      {data.valid_limit_time + "(即将到期)"}
                    </span>
                  ) : (
                    <span>{data.valid_limit_time}</span>
                  )}
                </>
              ) : (
                <span style={{ color: "#FE545F" }}>失效</span>
              )}
            </div>
          </div>
          <div className="valueItem" style={{ width: "30%" }}>
            <div className="label">订阅方式</div>
            <div className="value">
              {data.sub_way_list?.map((_) => _.sub_way_code_name)?.join("/")}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const SubscribeListDrawer = (props) => {
  const { isBond } = props;
  const [settingVisible, setSettingVisible] = useState(false);
  const [subscribeList, setSubscribeList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [choiceItem, setChoiceItem] = useState(null);

  const [isSupport, setIsSupport] = useState(true);

  const getData = () => {
    setLoading(true);
    let api = projectApi;
    if (isBond) {
      api = bondApi;
    }
    api.getMySub().then((res) => {
      if (res.code === 200) {
        setSubscribeList(res.data);
      } else {
        setSubscribeList([]);
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (props.visible) {
      getData();
      getLicenseValid(false, isBond).then((res) => {
        setIsSupport(res);
      });
    }
  }, [props.visible]);

  const choiceItemChange = (data) => {
    setChoiceItem(data);
  };
  return (
    <Drawer
      className="subscribeListDrawer"
      width="600px"
      closable={false}
      title="我的订阅"
      placement="right"
      onClose={props.onClose}
      visible={props.visible}
      push={false}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={null}
      /* <BtnBox onClose={props.onClose}></BtnBox> */
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {subscribeList.length > 0 ? (
            <div className="subBox">
              <div className="subCardBox">
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {subscribeList.map((item) => (
                    <MyCard
                      getData={getData}
                      key={item.id}
                      data={item}
                      setSettingVisible={setSettingVisible}
                      choiceItem={choiceItemChange}
                      isSupport={isSupport}
                      isBond={isBond}
                    ></MyCard>
                  ))}
                </Scrollbars>
              </div>
            </div>
          ) : (
            <div className="noData">
              <Empty></Empty>
            </div>
          )}
          <div className="subBtnBox">
            <Button
              type="primary"
              icon={<PlusSquareFilled />}
              onClick={() => {
                getLicenseValid(false, isBond).then((res) => {
                  if (res) {
                    setChoiceItem({});
                    setSettingVisible(true);
                  }
                });
              }}
            >
              新增订阅
            </Button>
          </div>
        </div>
      )}

      <SubscribeSettingDrawer
        subscribeList={subscribeList}
        subId={choiceItem?.id}
        companyId={choiceItem?.company_id}
        projectId={choiceItem?.project_id}
        visible={settingVisible}
        success={getData}
        onClose={() => setSettingVisible(false)}
        isBond={isBond}
      ></SubscribeSettingDrawer>
    </Drawer>
  );
};

export default SubscribeListDrawer;
