import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useState, useEffect, useRef } from "react";
import MyDiv from "../../../components/MyDiv/index";
import { guid, showTipFunc } from "../../../unit/unit.js";
import { Tabs, message } from "antd";
import MySpin from "../../../components/MySpin";
import useUrlState from "@ahooksjs/use-url-state";
import BiddingAnalysis from "./components/BiddingAnalysis";
import CompetitivenessAnalysis from "./components/CompetitivenessAnalysis";
import projectApi from "../../../apis/project";
import { useGetState } from "ahooks";
import CompetitorsTable from "./components/CompetitorsTable";
import DialogDrawer from "../../../components/DialogDrawer";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const TenderingAndBidding = (props) => {
  const { id } = useParams();

  const [type, setType, getType] = useGetState("");
  const [state, setState] = useUrlState({});
  const [dataInfo, setDataInfo] = useState({});
  const [showHeader, setShowHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tableType, setTableType] = useState("尽调对象及关联方");

  const [drawerTitle, setDrawerTitle] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const [formList, setFormList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [okLoading, setOkLoading] = useState(false);
  const ref = useRef();

  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  const [
    defaultFilterCriteria,
    setDefaultFilterCriteria,
    getDefaultFilterCriteria,
  ] = useGetState(null);

  const tableRef = useRef(null);
  const tabs = useRef({
    业务发展分析: "listed_bid_winner",
    竞争力分析: "listed_bid_winner",
    中标列表: "listed_bid_winner",
  });
  const typeNameList = {
    业务发展分析: "业务发展分析",
    竞争力分析: "竞争力分析",
    中标列表: "中标列表",
  };

  useEffect(() => {
    let _type = "业务发展分析";
    if (state.type) {
      _type = Object.keys(typeNameList)?.find(
        (_) => typeNameList[_] === state.type
      );
    }
    setType(_type);
    if (_type === "业务发展分析" || _type === "竞争力分析") {
      getSpecialFocusRemark(_type);
    }
  }, []);

  const tabChange = (key) => {
    console.log(key, tableType);
    setDefaultFilterCriteria(null);
    setType(key);
    if (key === "业务发展分析" || key === "竞争力分析") {
      getSpecialFocusRemark(key);
    } else {
      if (tableType === "竞争对手") {
        setTimeout(() => {
          ref.current?.onPageChange(1);
        }, 50);
      }
    }
  };

  const getSpecialFocusRemark = (type) => {
    projectApi
      .getSpecialFocusRemark(id, {
        table_name: "listed_bid_winner",
        event_type_code:
          type === "业务发展分析"
            ? "CDD_CONCERN_DLP_ANA"
            : "CDD_CONCERN_CTT_ANA",
      })
      .then((res) => {
        if (res.code === 200) {
          setDataInfo({
            event_type_remark: res.data?.event_type_remark,
          });
        } else {
          message.error(res.message);
          setDataInfo({});
        }
      });
  };

  const addOrEditCompetitors = (type, item) => {
    setDrawerTitle(`${type === "add" ? "新增" : "修改"}竞争对手中标记录`);
    projectApi.getTableStyle("listed_bid_winner", id, 1).then((res) => {
      if (res.code === 200) {
        setFormList(res.data);
        if (type === "edit") {
          setDefaultData(JSON.parse(JSON.stringify(item)));
        } else {
          setDefaultData(null);
        }
        setTimeout(() => {
          setDrawerVisible(true);
        }, 100);
      } else {
        message.error(res.message);
      }
    });
  };

  const success = (obj) => {
    setOkLoading(true);
    let params = {
      name: "listed_bid_winner",
      project_id: id,
      data_string: JSON.stringify(obj),
      is_bid: 1,
    };
    if (defaultData) {
      params.id = defaultData.id?.val;
    }
    projectApi.setTableItem(params).then(async (res) => {
      if (res.code === 200) {
        await setDrawerVisible(false);
        await ref.current?.onPageChange(1);
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      setOkLoading(false);
    });
  };

  const searchTableFunc = (filterCriteria, isBid) => {
    if (filterCriteria?.length > 0) {
      setDefaultFilterCriteria(filterCriteria);
    } else {
      setDefaultFilterCriteria(null);
    }
    setTableType(isBid ? "竞争对手" : "尽调对象及关联方");
    setTimeout(() => {
      setType("中标列表");
      if (isBid) {
        setTimeout(() => {
          if (filterCriteria) {
            ref.current?.setGroupByFields(filterCriteria);
          }
          ref.current?.onPageChange(1);
        }, 50);
      }
    }, 50);
  };

  const showTip = showTipFunc(projectProgressCode, "招投标");
  return (
    <div className="tenderingAndBiddingContent">
      <HeaderTitle title="招投标" showTip={showTip}></HeaderTitle>

      {loading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tabBox">
          <Tabs
            className="myTabs"
            onChange={tabChange}
            activeKey={type}
            destroyInactiveTabPane={true}
            // tabBarExtraContent={<div className="typeSreach">事项类型 <CaretDownOutlined /></div>}
          >
            {Object.keys(tabs.current).map((key) => (
              <TabPane
                tab={
                  type === key && showHeader ? (
                    <MyDiv
                      info={{
                        ...dataInfo,
                        comments: dataInfo?.event_type_remark,
                        function: ["comments", "remark"],
                        id: guid(),
                      }}
                      dataTable={tabs.current[key]}
                      typeName={typeNameList[key]}
                      getData={() => {
                        if (getType() === "中标列表") {
                          tableRef.current?.getData();
                          ref.current?.getCompetitiveHitData();
                        } else {
                          getSpecialFocusRemark(key);
                        }

                        setShowHeader(false);
                        setTimeout(() => {
                          setShowHeader(true);
                        }, 100);
                      }}
                      mode={30}
                      dataInfo={{}}
                    >
                      {key}
                    </MyDiv>
                  ) : (
                    key
                  )
                }
                key={key}
              >
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="infoBox">
                    {key === "中标列表" ? (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div className="tableTypeBox">
                          {["尽调对象及关联方", "竞争对手"]?.map((_) => (
                            <div
                              key={_}
                              className={tableType === _ ? "activeItem" : null}
                              onClick={() => {
                                setDefaultFilterCriteria(null);
                                setTableType(_);
                                if (_ === "竞争对手") {
                                  setTimeout(() => {
                                    ref.current?.onPageChange(1);
                                  }, 50);
                                }
                              }}
                            >
                              {_}{" "}
                              <span>
                                {_ === "竞争对手"
                                  ? dataInfo?.cbc_map?.bid_count
                                  : dataInfo?.cbc_map?.hit_count}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="tableTypeContent">
                          {tableType === "尽调对象及关联方" ? (
                            <MyTable
                              defaultFilterCriteria={defaultFilterCriteria}
                              typeName={typeNameList[key]}
                              dataTable={tabs.current[key]}
                              dataChange={setDataInfo}
                              ref={tableRef}
                            ></MyTable>
                          ) : (
                            <CompetitorsTable
                              defaultFilterCriteria={defaultFilterCriteria}
                              setIsDownFunc={() => {}}
                              ref={ref}
                              dataChange={setDataInfo}
                              addOrEditCompetitors={addOrEditCompetitors}
                            ></CompetitorsTable>
                          )}
                        </div>
                      </div>
                    ) : key === "业务发展分析" ? (
                      <BiddingAnalysis
                        searchTableFunc={searchTableFunc}
                      ></BiddingAnalysis>
                    ) : (
                      <CompetitivenessAnalysis
                        searchTableFunc={searchTableFunc}
                      ></CompetitivenessAnalysis>
                    )}
                  </div>
                </Scrollbars>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
      <DialogDrawer
        okLoading={okLoading}
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultData}
        formList={formList}
        onOk={success}
        visible={drawerVisible}
        typeName='中标列表'
        onCancel={() => {
          setDrawerVisible(false);
        }}
      ></DialogDrawer>
    </div>
  );
};
export default TenderingAndBidding;
