import moment from "moment";
import { getToken } from "./index.js";
import api from "../apis/project/index.js";
import { message, notification, Popover, Avatar } from "antd";
import store from "../store/index.js";

//获取联系商务 dom
export const getContactBusinessDom = (userInfo = {}) => {
  if (localStorage.getItem("deploy") === "BOX") {
    return <span style={{ color: "#0068B2" }}>联系商务</span>;
  }
  return (
    <a
      onClick={() => {
        window.open(localStorage.getItem("sub_url"), "sub_url");
      }}
    >
      联系商务
    </a>
  );
};

//获取管理员 dom
export const getAdminsDom = (userInfo = {}) => {
  return (
    <Popover
      placement="bottomLeft"
      title={null}
      // visible={true}
      overlayClassName="adminPopover"
      content={
        <div className="adminPopoverContent">
          {(store?.getState()?.User?.admins || [])?.map((item) => (
            <div className="adminPopoverItem">
              <span>
                <Avatar
                  style={{ marginRight: "8px" }}
                  size={24}
                  src={item?.pf_photo}
                />
                {item?.show_name}
              </span>
              <span>{item?.assist_name}</span>
            </div>
          ))}
        </div>
      }
    >
      <a className="adminBox">管理员</a>
    </Popover>
  );
};

//验证是否为管理员
export const verifyAdmin = (userInfo = {}) => {
  if (userInfo?.is_admin || userInfo?.is_creator) {
    return true;
  } else {
    return false;
  }
};

export const getLinkValue = (str) => {
  let isAdmin = false;
  let str1 = "";
  let str2 = "";
  if (str?.indexOf("管理员") !== -1) {
    isAdmin = false;
    let index = str?.indexOf("管理员");
    str1 = str.slice(0, index);
    str2 = str.slice(index + 3);
  } else if (str?.indexOf("联系商务") !== -1) {
    isAdmin = true;
    let index = str?.indexOf("联系商务");
    str1 = str.slice(0, index);
    str2 = str.slice(index + 4);
  }
  return {
    content: (
      <span>
        {str1}
        <span>{isAdmin ? getContactBusinessDom() : getAdminsDom()}</span>
        {str2}
      </span>
    ),
  };
};

//验证是否授权
export const getLicenseValid = (noMsg, isBond) => {
  // notification.open({
  //   type: 'info',
  //   message: (noMsg?'前端提示':'后台提示') + '----权限验证',
  //   description: <span>不用管我!只是用来确认是否校验权限了!</span>,
  //   placement:'topRight',
  //   duration: 1,
  // });
  let func = api.getLicenseValid;
  if (isBond) {
    func = api.getBondLicenseValid;
  }
  return func().then((res) => {
    if (res.code === 200) {
      if (res.data?.at(0)) {
        return true;
      } else {
        if (!noMsg) {
          message.warning({
            content: verifyAdmin(store?.getState()?.User?.userInfo) ? (
              <span>
                产品授权到期，该功能暂时无法使用，请
                {getContactBusinessDom()}
                续订
              </span>
            ) : (
              <span>
                产品授权到期，该功能暂时无法使用，请联系{getAdminsDom()}续订
              </span>
            ),
          });
        }
        return false;
      }
    } else {
      message.warning({
        content: verifyAdmin(store?.getState()?.User?.userInfo) ? (
          <span>
            产品授权到期，该功能暂时无法使用，请
            {getContactBusinessDom()}
            续订
          </span>
        ) : (
          <span>
            产品授权到期，该功能暂时无法使用，请联系
            {getAdminsDom()}
            续订
          </span>
        ),
      });
      return null;
    }
  });
};

// 防抖函数
export function debounce(func, delay, ...arg) {
  let timer = null;
  return function (...args) {
    // 取消已有的定时器（其实可以理解为，取消了上一次的函数调用）
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, [...args, ...arg]);
    }, delay);
  };
}

//创建唯一id方法
export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//提取汉字
function GetChinese(strValue) {
  if (strValue !== null && strValue !== "") {
    strValue = strValue?.toString() || "";
    const reg = /[\u4e00-\u9fa5]/g;
    if (strValue.match(reg)) {
      return strValue.match(reg).join("");
    }
  }
  return "";
}

//加千分位符
function comdify(n) {
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  var n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, "$&,") + s2;
  });
  return n1;
}

//
export function umberFormats(num) {
  if (num) {
    let isNegative = false;
    if (num < 0) {
      isNegative = true;
    } else if (num < 0.01) {
      return num;
    }
    let nums = Number(Math.abs(num)).toFixed(2);
    let newNum = Number(nums).toLocaleString("en-US"); // 数字转成千分位 = 123,456.78 有个弊端最多能保留三位小数
    const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
    if (!numArr[1]) {
      // 如果数组没有下标1的元素，就加.00，例：123,456 = 123,456.00
      newNum = newNum + ".00";
    } else if (numArr[1].length === 1) {
      // 如果数组下标1的元素只有一位小数，就加个0，例：123,456.7 = 123,456.70
      newNum = newNum + "0";
    } else if (numArr[1].length >= 2) {
      // // 如果数组下标1的元素小数位大于等于2位，就截取前两位，例：123,456.789 = 123,456.78
      newNum = numArr[0] + "." + numArr[1].slice(0, 2);
    }
    if (isNegative) {
      return "-" + newNum;
    }
    return newNum;
  } else {
    return "0.00";
  }
}

export function amountFormats(num) {
  if (num) {
    let nums = Number(num) / 10000;
    let newNum = comdify(nums.toString()); // 数字转成千分位 = 123,456.78
    const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
    if (!numArr[1]) {
      // 如果数组没有下标1的元素
      newNum = newNum + "万";
    } else {
      newNum = Number(num).toLocaleString("en-US");
    }
    return newNum;
  } else {
    return "0";
  }
}
//带单位的金额字符串加千分位符
export const addMillimeter = (str, digit) => {
  let num = parseFloat(str);
  let chinese = GetChinese(str);
  num = umberFormats(num);
  return num + chinese;
};

//带单位的金额字符串加千分位符并转成万.
export const addMillimeterAndInit = (str, digit) => {
  let num = parseFloat(str);
  let chinese = GetChinese(str);
  num = amountFormats(num);
  return num + chinese;
};

//判断2个数组是否相等
export function ArrayIsEqual(arr1, arr2) {
  if (arr1 === arr2) {
    //如果2个数组对应的指针相同，那么肯定相等，同时也对比一下类型
    return true;
  } else {
    if (arr1.length != arr2.length) {
      return false;
    } else {
      //长度相同
      for (let i in arr1) {
        //循环遍历对比每个位置的元素
        if (arr1[i] != arr2[i]) {
          //只要出现一次不相等，那么2个数组就不相等
          return false;
        }
      } //for循环完成，没有出现不相等的情况，那么2个数组相等
      return true;
    }
  }
}

//判断小数位是否超过给定的位数
export function isExceedDigit(num, digit) {
  let flag = false;
  const numArr = num.toString().split("."); // 按小数点吧数字拆分 = [123456, 78]
  if (numArr[1] && numArr[1]?.length > digit) {
    flag = true;
  }
  return flag;
}

//下载流文件
export function saveFile(blob, filename) {
  let binaryData = [];
  binaryData.push(blob);
  const objectUrl = window.URL.createObjectURL(new Blob(binaryData));
  const downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = objectUrl;
  downloadLink.click();
  URL.revokeObjectURL(objectUrl);
}

//数组 去重 keys为多个条件
export function uniqueArray(array, keys) {
  array = array.reduce((pre, next) => {
    let has = pre.some((item) => {
      let flag = true;
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (item[key] !== next[key]) {
          flag = false;
        }
      }
      return flag;
    });
    if (!has) {
      pre.push(next);
    }
    return pre;
  }, []);
  return array;
}

//数组 判重 keys为多个条件
export function judgmentDuplicateArray(array, keys) {
  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    const items = array?.filter((_) => {
      let flag = true;
      keys?.forEach((key) => {
        if (item[key] !== _[key]) {
          flag = false;
        }
      });
      return flag;
    });
    if (items?.length > 1) {
      return true;
    }
  }
  return false;
}

//下载文件
export function downloadFile(url, project_id, is_bond) {
  const token = getToken();
  var hiddenIFrameID = "hiddenDownloader" + guid();
  var iframe = document.createElement("iframe");
  iframe.id = hiddenIFrameID;
  iframe.style.display = "none";
  let src = `${
    process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "/cdd"
  }/pm/info/rpt/download_file?path=${url}&token=${token}&key=${guid()}&project_id=${project_id}`;
  //后督
  if (is_bond) {
    src = `${
      process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "/cdd"
    }/bond_rpt/download_file?path=${url}&token=${token}&key=${guid()}&company_id=${project_id}`;
  }
  iframe.src = src;
  document.body.appendChild(iframe);
  setTimeout(() => {
    iframe.remove();
  }, 30 * 1000);
}

export const fixArray = () => {
  /* Array.prototype.at() */
  if (!Array.prototype.at) {
    Array.prototype.at = function (index) {
      if (index >= 0) {
        return this[index];
      } else {
        return this[this.length + index];
      }
    };
  }
};

//添加换行
export const initWrap = (str, max) => {
  let line = 1;
  const addWrap = (str, max) => {
    if (str?.length > max) {
      line += 1;
      str = str?.slice(0, max) + "\n" + addWrap(str?.slice(max), max);
    }
    return str;
  };
  str = addWrap(str, max);
  return {
    str,
    line,
  };
};

//判断是否为图片
export const isImage = (path) => {
  path = path.toString();
  const index = path.lastIndexOf(".");
  const type = path.slice(index + 1) || "";
  return ["png", "jpg", "jpeg"].includes(type);
};

//判断是不是数字格式的字符串
export const isNumberStr = (value) => {
  //正整数
  const reNumber = /^\d+$/;
  //负整数
  const reNeNumber = /^-\d+$/;
  //正实数
  const reRealNumber1 = /^[1-9]\d*[.]\d+$/; //非零开头
  const reRealNumber2 = /^0[.]\d+$/; //零开头
  //负实数
  const reNeRealNumber1 = /^-[1-9]\d*[.]\d+$/; //非零开头
  const reNeRealNumber2 = /^-0[.]\d+$/; //零开头

  let isNumber = false;

  if (
    reNumber.test(value) ||
    reNeNumber.test(value) ||
    reRealNumber1.test(value) ||
    reRealNumber2.test(value) ||
    reNeRealNumber1.test(value) ||
    reNeRealNumber2.test(value)
  ) {
    isNumber = true;
  } else {
    isNumber = false;
  }
  return isNumber;
};

//下载base64图片
export const downloadBase64Img = (src, name) => {
  var base64 = src.toString(); // imgSrc 就是base64哈
  var byteCharacters = atob(
    base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
  );
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], {
    type: undefined,
  });
  var aLink = document.createElement("a");
  aLink.download = name;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
};

//判断 一个数组是否包含另一个数组的所有元素
export const arrayContainsAnotherArray = (needle, haystack) => {
  if (needle?.length === 0) {
    return false;
  }
  for (var i = 0; i < needle?.length; i++) {
    if (haystack.indexOf(needle[i]) === -1) return false;
  }
  return true;
};

//判断 标题是否显示tip
export const showTipFunc = (projectProgressCode, menu_name) => {
  const codeList = [
    "CDD_FIRST_PROCESS_2_BASICINFO",
    "CDD_FIRST_PROCESS_3_PENETRATION",
    "CDD_FIRST_PROCESS_4_RELATED",
    "CDD_FIRST_PROCESS_5_DATA",
  ];
  const typeList = [
    "商标",
    "专利",
    "作品著作",
    "软件著作",
    "资质证书",
    "行政许可",
    "招投标",
    "行政处罚",
    "动产抵质押",
    "不动产抵押",
    "司法拍卖",
    "司法案件",
    "法律文书",
    "失信被执行人",
    "被执行人",
    "新闻舆情",
    "公告舆情",
    // "经营分析",
    // "税务分析",
  ];
  if (
    codeList?.includes(projectProgressCode) &&
    typeList?.includes(menu_name)
  ) {
    return `尽调公司${menu_name}数据已获取完成，关联方数据还在获取中`;
  }
  return null;
};

//判断 标题是否显示tip
export const calcCompanyNameFunc = (
  companyName,
  itemNum,
  domID,
  offsetWidth = 0
) => {
  //companyName: 公司名称
  //itemNum: 所选公司数量
  //domID: 容器id
  //offsetWidth: 容器的宽度需要减去的宽(偏移量) 默认为 0
  let str = "";
  const dom = document.querySelector(`#${domID}`);
  if (dom?.clientWidth) {
    const w = Math.floor((dom?.clientWidth - 40 - offsetWidth) / itemNum);
    const len = Math.floor(w / 14);
    if (len <= 1) {
      return companyName?.slice(0, 1) + "...";
    }
    if (companyName?.length <= len) {
      return companyName;
    } else {
      return companyName?.slice(0, len - 1) + "...";
    }
  } else {
    if (itemNum < 7) {
      str = companyName?.slice(0, 4) + "...";
    } else if (itemNum >= 7 && itemNum <= 11) {
      str = companyName?.slice(0, 3) + "...";
    } else if (itemNum > 11 && itemNum <= 16) {
      str = companyName?.slice(0, 2) + "...";
    } else {
      str = companyName?.slice(0, 1) + "...";
    }
  }
  return str;
};

//判断是不是最新的一年
export const isCurrentYear = (year) => {
  return moment().format("YYYY") === year?.toString();
};

//保留小数点后两位 如果小数点后为0则不保留
export const unifyNumber = (num) => {
  if (num === "") {
    return 0;
  } else {
    let handleNum = parseFloat(num);
    let isToFixed =
      handleNum.toString().includes(".") &&
      handleNum.toString().split(".")[1].length > 2;
    if (isToFixed) {
      if (handleNum.toFixed(2).toString().split(".")[1] == "00") {
        return handleNum.toFixed(0);
      } else {
        return handleNum.toFixed(2);
      }
    } else {
      return handleNum;
    }
  }
};

/**
 * 检验18位身份证号码（15位号码可以只检测生日是否正确即可）
 * @author wolfchen
 * @param cid 18为的身份证号码
 * @return Boolean 是否合法
 **/
export const isCnNewID = (cid) => {
  var arrExp = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; //加权因子
  var arrValid = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2]; //校验码
  if (/^\d{17}\d|x$/i.test(cid)) {
    var sum = 0,
      idx;
    for (var i = 0; i < cid.length - 1; i++) {
      // 对前17位数字与权值乘积求和
      sum += parseInt(cid.substr(i, 1), 10) * arrExp[i];
    }
    // 计算模（固定算法）
    idx = sum % 11;
    // 检验第18为是否与校验码相等
    return arrValid[idx] == cid.substr(17, 1).toUpperCase();
  } else {
    return false;
  }
};

//颜色转换 hex转rgba第一种
export const hex2Rgb = (hexValue, alpha = 1) => {
  const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b);
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!rgb) {
    return hexValue;
  }
  const r = parseInt(rgb[1], 16),
    g = parseInt(rgb[2], 16),
    b = parseInt(rgb[3], 16);
  return `rgba(${r},${g},${b},${alpha})`;
};
