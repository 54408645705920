import "./index.scss";
import React from "react";
import { Spin } from "antd";
import MySpin from "../../../../components/MySpin";
import HeaderTitle from "../../../../components/HeaderTitle";
const LoadingPage = (props) => {
  const { menu_name } = props;
  return (
    <div className="loadingContent">
      <HeaderTitle title={menu_name}></HeaderTitle>
      <div className="loadingImg">
        <MySpin tip='数据正在获取中，请稍后再查看'></MySpin>
      </div>
    </div>
  );
};
export default LoadingPage;
