import "./index.scss";
import { Card, message, Typography, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useSize, useHover } from "ahooks";
import CommentsIcon from "../../../../components/Icons/CommentsIcon";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import CloseIcon from "../../../../components/Icons/CloseIcon";
import infoRouter from "../../../Company/router/router";
import projectApi from "../../../../apis/project";
import { useSelector } from "react-redux";
import { guid } from "../../../../unit/unit";
import HeadPortrait from "../../../../components/HeadPortrait";
import HeadPortraitLine from "../../../../components/HeadPortraitLine";

const { Text, Paragraph } = Typography;

const TopCom = (props) => {
  const { data = {}, isHovering, type, getData, showStatus, goUrl } = props;
  const [show, setShow] = useState(false);

  const [info, setInfo] = useState({});
  const [dataAll, setDataAll] = useState({});

  const myInfo = useSelector((state) => state.User.userInfo);
  const initWidth = (str) => {
    let strWidth = 0;
    str?.split("")?.forEach((_) => {
      if (["(", ")"].includes(_)) {
        strWidth = strWidth + 7;
      } else {
        strWidth = strWidth + 14;
      }
    });
    return strWidth;
  };
  const typeWidth = initWidth(data.event_type_code_name);
  // const typeWidth = (data.event_type_code_name?.length || 0) * 14;
  useEffect(() => {
    let info = {};
    if (dataAll?.data_field && dataAll.data) {
      info = { ...dataAll?.data[dataAll?.data_field] };
    } else {
      info.comments = dataAll?.data?.comments;
    }
    info.id = dataAll?.data?.id?.val || guid();
    info.key = dataAll?.data_field;
    setInfo(info);
  }, [dataAll]);

  const getFocusInfo = () => {
    projectApi.getFocusInfo(data.id, data.project_id).then((res) => {
      if (res.code === 200) {
        setDataAll(res.data);
      } else {
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (show) {
      getFocusInfo();
    }
  }, [show]);

  let wid = 0;
  if (data?.project_permission_urls?.includes("CDD_PRJ_COMMENT_VIEW")) {
    wid += 20;
  }
  if (data?.project_permission_urls?.includes("CDD_PRJ_COMMENT")) {
    wid += 20;
  }
  if (
    data?.duty_list?.some((_) => _.duty_user_id === myInfo.co_user_id) &&
    !showStatus
  ) {
    wid += 20;
  }
  return (
    <div
      className="top"
      style={{
        cursor: type === "推荐" && data?.cdd_composite_id ? "pointer" : "unset",
      }}
    >
      <div
        className="typeBox"
        onClick={() => {
          goUrl && goUrl();
        }}
      >
        {data.event_type_code_name}
      </div>

      <Paragraph
        ellipsis={{ rows: 1, tooltip: data.focus_content, expandable: false }}
        style={{
          width: `calc(100% - ${typeWidth + (type !== "推荐" ? wid : 20)}px)`,
          color: "#313131",
          // marginRight: "5px",
        }}
      >
        <span
          style={{ fontFamily: "PingFang SC-中等, PingFang SC" }}
          dangerouslySetInnerHTML={{
            __html: data?.focus_tips_content,
          }}
        ></span>
      </Paragraph>

      {/* isHovering?'block':'none' */}
      {type !== "推荐" ? (
        <div
          className="iconBox"
          style={{ display: isHovering || show ? "flex" : "none" }}
        >
          {data?.project_permission_urls?.includes("CDD_PRJ_COMMENT_VIEW") && (
            <InfoIcon
              fixed={true}
              focusContent={data.focus_tips_content}
              focusId={dataAll.id}
              // onClick={()=>{getInfo()}}
              setShow={setShow}
              getData={getData}
              mode={dataAll?.data_field ? 10 : dataAll?.data_id ? 20 : 30}
              projectId={dataAll?.project_id}
              info={info}
              dataInfo={dataAll?.data}
              dataTable={dataAll?.data_table}
              typeName={dataAll?.event_type_code_name}
            ></InfoIcon>
          )}

          {data?.project_permission_urls?.includes("CDD_PRJ_COMMENT") && (
            <CommentsIcon
              focusId={dataAll.id}
              focusContent={data.focus_tips_content}
              projectId={dataAll?.project_id}
              setShow={setShow}
              // onClick={()=>{getInfo()}}
              getData={getData}
              mode={dataAll?.data_field ? 10 : dataAll?.data_id ? 20 : 30}
              info={info}
              dataInfo={dataAll?.data}
              dataTable={dataAll?.data_table}
              typeName={dataAll?.event_type_code_name}
            />
          )}

          {data?.duty_list?.some((_) => _.duty_user_id === myInfo.co_user_id) &&
            !showStatus && (
              <CloseIcon
                focusId={dataAll.id}
                focusContent={data.focus_tips_content}
                projectId={data?.project_id}
                setShow={setShow}
                getData={getData}
                mode={dataAll?.data_field ? 10 : dataAll?.data_id ? 20 : 30}
                info={info}
                data={dataAll}
                dataInfo={dataAll?.data}
                dataTable={data?.data_table}
                typeName={data?.event_type_code_name}
              ></CloseIcon>
            )}
        </div>
      ) : null}
    </div>
  );
};

const FollowCard = (props) => {
  const { data, type, showStatus, getData, showDetailsFunc } = props;
  const cardRef = useRef();
  const isHovering = useHover(cardRef);
  const myInfo = useSelector((state) => state.User.userInfo);

  const statusColorList = {
    CDD_CONCERN_LEVEL_CHECK: "#FEC054",
    CDD_CONCERN_LEVEL_ATTENTION: "#0068B2",
    CDD_CONCERN_LEVEL_RISK: "#FE545F",
    CDD_CONCERN_LEVEL_CUSTOM: "green",
  };
  const getName = (list) => {
    if (!list) {
      return "";
    }
    let str = "";
    list.forEach((item, index) => {
      if (index) {
        str += "、";
      }
      str += item.name;
    });
    return str;
  };
  const initNameDom = (list) => {
    if (!list) {
      return "";
    }
    return list.map((item, index) => (
      <HeadPortrait
        size={20}
        src={item.pf_photo}
        title={
          item.show_name + (item.assist_name ? "/" + item.assist_name : "")
        }
        style={{ marginRight: "3px" }}
      ></HeadPortrait>
    ));
  };
  const goUrl = () => {
    let route = infoRouter.find((item) => {
      if (item.multiple) {
        return item.menu_name_list?.includes(data.event_type_code_name);
      }
      return item.menu_name === data.event_type_code_name;
    });
    if (route) {
      let url =
        window.location.origin + "/company/" + data.project_id + route.menu_url;
      if (route.multiple) {
        url = url + `?type=${data.event_type_code_name}`;
      }
      if (route.menu_name === "疑似关联方分析") {
        url = url + `?pathId=${data.data_id}`;
      }
      window.open(url, "_blank");
    }
  };
  return (
    <div className="followCardContent" ref={cardRef}>
      <Card
        className="followCard"
        bordered={false}
        onClick={() => {
          if (type !== "推荐") {
            return;
          }
          showDetailsFunc && showDetailsFunc(data);
        }}
      >
        {data.status ? (
          // <Tooltip placement="top" title={data.project_name} arrowPointAtCenter>
          <div
            className="status"
            style={{
              background: statusColorList[data.event_level_code],
            }}
          >
            {data.event_level_desc}
          </div>
        ) : // </Tooltip>
        null}
        <TopCom
          goUrl={type === "推荐" ? null : goUrl}
          getData={getData}
          data={data}
          isHovering={isHovering}
          type={type}
          showStatus={showStatus}
        ></TopCom>
        {data.project_name && (
          <div className="bottom">
            <div className="valueItem">
              <div className="label" style={{ width: "50px" }}>
                尽调项目
              </div>
              <div className="value">
                <Paragraph
                  ellipsis={{
                    tooltip: data.project_name,
                    rows: 1,
                    expandable: false,
                  }}
                  style={{
                    width: `100%`,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    goUrl();
                    e.stopPropagation();
                  }}
                >
                  {data.project_name}
                </Paragraph>
              </div>
            </div>
          </div>
        )}

        <div className="bottom">
          {showStatus ? (
            <div className="valueItem">
              <div className="label" style={{ width: "25px" }}>
                状态
              </div>
              <div className="value" style={{ width: "calc(100% - 30px)" }}>
                {data.status_name === "关注中" ? (
                  <span style={{ color: "#0068B2" }}>{data.status_name}</span>
                ) : (
                  data.status_name
                )}
              </div>
            </div>
          ) : null}

          {type !== "推荐" && myInfo?.co_type_code !== "INDIV_ORG" && (
            <div className="valueItem">
              <div className="label">责任人</div>
              <div className="value">
                {/* <Paragraph
                  ellipsis={{
                    tooltip: getName(data.duty_list),
                    rows: 1,
                    expandable: false,
                  }}
                  style={{
                    width: `100%`,
                  }}
                >
                  {initNameDom(data.duty_list)}
                </Paragraph> */}
                <HeadPortraitLine
                  data={data?.duty_list || []}
                  size={20}
                  num={3}
                ></HeadPortraitLine>
              </div>
            </div>
          )}

          <div className="valueItem">
            <div className="label" style={{ width: "25px" }}>
              时间
            </div>
            <div className="value" style={{ width: "calc(100% - 30px)" }}>
              {data.focus_time}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default FollowCard;
