import "./index.scss";
import React, { useState } from "react";
import wrapper from "./wrapper";
import { Drawer, Button, message, Tooltip, Tag, Table, Typography } from "antd";
import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import projectApi from "../../apis/project";
import MySpin from "../MySpin";
import HeaderTitle from "../HeaderTitle";
import MyDiv from "../MyDiv";
import { Provider } from "react-redux";
import store from "../../store/index";
import MessageModal from "../MessageModal";
import MyEmpty from "../Empty";
import { addMillimeter, addMillimeterAndInit } from "../../unit/unit";
const { Paragraph } = Typography;

const PersonInfoDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tableDate, setTableDate] = useState({});
  const [isAllDataEmpty, setIsAllDataEmpty] = useState(false);

  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 项目ID */
    projectId,
    /* 自然人名称 */
    personName,
    /* 查询的参数 */
    params,
  } = props;

  useEffect(() => {
    setVisible(true);
    getInfo();
  }, []);

  //查看省略部分信息
  const lookUp = (value, item) => {
    MessageModal({
      // type: "warning",
      // description: "确定要删除此条数据吗？",
      maskClosable: true,
      noIcon: true,
      noBtn: true,
      infoText: value,
      title: item.name,
    });
  };

  //格式化 table columns
  const initColumns = (rows) => {
    const minWidth = 26;
    let columns = Object.keys(rows).map((key, index) => {
      const item = rows[key];
      let column = {
        key: key,
        title: item.name,
        dataIndex: key,
        // width,
        align: "center",
      };

      if (item.name === "持股比例" || item.name === "注册资本") {
        // column.width = "90px";
        column.ellipsis = true;
      }
      if (item.name === "职位") {
        column.width = "70px";
      }
      if (item.name === "企业名称") {
        column.width = "115px";
      }
      if (item.name === "成立时间") {
        column.width = "110px";
      }
      if (item.name === "经营状态") {
        column.width = "80px";
      }
      column.render = (text, record, index) => {
        if (
          record[key]?.val === null ||
          record[key]?.val === undefined ||
          record[key]?.val === ""
        ) {
          return "-";
        }

        if (item.is_object) {
          return JSON.stringify(record[key]?.val);
        }
        if (item.need_millimeter) {
          //带单位
          if (item.join_field) {
            return (
              addMillimeterAndInit(record[key]?.val, 2) +
              record[item.join_field]?.val
            );
          }
          return addMillimeter(record[key]?.val, 2);
        }
        //转化为百分比
        if (item.need_percent) {
          return record[key]?.val
            ? (Number(record[key]?.val) * 100).toFixed(2) + "%"
            : "-";
        }
        //省略
        if (item.is_simple_show) {
          return (
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      lookUp(record[key]?.val, item);
                    }}
                  >
                    查看
                  </span>
                ),
              }}
            >
              {record[key]?.val}
            </Paragraph>
          );
        }
        //带上主体为范围
        if (item.is_show_related_parties) {
          if (record[key]?.val) {
            return (
              // record[key]?.val + "(" + record?.relatedPartiesType?.val + ")"
              <div>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      title: record[key]?.val,
                    },
                  }}
                  style={{ textAlign: "left" }}
                >
                  {record[key]?.val}
                </Paragraph>
                <div className="tagBox">
                  {(record?.relatedPartiesType?.val || []).map((tag) => {
                    // const len = (tag.length || 0) * 15;
                    return (
                      <Tooltip
                        key={Math.random()}
                        placement="top"
                        title={tag.full_name}
                        arrowPointAtCenter
                      >
                        <div>
                          <Tag
                            style={{
                              maxWidth: "100%",
                              whiteSpace: "normal",
                              flex: "none",
                            }}
                            key={Math.random()}
                          >
                            {tag.short_name}
                          </Tag>
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            );
          }
          return "-";
        }
        return record[key]?.val;
      };
      column.index = item.index + 1;
      return column;
    });
    //添加序号
    columns.unshift({
      index: 1,
      key: "序号",
      title: "序号",
      dataIndex: "序号",
      width: "60px",
      align: "center",
      render: (text, record, index) => <>{index + 1}</>,
    });
    columns = columns.sort((a, b) => a.index - b.index);
    return columns;
  };

  const getInfo = () => {
    setLoading(true);
    projectApi.getNaturalPersonInfo(projectId, params).then((res) => {
      console.log("获取自然人信息", res);
      if (res.code === 200) {
        setTableDate(res.data);
        setIsAllDataEmpty(res.data?.is_all_data_empty_flag);
      } else {
        message.error(res.message);
        setIsAllDataEmpty(false);
        handleCancel();
      }
      setLoading(false);
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <Provider store={store}>
      <Drawer
        title={personName || "自然人信息"}
        placement="right"
        width="600px"
        className="personInfoDrawer"
        onClose={handleCancel}
        visible={visible}
        push={false}
        closable={false}
        destroyOnClose={true}
        // maskClosable={false}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              handleCancel();
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                handleCancel();
              }}
              type="primary"
            >
              关闭
            </Button>
          </div>
        }
      >
        <div className="personInfoContent">
          {/* <div className="personInfoTitle">任职信息</div> */}
          {isAllDataEmpty ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MyEmpty description="暂无数据"></MyEmpty>
            </div>
          ) : (
            <div className="personInfoBox">
              {loading ? (
                <MySpin tip="信息正在获取中..."></MySpin>
              ) : (
                <div style={{ paddingBottom: "20px" }}>
                  <div className="shareholderContent">
                    <HeaderTitle
                      title="担任法定代表人"
                      // extra={
                      //   <div className="typeBox">
                      //     {["最新公示", "工商登记", "历史股东"].map((item) => (
                      //       <div
                      //         key={item}
                      //         className={
                      //           item === shareholderType
                      //             ? "typeItem activeType"
                      //             : "typeItem"
                      //         }
                      //         onClick={() => setShareholderType(item)}
                      //       >
                      //         {item}
                      //       </div>
                      //     ))}
                      //   </div>
                      // }
                    ></HeaderTitle>
                    <div className="dateBox">
                      更新时间：
                      {tableDate?.company_personnel_information
                        ?.data_update_time || "-"}
                    </div>
                    <Table
                      locale={{
                        emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
                      }}
                      rowKey={(record) => record.id.val}
                      className="myFormTable"
                      bordered={true}
                      pagination={false}
                      columns={initColumns(
                        tableDate?.company_personnel_information?.rows || {}
                      )}
                      dataSource={
                        tableDate?.company_personnel_information?.data || []
                      }
                    />
                  </div>

                  <div className="personnelContent">
                    <HeaderTitle title="担任股东"></HeaderTitle>
                    <div className="dateBox">
                      更新时间：
                      {tableDate?.company_investment_information
                        ?.data_update_time || "-"}
                    </div>
                    <Table
                      locale={{
                        emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
                      }}
                      rowKey={(record) => record.id.val}
                      className="myFormTable"
                      bordered={true}
                      pagination={false}
                      columns={initColumns(
                        tableDate?.company_investment_information?.rows || {}
                      )}
                      dataSource={
                        tableDate?.company_investment_information?.data || []
                      }
                    />
                  </div>

                  <div className="personnelContent">
                    <HeaderTitle title="担任董监高"></HeaderTitle>
                    <div className="dateBox">
                      更新时间：
                      {tableDate?.executive?.data_update_time || "-"}
                    </div>
                    <Table
                      locale={{
                        emptyText: <MyEmpty description="暂无数据"></MyEmpty>,
                      }}
                      rowKey={(record) => record.id.val}
                      className="myFormTable"
                      bordered={true}
                      pagination={false}
                      columns={initColumns(tableDate?.executive?.rows || {})}
                      dataSource={tableDate?.executive?.data || []}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </Provider>
  );
};
export default wrapper(PersonInfoDrawer);
