import "./index.scss";
import { useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { message, Tag, Tooltip, Typography } from "antd";
import MessageModal from "../../../../../components/MessageModal";
import { FormOutlined } from "@ant-design/icons";
import TrashSvg from "../../../../../assets/trash.svg";
import CaseDetailsDrawer from "../CaseDetailsDrawer";
import moment from "moment";
const { Text, Paragraph } = Typography;

const CaseCard = (props) => {
  const { caseDetailInfo, changeCaseDialogDrawer, changePagination } = props;
  const [caseDrawerVisible, setCaseDrawerVisible] = useState(false);
  const caseDetail = () => {
    setCaseDrawerVisible(true);
  };
  /* 删除项目 */
  const deleteItem = (item) => {
    MessageModal({
      type: "warning",
      description: "确定要删除司法案件吗？",
      title: "删除",
      onOk: () => {
        // ProjectApi.deleteProject(item.id).then((res) => {
        //   if (res.code === 200) {
        //     message.success(res.message);
        //     getData();
        //     changePagination(1);
        //   } else {
        //     message.error(res.message);
        //   }
        // });
      },
    });
  };
  // 修改司法案件
  const changeState = () => {
    changeCaseDialogDrawer();
  };
  const colorMap = useRef({
    刑事案件: {
      background: "rgba(108,174,255,0.15)",
      color: "#6CAEFF",
      border: "none",
    },
    民事案件: {
      background: "rgba(22,216,216,0.15)",
      color: "#16D8D8",
      border: "none",
    },
    执行类案件: {
      background: "rgba(255,162,1,0.149)",
      color: "#FFA201",
      border: "none",
    },
    已结案: {
      background: "rgba(97,212,173,0.149)",
      color: "#61D4AD",
      border: "none",
    },
    未结案: {
      background: "rgba(254,84,95,0.149)",
      color: "#FE545F",
      border: "none",
    },
  });

  return (
    <>
      <div className="caseCardContent" style={{ width: "calc(50% - 20px)" }}>
        <div className="tagsBox">
          <div>
            {caseDetailInfo.case_type?.map((type) => (
              <Tag
                style={
                  colorMap?.current[type]
                    ? colorMap?.current[type]
                    : {
                        color: "#006AB2",
                        background: "rgba(0,106,178,0.149)",
                        border: "none",
                      }
                }
                key={type}
              >
                {type}
              </Tag>
            ))}
            <Tag
              style={
                colorMap?.current[caseDetailInfo.case_state]
                  ? colorMap?.current[caseDetailInfo.case_state]
                  : {
                      color: "#A342B6",
                      background: "rgba(163,66,182,0.149)",
                      border: "none",
                    }
              }
            >
              {caseDetailInfo.case_state || "未知"}
            </Tag>
          </div>
          <div className="amountBox">
            金额{" "}
            <span>{Number(caseDetailInfo.case_amount || 0).toLocaleString()}</span>{" "}
            元
          </div>
        </div>

        <div className="infoValue" style={{ width: "100%" }}>
          <Paragraph
            ellipsis={{
              tooltip: {
                title: caseDetailInfo.title,
                overlayClassName: "sharesTip",
              },
              rows: 1,
              expandable: false,
            }}
            style={{
              width: `100%`,
              cursor: "pointer",
              marginBottom: 0,
            }}
            onClick={caseDetail}
          >
            <span className="title">{caseDetailInfo.title}</span>
          </Paragraph>

          <div className="item">
            <div className="label">案&emsp;&emsp;号</div>
            <Paragraph
              ellipsis={{
                tooltip: {
                  title: caseDetailInfo.related_case_no.join(","),
                  overlayClassName: "sharesTip",
                },
                rows: 1,
                expandable: false,
              }}
              style={{
                width: `calc(100% - 70px)`,
                marginBottom: 0,
              }}
            >
              <span className="value">
                {caseDetailInfo.related_case_no.join(",") || "-"}
              </span>
            </Paragraph>
          </div>

          <div className="item" style={{ width: "50%" }}>
            <div className="label">涉案主体</div>
            <Paragraph
              ellipsis={{
                tooltip: {
                  title: caseDetailInfo.case_parties.join(","),
                  overlayClassName: "sharesTip",
                },
                rows: 1,
                expandable: false,
              }}
              style={{
                width: `calc(100% - 70px)`,
                marginBottom: 0,
              }}
            >
              <span className="value">
                {caseDetailInfo.case_parties.join(",") || "-"}
              </span>
            </Paragraph>
          </div>
          <div className="item">
            <div className="label">案&emsp;&emsp;由</div>
            <Paragraph
              ellipsis={{
                tooltip: {
                  title: caseDetailInfo.case_reason,
                  overlayClassName: "sharesTip",
                },
                rows: 1,
                expandable: false,
              }}
              style={{
                width: `calc(100% - 70px)`,
                marginBottom: 0,
              }}
            >
              <span className="value">{caseDetailInfo.case_reason || "-"}</span>
            </Paragraph>
          </div>

          <div className="item">
            <div className="label" style={{ width: "100px" }}>
              最新审理程序日期
            </div>
            <div className="value">
              {caseDetailInfo.last_time === null
                ? ""
                : moment(new Date(caseDetailInfo.last_time)).format(
                    "YYYY-MM-DD"
                  )}
            </div>
          </div>
        </div>
        <div className="icon">
          {/* <div className="writeSvg">
            <Tooltip placement="top" title="编辑" arrowPointAtCenter>
              <FormOutlined
                style={{ color: "#0068B2", fontSize: "16px" }}
                onClick={changeState}
              />
            </Tooltip>
          </div>
          <div className="trashSvg">
            <Tooltip placement="top" title="删除" arrowPointAtCenter>
              <ReactSVG
                src={TrashSvg}
                onClick={() => deleteItem(caseDetailInfo)}
              ></ReactSVG>
            </Tooltip>
          </div> */}
        </div>
      </div>
      <CaseDetailsDrawer
        title="司法案件详情"
        visible={caseDrawerVisible}
        caseId={caseDetailInfo.case_id}
        onCancel={() => {
          setCaseDrawerVisible(false);
        }}
      ></CaseDetailsDrawer>
    </>
  );
};
export default CaseCard;
