import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import { Select, message, Popover, Tooltip } from "antd";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  addMillimeter,
  amountFormats,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import MyEmpty from "../../../../../../../components/Empty";
import { useSelector } from "react-redux";
import { useGetState } from "ahooks";
import YearSelect from "../../../YearSelect";
import moment from "moment";
import empPng from "../../../../../../../assets/empC.png";
import TableModal from "../../../TableModal";

const { Option } = Select;

const ShareProportion = (props) => {
  const { data, checkCompanyList, searchTableFunc, notHasData } = props;
  const { id } = useParams();
  const [noData, setNoData] = useState(false);
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectYear, setSelectYear, getSelectYear] = useGetState([]);
  const myChart = useRef(null);
  const myChart2 = useRef(null);
  const [downLoading, setDownLoading] = useState(false);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const [selectName, setSelectName, getSelectName] = useGetState(null);

  const [selectNoData, setSelectNoData, getSelectNoData] = useGetState(false);

  useEffect(() => {
    setSelectType(checkCompanyList);
    if (!checkCompanyList?.includes(getSelectName())) {
      setSelectName(null);
    }
    if (notHasData) {
      return;
    }
    setTimeout(() => {
      initECharts(getYearsObj()[getSelectYear()]);
    }, 100);
  }, [checkCompanyList]);

  const initCircleEchartsData = () => {
    const name = getSelectName();
    const chartData = getYearsObj()
      [getSelectYear()]?.filter((item) => {
        return item.bid_winner === name;
      })
      ?.sort((a, b) => Number(b.bid_winning_amo) - Number(a.bid_winning_amo));

    const top3 = chartData?.slice(0, 3);
    const other = chartData?.slice(3);
    const sum = top3?.reduce((a, b) => a + b.bid_winning_amo, 0) || 0;
    const otherSum = other?.reduce((a, b) => a + b.bid_winning_amo, 0) || 0;
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    if (otherSum === 0 && sum === 0) {
      setSelectNoData(true);
      return;
    } else {
      setSelectNoData(false);
    }
    setTimeout(() => {
      initCircleEcharts(sum, otherSum);
    }, 50);
  };

  const initCircleEcharts = (sum, otherSum) => {
    if (myChart2.current) {
      myChart2.current.dispose();
      myChart2.current = null;
    }
    const dom = document.getElementById("shareProportionBox2");
    if (!dom) {
      return;
    }
    myChart2.current = echarts.init(dom);

    const option = {
      // title: {
      //   text: name,
      //   left: "center",
      //   textStyle: {
      //     color: "#313131",
      //     fontWeight: "normal",
      //     fontSize: 16,
      //   },
      // },
      tooltip: {
        trigger: "item",
        confine: true,
        formatter: (a) => {
          return `<div class='pieChartTip'>
          <div class='leftBox'>
            <div>TOP3项目中标金额</div>
            <div>其他项目中标金额</div>
            <div>TOP3项目份额占比</div>
          </div>
          <div class='rightBox'>
            <div>${addMillimeter(a?.data?.sum, 2) + "元"}</div>
            <div>${addMillimeter(a?.data?.otherSum, 2) + "元"}</div>
            <div>${
              a?.data?.sum
                ? (
                    (a?.data?.sum / (a?.data?.sum + (a?.data?.otherSum || 0))) *
                    100
                  )?.toFixed(2) + "%"
                : "-"
            }</div>
          </div>
        </div>`;
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        top: "0px",
        selectedMode: false,
        show: false,
      },
      series: [
        {
          // name: "Access From",
          type: "pie",
          radius: ["50%", "80%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          // itemStyle: {
          //   borderRadius: 10,
          //   borderColor: "#fff",
          //   borderWidth: 2,
          // },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: sum,
              sum: sum,
              otherSum: otherSum,
              name: "TOP3项目中标金额",
              itemStyle: {
                color: "#61D4AD",
              },
            },
            {
              value: otherSum,
              sum: sum,
              otherSum: otherSum,
              name: "其他项目中标金额",
              itemStyle: {
                color: "#0068B2",
              },
            },
          ],
        },
      ],
    };
    myChart2.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart2.current && myChart2.current.resize();
    });
  };

  const initECharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    echartsData = getSelectType()?.map((item) => {
      let arr = echartsData.filter((_) => _.bid_winner === item);
      return arr
        ?.sort((a, b) => Number(b.bid_winning_amo) - Number(a.bid_winning_amo))
        ?.slice(0, 3);
    });
    let maxNum = 0;
    echartsData.forEach((item) => {
      item?.forEach((_item) => {
        if (_item.bid_winning_amo > maxNum) {
          maxNum = _item.bid_winning_amo;
        }
      });
    });
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("shareProportionBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        confine: true,
        formatter: (a, b, c) => {
          const item = echartsData?.at(a?.dataIndex).at(a?.seriesIndex);
          let name = item?.bid_winner;
          if(name.length > 30){
            name = name?.slice(0,30) + '...'
          }
          return `<div style='max-weight: 500px'>
            <div class='chartTipTitle'>TOP${a?.seriesIndex + 1}项目</div>
            <div class='chartTip'>
            <div class='leftBox'>
              <div>公告标题</div>
              <div>采购人</div>
              <div>供应商</div>
              <div>中标金额</div>
            </div>
            <div class='rightBox'>
              <div>${item?.anc_title}</div>
              <div>${item?.tenderee}</div>
              <div>${name}</div>
              <div>${addMillimeter(item?.bid_winning_amo, 2) + "元"}</div>
            </div>
            </div>
          </div>`;
        },
      },
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: false },
      //     magicType: { show: true, type: ['line', 'bar'] },
      //     restore: { show: true },
      //     saveAsImage: { show: true }
      //   }
      // },
      legend: {
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "bottom", //可设定图例在上、下、居中
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data: ["中标金额"],
        top: "10px",
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
      },
      xAxis: [
        {
          type: "category",
          data: getSelectType(),
          axisPointer: {
            type: "shadow",
            label:{
              formatter: (params) => {
                let str  = params.value
                if(str.length > 30){
                  str = str?.slice(0,30) + '...'
                }
                return str
              }
            }
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              return calcCompanyNameFunc(
                value,
                getSelectType()?.length,
                "shareProportionBox"
              );
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          // name: maxNum < 10000 ? "(元)" : "(万元)",
          // nameLocation: "end",
          // nameTextStyle: {
          //   align: "center",
          //   // padding: [0, 50, 0, 0],
          // },
          // nameGap: 10,
          // min: 0,
          max: maxNum <= 5 ? 5 : null,
          interval: maxNum <= 5 ? 1 : null,
          // interval: 50,
          axisLabel: {
            formatter: function (value, index) {
              // let num = parseInt(value / 10000).toLocaleString("en-US");
              // if (maxNum < 10000) {
              //   num = parseInt(value).toLocaleString("en-US");
              // }
              // return num;
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
        },
        // {
        //   type: "value",
        //   axisLabel: {
        //     formatter: "{value}%",
        //   },
        //   // name: '份额',
        //   min: 0,
        //   max: 100,
        //   // interval: 5,
        //   splitLine: {
        //     show: false,
        //     lineStyle: {
        //       type: "dashed",
        //     },
        //   },
        //   axisPointer: {
        //     show: false,
        //   },
        // },
      ],
      grid: {
        top: "20px",
        left: "0%",
        right: "0%",
        bottom: "10px",
        containLabel: true,
      },
      series: [
        {
          name: "项目1",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#6CAEFF",
            barBorderRadius: [4, 4, 0, 0],
          },
          data: echartsData.map((_) => _?.at(0)?.bid_winning_amo || 0),
        },
        {
          name: "项目2",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#AAD461",
            barBorderRadius: [4, 4, 0, 0],
          },
          data: echartsData.map((_) => _?.at(1)?.bid_winning_amo || 0),
        },
        {
          name: "项目3",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return addMillimeter(value, 2) + "元";
            },
          },
          itemStyle: {
            color: "#FEC054",
            barBorderRadius: [4, 4, 0, 0],
          },
          data: echartsData.map((_) => _?.at(2)?.bid_winning_amo || 0),
        },
      ],
    };
    myChart.current.setOption(option);
    if (!getSelectName()) {
      setSelectName(getSelectType()?.at(0));
    }
    setTimeout(() => {
      initCircleEchartsData();
    }, 50);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", function (params) {
      // setSelectName(params.name);
      // setTimeout(() => {
      //   initCircleEchartsData();
      // }, 50);
      const data = echartsData?.at(params?.dataIndex)?.at(params?.seriesIndex);
      let filterOptions = {
        供应商: params?.name,
        采购人: data?.tenderee,
        公告标题: data?.anc_title,
        年份: getSelectYear(),
      };
      TableModal({
        title: "中标详情",
        filterOptions,
        onOk: searchTableFunc,
        projectId: id,
      });
    });

    myChart.current.on("mouseover", function (params) {
      setSelectName(params.name);
      setTimeout(() => {
        initCircleEchartsData();
      }, 50);
    });
  };

  const initData = (data) => {
    let yearsObj = {};
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date].push(item);
      } else {
        yearsObj[item.anc_date] = [item];
      }
    });
    let keys = Object.keys(yearsObj);
    keys = keys.sort((a, b) => Number(b) - Number(a));
    const nowYear = moment(new Date())?.format("YYYY");
    setYearsObj(yearsObj);
    setSelectOptions(keys);
    setSelectYear(nowYear);
    initECharts(yearsObj[nowYear] || []);
  };

  useEffect(() => {
    if (notHasData) {
      return;
    }
    initData(data);
  }, [data]);

  useEffect(() => {
    setNoData(notHasData);
  }, [notHasData]);

  const downloadImg = () => {
    // myChart.current.dispose()
    let src = myChart.current.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    downloadBase64Img(src, "TOP3中标项目.png");
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "TOP3中标项目",
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  return (
    <div className="shareProportionContent">
      <div className="headerBox">
        TOP3中标项目
        {!noData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Select
              value={selectYear}
              style={{ minWidth: "100px", marginRight: "10px" }}
              onChange={(value) => {
                setSelectYear(value);
                initECharts(yearsObj[value]);
              }}
            >
              {selectOptions.map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}
            <YearSelect
              value={selectYear}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectYear(value?.at(0));
                setTimeout(() => {
                  initECharts(yearsObj[value?.at(0)]);
                }, 50);
              }}
              options={selectOptions?.sort((a, b) => Number(a) - Number(b))}
            ></YearSelect>

            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  />
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div className="shareProportionContentBox">
        {noData && <MyEmpty></MyEmpty>}
        {!noData && <div id="shareProportionBox"></div>}
        {!noData && (
          <div className="shareProportionBox2">
            <div className="shareProportionTitle">{selectName}</div>
            <div className="echartTipBox">
              <div>
                <i></i>TOP3项目中标金额
              </div>
              <div>
                <i></i>其他项目中标金额
              </div>
            </div>
            {getSelectNoData() ? (
              <div className="shareProportionBoxBg">
                <Popover
                  trigger="hover"
                  placement="topRight"
                  overlayClassName="empPopover"
                  content={<div>暂无数据</div>}
                >
                  <img src={empPng} alt="暂无数据" />
                </Popover>
              </div>
            ) : (
              <div id="shareProportionBox2"></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ShareProportion;
