import request from '../request';

//获取menu列表
function getMenus() {
    return request({
        url:  '/sys/permission/menu/tree/',
        method: 'get',
    })
}

//获取用户信息
function getUserInfo() {
    return request({
        url:  '/sys/user/info/',
        method: 'get',
    })
}

//获取菜单
function getMenuOfPersonal() {
    return request({
        url: '/sys/get_personal_menu',
        method: 'get',
    })
}

//用户退出
function logout(redirect) {
    return request({
        url:  `/sys/logout`,
        method: 'post',
        data: {
            redirect
        }
    })
}

//修改密码
function editPassword(data) {
    return request({
        url: `/sys/password/change`,
        method: 'post',
        data
    })
}

//用户登录
function login(ticket,redirect) {
    return request({
        url:  `/sys/login/`,
        method: 'get',
        params: {
            redirect,
            ticket
        }
    })
}

export default {
    getMenus,
    getUserInfo,
    logout,
    editPassword,
    login,
    getMenuOfPersonal
}