import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import { PropertySafetyOutlined, CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import MyDescriptions from "../../../components/MyDescriptions";
import MyDiv from "../../../components/MyDiv/index";
import { guid } from "../../../unit/unit.js";
import { useState, useEffect, useRef } from "react";
import { Button, Drawer } from "antd";
import MyTable from "../../../components/MyTable";

const HistoryBondBusinessInformationDrawer = (props) => {
  useEffect(() => {
  }, [props.visible]);

  return (
    <Drawer
      className="bondHistoryBusinessInformationDrawer"
      width="600px"
      closable={false}
      title="历史工商信息"
      placement="right"
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              props.onClose();
            }}
            type="primary"
          >
            关闭
          </Button>
        </div>
      }
    >
      <MyTable
        typeName={"历史工商信息"}
        dataTable={"company_change_record"}
        notOrder={true}
        isBond={true}
        notOperation={true}
        notShowDate={true}
        showQuickJumper={false}
        showSizeChanger={false}
      ></MyTable>
    </Drawer>
  );
};
const BondBusinessInformation = (props) => {
  const { id } = useParams();

  const defaultTableName = "company_basic_information";
  const typeName = "工商信息";

  const [showHeader, setShowHeader] = useState(true);
  const [dataInfo, setDataInfo] = useState({});
  const tableRef = useRef(null);

  const [visible, setVisible] = useState(false);

  const getHisBus = () => {
    setVisible(true);
  };

  return (
    <div className="bondBusinessInformationContent">
      {showHeader ? (
        <HeaderTitle
          title={
            <MyDiv
              info={{
                ...dataInfo,
                comments: dataInfo?.event_type_remark,
                id: null,
              }}
              dataTable={defaultTableName}
              typeName={typeName}
              getData={() => {
                tableRef.current?.getData();
                setShowHeader(false);
                setTimeout(() => {
                  setShowHeader(true);
                }, 100);
              }}
              mode={30}
              dataInfo={{}}
            >
              {typeName}
            </MyDiv>
          }
          extra={
            <div className="iconBox" onClick={() => getHisBus()}>
              <PropertySafetyOutlined />
              历史工商信息
            </div>
          }
        ></HeaderTitle>
      ) : null}

      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{ height: "calc(100% - 25px)", width: "100%" }}
      >
        <div className="infoBox">
          <MyDescriptions
            ref={tableRef}
            typeName={typeName}
            dataTable={defaultTableName}
            dataChange={setDataInfo}
            isBond={true}
          ></MyDescriptions>
        </div>
      </Scrollbars>

      <HistoryBondBusinessInformationDrawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      ></HistoryBondBusinessInformationDrawer>
    </div>
  );
};
export default BondBusinessInformation;
