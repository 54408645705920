import "./index.scss";
import { AutoComplete } from "antd";
import React, { useState, useRef, useMemo } from "react";
import debounce from "lodash/debounce";

const AutoSelect = ({
  debounceTimeout = 500,
  changeClear = false,
  placeholder,
  selectChange,
  options,
  ...props
}) => {
  const [autoOptions, setAutoOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const onSelect = (value) => {
    selectChange && selectChange(value);
    setTimeout(() => {
      ref.current.blur();
    }, 300);
  };

  const fetchOptions = (value) => {
    const list = options?.filter((_) => _.value?.includes(value)) || [];
    setLoading(false);
    setAutoOptions(list);
  };

  const loadOptions = (value) => {
    if (value?.length < 2) {
      setAutoOptions([]);
      return;
    }
    setLoading(true);
    fetchOptions(value);
  };

  const valueChange = (value) => {
    props.onChange && props.onChange(value);
    if (changeClear) {
      setAutoOptions([]);
    }
  };
  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);

  return (
    <AutoComplete
      {...props}
      loading={loading}
      ref={ref}
      options={autoOptions}
      placeholder={placeholder}
      onSelect={onSelect}
      onSearch={debounceFetcher}
      onChange={valueChange}
      onClear={() => {
        setAutoOptions([]);
      }}
      onFocus={(e) => {
        loadOptions(e.target.value);
      }}
      onBlur={() => {
        setLoading(false);
      }}
      style={{
        width: "100%",
      }}
    />
  );
};
export default AutoSelect;
