import { useEffect, useRef, useState } from "react";
import "./index.scss";
import * as echarts from "echarts";
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined,
  PayCircleFilled,
} from "@ant-design/icons";
import {
  addMillimeter,
  calcCompanyNameFunc,
  downloadBase64Img,
  saveFile,
} from "../../../../../../../unit/unit";
import { useParams } from "react-router-dom";
import projectApi from "../../../../../../../apis/project";
import { message, Select, Tooltip } from "antd";
import { useGetState } from "ahooks";
import MyEmpty from "../../../../../../../components/Empty";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import icon1 from "../../../../../../../assets/中标.svg";
import icon2 from "../../../../../../../assets/中标1.svg";
import moment from "moment";
import TableModal from "../../../TableModal";
import png from "../../../../../../../assets/logo-w.png";

const { Option } = Select;

const BidWinningSituation = (props) => {
  const { data, keyList, checkCompanyList, searchTableFunc, bidCompanyName } =
    props;
  const { id } = useParams();
  const [yearsObj, setYearsObj, getYearsObj] = useGetState({});
  const [noData, setNoData] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectType, setSelectType, getSelectType] = useGetState([]);
  const [chartType, setChartType, getChartType] = useGetState("金额");
  const [initType, setInitType, getInitType] = useGetState("按公司分组");

  const myChart = useRef(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const initECharts = (echartsData) => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    let countMin = 0,
      countMax = 0,
      amountMin = 0,
      amountMax = 0;
    for (const key in echartsData) {
      let _item = echartsData[key];
      _item?.forEach((item) => {
        if (item?.count > countMax) {
          countMax = item?.count;
        }
        if (item?.amount > amountMax) {
          amountMax = item?.amount;
        }
      });
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById("bidWinningSituationBox");
    if (!dom) {
      return;
    }
    myChart.current = echarts.init(dom);

    const initSeries = () => {
      let series = [];
      if (getInitType() === "按公司分组") {
        const colorList = [
          "#6CAEFF",
          "#AAD461",
          "#FEC054",
          "#FE545F",
          "#16D8D8",
        ];
        series = Object.keys(getYearsObj())?.map((item, index) => {
          return {
            name: item,
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return getChartType() === "金额"
                  ? addMillimeter(value, 2) + "元"
                  : value + "个";
              },
            },
            itemStyle: {
              color: colorList[index % 5],
              barBorderRadius: [4, 4, 0, 0],
            },
            data: getSelectType()?.map((_) => {
              const _item = echartsData[_]?.at(index);
              return getChartType() === "金额" ? _item?.amount : _item?.count;
            }),
          };
        });
      } else {
        series = [
          {
            name: "尽调公司",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return getChartType() === "金额"
                  ? addMillimeter(value, 2) + "元"
                  : value + "个";
              },
            },
            itemStyle: {
              color: "#0068B2",
              barBorderRadius: [4, 4, 0, 0],
            },
            data: echartsData["尽调公司"]?.map((item) =>
              getChartType() === "金额" ? item?.amount : item?.count
            ),
          },
          {
            name: "竞争对手",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return getChartType() === "金额"
                  ? addMillimeter(value, 2) + "元"
                  : value + "个";
              },
            },
            itemStyle: {
              color: "#61D4AD",
              barBorderRadius: [4, 4, 0, 0],
            },
            data: echartsData["竞争对手"]?.map((item) =>
              getChartType() === "金额" ? item?.amount : item?.count
            ),
          },
        ];
      }
      return series;
    };
    const series = initSeries();
    const max = getChartType() === "金额" ? amountMax : countMax;
    const option = {
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        itemHeight: 10,
        itemWidth: 10,
        icon: "circle",
        // orient: "vertical",
        x: "center", //可设定图例在左、右、居中
        y: "top", //可设定图例在上、下、居中
        top: "10px",
        // padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        data:
          getInitType() === "按公司分组"
            ? Object.keys(getYearsObj())
            : ["尽调公司", "竞争对手"],
      },
      xAxis: [
        {
          type: "category",
          data:
            getInitType() === "按公司分组"
              ? Object.keys(echartsData)
              : Object.keys(getYearsObj()),
          axisPointer: {
            type: "shadow",
            label: {
              formatter: (params) => {
                let str = params.value;
                if (str.length > 30) {
                  str = str?.slice(0, 30) + "...";
                }
                return str;
              },
            },
          },
          axisLabel: {
            interval: 0,
            // rotate: -15,
            formatter:
              getInitType() === "按公司分组"
                ? function (value) {
                    return calcCompanyNameFunc(
                      value,
                      getSelectType()?.length,
                      "bidWinningSituationBox"
                    );
                  }
                : undefined,
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      yAxis: [
        {
          type: "value",
          max: max <= 5 ? 5 : null,
          interval: max <= 5 ? 1 : null,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisPointer: {
            show: false,
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 100000000) {
                return value / 100000000 + "亿";
              } else if (value >= 10000) {
                return value / 10000 + "万";
              } else {
                return value;
              }
            },
          },
        },
      ],
      grid: {
        top: "50px",
        left: "0%",
        right: "0%",
        bottom: "0px",
        containLabel: true,
      },
      series: series,
    };
    myChart.current.setOption(option);

    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });

    myChart.current.on("click", (a) => {
      let isBid = true;
      let filterOptions = {};
      if (getInitType() === "按公司分组") {
        if (bidCompanyName === a?.name) {
          isBid = undefined;
        }
        filterOptions = {
          供应商: a?.name,
          年份: a?.seriesName,
        };
      } else {
        filterOptions = {
          年份: a?.name,
        };
        if (a?.seriesName === "尽调公司") {
          isBid = undefined;
          filterOptions["供应商"] = bidCompanyName;
        }
      }
      TableModal({
        title: "中标详情",
        filterOptions,
        isBid,
        onOk: searchTableFunc,
        projectId: id,
      });
    });
  };

  useEffect(() => {
    if (!checkCompanyList || checkCompanyList?.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
    setSelectType(checkCompanyList);
    setTimeout(() => {
      initEchartsData(getYearsObj(), checkCompanyList);
    }, 100);
  }, [checkCompanyList]);

  const initEchartsData = (yearsObj) => {
    const types = getSelectType();
    const echartsData = {};

    if (getInitType() === "按公司分组") {
      types.forEach((comName) => {
        let arr = [];
        Object.keys(yearsObj).forEach((year) => {
          let arr2 = yearsObj[year]?.filter(
            (item) => item.bid_winner === comName
          );
          let obj = {
            amount: 0,
            count: 0,
          };
          if (arr2?.length > 0) {
            obj.amount = arr2?.reduce((a, b) => a + b?.bid_winning_amo, 0);
            obj.count = arr2?.reduce((a, b) => a + b?.bidding_count, 0);
          }
          arr.push(obj);
        });

        echartsData[comName] = arr;
      });
    } else {
      ["尽调公司", "竞争对手"].forEach((type) => {
        let arr = [];
        Object.keys(yearsObj).forEach((year) => {
          let arr2 =
            type === "尽调公司"
              ? yearsObj[year]?.filter(
                  (item) => item.bid_winner === projectInfo.company_name
                )
              : yearsObj[year]?.filter(
                  (item) => item.bid_winner !== projectInfo.company_name
                );
          let obj = {
            amount: 0,
            count: 0,
          };
          if (arr2?.length > 0) {
            obj.amount = arr2?.reduce((a, b) => a + b?.bid_winning_amo, 0);
            obj.count = arr2?.reduce((a, b) => a + b?.bidding_count, 0);
          }
          arr.push(obj);
        });

        echartsData[type] = arr;
      });
    }
    initECharts(echartsData);
  };

  const initData = (data) => {
    let yearArr = [
      moment(new Date()).subtract(4, "years")?.format("YYYY"),
      moment(new Date()).subtract(3, "years")?.format("YYYY"),
      moment(new Date()).subtract(2, "years")?.format("YYYY"),
      moment(new Date()).subtract(1, "years")?.format("YYYY"),
      moment(new Date())?.format("YYYY"),
    ];

    let yearsObj = {};
    yearArr.forEach((year) => {
      yearsObj[year] = [];
    });

    let keyObj = {};
    data.forEach((item) => {
      if (yearsObj[item.anc_date]) {
        yearsObj[item.anc_date]?.push(item);
      } else {
        yearsObj[item.anc_date] = [JSON.parse(JSON.stringify(item))];
      }

      if (!keyObj[item.bid_winner]) {
        let index = keyList?.findIndex(
          (_) => _?.bid_winner === item.bid_winner
        );
        if (index !== null && index !== undefined) {
          index = index + 1;
        } else {
          index = 999;
        }
        keyObj[item.bid_winner] = index;
      }
    });
    setYearsObj(yearsObj);
    let objKeys = Object.keys(keyObj);
    objKeys = objKeys.sort((a, b) => Number(keyObj[a]) - Number(keyObj[b]));
    setSelectOptions(objKeys);
    // setSelectType(objKeys);
    initEchartsData(yearsObj, objKeys);
  };

  const downloadFile = () => {
    setDownLoading(true);
    projectApi
      .downloadBiddingAnalysisFile({
        project_id: id,
        event_name: "竞争对手历年中标情况",
        is_bid: 1,
      })
      .then((res) => {
        if (res.status === 200) {
          const fileName = window.decodeURI(
            res.headers["content-disposition"]?.split("''")?.at(1)
          );
          saveFile(res.data, fileName);
        } else {
          message.error("下载失败");
        }
        setDownLoading(false);
      });
  };
  useEffect(() => {
    initData(data || []);
  }, [data]);

  if (keyList?.length === 0) {
    return (
      <div className="bidWinningSituationContent">
        <div className="selectBox">
          <div
            style={{
              width: "24px",
              height: "18px",
              background: "#0068B2",
              borderRadius: "2px",
              marginRight: "8px",
            }}
          ></div>
          <div
            style={{
              width: "24px",
              height: "18px",
              // background: "#0068B2",
              borderRadius: "2px",
              border: "1px solid #D8D8D8",
            }}
          ></div>
        </div>
        <div className="headerBox">
          竞争对手历年中标情况
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "76px",
                height: "18px",
                background: "#0068B2",
                borderRadius: "2px",
                marginRight: "8px",
              }}
            ></div>
            <div
              style={{
                width: "100px",
                height: "18px",
                // background: "#0068B2",
                borderRadius: "2px",
                border: "1px solid #D8D8D8",
                marginRight: "16px",
              }}
            ></div>
            <Tooltip placement="top" title="下载" arrowPointAtCenter>
              <VerticalAlignBottomOutlined className="downloadIcon hoverIcon" />
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "calc(100% - 68px)",
            background: "#efefef",
            marginTop: "46px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ display: "block", height: "44px", width: "auto" }}
            src={png}
            alt="iWudao魔方全景查"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="bidWinningSituationContent">
      {!noData && (
        <div className="selectBox">
          <Tooltip placement="top" title="金额" arrowPointAtCenter>
            <div
              className={
                chartType === "金额"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "金额") {
                  return;
                }
                setChartType("金额");
                setTimeout(() => {
                  initEchartsData(yearsObj);
                }, 50);
              }}
            >
              <PayCircleFilled
                style={{
                  color: getChartType() === "金额" ? "#ffffff" : "#999999",
                }}
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" title="项目数" arrowPointAtCenter>
            <div
              className={
                chartType === "数量"
                  ? "selectItem activeSelectItem"
                  : "selectItem"
              }
              onClick={() => {
                if (getChartType() === "数量") {
                  return;
                }
                setChartType("数量");
                setTimeout(() => {
                  initEchartsData(yearsObj);
                }, 50);
              }}
            >
              <ReactSVG
                className="selectItemIcon"
                src={getChartType() === "数量" ? icon2 : icon1}
              ></ReactSVG>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="headerBox">
        竞争对手历年中标情况
        {!noData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Select
              // size='small'
              mode="multiple"
              maxTagCount="responsive"
              value={selectType}
              style={{ minWidth: "160px", marginRight: "10px" }}
              onChange={(value) => {
                if (value?.length === 0) {
                  return;
                }
                setSelectType(value);
                initEchartsData(yearsObj, value);
              }}
            >
              {selectOptions.map((item, index) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select> */}
            <div className="selectBox2">
              <div
                className={
                  initType === "按公司分组"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "按公司分组") {
                    return;
                  }
                  setInitType("按公司分组");
                  setTimeout(() => {
                    initEchartsData(yearsObj);
                  }, 50);
                }}
              >
                按公司分组
              </div>
              <div
                className={
                  initType === "按竞争对手合并"
                    ? "selectItem2 activeSelectItem2"
                    : "selectItem2"
                }
                onClick={() => {
                  if (getInitType() === "按竞争对手合并") {
                    return;
                  }
                  setInitType("按竞争对手合并");
                  setTimeout(() => {
                    initEchartsData(yearsObj);
                  }, 50);
                }}
              >
                按竞争对手合并
              </div>
            </div>
            {projectInfo?.project_permission_urls.includes(
              "CDD_PRJ_CDD_ITEM_EXPORT"
            ) &&
              (downLoading ? (
                <LoadingOutlined className="downloadIcon" />
              ) : (
                <Tooltip placement="top" title="下载" arrowPointAtCenter>
                  <VerticalAlignBottomOutlined
                    className="downloadIcon hoverIcon"
                    onClick={downloadFile}
                  />
                </Tooltip>
              ))}
          </div>
        )}
      </div>
      <div
        id="bidWinningSituationBox"
        style={{ display: noData ? "none" : "block" }}
      ></div>
      {noData && <MyEmpty></MyEmpty>}
    </div>
  );
};
export default BidWinningSituation;
