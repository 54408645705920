import "./index.scss";
import bondApi from "../../../../../apis/bond";
import { useParams } from "react-router-dom";
import {
  message,
  Button,
  Form,
  Input,
  Dropdown,
  Menu,
  notification,
  Tooltip,
  Drawer,
  DatePicker,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import G6 from "@antv/g6";
import peopleImg from "./people.png";
import comImg from "./com.png";
import icon3 from "./all.svg";
import icon4 from "./筛选.svg";
import {
  useUnmountedRef,
  useGetState,
  useFullscreen,
  useUnmount,
} from "ahooks";
import { toleranceValue } from "../../../../../setting";
import {
  VerticalAlignBottomOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import { getLicenseValid, initWrap } from "../../../../../unit/unit";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";
import _, { includes, parseInt } from "lodash";
import HeaderTitle from "../../../../../components/HeaderTitle";
import MyTextCheckBox from "../../../../../components/MyTextCheckBox";
import moment from "moment";
import regionJson from "../../../../../json/province.json";

const Charts = (props) => {
  const { setUploadDate } = props;
  const { id } = useParams();
  const unmountedRef = useUnmountedRef();

  const [showTip, setShowTip, getShowTip] = useGetState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);
  const [form] = Form.useForm();
  const [editNodes, setEditNodes] = useState([]);
  const [loading, setLoading, getLoading] = useGetState(false);
  const [maxLevel, setMaxLevel, getMaxLevel] = useGetState(0);
  const [hasController, setHasController, getHasController] =
    useGetState(false);
  const [expandOptions, setExpandOptions, getExpandOptions] = useGetState([]);

  const [percentWaring, setPercentWaring] = useState(false);

  const graph = useRef(null);

  const downloadGraph = useRef(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [percentProps, setPercentProps] = useState({});

  const ref = useRef(null);
  const [isFullscreen, { enterFullscreen, exitFullscreen, toggleFullscreen }] =
    useFullscreen(ref);

  const getDataTimer = useRef(null);

  const bondProjectInfo = useSelector((state) => state.BondProject.bondProjectInfo) || {};

  const selectKey = useRef(Math.random());
  const menu = (
    <Menu
      items={[
        {
          key: "当前页面",
          label: (
            <div
              onClick={() => {
                getLicenseValid(false, true).then((res) => {
                  if (res) {
                    downloadPicHandle("当前页面");
                  }
                });
              }}
            >
              当前页面
            </div>
          ),
        },
        {
          key: "疑似关联方",
          label: (
            <div
              onClick={() => {
                getLicenseValid(false, true).then((res) => {
                  if (res) {
                    downloadPicHandle("仅主要股东和实际控制人");
                  }
                });
              }}
            >
              仅主要股东和实际控制人
            </div>
          ),
        },
        // {
        //   key: "自定义",
        //   label: (
        //     <div
        //       onClick={() => {
        //         downloadPicHandle("自定义");
        //       }}
        //     >
        //       自定义
        //     </div>
        //   ),
        // },
      ]}
    />
  );

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filterLevel, setFilterLevel, getFilterLevel] = useGetState(2);
  const [filterDirection, setFilterDirection, getFilterDirection] =
    useGetState("全部");
  const [directionOptions, setDirectionOptions] = useState([
    {
      value: "全部",
      label: "双向展开",
    },
    {
      value: "top",
      label: "向上展开 (仅股东)",
    },
    {
      value: "bottom",
      label: "向下展开(仅投资)",
    },
  ]);
  const [
    filterEnterpriseStatus,
    setFilterEnterpriseStatus,
    getFilterEnterpriseStatus,
  ] = useGetState(["存续", "开业", "吊销", "注销", "迁出", "其他"]);
  const [enterpriseStatusOptions, setEnterpriseStatusOptions] = useState([
    {
      value: "存续",
      label: "存续",
      disabled: true,
    },
    {
      value: "开业",
      label: "开业",
    },
    {
      value: "吊销",
      label: "吊销",
    },
    {
      value: "注销",
      label: "注销",
    },
    {
      value: "迁出",
      label: "迁出",
    },
    {
      value: "其他",
      label: "其他",
    },
  ]);
  const [
    filterEnterpriseType,
    setFilterEnterpriseType,
    getFilterEnterpriseType,
  ] = useGetState([
    "集体企业及社会团体",
    "上市公司",
    "基金公司",
    "股份制有限公司（非上市）",
  ]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([
    {
      value: "集体企业及社会团体",
      label: "集体企业及社会团体",
      // disabled: true,
    },
    {
      value: "上市公司",
      label: "上市公司",
    },
    {
      value: "基金公司",
      label: "基金公司",
    },
    {
      value: "股份制有限公司（非上市）",
      label: "股份制有限公司（非上市）",
    },
    // {
    //   value: "其他",
    //   label: "其他",
    // },
  ]);
  const [
    minShareholdingRatio,
    setMinShareholdingRatio,
    getMinShareholdingRatio,
  ] = useGetState(null);
  const [
    maxShareholdingRatio,
    setMaxShareholdingRatio,
    getMaxShareholdingRatio,
  ] = useGetState(null);

  const [
    minRegisteredCapital,
    setMinRegisteredCapital,
    getMinRegisteredCapital,
  ] = useGetState(null);
  const [
    maxRegisteredCapital,
    setMaxRegisteredCapital,
    getMaxRegisteredCapital,
  ] = useGetState(null);
  const [registrationTime, setRegistrationTime, getRegistrationTime] =
    useGetState([]);
  const [
    registrationStartTime,
    setRegistrationStartTime,
    getRegistrationStartTime,
  ] = useGetState(null);
  const [registrationEndTime, setRegistrationEndTime, getRegistrationEndTime] =
    useGetState(null);
  const [filterRegion, setFilterRegion, getFilterRegion] = useGetState([
    "全部",
  ]);
  const [filterLoading, setFilterLoading, getFilterLoading] =
    useGetState(false);

  // 初次进入获取穿透数据接口 ==> 若穿透中则不能编辑,定时器轮训获取最新数据.
  const getDataFunc = (first) => {
    setLoading(true);
    bondApi
      .getPenetrationInfo(id)
      .then((res) => {
        // 拿到上一次的穿透规则 setRulesInfo()
        if (res.code !== 200) {
          message.error(res.message);
          return;
        }
        const { down, up, update_time } = res.data;
        setLoading(false);
        initData(up || {}, down || {}, true);
        setUploadDate(update_time);
      })
  };

  useEffect(() => {
    getDataFunc(true);
  }, []);

  //展开隐藏的节点
  const expandHideNodes = (node) => {
    //停止动画
    graph.current.set("animate", false);
    let item = graph.current.find("node", (item) => {
      const model = item?.getModel();
      return model.id === node.id;
    });
    let parentItem = graph.current.find("node", (item) => {
      const model = item?.getModel();
      return model._id === node.parent_id && model.id === node.parent_key;
    });

    const parentModel = parentItem?.getModel();
    const model = item?.getModel();
    const ellipsisNodeList = JSON.parse(
      JSON.stringify(model?.ellipsisNodeList || [])
    );
    // let children = parentModel.children.filter((_) => _.id !== node.id);
    // children = children.concat(ellipsisNodeList);
    let _children = parentModel._children.filter((_) => _.id !== node.id);
    _children = _children.concat(ellipsisNodeList);
    graph.current.removeChild(node.id);
    (ellipsisNodeList || [])?.forEach((node) => {
      delete node.isEllipsisNode;
      graph.current.addChild(node, parentItem);
    });

    parentItem.update({
      ...parentModel,
      _children: _children,
    });
    setTimeout(() => {
      graph.current.layout();
      graph.current.set("animate", true);
    }, 300);
  };

  // 格式化节点数据 并获取change有值的节点
  const initIdAndGetEditNodes = (
    list,
    direction,
    level,
    parent,
    filterParams
  ) => {
    let editNodes = [];
    let maxLevel = 0;
    let hasController = false;
    let remarkEditNodes = [];
    let concertedActionPersonNodes = [];

    const initId = (list, direction, level, parent) => {
      list = list.map((item, index) => {
        item.level = level;

        item._id = item.id;
        item._label = item.label;
        item.label = null;
        item.parent_key = parent?.id;
        item.parent_id = parent?._id;
        item.parent_type = parent?._label;

        // if (item.percent < 0.02 && level > 1) {
        //   item.isEllipsisNode = true;
        // }

        if (level > maxLevel) {
          maxLevel = level;
        }
        if (item.controller === 1) {
          hasController = true;
        }
        if (direction) {
          item.direction = direction;
        }
        if (!item.percent) {
          item.percent = 0;
        }
        item.id =
          (parent.id || "") + item.id + item._label + (item.direction || "");

        //判断是否隐藏
        //公司层面
        if (item._label === "Company") {
          //判断企业状态
          if (filterParams?.filterEnterpriseStatus?.length > 0) {
            let flag = false;
            filterParams?.filterEnterpriseStatus?.forEach((_) => {
              if ((item.company_status || "")?.includes(_)) {
                flag = true;
              }
            });
            if (!flag) {
              item.isEllipsisNode = true;
            }
          }

          //判断企业类型
          if (filterParams?.filterEnterpriseType?.length > 0) {
            let flag = false;
            filterParams?.filterEnterpriseType?.forEach((_) => {
              if ((item.company_type || "")?.includes(_)) {
                flag = true;
              }
            });
            if (!flag) {
              item.isEllipsisNode = true;
            }
          }

          //判断地区
          if (filterParams?.filterRegion?.length > 0) {
            if (!filterParams?.filterRegion?.includes("全部")) {
              let flag = false;
              filterParams?.filterRegion?.forEach((_) => {
                if ((item.company_base || "")?.includes(_)) {
                  flag = true;
                }
              });
              if (!flag) {
                item.isEllipsisNode = true;
              }
            }
          }

          //判断注册资本
          if (Number(filterParams?.minRegisteredCapital || 0)) {
            if (
              item.company_capital <
              Number(filterParams?.minRegisteredCapital) * 10000
            ) {
              item.isEllipsisNode = true;
            }
          }
          if (Number(filterParams?.maxRegisteredCapital || 0)) {
            if (
              item.company_capital >
              Number(filterParams?.maxRegisteredCapital) * 10000
            ) {
              item.isEllipsisNode = true;
            }
          }

          //判断注册时间
          if (filterParams?.registrationStartTime) {
            if (
              new Date(item.company_estiblish_date).getTime() <
              new Date(filterParams?.registrationStartTime).getTime()
            ) {
              item.isEllipsisNode = true;
            }
          }
          if (filterParams?.registrationEndTime) {
            if (
              new Date(item.company_estiblish_date).getTime() >
              new Date(filterParams?.registrationEndTime).getTime()
            ) {
              item.isEllipsisNode = true;
            }
          }
        }

        //判断持股比例
        if (Number(filterParams?.minShareholdingRatio || 0)) {
          if (
            Number(item.percent || 0) * 100 <
            Number(filterParams?.minShareholdingRatio)
          ) {
            item.isEllipsisNode = true;
          }
        }
        if (Number(filterParams?.maxShareholdingRatio || 0)) {
          if (
            Number(item.percent || 0) * 100 >
            Number(filterParams?.maxShareholdingRatio)
          ) {
            item.isEllipsisNode = true;
          }
        }

        //TODO
        if (item.children) {
          let nodes =
            initId(item.children, item.direction, level + 1, item) || [];
          const _children = nodes.filter(
            (_) => _.change !== "del" && !_.isEllipsisNode
          );

          if (level === 1) {
            const topEllipsisNodeList =
              nodes.filter(
                (_) =>
                  _.change !== "del" &&
                  _.isEllipsisNode &&
                  _.direction === "top"
              ) || [];
            const bottomEllipsisNodeList =
              nodes.filter(
                (_) =>
                  _.change !== "del" &&
                  _.isEllipsisNode &&
                  _.direction === "bottom"
              ) || [];

            if (topEllipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: "top",
                level: level + 1,
                ellipsisNodeList: JSON.parse(
                  JSON.stringify(topEllipsisNodeList)
                ),
                id: level + 1 + "" + item._id + "topEllipsisNode",
                children: [],
                label: "",
                percent: topEllipsisNodeList?.reduce(
                  (a, b) => b?.percent + a,
                  0
                ),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
            if (bottomEllipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: "bottom",
                level: level + 1,
                ellipsisNodeList: JSON.parse(
                  JSON.stringify(bottomEllipsisNodeList)
                ),
                id: level + 1 + "" + item._id + "bottomEllipsisNode",
                children: [],
                label: "",
                percent: bottomEllipsisNodeList?.reduce(
                  (a, b) => b?.percent + a,
                  0
                ),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
          } else {
            const ellipsisNodeList =
              nodes.filter((_) => _.change !== "del" && _.isEllipsisNode) || [];
            if (ellipsisNodeList?.length > 0) {
              _children.push({
                isEllipsisNode: true,
                direction: item.direction,
                name: "",
                level: level + 1,
                ellipsisNodeList: JSON.parse(JSON.stringify(ellipsisNodeList)),
                id: level + 1 + "" + item._id + item.direction + "EllipsisNode",
                children: [],
                label: "",
                percent: ellipsisNodeList?.reduce((a, b) => b?.percent + a, 0),
                parent_key: item.id,
                parent_id: item._id,
                parent_type: item._label,
                open: false,
              });
            }
          }
          item._children = JSON.parse(JSON.stringify(_children));
          //判断子集是否有 实际控制人
          if (
            item._children.some(
              (_) => _.controller === 1 || _.childrenHasController === true
            )
          ) {
            item.childrenHasController = true;
          }
          //展示层级
          if (filterParams?.filterLevel === "controller") {
            item.children =
              item._children.some(
                (_) => _.controller === 1 || _.childrenHasController === true
              ) || level <= 1
                ? item._children
                : [];
          } else {
            let _level =
              filterParams?.filterLevel === "全部"
                ? 100
                : filterParams?.filterLevel || 2;
            item.children = _level > level ? item._children : [];
          }
        }
        //展示方向
        if (filterParams?.filterDirection && item.level === 1) {
          if (filterParams?.filterDirection === "top") {
            item.children = item.children?.filter((_) => _.direction === "top");
          } else if (filterParams?.filterDirection === "bottom") {
            item.children = item.children?.filter(
              (_) => _.direction === "bottom"
            );
          }
        }

        item.open = item?.children?.length > 0;

        if (item.change) {
          item.isEdit = true;
          item.parent_cpercent = parent.cpercent || 0;
          const _item = JSON.parse(JSON.stringify(item));
          editNodes?.push(_item);
        }
        if (item.remark_change) {
          const __item = JSON.parse(JSON.stringify(item));
          remarkEditNodes?.push(__item);
        }
        if (item.group) {
          const __item = JSON.parse(JSON.stringify(item));
          concertedActionPersonNodes?.push(__item);
        }
        return item;
      });
      return list;
    };
    const nodes = initId(list, direction, level, parent);
    return {
      nodes,
      editNodes,
      maxLevel,
      hasController,
      remarkEditNodes,
      concertedActionPersonNodes,
    };
  };

  // 格式化穿透数据并渲染图表
  const initData = (
    shareholderData,
    investmentData,
    isRender,
    filterParams
  ) => {
    if (unmountedRef.current) {
      console.log("组件已经卸载");
      return;
    }
    let data = {
      level: 1,
      open: true,
    };
    let keys = Object.keys(shareholderData);
    if (keys.length === 0) {
      keys = Object.keys(investmentData);
    }
    keys.forEach((key) => {
      if (key !== "children") {
        data[key] = shareholderData[key] || investmentData[key];
      } else {
        let investmentChildren = (investmentData?.children || []).map((_) => {
          _.direction = "bottom";
          return _;
        });
        let shareholderChildren = (shareholderData?.children || []).map((_) => {
          _.direction = "top";
          return _;
        });
        data.children = [...investmentChildren, ...shareholderChildren];
      }
    });
    const {
      nodes,
      editNodes,
      maxLevel,
      hasController,
      remarkEditNodes,
      concertedActionPersonNodes,
    } = initIdAndGetEditNodes([data], null, 1, {}, filterParams);

    data = nodes?.at(0);
    setMaxLevel(maxLevel);
    setHasController(hasController);
    setEditNodes(editNodes);
    //需要渲染
    if (isRender) {
      if (keys.length !== 0) {
        initCharts(data);
      }
      if (getDataTimer.current) {
        clearInterval(getDataTimer.current);
        getDataTimer.current = null;
      }
      // getDataTimer.current = setInterval(() => {
      //   getNewData();
      // }, 5000);
    }
    return {
      data,
      editNodes,
      remarkEditNodes,
      concertedActionPersonNodes,
    };
  };

  //递归边 改变样式
  const edgeUpdateFunc = (node) => {
    const model = node.getModel();
    //截止到一级节点(尽调公司)
    if (model?.level === 1) {
      return;
    }
    const edges = node.getInEdges();
    edges.forEach((edge) => {
      graph.current.setItemState(edge, "hover", true);
      edge.toFront();
      const newNode = edge.getSource();
      edgeUpdateFunc(newNode);
    });
  };

  //边 添加 取消样式的方法
  const edgeActiveFunc = (node, flag) => {
    if (flag) {
      edgeUpdateFunc(node);
    } else {
      const allEdges = graph.current.getEdges();
      allEdges.forEach((edge) => {
        graph.current.setItemState(edge, "hover", false);
        edge.toBack();
      });
    }
  };

  // 渲染图表的函数
  const initCharts = (data, isDownLoad) => {
    if (unmountedRef.current) {
      console.log("组件已经卸载");
      return;
    }
    const tooltip = new G6.Tooltip({
      offsetX: 10,
      offsetY: 10,
      // the types of items that allow the tooltip show up
      // 允许出现 tooltip 的 item 类型
      itemTypes: ["node", "edge"],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e) => {
        const outDiv = document.createElement("div");
        outDiv.style.width = "fit-content";
        if (
          [
            // "编辑",
            // "添加",
            // "删除",
            "行动",
            // "批注列表",
            // "批注",
            "collapse-icon",
          ]?.includes(e.target?.cfg?.name)
        ) {
          let type = "";
          if (e.target?.cfg?.name === "编辑") {
            type = "修改";
          } else if (e.target?.cfg?.name === "添加") {
            type = "新增";
          } else if (e.target?.cfg?.name === "删除") {
            type = "删除";
          } else if (e.target?.cfg?.name === "批注列表") {
            type = "历史批注";
          } else if (e.target?.cfg?.name === "行动") {
            type = "一致行动人";
          } else if (e.target?.cfg?.name === "批注") {
            type = "批注";
          } else if (e.target?.cfg?.name === "collapse-icon") {
            let model = e.item?.getModel();
            type = model?.children?.length > 0 ? "收起" : "展开";
          }
          outDiv.innerHTML = `${type}`;
          return outDiv;
        }
        let model = e.item?.getModel();
        if (e.item.getType() === "edge") {
          let item = graph.current?.find("node", (item) => {
            const model = item?.getModel();
            return model.id === e.item?.getModel().target;
          });
          model = item?.getModel();
        }
        if (
          e.target?.cfg?.name === "action-circle" ||
          e.target?.cfg?.name === "action-text"
        ) {
          outDiv.style.margin = "0px";
          // outDiv.style.background = "#000";
          outDiv.userSelect = "none";
          outDiv.style.borderRadius = "4px";
          // outDiv.style.color = "#000";
          outDiv.style.padding = "4px";
          outDiv.style.display = "flex";
          outDiv.style.alignItems = "center";
          outDiv.innerHTML = `
          一致行动人组 <span style='margin-left:4px;display: flex;align-items: center;justify-content: center; width: 16px;height: 16px;border: 1px solid #313131;border-radius: 50%;font-size: 12px;font-weight: 700;transform:scale(0.8)'>${
            model.group
          }</span>（${
            (model?.gpercent || 0) * 100
              ? ((model?.gpercent || 0) * 100)?.toFixed(2)
              : 0
          }%）`;
          return outDiv;
        }

        //outDiv.style.padding = '0px 0px 20px 0px';
        outDiv.innerHTML = `
          <div>
            <li>${
              e.item.getType() === "edge" ? "持股比例" : "最终收益股份"
            } ${parseFloat(
          (
            Number(
              (e.item.getType() === "edge" ? model.percent : model.fpercent) ||
                0
            ) * 100
          ).toPrecision(14)
        )}%</li>
          </div>`;
        return outDiv;
      },
      shouldBegin: (e) => {
        const percent =
          e.target?.attrs?.text?.toString()?.replace(/[^\d.]/g, "") || 0;
        let res = false;
        switch (e.target.get("name")) {
          case "批注列表":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "批注":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "编辑":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "添加":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "删除":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "行动":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "collapse-icon":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;

          case "action-circle":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "action-text":
            res = e.target?.attrs?.opacity === 1 ? true : false;
            break;
          case "text1":
            res = e.target?.attrs?.text?.includes("-")
              ? false
              : !parseFloat(percent);
            break;
          case "percentText":
            res = !parseFloat(percent);
            break;
          default:
            res = false;
            break;
        }
        return res;
      },
    });

    const COLLAPSE_ICON = function COLLAPSE_ICON(x, y, r) {
      return [
        ["M", x - r, y],
        ["a", r, r, 0, 1, 0, r * 2, 0],
        ["a", r, r, 0, 1, 0, -r * 2, 0],
        ["M", x + 4, y],
        ["L", x - 4, y],
      ];
    };
    const EXPAND_ICON = function EXPAND_ICON(x, y, r) {
      return [
        ["M", x - r, y],
        ["a", r, r, 0, 1, 0, r * 2, 0],
        ["a", r, r, 0, 1, 0, -r * 2, 0],
        ["M", x + 4, y],
        ["L", x - 4, y],
        ["M", x, y + 4],
        ["L", x, y - 4],
      ];
    };
    G6.registerNode(
      "icon-node",
      {
        options: {
          size: [120, 20],
          stroke: "#91d5ff",
          fill: "#91d5ff",
        },
        draw(cfg, group) {
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;
          const notTop = cfg.direction === "bottom";
          const notRoot = cfg.level !== 1;
          const w = styles.width;
          const h = styles.height;
          if (!cfg.name) {
            cfg.name = "       ";
          }
          const keyShape = group.addShape("rect", {
            attrs: {
              ...styles,
              x: -w / 2,
              y: -h / 2,
              // stroke: "black",
            },
            name: "key-shape",
          });

          // 整体区域
          const rectGroup = group.addGroup({
            name: "rect-box",
          });

          if (cfg?.isEllipsisNode && notRoot) {
            rectGroup.addShape("circle", {
              attrs: {
                x: 0,
                y: (notTop ? 30 : 40) - h / 2,
                r: 20,
                fill: "#0068B2",
                cursor: "pointer",
              },
              name: "image-circle",
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 16,
                textAlign: "center",
                text: `+${cfg?.ellipsisNodeList?.length}`,
                y: notTop ? -16 : -6,
                cursor: "pointer",
              },
              name: "ellipsis-number",
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#000",
                fontSize: 14,
                textAlign: "center",
                text: `...`,
                x: 0,
                y: (notTop ? 67 : 77) - h / 2 + 5 + (notTop ? 10 : 0),
                cursor: "pointer",
              },
            });

            return keyShape;
          }
          rectGroup.addShape("rect", {
            attrs: {
              ...styles,
              fill: "#fff",
              stroke: "#fff",
              x: -w / 2,
              y: -h / 2,
            },
          });

          if (!isDownLoad) {
            if (notRoot) {
              //一级以为的元素 展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: notTop ? h / 2 - 6 : 15 - h / 2 + 2 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: cfg._children?.length > 0 ? "pointer" : null,
                  opacity: cfg._children?.length > 0 ? 1 : 0,
                  symbol:
                    cfg.children?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "collapse-icon",
              });
            } else {
              const topAllChildren = cfg._children?.filter(
                (_) => _.direction === "top"
              );
              const bottomAllChildren = cfg._children?.filter(
                (_) => _.direction === "bottom"
              );
              const topChildren = cfg.children?.filter(
                (_) => _.direction === "top"
              );
              const bottomChildren = cfg.children?.filter(
                (_) => _.direction === "bottom"
              );
              //一级元素 上半部分展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: 16 - h / 2 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: topAllChildren?.length > 0 ? "pointer" : null,
                  opacity: topAllChildren?.length > 0 ? 1 : 0,
                  symbol: topChildren?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "root-top-collapse-icon",
              });
              //一级元素 下半部分展开关闭按钮
              rectGroup.addShape("marker", {
                attrs: {
                  x: 0,
                  y: h / 2 - 5 - 6,
                  r: 6,
                  stroke: "#0068B2",
                  cursor: bottomAllChildren?.length > 0 ? "pointer" : null,
                  opacity: bottomAllChildren?.length > 0 ? 1 : 0,
                  symbol:
                    bottomChildren?.length > 0 ? COLLAPSE_ICON : EXPAND_ICON,
                },
                name: "root-bottom-collapse-icon",
              });
            }
          }

          //图像logo
          let imgFill = cfg._label === "Company" ? "#AAD461" : "#FEC054";
          if (cfg.controller === 1) {
            imgFill = "#FE545F";
          }
          // if (cfg.isEdit) {
          //   imgFill = "red";
          // }
          rectGroup.addShape("circle", {
            attrs: {
              name: "image-circle",
              x: 0,
              y: (notTop ? 30 : 42) - h / 2,
              r: notRoot ? 20 : 22,
              fill: notRoot ? imgFill : "#0068B2",
            },
            name: "image-circle",
          });
          rectGroup.addShape("image", {
            attrs: {
              x: notRoot ? -13 : -15,
              y: (notTop ? 16 : notRoot ? 26 : 25) - h / 2,
              width: notRoot ? 26 : 30,
              height: notRoot ? 26 : 30,
              img: cfg._label === "Company" ? comImg : peopleImg,
            },
            name: "image-shape",
          });
          //名称是否换行
          let isLineFeed = false;
          //公司名称
          if (cfg.name) {
            let max = 12;
            if (cfg.name?.length > 24) {
              max = Math.round(cfg.name?.length / 2);
            }
            isLineFeed = cfg.name?.length > max;
            const name = isLineFeed
              ? cfg.name?.slice(0, max) + "\n" + cfg.name?.slice(max)
              : cfg.name;
            const len = isLineFeed ? max : cfg.name?.length;
            if (notRoot) {
              rectGroup.addShape("text", {
                attrs: {
                  ...labelCfg.style,
                  text: name,
                  textAlign: "center",
                  x: 0,
                  y:
                    (notTop ? 67 : 77) -
                    h / 2 +
                    (isLineFeed ? 11 : 5) +
                    (notTop ? 10 : 0),
                },
              });
            } else {
              rectGroup.addShape("text", {
                attrs: {
                  fill: "#0068B2",
                  fontSize: 14,
                  text: cfg.name,
                  textAlign: "center",
                  x: 0,
                  y: 84 - h / 2,
                  // stroke: 'black'
                },
              });
            }
          }

          if (notRoot && !notTop) {
            //最终收益股份
            rectGroup.addShape("text", {
              attrs: {
                fill: "#0068B2",
                fontSize: 12,
                text:
                  cfg.fpercent === null || cfg.fpercent === undefined
                    ? "最终收益股份-%"
                    : `最终收益股份${Number(cfg.fpercent * 100).toFixed(2)}%`,
                x:
                  0 -
                  (cfg.fpercent === null || cfg.fpercent === undefined
                    ? 7
                    : 9) *
                    6,
                y: (notTop ? 85 : 95) - h / 2 + 10,
              },
              name: "text1",
            });
          }
          //一致行动人
          // const actionGroup = group.addGroup({
          //   name: "action-box",
          // });
          rectGroup.addShape("circle", {
            attrs: {
              name: "action-circle",
              x: 30,
              y: notTop ? -40 : -30,
              r: 7,
              stroke: cfg.controller === 1 ? "#FE545F" : "#0068B2",
              opacity: cfg.group ? 1 : 0,
              fill: "#fff",
            },
            name: "action-circle",
          });
          rectGroup.addShape("text", {
            attrs: {
              fill: cfg.controller === 1 ? "#FE545F" : "#0068B2",
              fontSize: 10,
              textAlign: "center",
              text: cfg.group,
              x: 30,
              y: notTop ? -35 : -25,
              opacity: cfg.group ? 1 : 0,
            },
            name: "action-text",
          });
          // 疑似实际控制人
          if (cfg.controller === 1) {
            const nameLen = cfg.name.length > 2 ? cfg.name.length : 3;
            rectGroup.addShape("rect", {
              attrs: {
                fill: "#6CAEFF",
                stroke: "#6CAEFF",
                radius: 2,
                zIndex: 999,
                x: -110,
                y: (notTop ? 32 : 42) - h / 2 - 15 + 5,
                width: 80,
                height: 15,
              },
            });
            rectGroup.addShape("polygon", {
              attrs: {
                points: [
                  [-30, (notTop ? 32 : 42) - h / 2 + 2 - 7 + 5],
                  [-30, (notTop ? 32 : 42) - h / 2 - 2 - 7 + 5],
                  [-30 + 6, (notTop ? 32 : 42) - h / 2 - 7 + 5],
                ],
                fill: "#6CAEFF",
              },
            });
            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 10,
                text: "疑似实际控制人",
                x: -110 + 5,
                y: (notTop ? 32 : 42) - h / 2 - 2 + 5,
              },
            });
          }
          //公司性质
          if (cfg.company_tag) {
            const max = 7;
            const _isLineFeed = cfg.company_tag?.length > max;
            const len = _isLineFeed ? max : cfg.company_tag?.length;
            const width = len * 12;
            const { str, line } = initWrap(cfg.company_tag, max);
            const company_tag = str;
            let _x = isLineFeed ? 75 : (cfg.name?.length / 2) * 12;
            if (_x < 24) {
              _x = 24;
            }
            rectGroup.addShape("rect", {
              attrs: {
                fill: "#FE545F",
                stroke: "#FE545F",
                radius: 2,
                x: _x,
                y:
                  (notTop ? 72 : 77) -
                  h / 2 -
                  (isLineFeed ? 35 : 30) -
                  (line - 1) * 15,
                width: width,
                height: line * 15,
              },
              zIndex: 11,
            });

            rectGroup.addShape("polygon", {
              attrs: {
                points: [
                  [
                    _x + 10 - 0.5,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 3 + 8,
                  ],
                  [
                    _x - 0.5 - 2,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 9 + 8,
                  ],
                  [
                    _x - 0.5,
                    (notTop ? 72 : 77) - h / 2 - (isLineFeed ? 35 : 30) + 3 + 8,
                  ],
                ],
                fill: "#FE545F",
              },
              zIndex: 11,
            });

            rectGroup.addShape("text", {
              attrs: {
                fill: "#fff",
                fontSize: 10,
                text: company_tag,
                x: _x + len,
                y:
                  (notTop ? 72 : 77) -
                  h / 2 -
                  (isLineFeed ? 35 : 30) +
                  line * 13 -
                  (line - 1) * 15,
              },
              zIndex: 11,
            });
          }

          //公司状态
          // if (cfg.name.includes("公司") && notRoot) {
          //   const max = 14;
          //   const isLineFeed = cfg.name?.length > max;
          //   const len = isLineFeed ? max : cfg.name?.length;

          //   const type = "已注销";
          //   const width = type.length * 15;
          //   rectGroup.addShape("rect", {
          //     attrs: {
          //       fill: "#FE545F",
          //       stroke: "#FE545F",
          //       radius: 2,
          //       x: (len / 2) * 6 + width,
          //       y: (notTop ? 67 : 77) - h / 2 - 30,
          //       width: width,
          //       height: 15,
          //     },
          //   });

          //   rectGroup.addShape("polygon", {
          //     attrs: {
          //       points: [
          //         [
          //           (len / 2) * 6 + width + 10 - 0.5,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 3 + 9,
          //         ],
          //         [
          //           (len / 2) * 6 + width - 0.5 - 2,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 9 + 9,
          //         ],
          //         [
          //           (len / 2) * 6 + width - 0.5,
          //           (notTop ? 67 : 77) - h / 2 - 30 + 3 + 9,
          //         ],
          //       ],
          //       fill: "#FE545F",
          //     },
          //   });

          //   rectGroup.addShape("text", {
          //     attrs: {
          //       fill: "#fff",
          //       fontSize: 10,
          //       text: type,
          //       x: (len / 2) * 6 + width + 5,
          //       y: (notTop ? 67 : 77) - h / 2 - 17,
          //     },
          //   });
          // }
          //股份合计不等于100%
          // if (notRoot) {
          rectGroup.addShape("rect", {
            attrs: {
              fill: "#FE545F",
              stroke: "#FE545F",
              radius: 2,
              x: notRoot ? 15 : 30,
              y: notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15,
              width: 110,
              height: 15,
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-rect",
          });
          rectGroup.addShape("polygon", {
            attrs: {
              points: [
                [
                  notRoot ? 15 : 30,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) + 2 + 7,
                ],
                [
                  notRoot ? 15 : 30,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) - 2 + 7,
                ],
                [
                  (notRoot ? 15 : 30) - 6,
                  (notTop ? h / 2 + 2 - 15 : 15 - h / 2 - 15) + 7,
                ],
              ],
              fill: "#FE545F",
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-polygon",
          });

          rectGroup.addShape("text", {
            attrs: {
              fill: "#fff",
              fontSize: 10,
              text: `当前股份合计${Number(cfg.cpercent * 100).toFixed(2)}%`,
              x: notRoot ? 20 : 35,
              y: notTop ? h / 2 + 2 - 2 : 15 - h / 2 - 2,
              opacity:
                cfg._children?.length > 0 &&
                (cfg.cpercent > 1 + toleranceValue ||
                  cfg.cpercent < 1 - toleranceValue) &&
                (cfg.direction === "top" || cfg.level === 1)
                  ? 1
                  : 0,
            },
            name: "shares-text",
          });

          rectGroup.sort();
          // }
          return keyShape;
        },
        update(cfg, node) {
          const model = node?.getModel();
          if (model.isEllipsisNode) {
            const edges = node.getInEdges();
            const keyShape = edges?.at(0)?.getKeyShape();
            keyShape?.attr("fill", "#A342B6");
            const percent = model?.ellipsisNodeList?.reduce(
              (a, b) => b?.percent + a,
              0
            );
            keyShape?.attr("text", Number(percent * 100).toFixed(2) + "%");

            const group = node.getContainer();
            const sharesRect = group.find(
              (e) => e.get("name") === "ellipsis-number"
            );
            sharesRect?.attr("text", `+${model?.ellipsisNodeList?.length}`);
            return;
          }
          if (model.level === 1) {
            const group = node.getContainer();
            const shareholderList = model.children?.filter(
              (_) => _.direction === "top"
            );
            if (
              shareholderList?.length > 0 &&
              (model.cpercent > 1 + toleranceValue ||
                model.cpercent < 1 - toleranceValue)
            ) {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 1);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 1);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr(
                "text",
                `当前股份合计${Number(model.cpercent * 100).toFixed(2)}%`
              );
              sharesText.attr("opacity", 1);
            } else {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 0);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 0);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr("opacity", 0);
            }
            return;
          }
          //一致行动人
          const _group = node.getContainer();
          const actionCircle = _group.find(
            (e) => e.get("name") === "action-circle"
          );
          const actionText = _group.find(
            (e) => e.get("name") === "action-text"
          );
          if (model.group) {
            if (actionText?.attr("text") !== model.group) {
              actionText?.attr("text", model.group);
            }
            if (actionCircle?.attr("opacity") === 0) {
              actionCircle?.attr("opacity", 1);
              actionText?.attr("opacity", 1);
            }
          } else {
            if (actionCircle?.attr("opacity") === 1) {
              actionCircle?.attr("opacity", 0);
              actionText?.attr("opacity", 0);
            }
          }
          //
          if (model.isEdit) {
            const edges = node.getInEdges();
            const keyShape = edges?.at(0)?.getKeyShape();
            keyShape?.attr("fill", "#A342B6");
            keyShape?.attr(
              "text",
              Number(model.percent * 100).toFixed(2) + "%"
            );
            // const container = node.getContainer();
            // const imageCircle = container.find(
            //   (_) => _?.attr("name") === "image-circle"
            // );
            // imageCircle?.attr("fill", "red");
          }
          if (model.children?.length > 0) {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon.attr("opacity", 1);
            icon.attr("cursor", "pointer");
            if (model.open) {
              icon.attr("symbol", COLLAPSE_ICON);
            } else {
              icon.attr("symbol", EXPAND_ICON);
            }
          }
          if (model.direction === "top") {
            const group = node.getContainer();
            if (
              (model.cpercent > 1 + toleranceValue ||
                model.cpercent < 1 - toleranceValue) &&
              model.cpercent > 0
            ) {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 1);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 1);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr(
                "text",
                `当前股份合计${Number(model.cpercent * 100).toFixed(2)}%`
              );
              sharesText.attr("opacity", 1);
            } else {
              const sharesRect = group.find(
                (e) => e.get("name") === "shares-rect"
              );
              sharesRect.attr("opacity", 0);
              const sharesPolygon = group.find(
                (e) => e.get("name") === "shares-polygon"
              );
              sharesPolygon.attr("opacity", 0);
              const sharesText = group.find(
                (e) => e.get("name") === "shares-text"
              );
              sharesText.attr("opacity", 0);
            }
          }
          if (model._children?.length === 0) {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon?.attr("opacity", 0);
            const sharesRect = group.find(
              (e) => e.get("name") === "shares-rect"
            );
            sharesRect?.attr("opacity", 0);
            const sharesPolygon = group.find(
              (e) => e.get("name") === "shares-polygon"
            );
            sharesPolygon?.attr("opacity", 0);
            const sharesText = group.find(
              (e) => e.get("name") === "shares-text"
            );
            sharesText?.attr("opacity", 0);
          } else {
            const group = node.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            icon.attr("opacity", 1);
            if (model.children?.length == 0) {
              icon.attr("symbol", EXPAND_ICON);
            } else {
              icon.attr("symbol", COLLAPSE_ICON);
            }
          }

          // console.log(model);
          if (
            model.level !== 1 &&
            !["add", "delete"].includes(model.change) &&
            model.remark_change
          ) {
            const group = node.getContainer();
            const sharesRect = group.find((e) => e.get("name") === "批注列表");
            if (!sharesRect) {
              return;
            }
            const has = sharesRect?.attr("opacity") === 1;
            if (model.remark !== has) {
              let list = ["编辑", "添加", "删除", "行动"]; // "批注列表", ,
              if (
                model._label !== "Company" ||
                model?.change === "add" ||
                model.stop ||
                model.level >= 11
              ) {
                list = ["编辑", "删除", "行动"];
              }
              list.push("批注");
              list.unshift("批注列表");
              list.forEach(async (_, index) => {
                if (!model.remark) {
                  index = index - 1;
                }
                const _sharesRect = group.find((e) => e.get("name") === _);
                await _sharesRect.attr("x", 25 + index * 15);
                if (_ === "批注列表") {
                  await _sharesRect.attr("opacity", model.remark ? 1 : 0);
                  await _sharesRect.attr(
                    "cursor",
                    model.remark ? "pointer" : "default"
                  );
                }
              });
              const iconRect = group.find(
                (e) => e.get("name") === "icon-bg-rect"
              );
              iconRect?.attr({
                cursor: "pointer",
                width: list?.length * 14 + 1,
              });
            }
          }
        },
        setState(name, value, item) {
          const group = item.get("group");
          const model = item.getModel();
          if (model.isEllipsisNode) {
            return;
          }
          if (name === "hover") {
            let iconList = ["编辑", "添加", "删除", "行动", "批注"];
            let len = 0;
            iconList.forEach((key) => {
              if (
                getLoading() &&
                ["编辑", "添加", "删除", "行动", "批注"].includes(key)
              ) {
                const img = group.find((e) => e.get("name") === key);
                img?.attr({
                  cursor: "default",
                });
                return;
              }
              const img = group.find((e) => e.get("name") === key);
              if (img) {
                len = len + 1;
              }
              img?.attr({
                cursor: "pointer",
                opacity: value ? 1 : 0,
              });
            });

            const listIcon = group.find((e) => e.get("name") === "批注列表");
            if (listIcon && listIcon?.attr("opacity") === 1) {
              len = len + 1;
            }

            const iconRect = group.find(
              (e) => e.get("name") === "icon-bg-rect"
            );
            iconRect?.attr({
              cursor: "pointer",
              width: len * 14 + 1,
              opacity: value ? 1 : 0,
            });
          }
        },
      },
      "rect"
    );

    G6.registerEdge("flow-line", {
      draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;
        // if (
        //   endPoint?.includes("EllipsisNode") ||
        //   startPoint?.includes("EllipsisNode")
        // ) {
        //   console.log(cfg);
        // }
        const targetModel = cfg.targetNode.getModel();
        // const sourceModel = cfg.sourceNode.getModel();
        const notTop = targetModel.direction === "bottom";
        // const isRoot = sourceModel.level === 1;
        endPoint.y = endPoint.y + (notTop ? -55 : 55);
        const { style } = cfg;
        let shape = null;
        shape = group.addShape("path", {
          attrs: {
            stroke: style.stroke,
            endArrow: style.endArrow,
            label: "triangleRect arrow",
            path: [
              ["M", startPoint.x, startPoint.y + (notTop ? 66 : -63)],
              [
                "L",
                startPoint.x,
                (startPoint.y + endPoint.y) / 2 + (notTop ? 20 : -20),
              ],
              [
                "L",
                endPoint.x,
                (startPoint.y + endPoint.y) / 2 + (notTop ? 20 : -20),
              ],
              ["L", endPoint.x, endPoint.y],
            ],
          },
          name: "flowLinePath",
        });
        if (targetModel.percent !== null && targetModel.percent !== undefined) {
          shape = group.addShape("text", {
            attrs: {
              fill: targetModel.isEdit ? "#A342B6" : "#6CAEFF",
              fontSize: 12,
              text: Number(targetModel.percent * 100).toFixed(2) + "%",
              x: endPoint.x + 5,
              y: endPoint.y - (notTop ? 25 : -40),
            },
            name: "percentText",
          });
        }
        return shape;
      },
      setState(name, value, item) {
        const group = item.get("group");
        if (name === "hover") {
          const path = group.find((e) => e.get("name") === "flowLinePath");
          path?.attr({
            stroke: value ? "#006AB2" : "#D8D8D8",
            lineWidth: value ? 2 : 1,
            endArrow: {
              fill: value ? "#006AB2" : "#D8D8D8",
              path: G6.Arrow.circle(5, 2),
              d: 2,
            },
          });
        }
      },
    });

    const defaultStateStyles = {
      hover: {
        stroke: "#1890ff",
        lineWidth: 2,
      },
    };

    const defaultNodeStyle = {
      // fill: "#91d5ff",
      // stroke: "#40a9ff",
      radius: 5,
    };

    const defaultEdgeStyle = {
      stroke: "#D8D8D8",
      endArrow: {
        fill: "#D8D8D8",
        path: G6.Arrow.circle(5, 2),
        d: 2,
      },
    };

    const defaultLayout = {
      type: "compactBox",
      direction: "V",
      getSide: function getSide(d) {
        if (d.data.direction === "bottom") return "right";
        return "left";
      },
      getId: function getId(d) {
        return d.id;
      },
      getHeight: function getHeight() {
        return 110;
      },
      getWidth: function getWidth() {
        return 120;
      },
      getVGap: function getVGap() {
        return 40;
      },
      getHGap: function getHGap() {
        return 70;
      },
    };

    const defaultLabelCfg = {
      style: {
        fill: "#000",
        fontSize: 12,
      },
    };

    const container = document.documentElement.querySelector(
      isDownLoad ? "#downloadPicCanvasBox" : "#stockRightCharts"
    );
    const width = container.scrollWidth - 10;
    const height = container.scrollHeight - 10;
    if (!isDownLoad) {
      if (graph.current) {
        graph.current.destroy();
        graph.current = null;
      }
      graph.current = new G6.TreeGraph({
        container: "stockRightCharts",
        width,
        height,
        linkCenter: true,
        animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
        animateCfg: {
          duration: 300, // Number，一次动画的时长
          easing: "linearEasing", // String，动画函数
        },
        // animate: false,
        modes: {
          default: ["drag-canvas", "zoom-canvas"],
        },
        defaultNode: {
          type: "icon-node",
          size: [200, 110],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "flow-line",
          style: defaultEdgeStyle,
        },
        nodeStateStyles: defaultStateStyles,
        edgeStateStyles: defaultStateStyles,
        layout: defaultLayout,
        plugins: [tooltip],
      });
      graph.current.data(data);
      graph.current.render();
      graph.current.fitView();

      graph.current.on("rect-box:mouseenter", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item } = evt;
        graph.current.setItemState(item, "hover", true);
        edgeActiveFunc(item, true);
      });

      graph.current.on("rect-box:mouseleave", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item } = evt;
        graph.current.setItemState(item, "hover", false);
        edgeActiveFunc(item, false);
      });

      graph.current.on("node:click", (evt) => {
        if (getShowTip()) {
          return;
        }
        const { item, target } = evt;
        const model = item.getModel();
        //展开隐藏
        if (model?.isEllipsisNode && model.level !== 1) {
          expandHideNodes(model);
          return;
        }
        const targetType = target.get("type");
        const name = target.get("name");
        // 增加元素
        if (targetType === "marker") {
          const model = item.getModel();
          if (name === "collapse-icon") {
            const group = item.getContainer();
            const icon = group.find((e) => e.get("name") === "collapse-icon");
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            if (model.open) {
              graph.current.updateChildren([], model.id);
              item.update({
                ...model,
                open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              graph.current.updateChildren(model._children, model.id);
              item.update({
                ...model,
                open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
          if (name === "root-top-collapse-icon") {
            const group = item.getContainer();
            const icon = group.find(
              (e) => e.get("name") === "root-top-collapse-icon"
            );
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            const topChildren =
              model?.children?.filter((_) => _.direction === "top") || [];
            if (topChildren?.length > 0) {
              const otherChildren =
                model?.children?.filter((_) => _.direction === "bottom") || [];
              graph.current.updateChildren(otherChildren, model.id);
              item.update({
                ...model,
                // open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              const otherChildren =
                model?._children?.filter((_) => _.direction === "top") || [];

              graph.current.updateChildren(
                [...model.children, ...otherChildren],
                model.id
              );
              item.update({
                ...model,
                // open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
          if (name === "root-bottom-collapse-icon") {
            const group = item.getContainer();
            const icon = group.find(
              (e) => e.get("name") === "root-bottom-collapse-icon"
            );
            const opacity = icon.attr("opacity");
            if (!opacity) {
              return;
            }
            const bottomChildren =
              model?.children?.filter((_) => _.direction === "bottom") || [];
            if (bottomChildren?.length > 0) {
              const otherChildren =
                model?.children?.filter((_) => _.direction === "top") || [];
              graph.current.updateChildren(otherChildren, model.id);
              item.update({
                ...model,
                // open: false,
                // children: [],
              });
              icon.attr("symbol", EXPAND_ICON);
            } else {
              const otherChildren =
                model?._children?.filter((_) => _.direction === "bottom") || [];

              graph.current.updateChildren(
                [...model.children, ...otherChildren],
                model.id
              );
              item.update({
                ...model,
                // open: true,
                // children: model._children,
              });
              icon.attr("symbol", COLLAPSE_ICON);
            }
            graph.current.setItemState(item, "hover", true);
          }
        }
      });
      graph.current.on("canvas:click", (evt) => {
        setShowTip(false);
      });
      if (typeof window !== "undefined")
        window.onresize = () => {
          if (!graph.current || graph.current?.get("destroyed")) return;
          const container = document.getElementById("stockRightCharts");
          if (!container || !container.clientWidth || !container.clientHeight)
            return;
          graph.current.changeSize(
            container.clientWidth - 10,
            container.clientHeight - 10
          );
        };
    } else {
      downloadGraph.current = new G6.TreeGraph({
        container: "downloadPicCanvasBox",
        width,
        height,
        linkCenter: true,
        animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
        animateCfg: {
          duration: 300, // Number，一次动画的时长
          easing: "linearEasing", // String，动画函数
        },
        // animate: false,
        modes: {
          default: ["drag-canvas", "zoom-canvas"],
        },
        defaultNode: {
          type: "icon-node",
          size: [200, 110],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "flow-line",
          style: defaultEdgeStyle,
        },
        nodeStateStyles: defaultStateStyles,
        edgeStateStyles: defaultStateStyles,
        layout: defaultLayout,
      });
      downloadGraph.current.data(data);
      downloadGraph.current.render();
    }
  };

  useEffect(() => {
    if (!isModalVisible) {
      form?.resetFields();
    }
    setPercentWaring(false);
  }, [isModalVisible]);

  useEffect(() => {
    if (
      percentWaring &&
      choiceItem.cpercent !== null &&
      choiceItem.cpercent !== undefined
    ) {
      setPercentProps({
        validateStatus: "warning",
        help: `当前已录入股份合计${Number(choiceItem.cpercent * 100).toFixed(
          2
        )}%`,
      });
    } else {
      setPercentProps({});
    }
  }, [percentWaring]);

  // 下载数据格式化
  const initDownLoadData = (data) => {
    data = JSON.parse(JSON.stringify(data));
    let size = 0;
    const addChildren = (item) => {
      if (item._children.length > 0) {
        item._children = item._children.map((_) => addChildren(_));
      }
      item.children = item._children;
      size += item.children.length || 0;
      return item;
    };
    data = addChildren(data);
    return {
      data,
      size,
    };
  };

  //导出图片
  const downloadPicHandle = async (type) => {
    setDownloadLoading(true);
    let downloadData = [];
    let nodesNum = 0;
    if (type === "当前页面") {
      downloadData = graph.current.save();
      nodesNum = graph.current.getNodes()?.length;
    } else if (type === "仅主要股东和实际控制人") {
      await bondApi.getPenetrationInfo(id).then((res) => {
        const { up, down, update_time } = res.data;
        const { data, editNodes } = initData(up || {}, down || {}, false, {
          filterLevel: "controller",
        });
        downloadData = {
          ...data,
          children: data.children.filter((_) => _.direction === "top"),
        };
        nodesNum = 151;
      });
    } else if (type === "自定义") {
      const { data, size } = initDownLoadData(graph.current.save());
      downloadData = data;
      nodesNum = size;
    }
    await initCharts(downloadData, true);
    let zoomToSize = 2;
    if (nodesNum > 1000) {
      zoomToSize = 0.05;
    } else if (nodesNum > 600) {
      zoomToSize = 0.1;
    } else if (nodesNum > 400) {
      zoomToSize = 0.25;
    } else if (nodesNum > 250) {
      zoomToSize = 0.3;
    } else if (nodesNum > 150) {
      zoomToSize = 0.4;
    } else if (nodesNum > 100) {
      zoomToSize = 1;
    } else if (nodesNum > 60) {
      zoomToSize = 1.2;
    }
    if (nodesNum > 250) {
      notification.warning({
        message: "提示",
        description: "当前下载的节点过多,图片可能不清晰!",
      });
    }
    console.log(`下载图片=========>节点数量:${nodesNum}缩放比例:${zoomToSize}`);
    downloadGraph.current.zoomTo(zoomToSize);
    downloadGraph.current.get("canvas").set("pixelRatio", 2);
    setTimeout(async () => {
      const a = await downloadGraph.current.downloadFullImage(
        `${bondProjectInfo?.issuer_name}股权关系`,
        "image/png",
        {
          backgroundColor: "#fff",
          padding: [15, 15, 15, 15],
        }
      );
      await downloadGraph.current.destroy();
      downloadGraph.current = null;
      setDownloadLoading(false);
    }, 300);
  };

  useEffect(() => {
    initExpandOption();
  }, [maxLevel, hasController]);

  //初始化展开选项
  const initExpandOption = () => {
    selectKey.current = Math.random();
    if (getMaxLevel() === 2) {
      setExpandOptions([
        {
          label: "全部",
          value: "全部",
        },
        {
          label: "一级",
          value: 2,
        },
      ]);
      return;
    }
    let arr = [
      { label: "一级", value: 2 },
      { label: "二级", value: 3 },
      { label: "三级", value: 4 },
      { label: "四级", value: 5 },
      { label: "五级", value: 6 },
      { label: "六级", value: 7 },
      { label: "七级", value: 8 },
      { label: "八级", value: 9 },
      { label: "九级", value: 10 },
      { label: "十级", value: 11 },
    ];
    arr = arr.filter((_) => _.value <= getMaxLevel());
    if (getHasController()) {
      arr.push({ label: "实际控制人", value: "controller" });
    }
    arr.unshift({ label: "全部", value: "全部" });
    setExpandOptions(arr);
  };

  const handleFilter = () => {
    if (getMinShareholdingRatio() && getMaxShareholdingRatio()) {
      if (
        Number(getMaxShareholdingRatio()) < Number(getMinShareholdingRatio())
      ) {
        message.info("持股比例最大值不能小于最小值");
        return;
      }
    }
    if (getMinRegisteredCapital() && getMaxRegisteredCapital()) {
      if (
        Number(getMaxRegisteredCapital()) < Number(getMinRegisteredCapital())
      ) {
        message.info("注册资本最大值不能小于最小值");
        return;
      }
    }
    if (getRegistrationStartTime() && getRegistrationEndTime()) {
      if (
        new Date(getRegistrationEndTime())?.getTime() <
        new Date(getRegistrationStartTime())?.getTime()
      ) {
        message.info("注册结束时间不能小于开始时间");
        return;
      }
    }
    setFilterLoading(true);
    bondApi.getPenetrationInfo(id).then((res) => {
      const { up, down, update_time } = res.data;
      const params = {
        filterLevel: getFilterLevel(),
        filterDirection: getFilterDirection(),
        filterEnterpriseStatus: getFilterEnterpriseStatus(),
        filterEnterpriseType: [...getFilterEnterpriseType(), "其他"],
        minShareholdingRatio: getMinShareholdingRatio(),
        maxShareholdingRatio: getMaxShareholdingRatio(),
        minRegisteredCapital: getMinRegisteredCapital(),
        maxRegisteredCapital: getMaxRegisteredCapital(),
        // registrationTime: getRegistrationTime(),
        registrationStartTime: getRegistrationStartTime(),
        registrationEndTime: getRegistrationEndTime(),
        filterRegion: getFilterRegion(),
      };
      const { data, editNodes } = initData(up || {}, down || {}, false, params);
      graph.current.changeData(data);
      graph.current.fitView();
      setUploadDate(update_time);
      setFilterLoading(false);
      setFilterModalVisible(false);
    });
  };

  const handleFilterCancel = () => {
    setFilterModalVisible(false);
  };

  const clearFilter = () => {
    setFilterLevel(2);
    setFilterDirection("全部");
    setFilterEnterpriseStatus(["存续", "开业", "吊销", "注销", "迁出", "其他"]);
    setFilterEnterpriseType([
      "集体企业及社会团体",
      "上市公司",
      "基金公司",
      "股份制有限公司（非上市）",
    ]);
    setMinShareholdingRatio(null);
    setMaxShareholdingRatio(null);
    setMinRegisteredCapital(null);
    setMaxRegisteredCapital(null);
    setRegistrationTime([]);
    setRegistrationStartTime(null);
    setRegistrationEndTime(null);
    setFilterRegion(["全部"]);
  };

  return (
    <div className="charts" id="stockRightCharts" ref={ref}>
      <Drawer
        title={"股权关系筛选"}
        placement="right"
        width="600px"
        visible={filterModalVisible}
        push={false}
        closable={false}
        onOk={handleFilter}
        onClose={handleFilterCancel}
        className="filterDrawer"
        getContainer={isFullscreen ? false : document.body}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              handleFilterCancel();
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <div className="btnBox11">
              <Button
                type="primary"
                loading={filterLoading}
                onClick={() => {
                  handleFilter();
                }}
              >
                确定
              </Button>
              <Button
                className="noBg"
                onClick={() => {
                  handleFilterCancel();
                }}
              >
                取消
              </Button>
            </div>
            <Button
              type="primary"
              loading={filterLoading}
              style={{}}
              onClick={() => {
                clearFilter();
              }}
            >
              恢复默认
            </Button>
          </div>
        }
      >
        <div className="formContent">
          {/* 展示层级 */}
          <div className="formItemBox">
            <HeaderTitle title="展示层级"></HeaderTitle>
            <div>
              <MyTextCheckBox
                value={filterLevel}
                options={getExpandOptions()}
                onChange={(value) => {
                  setFilterLevel(value);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 展示方向 */}
          <div className="formItemBox">
            <HeaderTitle title="展示方向"></HeaderTitle>
            <div>
              <MyTextCheckBox
                value={filterDirection}
                options={directionOptions}
                onChange={(value) => {
                  setFilterDirection(value);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 企业状态 */}
          <div className="formItemBox">
            <HeaderTitle title="企业状态"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterEnterpriseStatus}
                options={enterpriseStatusOptions}
                onChange={(value) => {
                  setFilterEnterpriseStatus([...new Set([...value, "存续"])]);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 企业类型 */}
          <div className="formItemBox">
            <HeaderTitle title="企业类型"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterEnterpriseType}
                options={enterpriseTypeOptions}
                onChange={(value) => {
                  setFilterEnterpriseType([...new Set([...value])]);
                }}
              ></MyTextCheckBox>
            </div>
          </div>
          {/* 持股比例 */}
          <div className="formItemBox">
            <HeaderTitle title="持股比例"></HeaderTitle>
            <div className="formInputItemBox">
              <Input
                min={0}
                max={100}
                placeholder="最小值"
                style={{ width: "200px", marginRight: "10px" }}
                value={minShareholdingRatio}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMinShareholdingRatio(null);
                    return;
                  }
                  let min = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = min?.split(".");
                  int = parseInt(int || 0) + "";
                  if (Number(min) >= 100) {
                    int = "100";
                    setMinShareholdingRatio(int);
                    return;
                  }
                  if (decimal?.length > 2) {
                    decimal = decimal?.slice(0, 2);
                  }

                  min =
                    int +
                    (decimal ? "." + decimal : min.includes(".") ? "." : "");

                  setMinShareholdingRatio(min);
                }}
              />
              <div className="unitBox">%</div>
              <span className="separateSpan">~</span>
              <Input
                min={0}
                max={100}
                placeholder="最大值"
                style={{ width: "200px", marginRight: "10px" }}
                value={maxShareholdingRatio}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMaxShareholdingRatio(null);
                    return;
                  }
                  let max = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = max?.split(".");
                  int = parseInt(int || 0) + "";
                  if (Number(max) >= 100) {
                    int = "100";
                    setMaxShareholdingRatio(int);
                    return;
                  }
                  if (decimal?.length > 2) {
                    decimal = decimal?.slice(0, 2);
                  }

                  max =
                    int +
                    (decimal ? "." + decimal : max.includes(".") ? "." : "");
                  setMaxShareholdingRatio(max);
                }}
              />
              <div className="unitBox">%</div>
            </div>
          </div>
          {/* 注册资本 */}
          <div className="formItemBox">
            <HeaderTitle title="注册资本"></HeaderTitle>
            <div className="formInputItemBox">
              <Input
                min={0}
                placeholder="最小值"
                style={{ width: "200px", marginRight: "10px" }}
                value={minRegisteredCapital}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMinRegisteredCapital(null);
                    return;
                  }
                  let min = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = min?.split(".");
                  int = parseInt(int || 0) + "";
                  if (int?.length > 10) {
                    int = int?.slice(0, 10);
                  }
                  if (decimal?.length > 6) {
                    decimal = decimal?.slice(0, 6);
                  }

                  min =
                    int +
                    (decimal ? "." + decimal : min.includes(".") ? "." : "");
                  setMinRegisteredCapital(min);
                }}
              />
              <div className="unitBox">万元</div>
              <span className="separateSpan">~</span>
              <Input
                min={0}
                placeholder="最大值"
                style={{ width: "200px", marginRight: "10px" }}
                value={maxRegisteredCapital}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setMaxRegisteredCapital(null);
                    return;
                  }
                  let max = e.target.value
                    .replace(/[^\d\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  let [int, decimal] = max?.split(".");
                  int = parseInt(int || 0) + "";
                  if (int?.length > 10) {
                    int = int?.slice(0, 10);
                  }
                  if (decimal?.length > 6) {
                    decimal = decimal?.slice(0, 6);
                  }
                  max =
                    int +
                    (decimal ? "." + decimal : max.includes(".") ? "." : "");
                  setMaxRegisteredCapital(max);
                }}
              />
              <div className="unitBox">万元 </div>
            </div>
          </div>
          {/* 注册时间 */}
          <div className="formItemBox">
            <HeaderTitle title="注册时间"></HeaderTitle>
            <div className="formInputItemBox">
              <DatePicker
                onChange={(date) => {
                  setRegistrationStartTime(date);
                }}
                placeholder="开始时间"
                value={registrationStartTime}
                style={{ width: "215px", marginRight: "10px" }}
                disabledDate={(currentDate) => {
                  let max = moment(new Date());
                  if (getRegistrationEndTime()) {
                    max = moment.min(
                      moment(new Date()),
                      moment(getRegistrationEndTime())
                    );
                  }
                  return moment(currentDate).diff(moment(max)) > 0;
                }}
              />
              <span className="separateSpan">~</span>
              <DatePicker
                onChange={(date) => {
                  setRegistrationEndTime(date);
                }}
                disabledDate={(currentDate) => {
                  let max = moment(new Date());
                  if (getRegistrationStartTime()) {
                    return (
                      moment(currentDate).diff(moment(max)) > 0 ||
                      moment(currentDate).diff(
                        moment(getRegistrationStartTime())
                      ) < 0
                    );
                  }
                  return moment(currentDate).diff(moment(max)) > 0;
                }}
                placeholder="结束时间"
                value={registrationEndTime}
                style={{ width: "215px", marginRight: "10px" }}
              />
              {/* <MyRangePicker
                allowClear={true}
                format="YYYY-MM-DD"
                style={{ width: "486px" }}
                value={registrationTime.map((_) => {
                  if (_) {
                    return moment(_);
                  }
                  return null;
                })}
                onChange={(date, dateString) => {
                  setRegistrationTime(dateString);
                }}
              ></MyRangePicker> */}
            </div>
          </div>
          {/* 地区 */}
          <div className="formItemBox">
            <HeaderTitle title="地区"></HeaderTitle>
            <div>
              <MyTextCheckBox
                multiple={true}
                value={filterRegion}
                options={[{ label: "全部", value: "全部" }, ...regionJson]}
                onClick={(value) => {
                  setFilterRegion((prev) => {
                    if (value === "全部") {
                      return ["全部"];
                    } else {
                      let list = prev;
                      if (list?.includes("全部")) {
                        list = list?.filter((_) => _ !== "全部");
                      }
                      if (list?.includes(value)) {
                        list = list?.filter((_) => _ !== value);
                      } else {
                        list = [...list, value];
                      }
                      if (list?.length === 0) {
                        list = [value];
                      }
                      return [...list];
                    }
                  });
                }}
              ></MyTextCheckBox>
            </div>
          </div>
        </div>
      </Drawer>

      {!loading && (
        <div
          className="chartHandleBox"
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          {/* {getExpandOptions()?.length > 0 && (
          <div className="openBox">
            展开
            <Select
              style={{
                width: "calc(100% - 40px)",
                marginLeft: "5px",
              }}
              placeholder="请选择"
              // allowClear
              onChange={(value) => {
                expandChange(value);
              }}
              key={selectKey.current}
              defaultValue={2}
              getPopupContainer={() =>
                document.documentElement.querySelector("#stockRightCharts")
              }
            >
              {getExpandOptions().map((_) => (
                <Option key={_.label} value={_.value}>
                  {_.label}
                </Option>
              ))}
            </Select>
          </div>
        )} */}

          <ReactSVG
            className="allIcon"
            src={icon4}
            onClick={() => {
              setFilterModalVisible(true);
            }}
          ></ReactSVG>

          <ReactSVG
            className="allIcon"
            src={icon3}
            onClick={toggleFullscreen}
          ></ReactSVG>

          {/* <VerticalAlignBottomOutlined
          className="downIcon hoverIcon"
          onClick={() => {
            downloadPicHandle();
          }}
        /> */}

          {downloadLoading ? (
            <LoadingOutlined className="downIcon" />
          ) : (
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              overlayClassName="dropdownItemA"
            >
              <div>
                <VerticalAlignBottomOutlined
                  style={{ cursor: "pointer" }}
                  className="downIcon hoverIcon"
                />
              </div>
            </Dropdown>
          )}
        </div>
      )}

      {loading ? (
        <div className="firstLoadingBox">
          <MySpin></MySpin>
        </div>
      ) : null}

      <div id="downloadPicCanvasBox"></div>
    </div>
  );
};
export default Charts;
