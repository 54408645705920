import "./index.scss";
import img1 from "../../assets/404_images/404.png";
import img2 from "../../assets/404_images/404_cloud.png";
import { useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import { useEffect } from "react";

const NotFound = () => {
  const Navigate = useNavigate();
  useEffect(() => {
    nProgress.done();
    return () => nProgress.start();
  });
  const goHome = () => {
    Navigate("/");
  };

  return (
    <div className="wscn-http404-container">
      <div className="wscn-http404">
        <div className="pic-404">
          <img className="pic-404__parent" src={img1} alt="404" />
          <img className="pic-404__child left" src={img2} alt="404" />
          <img className="pic-404__child mid" src={img2} alt="404" />
          <img className="pic-404__child right" src={img2} alt="404" />
        </div>
        <div className="bullshit">
          <div className="bullshit__oops">您访问的页面不存在!</div>
          <div className="bullshit__headline">请点击下方的返回，返回到首页</div>
          <div className="bullshit__info">
            当我们浏览网页时，服务器无法提供该网页信息或该网页不存在，且不知道原因所返回的页面。通常产生404错误是由于该目标网页被删除或不存在，导致访问出错后所显示的页面.
          </div>
          <div onClick={goHome} className="bullshit__return-home">
            返回首页
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
