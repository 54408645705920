import "./index.scss";

const MyTextCheckBox = (props) => {
  const { value, options, multiple, onChange, onClick } = props;

  return (
    <div className="myTextCheckBox">
      {options?.map((item) => (
        <div
          key={item.value}
          className={
            (multiple ? value?.includes(item.value) : value === item.value)
              ? "TextCheckItem activeTextCheckItem"
              : "TextCheckItem"
          }
          style={{ cursor: item?.disabled ? "no-drop" : "pointer" }}
          onClick={() => {
            if (multiple) {
              if (value?.includes(item.value)) {
                onChange && onChange(value?.filter((_) => _ !== item.value));
              } else {
                onChange && onChange([...value, item.value]);
              }
            } else {
              onChange && onChange(item.value);
            }
            onClick && onClick(item.value);
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default MyTextCheckBox;
